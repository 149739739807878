import React from "react";
import { useQuickstart } from "../../useQuickstartStore";
import { EditableMarkdown } from "../../EditableMarkdown";
import { Paper } from "@mui/material";
import { wgetQuickstartCode } from "../../poc-constants";
import {
  QuickstartViewCloseButton,
  QuickstartViewNextButton,
} from "../components/QuickstartViewControls";
import { useCloseStepViewOnEscape } from "../useCloseStepViewOnEscape";

export const GettingQuickstartView = () => {
  const allValues = useQuickstart((state) => state.allValues());

  useCloseStepViewOnEscape();

  return (
    <Paper
      elevation={1}
      className="flex-col-container"
      style={{
        borderRadius: 10,
        width: "100%",
        height: "100%",
        padding: 16,
        overflowY: "auto",
      }}
    >
      <EditableMarkdown
        markdown={`## Download the quickstart

Run the following command from a terminal in your EC2 instance:`}
        id="getting-quickstart-header"
      />

      <EditableMarkdown
        markdown={`~~~bash
${wgetQuickstartCode(allValues.apiToken)}
~~~`}
        id="create-instance-footer"
      />

      <EditableMarkdown
        markdown={`Extract the downloaded archive:

~~~bash
tar -xzvf quickstart.tar.gz
ls -l
~~~

If successful, you will see a list of files including \`install.sh\``}
        id="create-instance-footer"
      />

      <QuickstartViewNextButton />
      <QuickstartViewCloseButton />
    </Paper>
  );
};
