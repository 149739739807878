// @ts-check
import { API } from "aws-amplify";

/**
 *
 * @param {{ email: string }} fileInfo
 * @returns {Promise<{type: "success", token: string } | {type: "error", reason: string}>}
 */
export const resetTokenApi = async ({ email }) => {
  const apiName = "token";
  const path = `/api/v1/token/reset?email=${encodeURIComponent(email)}`;
  const myInit = {
    headers: {},
  };

  try {
    const response = await API.put(apiName, path, myInit);

    console.log("Reset token API call succeeded");

    return {
      type: "success",
      token: response,
    };
  } catch (error) {
    console.error("Reset token API call failed", JSON.stringify(error));

    return {
      type: "error",
      reason: `We were unable to reset the API token. Please try again. If this does not work, please contact Anjuna at support@anjuna.io.`,
    };
  }
};
