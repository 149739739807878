import React from "react";
import { Toolbar } from "@mui/material";
import { AccountButton } from "./account/AccountButton";
import anjuna from "../assets/anjuna.svg";
import { useAppStage } from "../context/AppStageContext";
import { AccountBanner } from "./AccountBanner";
import { TemplatesHeader } from "../pages/ResourceCenter/POC/TemplatesHeader";

export const PageHeader = ({ children }: React.PropsWithChildren<{}>) => {
  const [{ stage }] = useAppStage();

  return (
    <div style={{ width: "100%" }}>
      <Toolbar sx={{ width: "100%", padding: "0 !important" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            boxShadow:
              "rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px",
            zIndex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              padding: "4px 24px",
            }}
          >
            <img src={anjuna} alt="Anjuna" />
            <div style={{ width: 20 }} />
            {children}
            {(stage as any).user ? <AccountButton /> : null}
          </div>
          <TemplatesHeader />
          <AccountBanner />
        </div>
      </Toolbar>
    </div>
  );
};
