import React from "react";
import { CircularProgress, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    height: "80vh",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export function CenteredCircularProgress() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
}
