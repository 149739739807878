import * as THREE from "three";
import {
  Axis,
  CompositeMeshNode,
  CompositeMeshNodeInfo,
  CompositeMeshNodeVM,
  ConnectedTo,
  ConnectionLabel,
  ConstrainPosTo,
  ConstrainSizeTo,
  Direction,
  MeshGeom,
  MeshGeomLocation,
  Side,
} from "./composite-mesh-types";
import { createCylinderLine } from "./PulsingCubicBezier";

const calcNextPos = (
  geom: MeshGeom,
  prevPos: THREE.Vector3,
  prevSize: THREE.Vector3,
  dir: Direction,
  size: THREE.Vector3,
  gaps: THREE.Vector3,
  status: "collapsed" | undefined
): THREE.Vector3 => {
  const strides = prevSize.clone().add(gaps);

  if (status === "collapsed") return prevPos.clone();

  const start = prevPos.clone().add(geom.offset || new THREE.Vector3());

  switch (dir) {
    case "centered":
      return start.add(
        new THREE.Vector3(-strides.x / 2, -strides.y / 2, -strides.z / 2)
      );
    case "top":
      return start.add(new THREE.Vector3(0, 0, strides.z));
    case "bottom":
      return start.add(new THREE.Vector3(0, 0, -strides.z));
    case "front":
      return start.add(new THREE.Vector3(0, -strides.y, 0));
    case "back":
      return start.add(new THREE.Vector3(0, strides.y, 0));
    case "left":
      return start.add(new THREE.Vector3(-strides.x, 0, 0));
    case "right":
      return start.add(new THREE.Vector3(strides.x, 0, 0));
  }
};

export const sizeOf = (
  node: Pick<CompositeMeshNodeVM, "geom" | "status"> & {
    size?: THREE.Vector3;
  },
  forLayout: boolean = true
): THREE.Vector3 => {
  if (node.status === "collapsed" && forLayout)
    return new THREE.Vector3(0, 0, 0);

  const minSize = node.geom.minSize || new THREE.Vector3();
  const defaultSize = (node.size?.clone() || new THREE.Vector3(1, 1, 1)).max(
    minSize
  );

  switch (node.geom.kind) {
    case "null":
      // return new THREE.Vector3(0, 0, 0);
      return defaultSize;
    case "substrate":
      return new THREE.Vector3(
        defaultSize.x,
        defaultSize.y,
        node.geom.thickness === "bounds of children"
          ? defaultSize.z
          : node.geom.thickness
      );
    case "box":
      return node.geom.size || defaultSize;
    case "cylinder":
      return new THREE.Vector3(
        2 * (node.geom.radius || defaultSize.x),
        2 * (node.geom.radius || defaultSize.y),
        node.geom.height || defaultSize.z
      );
  }
};

const gapsOf = (
  node: Pick<CompositeMeshNode, "gaps" | "geom">
): THREE.Vector3 => node.gaps?.clone() || new THREE.Vector3(0, 0, 0);

const borderOf = (
  node: Pick<CompositeMeshNode, "border" | "geom">
): THREE.Vector3 => node.border?.clone() || new THREE.Vector3(1, 1, 0.2);

const boundsOf = (
  positions: THREE.Vector3[],
  sizes: THREE.Vector3[]
): { min: THREE.Vector3; max: THREE.Vector3 } => {
  const left = Math.min(...positions.map((pos) => pos.x));
  const right = Math.max(...positions.map((pos, i) => pos.x + sizes[i].x));
  const front = Math.min(...positions.map((pos) => pos.y));
  const back = Math.max(...positions.map((pos, i) => pos.y + sizes[i].y));
  const top = Math.max(...positions.map((pos, i) => pos.z + sizes[i].z));
  const bottom = Math.min(...positions.map((pos) => pos.z));

  return {
    min: new THREE.Vector3(left, front, bottom),
    max: new THREE.Vector3(right, back, top),
  };
};

const centerAlignAlongAxis = (
  axis: Axis,
  containerSize: THREE.Vector3,
  itemSize: THREE.Vector3,
  itemPosition: THREE.Vector3
): THREE.Vector3 => {
  const offset = new THREE.Vector3();
  switch (axis) {
    case "x":
      offset.y = (containerSize.y - itemSize.y) / 2;
      break;
    case "y":
      offset.x = (containerSize.x - itemSize.x) / 2;
      break;
    case "z":
      offset.z = -(containerSize.z - itemSize.z) / 2;
      break;
  }

  return itemPosition.clone().add(offset);
};

const calcChildrenSizesAndPositions = (
  children: CompositeMeshNodeVM["children"],
  gaps: THREE.Vector3,
  border: THREE.Vector3,
  alignAxis?: Axis,
  minSize: THREE.Vector3 = new THREE.Vector3(0, 0, 0)
): {
  size: THREE.Vector3;
  position: THREE.Vector3;
  constrainSizeTo: ConstrainSizeTo | undefined;
  constrainPosTo: ConstrainPosTo | undefined;
}[] => {
  let prevChildPos = new THREE.Vector3(0, 0, border.z);
  let prevChildSize = new THREE.Vector3(0, 0, 0);
  const sizesAndPositions = children.map((child, i) => {
    const childPos =
      i === 0
        ? prevChildPos
        : calcNextPos(
            child.geom,
            prevChildPos,
            prevChildSize,
            child.dir,
            sizeOf(child),
            gaps,
            child.status
          );
    prevChildPos = childPos;
    prevChildSize = sizeOf(child);
    return {
      position: childPos
        .clone()
        .add(child.geom.offset || new THREE.Vector3())
        .add(border),
      size: prevChildSize,
      constrainSizeTo: child.constrainSizeTo,
      constrainPosTo: child.constrainPosTo,
    };
  });

  const layoutAffectingChildren = sizesAndPositions.filter(
    ({ constrainPosTo }) => constrainPosTo === undefined
  );

  const boundsOfChildren = boundsOf(
    layoutAffectingChildren.map((c) => c.position),
    layoutAffectingChildren.map((c) => c.size)
  );

  const childrenExtent = boundsOfChildren.max.clone().sub(boundsOfChildren.min);

  const balancedMin = minSize.clone().sub(childrenExtent).multiplyScalar(0.5);
  const offset = balancedMin
    .sub(boundsOfChildren.min)
    .max(new THREE.Vector3(0, 0, 0));

  return sizesAndPositions.map(({ size, position, ...rest }) => ({
    size,
    position: alignAxis
      ? centerAlignAlongAxis(
          alignAxis,
          boundsOfChildren.max.clone().sub(boundsOfChildren.min),
          size,
          position.clone().add(offset)
        )
      : position,
    ...rest,
  }));
};

const deepestDescendantDepthOf = (
  node: Pick<CompositeMeshNodeVM, "depth" | "children">
): number => {
  if (node.children.length === 0) {
    return node.depth;
  }

  return Math.max(
    ...node.children.map(
      (child, index) => deepestDescendantDepthOf(child) //+ index
    )
  );
};

export const assembleCompositeMeshVM = (
  node: CompositeMeshNode,
  depth: number = 0,
  parentDir?: Direction
): CompositeMeshNodeVM => {
  const dir = node.dir || parentDir;
  const visibleChildren = node.children.filter(
    (child) => child.status !== "collapsed"
  );

  if (visibleChildren.length === 0 || node.status === "collapsed") {
    const size = sizeOf(node);
    return {
      ...node,
      dir,
      pos: new THREE.Vector3(),
      size,
      depth,
      deepestDescendantDepth: depth,
      children: [],
    };
  }

  const children = visibleChildren.map((child, index) =>
    assembleCompositeMeshVM(
      child,
      depth + index + (node.geom.kind === "null" ? 0 : 1),
      dir
    )
  );

  const sizesAndPositions = calcChildrenSizesAndPositions(
    children,
    gapsOf(node),
    borderOf(node),
    node.axisToAlignChildrenAlong,
    node.geom.minSize
  );

  const layoutAffectingChildren = sizesAndPositions.filter(
    ({ constrainPosTo }) => constrainPosTo === undefined
  );

  const bounds = boundsOf(
    layoutAffectingChildren.map((c) => c.position),
    layoutAffectingChildren.map((c) => c.size)
  );

  const maxChildSize = layoutAffectingChildren.reduce(
    (max, { size }) => max.max(size),
    new THREE.Vector3(0, 0, 0)
  );

  const extentOfChildren = bounds.max.clone().sub(bounds.min);

  // const centerOfChildren = bounds.min
  //   .clone()
  //   .add(extentOfChildren.clone().multiplyScalar(0.5));
  // const maxChildSize = sizesAndPositions.reduce(
  //   (max, { size }) => max.max(size),
  //   new THREE.Vector3(0, 0, 0)
  // );

  const constrainedSizesAndPositions = sizesAndPositions.map((c) => {
    return {
      ...c,
      size: c.constrainSizeTo === "max of siblings" ? maxChildSize : c.size,
    };
  });

  return {
    ...node,
    pos: new THREE.Vector3(),
    size: sizeOf({
      geom: node.geom,
      size: extentOfChildren.add(borderOf(node).multiplyScalar(2)),
    }),
    depth,
    deepestDescendantDepth: deepestDescendantDepthOf({ depth, children }),
    children: children.map((child, i) => ({
      ...child,
      pos: constrainedSizesAndPositions[i].constrainPosTo
        ? constrainedSizesAndPositions[i].constrainPosTo!.clone()
        : constrainedSizesAndPositions[i].position,
      size: constrainedSizesAndPositions[i].size,
    })),
  };
};

export const makeCompositeMeshNodesMap = (
  existing: Map<string, CompositeMeshNodeInfo>,
  position: THREE.Vector3,
  node: CompositeMeshNodeVM,
  parentCollapsed?: boolean,
  absolutePos: THREE.Vector3 = new THREE.Vector3(0, 0, 0)
): Map<string, CompositeMeshNodeInfo> => {
  existing.set(node.id, {
    node: {
      ...node,
      children: node.children.map((c) => c.id),
    },
    absolutePos: absolutePos.clone().add(position),
    visible: !parentCollapsed && node.status !== "collapsed",
  });
  node.children.forEach((child) => {
    makeCompositeMeshNodesMap(
      existing,
      position,
      child,
      parentCollapsed || node.status === "collapsed",
      absolutePos.clone().add(child.pos)
    ).forEach((childNode, id) => {
      existing.set(id, childNode);
    });
  });

  return existing;
};

export const idOfConnectionTarget = (connectedTo: ConnectedTo): string => {
  return connectedTo.id;
};

export const mapVectorToClosestDirection = (dir3d: THREE.Vector3): Side => {
  const dir = dir3d.clone().normalize();
  const x = Math.abs(dir.x);
  const y = Math.abs(dir.y);
  const z = Math.abs(dir.z);

  const NUDGE_FACTOR = 0.5; // Bias left/right
  if (x > y - NUDGE_FACTOR && x > z - NUDGE_FACTOR) {
    return dir.x > 0 ? "right" : "left";
  } else if (y > x && y > z) {
    return dir.y > 0 ? "back" : "front";
  } else {
    return dir.z > 0 ? "top" : "bottom";
  }
};

export const expandDirectionBasedOnAlignment = (
  node: Pick<CompositeMeshNodeVM, "dir" | "size">
): THREE.Vector3 => {
  const alignment = node.dir || "right";
  const size = node.size;
  switch (alignment) {
    case "centered":
      return new THREE.Vector3(-size.x / 2, -size.y / 2, 0);
    case "left":
      return new THREE.Vector3(-size.x, -size.y / 2, 0);
    case "right":
      return new THREE.Vector3(0, -size.y / 2, 0);
    case "top":
      return new THREE.Vector3(0, 0, size.z / 2);
    case "bottom":
      return new THREE.Vector3(0, 0, -size.z / 2);
    case "front":
      return new THREE.Vector3(0, -size.y / 2, 0);
    case "back":
      return new THREE.Vector3(0, size.y / 2, 0);
  }
};

export const mapSideToVector3 = (
  side: Side,
  size: THREE.Vector3
): THREE.Vector3 => {
  switch (side) {
    case "left":
      return new THREE.Vector3(-size.x / 2, 0, 0);
    case "right":
      return new THREE.Vector3(size.x / 2, 0, 0);
    case "top":
      return new THREE.Vector3(0, 0, size.z / 2);
    case "bottom":
      return new THREE.Vector3(0, 0, -size.z / 2);
    case "front":
      return new THREE.Vector3(0, -size.y / 2, 0);
    case "back":
      return new THREE.Vector3(0, size.y / 2, 0);
  }
};

export const oppositeSide = (side: Side): Side => {
  switch (side) {
    case "left":
      return "right";
    case "right":
      return "left";
    case "top":
      return "bottom";
    case "bottom":
      return "top";
    case "front":
      return "back";
    case "back":
      return "front";
  }
};

// Other node may be a parent.
export const directionToOtherNode = (
  absolutePos: THREE.Vector3,
  otherAbsolutePos: THREE.Vector3
): Side => {
  const directionToTargetNode = new THREE.Vector3()
    .copy(otherAbsolutePos)
    .sub(absolutePos)
    .normalize();

  return mapVectorToClosestDirection(directionToTargetNode);
};

export const handlePos = (
  node: Pick<CompositeMeshNodeVM, "size">,
  absolutePos: THREE.Vector3,
  side: Side,
  align: "top" | "center"
): THREE.Vector3 => {
  return absolutePos
    .clone()
    .add(node.size.clone().multiplyScalar(0.5))
    .add(new THREE.Vector3(0, 0, 0))
    .add(mapSideToVector3(side, node.size));
};

export const calcBoundedSize = (parentSize: THREE.Vector3, side: Side) => {
  switch (side) {
    case "top":
    case "bottom":
      return Math.min(parentSize.x, parentSize.y);
    case "left":
    case "right":
      return Math.min(parentSize.y, parentSize.z);
    case "front":
    case "back":
      return Math.min(parentSize.x, parentSize.z);
  }
};

export const calculatePositionAndOrientation = (
  nodeSize: THREE.Vector3,
  location: MeshGeomLocation,
  offsetH: number = 0,
  offsetV: number = 0,
  offsetD: number = 0
): { position: THREE.Vector3; orientation: THREE.Euler } => {
  const { side, alignHorizontally, alignVertically } = location;

  const position = new THREE.Vector3();
  const orientation = new THREE.Euler();
  const extent = nodeSize.clone().multiplyScalar(0.5);
  const THETA = 0.02;

  switch (side) {
    case "top":
      position.set(0, 0, extent.z + THETA);
      orientation.set(0, 0, 0);
      break;
    case "bottom":
      position.set(0, 0, -nodeSize.z);
      orientation.set(0, 0, 0);
      break;
    case "left":
      position.set(-nodeSize.x / 2, 0, 0);
      orientation.set(0, -Math.PI / 2, Math.PI / 2);
      break;
    case "right":
      position.set(nodeSize.x / 2, 0, 0);
      orientation.set(0, Math.PI / 2, -Math.PI / 2);
      break;
    case "front":
      position.set(0, -extent.y - THETA, 0);
      orientation.set(Math.PI / 2, 0, 0);
      break;
    case "back":
      position.set(0, nodeSize.y, 0);
      orientation.set(Math.PI / 2, 0, 0);
      break;
  }

  if (alignHorizontally === "left") {
    position.x = -extent.x;
  } else if (alignHorizontally === "right") {
    position.x = extent.x;
  }

  if (alignVertically === "top") {
    position.y = extent.y;
  } else if (alignVertically === "bottom") {
    switch (side) {
      case "top":
        position.y = -extent.y;
        break;
      default:
        break;
    }
  }

  if (offsetH) {
    position.x += offsetH;
  }

  if (offsetV) {
    if (side === "front" || side === "back") position.z += offsetV;
    else position.y += offsetV;
  }

  if (offsetD) {
    if (side === "front" || side === "back") position.y += offsetD;
    else position.z += offsetD;
  }

  return { position, orientation };
};

export const ANIMATION_DELAY_MS = 80;
export const REVERSE_ANIMATION_DELAY_MS = 10;
export const MESH_SELECTION_COLOR = "#88bbff";

export type ConnectionNode = Pick<
  CompositeMeshNodeVM,
  "size" | "depth" | "deepestDescendantDepth" | "id"
>;

export type ConnectionInfo = {
  absolutePos: THREE.Vector3;
  toAbsolutePos: THREE.Vector3;
  midA: THREE.Vector3;
  midB: THREE.Vector3;
  points: THREE.Vector3[];
  curve: THREE.CubicBezierCurve3;
  mesh: THREE.Mesh;
  node: ConnectionNode;
  toNode: ConnectionNode;
  visible: boolean;
  toVisible: boolean;
  controlDirection: Side;
  toControlDirection: Side;
  kind: ConnectedTo["kind"];
  label?: ConnectionLabel;
  color?: string;
};

export const calcConnections = (
  nodeInfosMap: Map<string, CompositeMeshNodeInfo>
) => {
  return Array.from(nodeInfosMap.values())
    .filter((node) => node.node.connectedTo?.length)
    .flatMap(({ node, absolutePos, visible }): ConnectionInfo[] => {
      const alignmentOffset = expandDirectionBasedOnAlignment(
        nodeInfosMap.get("root")!.node
      );

      const applyMeshTransform = (v: THREE.Vector3) => {
        const result = v.clone();
        result.add(alignmentOffset);
        return result;
      };

      return node
        .connectedTo!.filter(
          (conn) => nodeInfosMap.get(idOfConnectionTarget(conn))?.visible
        )
        .map((conn) => {
          const id = idOfConnectionTarget(conn);
          const {
            node: toNode,
            absolutePos: toAbsolutePos,
            visible: toVisible,
          } = nodeInfosMap.get(id)!;

          const dir = directionToOtherNode(absolutePos, toAbsolutePos);
          const side = conn.sourceSide || dir;
          const toSide = conn.targetSide || oppositeSide(dir);
          const offsetSource = conn.sourceOffset || new THREE.Vector3();
          const offsetTarget = conn.targetOffset || new THREE.Vector3();
          const sourceHandlePos = handlePos(node, absolutePos, side, "top").add(
            offsetSource
          );
          const targetHandlePos = handlePos(
            toNode,
            toAbsolutePos,
            toSide,
            "top"
          ).add(offsetTarget);

          const controlDirection = conn.sourceControl || dir;
          const toControlDirection = conn.targetControl || oppositeSide(dir);

          const source = applyMeshTransform(sourceHandlePos);
          const targetHandleAbsPos = applyMeshTransform(targetHandlePos);

          const distance = source.distanceTo(targetHandleAbsPos);
          const dirV = mapSideToVector3(
            controlDirection,
            new THREE.Vector3(1, 1, 1)
          ).multiplyScalar(distance / 1.6);
          const toDirV = mapSideToVector3(
            toControlDirection,
            new THREE.Vector3(1, 1, 1)
          ).multiplyScalar(distance / 1.25);

          const target = targetHandleAbsPos.add(
            toDirV.clone().normalize().multiplyScalar(0.2)
          );

          const midA = source.clone().add(dirV);
          const midB = target.clone().add(toDirV);

          const POINTS = 50;
          const curve = new THREE.CubicBezierCurve3(source, midA, midB, target);
          const points = curve.getPoints(POINTS);
          const baseWidth = 0.15;

          const color = conn.color || "#0ff";

          return {
            toInstanceId: idOfConnectionTarget(conn).split("-")[0],
            absolutePos: source,
            toAbsolutePos: target,
            midA,
            midB,
            points,
            curve,
            mesh: createCylinderLine(
              points,
              points.map((p, i) => baseWidth),
              color
            ),
            node,
            toNode,
            visible,
            toVisible,
            controlDirection,
            toControlDirection,
            kind: conn.kind,
            label: conn.label,
            color,
          } as ConnectionInfo;
        });
    });
};
