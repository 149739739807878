import * as THREE from "three";
import React from "react";
import { Html, useGLTF } from "@react-three/drei";
import { a, useSpring } from "@react-spring/three";
import { MeshEntity } from "../../../composite-mesh/composite-mesh-types";
import { useInteractivity } from "../../../composite-mesh/useInteractivity";
import { GroupProps } from "@react-three/fiber";
import { useQuickstart } from "../../useQuickstartStore";
import { useCloseStepViewOnEscape } from "../useCloseStepViewOnEscape";
import { AnimatedHtml } from "../../../composite-mesh/CompositeMesh3d";

export function MacbookModel(props: {
  entity?: MeshEntity;
  render: () => JSX.Element | null;
}) {
  const gltf = useGLTF("/mac-draco.svg");

  const [materials, setMaterials] = React.useState<{
    [key: string]: THREE.Material;
  }>({});
  const [nodes, setNodes] = React.useState<{ [key: string]: THREE.Mesh }>({});

  React.useEffect(() => {
    const loadedNodes: { [key: string]: THREE.Mesh } = {};
    const loadedMaterials: { [key: string]: THREE.Material } = {};

    gltf.scene.traverse((object) => {
      if (object instanceof THREE.Mesh) {
        loadedNodes[object.name] = object;
        loadedMaterials[object.material.name] = object.material;
      }
    });

    setMaterials(loadedMaterials);
    setNodes(loadedNodes);
  }, [gltf.scene]);

  const closeStepView = useQuickstart((state) => state.closeStepView);

  const onDeselect = React.useCallback(() => {
    closeStepView();
  }, [closeStepView]);

  const DEFAULT_CAMERA_DISTANCE_WHEN_SELECTED = 4.5;

  const { entity } = props;
  const baseScale = 0.1;
  const {
    isSelected,
    isHovered,
    onPointerDown,
    onPointerEnter,
    onPointerLeave,
    ref,
    spring,
    InfoButton,
  } = useInteractivity({
    entity,
    scale: baseScale,
    cameraDistanceWhenSelected: DEFAULT_CAMERA_DISTANCE_WHEN_SELECTED,
    cameraLookAtOffsetWhenSelected: new THREE.Vector3(0, 0, -1.45),
    onDeselect,
  });

  const scaleSpring = useSpring({
    scale: entity && isHovered && !isSelected ? 1.1 : 1,
  });

  useCloseStepViewOnEscape();

  if (Object.keys(materials).length === 0) {
    return null;
  }

  const distanceFactor = 3;

  return (
    <a.group
      {...props}
      dispose={null}
      {...(spring as any)}
      rotation={[Math.PI / 2, 0, 0]}
    >
      <AnimatedHtml position={[-7.5, 7, -3]}>
        <InfoButton />
      </AnimatedHtml>
      <a.group {...scaleSpring}>
        <group
          rotation-x={-0.425}
          position={[0, -0.04, 0.41]}
          ref={ref as GroupProps["ref"]}
        >
          <group position={[0, 2.96, -0.13]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              material={materials.aluminium}
              geometry={nodes["Cube008"].geometry}
              onPointerEnter={onPointerEnter}
              onPointerLeave={onPointerLeave}
              onPointerDown={onPointerDown}
              castShadow
            />
            <mesh
              material={materials["matte.001"]}
              geometry={nodes["Cube008_1"].geometry}
              castShadow
            />
            <mesh geometry={nodes["Cube008_2"].geometry} castShadow>
              <Html
                className="content"
                rotation-x={-Math.PI / 2}
                position={[0, 0.05, -0.09]}
                transform
                distanceFactor={10 / distanceFactor}
                pointerEvents={"none"}
                style={{ cursor: "default" }}
                zIndexRange={[800, 900]}
              >
                <div className="wrapper">
                  <div
                    style={{
                      width: 334 * distanceFactor,
                      height: 216 * distanceFactor,
                      pointerEvents: isSelected ? "auto" : "none",
                    }}
                    onPointerDown={(e) => e.stopPropagation()}
                  >
                    {props.render()}
                  </div>
                </div>
              </Html>
            </mesh>
          </group>
        </group>
        <mesh
          material={materials.keys}
          geometry={nodes.keyboard.geometry}
          position={[1.79, 0, 3.45]}
          castShadow
        />
        <group position={[0, -0.1, 3.39]}>
          <mesh
            material={materials.aluminium}
            geometry={nodes["Cube002"].geometry}
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
            onPointerDown={onPointerDown}
            castShadow
          />
          <mesh
            material={materials.trackpad}
            geometry={nodes["Cube002_1"].geometry}
            castShadow
          />
        </group>
        <mesh
          material={materials.touchbar}
          geometry={nodes.touchbar.geometry}
          position={[0, -0.03, 1.2]}
          castShadow
        />
      </a.group>
    </a.group>
  );
}
