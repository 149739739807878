// @ts-check
import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppStage } from "../../context/AppStageContext";
import { acceptEULAApi } from "../../api/acceptEULAApi";
import { eulaHtml } from "./eulaHtml";
import { useAmplifyAuth } from "../../hooks/useAmplifyAuth";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  width: 1024,
  maxHeight: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
  borderRadius: 1,
};

/**
 * @type {React.CSSProperties}
 */
const closeButtonStyle = {
  position: "absolute",
  right: 0,
  top: 0,
  margin: 16,
};

const buttonStyle = {
  minWidth: 131,
  marginLeft: 12,
  height: 36,
  fontWeight: "bold",
};

const ModalContent = React.forwardRef((props, ref) => {
  // @ts-ignore
  const { handleClose, handleAccept, busy } = props;

  const [signoutBusy, setSignoutBusy] = React.useState(false);

  const handleSignout = React.useCallback(() => {
    setSignoutBusy(true);

    handleClose();
  }, [handleClose]);

  return (
    <Box sx={modalStyle}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography
          id="modal-modal-title"
          variant="h2"
          component="h1"
          style={{ marginBottom: 20 }}
        >
          Anjuna's Terms and Conditions
        </Typography>
        <IconButton style={closeButtonStyle} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-description" sx={{ mt: -1 }}>
          Before you proceed, please review and accept the terms and conditions.
        </Typography>
        <Typography
          sx={{ p: 3, mt: 3, flex: 1, overflow: "auto", background: "#F9F9F9" }}
        >
          <div
            className="eula"
            dangerouslySetInnerHTML={{ __html: eulaHtml }}
            style={{ color: "black" }}
          />
        </Typography>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 32 }}>
          <div style={{ flex: 1 }} />
          <Button
            style={buttonStyle}
            variant="outlined"
            disabled={signoutBusy}
            onClick={handleSignout}
          >
            Log Out
            {signoutBusy ? (
              <CircularProgress
                size={20}
                style={{ marginLeft: 8, color: "white" }}
                data-testid="ManageSettings-resetting-progress"
              />
            ) : null}
          </Button>
          <Button
            style={buttonStyle}
            variant="contained"
            onClick={handleAccept}
          >
            I Accept
            {busy ? (
              <CircularProgress
                size={20}
                style={{ marginLeft: 8, color: "white" }}
                data-testid="ManageSettings-resetting-progress"
              />
            ) : null}
          </Button>
        </div>
      </div>
    </Box>
  );
});

export const EULA = () => {
  const [{ stage }, dispatch] = useAppStage();

  const open = stage.type === "dashboard" && stage.showEULA;

  const [busy, setBusy] = React.useState(false);

  const handleAccept = React.useCallback(async () => {
    setBusy(true);

    const email =
      stage.type === "dashboard" ? stage.user.attributes.email : undefined;

    if (!email) {
      console.error("Unable to access email. Current stage is: " + stage.type);
      return;
    }

    const result = await acceptEULAApi({
      email,
    });

    if (result.type === "success") {
      dispatch({ type: "eula accepted" });
    }

    setBusy(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, stage.type]);

  const { signout } = useAmplifyAuth();

  const handleExit = React.useCallback(() => {
    signout();
  }, [signout]);

  return (
    <Modal
      open={open}
      onClose={handleExit}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContent
        // @ts-ignore
        apiToken={stage.apiToken}
        handleClose={handleExit}
        handleAccept={handleAccept}
        busy={busy}
      />
    </Modal>
  );
};
