// @ts-check
import React from "react";
import { ProductAccess } from "../api/ApiTypes";

export type User = {
  attributes: {
    username: string;
    email: string;
    name: string;
    family_name: string;
  };
};
type AppStageOrg = {
  companyName: string;
  access: ProductAccess[];
  canApproveUsers: boolean;
  templatesAccess: boolean;
  prereleasesAccess: boolean;
  freeTrialAccess: boolean;
};

export type AppStage = { apiToken?: string } & (
  | { type: "login" }
  | { type: "confirm sign up AWS email"; user: User }
  | { type: "confirm sign up downloads email"; user: User }
  | { type: "link account"; user: User }
  | { type: "check account"; user: User }
  | { type: "create downloads account"; user: User }
  | (AppStageOrg & {
      type: "dashboard";
      user: User;
      accountType: "pending" | "downloads" | "missing";
      currentModal?:
        | "api settings"
        | "account details"
        | "user access"
        | "licence via api";
      showEULA: boolean;
    })
  | {
      type: "link account error";
      reason: string;
      reasonHtml: string;
      user: User;
      accountType: "AWS" | "downloads";
    }
  | {
      type: "open modal";
      modalId: "api settings" | "account details" | "licence via api";
      apiToken: string;
    }
);
export type AppStageState = {
  stage: AppStage;
};
export type AppStageAction =
  | { type: "user signed in"; user: User }
  | (AppStageOrg & {
      type: "account linked";
      user: User;
      accountType: "downloads" | "pending" | "missing";
      apiToken?: string;
      showEULA: boolean;
    })
  | {
      type: "account link failed";
      user: User;
      reason: string;
      reasonHtml: string;
      accountType: "downloads";
    }
  | { type: "user signed out" }
  | {
      type: "open modal";
      modalId: "api settings" | "account details" | "licence via api";
    }
  | { type: "exit modal" }
  | { type: "api token updated"; apiToken: string }
  | { type: "eula accepted" };
export type AppStageDispatch = (action: AppStageAction) => AppStageState;

/**
 * @typedef {{attributes: {username: string, email: string, name: string, family_name: string}}} User
 * @typedef {{type: 'login'} | {type: 'confirm sign up AWS email', user: User} | {type: 'confirm sign up downloads email', user: User} | {type: 'link account', user: User} | {type: 'check account', user: User} | { type: 'create downloads account', user: User } | {type: 'dashboard', user: User, apiToken?: string, access: import("../api/createAccountAPI").ProductAccess[], companyName: string, accountType: 'pending' | 'downloads' | 'missing', currentModal?: 'api settings' | 'account details' | 'user access' | 'licence via api', showEULA: boolean, canApproveUsers: boolean } | {type: 'link account error', reason: string, reasonHtml: string, user: User, accountType: 'AWS' | 'downloads'} | {type: 'open modal', modalId: 'api settings' | 'account details' | 'licence via api', apiToken: string}} AppStage
 * @typedef {{stage: AppStage}} AppStageState
 * @typedef {{type: 'user signed in', user: User} | {type: 'account linked', user: User, accountType: "downloads" | "pending" | "missing", apiToken?: string, access: import("../api/createAccountAPI").ProductAccess[], companyName: string, showEULA: boolean, canApproveUsers: boolean, templatesAccess: boolean } | {type: 'account link failed', user: User, reason: string, reasonHtml: string, accountType: "downloads" } | {type: 'user signed out'} | {type: 'open modal', modalId: 'api settings' | 'account details' | 'licence via api'} | {type: 'exit modal'} | {type: 'api token updated', apiToken: string} | {type: 'eula accepted'}} AppStageAction
 * @typedef {(action: AppStageAction) => AppStageState} AppStageDispatch
 * @typedef {React.Context<AppStageState>} AppStageStateContext
 */

export const AppStageStateContext = React.createContext({
  stage: { type: "login" },
});

export const AppStageDispatchContext = React.createContext<
  AppStageDispatch | undefined
>(undefined);

/**
 * @param {AppStageState} state
 * @param {AppStageAction} action
 * @returns {AppStageState}
 */
const appStageReducer = (
  state: AppStageState,
  action: AppStageAction
): AppStageState => {
  switch (action.type) {
    case "user signed in":
      if (
        state.stage.type !== "login"
      )
        return state;

      return {
        ...state,
        stage: { type: "check account", user: action.user },
      };

    case "user signed out":
      return {
        ...state,
        stage: { type: "login" },
      };

    case "account linked":
      const { type, ...rest } = action;
      return {
        ...state,
        stage: {
          type: "dashboard",
          apiToken: action.apiToken,
          ...rest,
        },
      };

    case "account link failed":
      if (
        state.stage.type === "link account error" &&
        state.stage.reason === action.reason
      )
        return state;

      return {
        ...state,
        stage: {
          type: "link account error",
          reason: action.reason,
          reasonHtml: action.reasonHtml,
          user: action.user,
          accountType: action.accountType,
        },
      };

    case "open modal": {
      if (state.stage.type === "dashboard") {
        return {
          ...state,
          stage: {
            ...state.stage,
            currentModal: action.modalId,
          },
        };
      }

      return state;
    }

    case "exit modal": {
      if (state.stage.type === "dashboard") {
        return {
          ...state,
          stage: {
            ...state.stage,
            currentModal: undefined,
          },
        };
      }

      return state;
    }

    case "api token updated": {
      if (state.stage.type === "dashboard") {
        return {
          ...state,
          stage: {
            ...state.stage,
            apiToken: action.apiToken,
          },
        };
      }

      return state;
    }

    case "eula accepted": {
      if (state.stage.type === "dashboard") {
        return {
          ...state,
          stage: {
            ...state.stage,
            showEULA: false,
          },
        };
      }

      return state;
    }

    default:
      console.warn("Unexpected action type:", action);
      return state;
  }
};

export const AppStageProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [state, dispatch] = React.useReducer(appStageReducer, {
    stage: { type: "login" },
  });

  return (
    <AppStageStateContext.Provider value={state}>
      {/* @ts-ignore-next-line */}
      <AppStageDispatchContext.Provider value={dispatch}>
        {children}
      </AppStageDispatchContext.Provider>
    </AppStageStateContext.Provider>
  );
};

/**
 * @returns {AppStageState}
 */
export const useAppStageState = (): AppStageState => {
  const context = React.useContext(AppStageStateContext);
  if (context === undefined) {
    throw new Error("useAppStageState must be used within a AppStageProvider");
  }
  // @ts-ignore
  return context;
};

export const useAppStageDispatchIfAvailable = () => {
  return React.useContext(AppStageDispatchContext);
};

/**
 * @returns {AppStageDispatch}
 */
export const useAppStageDispatch = () => {
  const context = React.useContext(AppStageDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useAppStageDispatch must be used within a AppStageProvider"
    );
  }
  return context;
};

/**
 * @returns {[AppStageState, AppStageDispatch]}
 */
export const useAppStage = (): [AppStageState, AppStageDispatch] => {
  return [useAppStageState(), useAppStageDispatch()];
};
