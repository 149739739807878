import React, { useCallback, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { QuickstartDownloadBox } from "./PocArtifactStep";
import { Visualisation } from "./Visualisation";
import { PocLayout } from "./poc-constants";
import {
  INITIAL_CAMERA_POS,
  QuickstartStepInfo,
  useQuickstart,
} from "./useQuickstartStore";
import { QuickstartSelector } from "./views/components/QuickstartSelector";
import { logAnyPropChanges } from "../../../core/logAnyPropsChanges";
import { TOOLBAR_HEIGHT } from "../../../components/LayoutConstants";
import { Close, Info } from "@mui/icons-material";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  lhsContainer: {
    zIndex: 1e6, // Bring above diagram HTML
  },
  visualSteps: {
    borderRadius: theme.spacing(1),
    marginTop: 16,
    width: 750,
    left: "10%",
    border: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.default,
    alignItems: "center",
  },
  controlsContentContainer: {
    margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
    justifyContent: "space-between",
  },
  progressTrack: {
    height: 10,
    borderRadius: "7px 7px 0 0",
    background: theme.palette.divider,
  },
  progress: {
    top: 0,
    left: 0,
    background: theme.palette.primary.main,
    borderRadius: "11px 11px 11px 0px",
    height: 10,
    marginTop: -10,
    transition: "all .3s",
  },
  startOverBtn: { textTransform: "none", marginRight: 16 },
  previousBtn: { marginRight: 16 },
  progressComplete: {
    borderRadius: "11px 11px 0 0",
  },
  selectedTitle: { fontWeight: 700, fontSize: 22 },
  infoDialog: {
    borderRadius: 8,
  },
  infoDialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    margin: `32px 40px 0`,
  },
  infoDialogContent: { padding: 0, margin: `24px 40px 32px` },
  selectedInfo: { display: "flex", alignItems: "center" },
}));

const QuickstartControls = React.memo((props: {}) => {
  const classes = useStyles();

  const hasNext = useQuickstart((state) => state.hasNext());
  const hasPrev = useQuickstart((state) => state.hasPrevious());
  const walkthroughStarted = useQuickstart((state) => state.walkthroughStarted);
  const startWalkthrough = useQuickstart((state) => state.startWalkthrough);
  const startOver = useQuickstart((state) => state.startOver);
  const skipWalkthrough = useQuickstart((state) => state.skipWalkthrough);

  const handleStartClick = React.useCallback(() => {
    if (walkthroughStarted) startOver();
    else startWalkthrough();
  }, [startOver, startWalkthrough, walkthroughStarted]);

  return (
    <div
      style={{
        margin: `0 16px`,
        zIndex: PocLayout.ContentZIndex,
      }}
      className={classes.lhsContainer}
    >
      <Typography variant="h1">Anjuna Quickstart</Typography>
      <div
        className="flex-col-container"
        style={{
          marginTop: 16,
          padding: 0,
        }}
      >
        <Typography variant="h3" style={{ marginBottom: 16 }}>
          Select a quickstart
        </Typography>
        <QuickstartSelector />
        <br />
        <div className="flex-col-container">
          <Button
            style={{
              textTransform: "unset",
              fontWeight: "bold",
            }}
            variant={walkthroughStarted ? "outlined" : "contained"}
            onClick={handleStartClick}
            disabled={walkthroughStarted && !hasPrev}
            fullWidth
            data-testid={`start-walkthrough-button`}
          >
            {walkthroughStarted ? "Restart" : "Start"} Walkthrough
          </Button>

          <Button
            variant="outlined"
            style={{
              marginTop: 16,
              textTransform: "unset",
              fontWeight: "bold",
            }}
            onClick={skipWalkthrough}
            data-testid={"skip-walkthrough-button"}
            disabled={!hasNext}
            fullWidth
          >
            Skip to Download
          </Button>
        </div>
        {!hasNext ? (
          <Fade in timeout={{ enter: 2500 }}>
            <div>
              <Typography
                variant="h3"
                style={{ marginTop: 24, marginBottom: 16 }}
              >
                Access your Quickstart
              </Typography>
              <QuickstartDownloadBox />
            </div>
          </Fade>
        ) : null}
      </div>
    </div>
  );
});

const QuickstartSteps = React.memo((props: {}) => {
  const classes = useStyles();

  const activeStep = useQuickstart((state) => state.state);
  const selected = useQuickstart((state) => state.selectedApp());
  const nextStep = useQuickstart((state) => state.nextStep);
  const hasPrevious = useQuickstart((state) => state.hasPrevious());
  const previousStep = useQuickstart((state) => state.previousStep);
  const completePercent = useQuickstart((state) => state.completePercent());
  const hasNext = useQuickstart((state) => state.hasNext());
  const walkthroughStarted = useQuickstart((state) => state.walkthroughStarted);
  const startOver = useQuickstart((state) => state.startOver);

  const [show, setShow] = useState<boolean>(false);
  const toggleInfoDialog = useCallback(() => setShow(!show), [show]);

  if (!walkthroughStarted) return null;

  return (
    <Fade
      in
      data-testid="quickstart-steps"
      style={{ zIndex: 1e6, marginLeft: 100 }}
    >
      <Paper elevation={0} className={classes.visualSteps}>
        <div className={classes.progressTrack} />
        <div
          className={clsx(classes.progress, {
            [classes.progressComplete]: completePercent === 100,
          })}
          style={{
            width: `${completePercent}%`,
          }}
        />
        <div
          className={clsx(
            classes.controlsContentContainer,
            "flex-row-container flex-centered"
          )}
        >
          <div className="flex-col-container">
            <div className={classes.selectedInfo}>
              <Typography variant="h3" className={classes.selectedTitle}>
                {selected.title}
              </Typography>
              <Tooltip title="More information" placement="right">
                <IconButton onClick={toggleInfoDialog}>
                  <Info />
                </IconButton>
              </Tooltip>
            </div>
            <Typography style={{ fontSize: "1.1rem" }}>
              {activeStep.title}
            </Typography>
          </div>
          <div className="flex-row-container">
            <Button
              onClick={startOver}
              className={classes.startOverBtn}
              disabled={!hasPrevious}
              data-testid="quickstart-steps-start-over"
            >
              Start Over
            </Button>
            <Button
              className={classes.previousBtn}
              onClick={previousStep}
              disabled={!hasPrevious}
              variant="outlined"
            >
              Previous
            </Button>
            <Button
              variant="contained"
              onClick={nextStep}
              disabled={!hasNext}
              data-testid="quickstart-next-step"
            >
              {(activeStep as QuickstartStepInfo).nextStepText || "Next"}
            </Button>
          </div>
        </div>
        <Dialog
          open={show}
          onClose={toggleInfoDialog}
          classes={{ paper: classes.infoDialog }}
          maxWidth="md"
        >
          <DialogTitle className={classes.infoDialogTitle}>
            <Typography
              variant="h3"
              style={{
                fontWeight: 700,
                fontSize: 24,
              }}
            >
              About This Quickstart
            </Typography>
            <IconButton onClick={toggleInfoDialog}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.infoDialogContent}>
            <ReactMarkdown children={selected.descriptionMarkdown || ""} />
          </DialogContent>
        </Dialog>
      </Paper>
    </Fade>
  );
});

export const QuickstartContent = React.memo((props: {}) => {
  const classes = useStyles();

  const parent = React.useRef(null);

  const definitions = useQuickstart((state) => state.definitions);

  console.log({ definitions });
  logAnyPropChanges("QuickstartContent", props);

  return (
    <div ref={parent} key="steps-parent" style={{ flex: 1 }}>
      <div
        className={clsx(classes.container, "flex-col-container flex-centered")}
      >
        <div
          className="flex-row-container"
          style={{
            width: "100vw",
            height: `calc(100vh - ${TOOLBAR_HEIGHT}px)`,
          }}
        >
          <div className="flex-row-container flex-spacer">
            <QuickstartControls />
            <div
              className="flex-col-container"
              style={{ position: "relative", flex: 1 }}
            >
              <QuickstartSteps />
              <div className="flex-col-container">
                <Visualisation id={"combined"} cameraPos={INITIAL_CAMERA_POS} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
