import { API } from "aws-amplify";

export const downloadApiEndpoint = () => {
  const { hostname } = window.location;
  const isTestEnv =
    hostname.startsWith("test") ||
    hostname.startsWith("localhost") ||
    hostname.startsWith("192");

  const endpoint = isTestEnv
    ? "https://evgrwvax14.execute-api.us-east-1.amazonaws.com/test"
    : "https://api.downloads.anjuna.io";

  return endpoint;
};

export type DownloadBinary = {
  description: string;
  filename: string;
  md5: string;
  sha256: string;
  contentLength: number;
  contentType: string;
};

export const downloadAPI = async ({
  apiToken,
  filename,
}: {
  apiToken: string;
  filename: string;
}): Promise<
  | {
      type: "success";
      response?:
        | {
            location: string;
          }
        | undefined;
    }
  | {
      type: "error";
      message: string;
    }
> => {
  const apiName = "releases"; // replace this with your api name.
  const path = `/api/v1/releases/${filename}?token=${apiToken}`; // replace this with the path you have configured on your API
  const myInit = {
    headers: {},
  };

  try {
    const response = await API.get(apiName, path, myInit);

    console.log("Download API call succeeded", JSON.stringify(response));

    return {
      type: "success",
      response,
    };
  } catch (error) {
    console.error("download API call failed", JSON.stringify(error));

    return {
      type: "error",
      message: `We were unable to download the installer. Please try again. If this does not work, please contact Anjuna at support@anjuna.io.`,
    };
  }
};
