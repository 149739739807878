export type Tech = "nitro" | "sgx" | "gcp" | "azsev" | "k8s-sev" | "apm";

export type LicenseType = "production" | "engineering" | "trial";

export type ProductAccess = {
  type: Tech;
  endsAt: "expired" | Exclude<string, "expired">;
  licenseType: LicenseType;
  licenseYaml: string | undefined;
};

export type DownloadArtifact = {
  description: string;
  filename: string;
  md5: string;
  sha256: string;
  contentLength: number;
  contentType: string;
};

export type DownloadInfo = {
  type: Tech;
  version: string;
  build: string;
  releaseDate: string;
  licenseRequired?: boolean;
  isPrerelease?: boolean;
  releaseNotesUrl: string;
  docsUrl?: string;
  artifacts: DownloadArtifact[];
};

export type SortOrder = "asc" | "desc";

export const TECH_TYPES_UI: Tech[] = [
  "sgx",
  "nitro",
  "gcp",
  "azsev",
  "k8s-sev",
  "apm",
];

export type AccountStatus = "active" | "missing" | "pending";

export type AccountInfo = {
  status: AccountStatus;
  companyName?: string;
  access: ProductAccess[];
  canApproveUsers: boolean;
  templatesAccess: boolean;
  prereleasesAccess: boolean;
  freeTrialAccess: boolean;
};

export type OrgInfo = Pick<
  AccountInfo,
  "access" | "templatesAccess" | "prereleasesAccess" | "freeTrialAccess"
> & {
  domain?: string;
  orgId?: string;
  name: string;
  licenseEmail?: string;
};

export type UserInfo = {
  email: string;
  name: string;
  guid: string;
  companyName: string;
  orgId?: string;
};
