// @ts-check
import React, { SyntheticEvent } from "react";
import Typography from "@mui/material/Typography";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Modal,
  Autocomplete,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAppStageState } from "../../context/AppStageContext";
import { OrgInfo, UserInfo } from "../../api/ApiTypes";
import { MuiChipsInput } from "mui-chips-input";
import { batchInviteUsersToOrgAPI } from "../../api/userAccessAPI";
import { UserPlusOrg } from "../../api/usersReportAPI";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles({
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    minWidth: 120,
    marginRight: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: 400,
    textAlign: "left",
  },
  helperText: {
    fontSize: 14,
    marginTop: 8,
    textAlign: "left",
  },
});

export type HandleInviteUsersModalClose =
  | { success: true; invitedUserEmails: string[] }
  | { success: false };

type Props = {
  handleClose: (changes?: HandleInviteUsersModalClose) => void;
  email: string;
  orgs: OrgInfo[];
  users?: UserPlusOrg[];
  selectedUser?: UserInfo;
  selectedOrg?: OrgInfo;
};

type UserToChange = {
  userEmail: string;
  currentOrgName: string;
  newOrgName: string;
};

const ModalContent = React.forwardRef<any, Props>((props, ref) => {
  const {
    handleClose,
    email,
    orgs,
    users,
    selectedUser: selectedUserIn,
    selectedOrg: selectedOrgIn,
  } = props;

  let orgList = orgs || [selectedOrgIn!];

  const orgMap = React.useMemo(() => {
    const newMap = new Map<string, string>();
    orgs.forEach((org) => {
      if (org.orgId) {
        newMap.set(org.orgId, org.name);
      } else {
        console.error(`Org '${org.name}' has no orgId`, org);
      }
    });
    return newMap;
  }, [orgs]);

  let userMap = React.useMemo(() => {
    const newMap = new Map<string, UserPlusOrg>();
    users?.forEach((user) => newMap.set(user.email, user));
    return newMap;
  }, [users]);

  const classes = useStyles();

  const [userEmailList, setUserEmailList] = React.useState(
    (selectedUserIn && [selectedUserIn.email]) || []
  );
  const [selectedOrgId, setSelectedOrgId] = React.useState(
    selectedOrgIn?.orgId || ""
  );
  const [userFormError, setUserFormError] = React.useState("");
  const [orgFormError, setOrgFormError] = React.useState("");
  const [status, setStatus] = React.useState("idle");
  const [error, setError] = React.useState("");

  const [usersToChange, setUsersToChange] = React.useState<UserToChange[]>([]);
  const [usersWithNewOrgs, setUsersWithNewOrgs] = React.useState<
    UserToChange[]
  >([]);

  const onUserEmailListChanged = React.useCallback(
    (newUserEmailList: string[]) => {
      let finalList: string[] = [];

      for (let emailInput of newUserEmailList) {
        const formattedEmailInput = emailInput.replaceAll(" ", "");
        finalList.push(...formattedEmailInput.split(","));
      }
      setUserEmailList(finalList);
      setUserFormError("");
    },
    []
  );

  const callUpdateApi = React.useCallback(() => {
    const apiCall = batchInviteUsersToOrgAPI(
      email,
      userEmailList,
      selectedOrgId
    );

    apiCall
      .then((response) => {
        const resultList = response.results;
        if (resultList.some((r) => !r.success)) {
          let failedEmailsList = resultList.filter((r) => !r.success);
          console.error(
            `failedEmailsList when batch inviting: ${JSON.stringify(
              failedEmailsList
            )}`
          );
          setError(
            `the invitation for the following email addresses failed: ${failedEmailsList
              .map((f) => f.email)
              .join(", ")}`
          );
        } else {
          handleClose({
            success: true,
            invitedUserEmails: resultList.map((f) => f.email),
          });
        }
      })
      .catch((err) => {
        console.error(`error when batch inviting: ${err}`);
        setError(`The invite request failed`);
      })
      .finally(() => {
        setStatus("idle");
      });
  }, [email, handleClose, selectedOrgId, userEmailList]);

  const onInvite = React.useCallback(() => {
    if (!userEmailList || !userEmailList.length) {
      setUserFormError(
        "Please enter at least one email address and press the Enter key"
      );
    }

    if (!selectedOrgId) {
      setOrgFormError("Please select a valid organization");
    }

    if (!userEmailList || !userEmailList.length || !selectedOrgId) return;

    setStatus("updating");
    setError("");

    if (users && users.length > 0) {
      console.log("checking users", users);
      let updatedUsers: UserToChange[] = [];
      let usersWithNewOrg: UserToChange[] = [];

      const newOrgName = orgMap.get(selectedOrgId) || "- Not set -";
      const nonOrgName = `<No preexisting org>`;

      userEmailList.forEach((userEmail) => {
        let currentUserOrgId = userMap.get(userEmail)?.org?.orgId;
        if (!currentUserOrgId) {
          usersWithNewOrg.push({
            userEmail,
            currentOrgName: nonOrgName,
            newOrgName,
          });
        }
        if (currentUserOrgId && currentUserOrgId !== selectedOrgId) {
          let currentOrgName = orgMap.get(currentUserOrgId) || "- Not set -";
          updatedUsers.push({ userEmail, currentOrgName, newOrgName });
        }
      });

      console.log("updatedUsers", updatedUsers);
      console.log("usersWithNewOrg", usersWithNewOrg);

      setUsersToChange(updatedUsers);
      setUsersWithNewOrgs(usersWithNewOrg);
      if (updatedUsers.length > 0) {
        setStatus("review");
        return;
      }
    }

    callUpdateApi();
  }, [userEmailList, selectedOrgId, callUpdateApi, orgMap, userMap, users]);

  const onInviteFromReview = React.useCallback(() => {
    if (!userEmailList || !userEmailList.length) {
      setUserFormError(
        "Please enter at least one email address and press the Enter key"
      );
    }

    if (!selectedOrgId) {
      setOrgFormError("Please select a valid organization");
    }

    if (!userEmailList || !userEmailList.length || !selectedOrgId) return;

    setStatus("updatingFromReview");
    setError("");

    callUpdateApi();
  }, [userEmailList, selectedOrgId, callUpdateApi]);

  const onCancel = React.useCallback(() => {
    handleClose();
  }, [handleClose]);

  const onBack = React.useCallback(() => {
    setStatus("idle");
  }, []);

  const errorAlert = error ? (
    <Alert style={{ marginTop: 16 }} severity={"error"}>
      {`Inviting users to org failed: ${error}`}
    </Alert>
  ) : null;

  const handleOrgIdChange = (event: SyntheticEvent, value: any) => {
    setOrgFormError("");
    setSelectedOrgId(value || "");
  };

  return (
    <Box sx={modalStyles} component="div">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <PersonAddIcon color="primary" fontSize={"large"} />
        <Typography
          id="license-modal-title"
          variant="h2"
          component="h2"
          style={{ marginLeft: 12, marginBottom: 0 }}
        >
          Unassigned users
        </Typography>
      </div>
      <div className="flex-col-container">
        {status === "review" || status === "updatingFromReview" ? (
          <>
            {usersToChange && usersToChange.length > 0 && (
              <div
                className="flex-col-container"
                style={{ flex: 1, marginRight: 12, marginBottom: 20 }}
              >
                <Typography
                  className={classes.helperText}
                  fontSize="smaller"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 15,
                  }}
                >
                  {`The following users will be moved from their current organization to the new one:`}
                </Typography>
                <div className="flex-col-container" style={{ marginRight: 12 }}>
                  {usersToChange.map((userToChange, index) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                        marginBottom: 10,
                      }}
                    >
                      <Typography
                        key={index}
                        className={classes.helperText}
                        fontSize="smaller"
                      >
                        <b>{userToChange.userEmail}</b> will be moved from{" "}
                        <b>'{userToChange.currentOrgName}'</b> to{" "}
                        <b>'{userToChange.newOrgName}'</b>
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {usersWithNewOrgs && usersWithNewOrgs.length > 0 && (
              <div
                className="flex-col-container"
                style={{ flex: 1, marginRight: 12 }}
              >
                <Typography
                  className={classes.helperText}
                  fontSize="smaller"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 15,
                  }}
                >
                  {`The following users will be added to a new organization:`}
                </Typography>
                <div className="flex-col-container" style={{ marginRight: 12 }}>
                  {usersWithNewOrgs.map((userWithNewOrg, index) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                        marginBottom: 10,
                      }}
                    >
                      <Typography
                        key={index}
                        className={classes.helperText}
                        fontSize="smaller"
                      >
                        <b>{userWithNewOrg.userEmail}</b> will be added to{" "}
                        <b>'{userWithNewOrg.newOrgName}'</b>
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        ) : (
          <div
            className="flex-col-container"
            style={{ flex: 1, marginRight: 12 }}
          >
            <MuiChipsInput
              value={userEmailList}
              onChange={onUserEmailListChanged}
              error={!!userFormError}
              helperText={userFormError || ""}
              disabled={!!selectedUserIn}
              data-testid="invite-user-modal-email-input"
              placeholder={
                selectedUserIn ? `` : "Type the email address and press enter"
              }
              addOnBlur
            />
            {selectedUserIn ? (
              <>
                <Typography className={classes.helperText} fontSize="smaller">
                  {`The Company name entered by the user during sign up: "${selectedUserIn.companyName}"`}
                </Typography>
              </>
            ) : null}
            <Autocomplete
              value={selectedOrgId}
              onChange={handleOrgIdChange}
              getOptionLabel={(option) =>
                orgList.find((org) => org.orgId === option)?.name || ""
              }
              sx={{ mt: 2 }}
              id="select-label"
              options={orgList
                .filter((a) => !!a.name)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((org) => org.orgId)}
              renderInput={(params) => (
                <TextField {...params} label="Select the org" />
              )}
            />
            {orgFormError ? (
              <Alert severity={"error"}>{orgFormError}</Alert>
            ) : null}
            {selectedUserIn && selectedOrgId ? (
              <Typography className={classes.helperText} fontSize="smaller">
                {`${selectedUserIn.name} will be added to ${orgMap.get(
                  selectedOrgId
                )}`}
              </Typography>
            ) : null}
          </div>
        )}
      </div>
      <div className={classes.flexRow} style={{ marginTop: 24 }}>
        <div style={{ flex: 1 }} />
        {status === "review" || status === "updatingFromReview" ? (
          <Button
            onClick={onInviteFromReview}
            className={classes.button}
            variant="contained"
            title="Close"
            disabled={status !== "review" || !!userFormError || !!orgFormError}
          >
            {"Confirm changes"}
            {status === "updatingFromReview" ? (
              <CircularProgress
                data-testid="org-access-progress"
                size={20}
                style={{ marginLeft: 8 }}
              />
            ) : null}
          </Button>
        ) : (
          <Button
            onClick={onInvite}
            className={classes.button}
            variant="contained"
            title="Close"
            disabled={status !== "idle" || !!userFormError || !!orgFormError}
          >
            {"Invite"}
            {status === "updating" ? (
              <CircularProgress
                data-testid="org-access-progress"
                size={20}
                style={{ marginLeft: 8 }}
              />
            ) : null}
          </Button>
        )}
        <Button
          className={classes.button}
          variant="outlined"
          onClick={onCancel}
        >
          Cancel
        </Button>
        {(status === "review" || status === "updatingFromReview") && (
          <Button
            className={classes.button}
            variant="outlined"
            onClick={onBack}
          >
            Back
          </Button>
        )}
      </div>
      {errorAlert}
    </Box>
  );
});

export const InviteUsers = (props: Omit<Props, "email">) => {
  const { stage } = useAppStageState();

  if (stage.type !== "dashboard") return null;

  return (
    <>
      <Modal
        open
        onClose={() => props.handleClose()}
        aria-labelledby="invite-users-modal-title"
        aria-describedby="invite-users-modal-description"
      >
        <ModalContent email={stage.user.attributes.email} {...props} />
      </Modal>
    </>
  );
};
