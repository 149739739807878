import React from "react";
import { useQuickstart } from "../../useQuickstartStore";
import { PocOperation } from "../../poc-types";
import { QuickstartParameters } from "./QuickstartParameters";
import { QuickstartFiles } from "./QuickstartFiles";
import { makeStyles } from "@mui/styles";
import { Paper, Theme } from "@mui/material";
import {
  QuickstartViewCloseButton,
  QuickstartViewNextButton,
} from "./QuickstartViewControls";
import { EditableMarkdown } from "../../EditableMarkdown";
import { useCloseStepViewOnEscape } from "../useCloseStepViewOnEscape";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.background.paper,
    width: "100%",
    height: "100%",
    borderRadius: 10,
    padding: 32,
  },
}));

export const ParamScriptView = ({
  operation,
  container,
  descriptionMarkdown,
}: {
  operation: PocOperation;
  container?: string;
  descriptionMarkdown?: string;
}) => {
  const classes = useStyles();

  const params = useQuickstart((state) =>
    state.parametersForOperation(operation, container)
  );

  useCloseStepViewOnEscape();

  return (
    <Paper elevation={1} className={`flex-row-container ${classes.container}`}>
      <div
        className="flex-spacer"
        style={{
          flex: 0.8,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          paddingRight: 12,
        }}
      >
        <div style={{ flex: 0 }}>
          {descriptionMarkdown && (
            <EditableMarkdown
              id={`script-view-${operation}-description`}
              markdown={descriptionMarkdown}
            />
          )}
        </div>
        <div style={{ flex: 1 }}>
          <QuickstartParameters parameters={params} store={useQuickstart} />
        </div>
      </div>
      <div style={{ marginRight: 16 }} />
      <div
        className="flex-spacer"
        style={{ width: "60%", display: "flex", flexDirection: "column" }}
      >
        <QuickstartFiles operation={operation} container={container} />
      </div>
      <QuickstartViewNextButton />
      <QuickstartViewCloseButton />
    </Paper>
  );
};
