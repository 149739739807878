import React from "react";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@mui/material";

type Option = {
  label: string;
  value: string;
};

type Props = {
  title: string;
  options: Option[];
  value: string;
  onSelect: (value: string) => void;
};

export const RadioList = ({ title, options, value, onSelect }: Props) => {
  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, newValue: any) => {
      onSelect(newValue);
    },
    [onSelect]
  );

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" focused={false}>
        {title}
      </FormLabel>
      <RadioGroup
        aria-label={title}
        name={title}
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
