import { sleep } from "./awsSubscribe";

export type AiResponse = {
  document: string;
  response: string;
  prompt: string;
};

export const postArcAIApi = async (
  token: string,
  question: string,
  temperature?: number,
  model?: string,
  prompt?: string
): Promise<AiResponse> => {
  const RETRIES = 20;

  for (let i = 0; i < RETRIES; i++) {
    try {
      const response = await fetch(
        "https://2iv2qhr4j3.execute-api.us-east-1.amazonaws.com/Prod/classify_digit/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token,
            question,
            temperature,
            model,
            prompt,
          }),
        }
      );

      const result = await response.json();

      if (result.response === "generating answer...") {
        await sleep(2000);
        throw Error("Still generating answer...");
      }

      return result;
    } catch (e) {
      console.log(e);
    }
  }

  return {
    document: "",
    response: "",
    prompt: "",
  };
};
