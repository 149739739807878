import React, { useState } from "react";
import { TextFieldProps } from "@mui/material/TextField";
import { ValidatedTextField } from "./views/components/QuickstartFormControls";
import { useTheme } from "@mui/material";

type NumericInputProps = {
  label: string;
  value: number | undefined | string;
  min: number;
  max: number;
  onChange?: (value: number) => void;
} & Omit<TextFieldProps, "onChange">;

export const NumericInput = (props: NumericInputProps) => {
  const [value, setValue] = useState<string>(props.value?.toString() || "");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const newValue = parseFloat(input);
    setValue(input);
    const outOfRange = newValue < props.min || newValue > props.max;
    !outOfRange && props.onChange && props.onChange(newValue);
  };

  const theme = useTheme();

  return (
    <>
      <ValidatedTextField
        {...props}
        type="text"
        value={value}
        onChange={handleChange}
        onValidate={(input) => {
          const isValid = /^-?\d*\.?\d+$/.test(input);
          const newValue = parseFloat(input);
          const outOfRange = newValue < props.min || newValue > props.max;
          return isValid && !outOfRange
            ? ""
            : `${props.label} must be between ${props.min} and ${props.max}`;
        }}
        size="small"
        style={{ background: theme.palette.background.paper }}
      />
    </>
  );
};
