// @ts-check
import { API } from "aws-amplify";

/**
 *
 * @param {{ email: string }} user
 * @returns {Promise<{type: "success" } | {type: "error", reason: string}>}
 */
export const acceptEULAApi = async ({ email }) => {
  const apiName = "accepteula";
  const path = `/api/v1/accept-eula?email=${encodeURIComponent(email)}`;
  const myInit = {
    headers: {},
  };

  try {
    await API.put(apiName, path, myInit);

    console.log("Accept EULA API call succeeded");

    return {
      type: "success",
    };
  } catch (error) {
    console.error("Accept EULA API call failed", JSON.stringify(error));

    return {
      type: "error",
      reason: `We were unable to receive the request to accept the EULA. Please try again. If this does not work, please contact Anjuna at support@anjuna.io.`,
    };
  }
};
