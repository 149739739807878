import React from "react";
import * as THREE from "three";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils";
import { AnimatedHtml } from "./CompositeMesh3d";
import { useSpring } from "@react-spring/web";
import { ConnectionInfo } from "./composite-mesh-vm";

export function createCylinderLine(
  points: THREE.Vector3[],
  lineWidths: number[],
  color: string
) {
  // Create an array to hold individual cylinder geometries
  const geometries: THREE.CylinderGeometry[] = [];

  // Iterate through points, creating cylinders for each segment
  for (let i = 0; i < points.length - 1; i++) {
    const p1 = points[i];
    const p2 = points[i + 1];

    const length = p1.distanceTo(p2);
    const orientation = new THREE.Matrix4();
    orientation.lookAt(p1, p2, new THREE.Vector3(0, 0, 1));

    const geometry = new THREE.CylinderGeometry(
      lineWidths[i] / 2, // Top radius
      lineWidths[i + 1] / 2, // Bottom radius
      length, // Height
      6, // Radial segments
      1, // Height segments
      false
    );

    geometry.rotateX(Math.PI / 2);
    geometry.applyMatrix4(orientation);
    geometry.translate(p1.x, p1.y, p1.z);
    geometries.push(geometry);
  }

  // Merge the geometries using BufferGeometryUtils
  const mergedGeometry = BufferGeometryUtils.mergeGeometries(geometries);

  // Create the material
  const material = new THREE.MeshStandardMaterial({ color });

  // Create and return the mesh
  return new THREE.Mesh(mergedGeometry, material);
}

export function PulsingCubicBezier({
  color,
  label,
  mesh,
  points,
}: ConnectionInfo & {}): JSX.Element {
  const meshRef = React.createRef<THREE.Mesh>();

  React.useEffect(() => {
    if (!meshRef.current) return;

    meshRef.current.receiveShadow = true;
    meshRef.current.castShadow = true;

    meshRef.current.material = new THREE.MeshStandardMaterial({ color });
  }, [color, meshRef]);

  const labelSpring = useSpring({
    opacity: label ? 1 : 0,
    delay: 1000,
  });

  return (
    <>
      <primitive ref={meshRef} object={mesh} />
      {
        <AnimatedHtml
          position={points[Math.floor(points.length / 2)]}
          style={{ ...label?.style, ...labelSpring }}
        >
          {label?.text}
        </AnimatedHtml>
      }
    </>
  );
}
