import {
  AlwaysDepth,
  Group,
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry,
  TextureLoader,
} from "three";
import { LoadedImageMesh } from "./loadSVGMesh";

export const loadRasterImage = (url: string): Promise<LoadedImageMesh> => {
  return new Promise((resolve, reject) => {
    let loader = new TextureLoader();

    loader.load(url, (texture) => {
      const material = new MeshBasicMaterial({
        map: texture,
        transparent: true,
        depthFunc: AlwaysDepth,
        color: 0xbbbbbb,
      });

      const geometry = new PlaneGeometry(1, 1);
      const group = new Group();
      const mesh = new Mesh(geometry, material);
      group.add(mesh);

      resolve({
        group,
        width: 1,
        height: 1,
      });
    });
  });
};
