import { create } from "zustand";
import * as THREE from "three";
import { CompositeMeshNodeInfo, MeshEntityId } from "./composite-mesh-types";
import {
  ConnectionInfo,
  calcConnections,
  makeCompositeMeshNodesMap,
} from "./composite-mesh-vm";
import _ from "lodash";
import { CompositeMeshBuilder } from "./CompositeMeshBuilder";

export type SelectedEntity = {
  id: string;
} & (
  | {
      kind: "view";
      item: THREE.Object3D;
      distance: number;
      cameraLookAtOffset?: THREE.Vector3;
    }
  | {
      kind: "popup";
      item: THREE.Object3D;
    }
);

type CompositeMeshStore = {
  nodeInfosMap: Map<string, CompositeMeshNodeInfo>;

  update: (builder: CompositeMeshBuilder) => void;

  nodeInfo: (id: string) => CompositeMeshNodeInfo;
  connections: ConnectionInfo[];

  selectedEntity: SelectedEntity | undefined;
  setSelectedEntity: (entity: SelectedEntity | undefined) => void;

  hoveredEntity: MeshEntityId | undefined;
  setHoveredEntity: (entity: MeshEntityId | undefined) => void;

  visitedEntities: Set<SelectedEntity["id"]>;
};

export const createCompositeMeshStore = (instanceId: string) =>
  create<CompositeMeshStore>((set, get) => ({
    nodeInfosMap: new Map<string, CompositeMeshNodeInfo>(),
    connections: [],

    update: (builder: CompositeMeshBuilder) => {
      // Ensures all edges are cleared out.
      [...get().nodeInfosMap.values()].forEach((nodeInfo) => {
        nodeInfo.visible = false;
      });

      const nodesMap = makeCompositeMeshNodesMap(
        new Map(get().nodeInfosMap),
        new THREE.Vector3(),
        builder.vm
      );

      const newNodeInfos = new Map<string, CompositeMeshNodeInfo>(
        get().nodeInfosMap
      );

      let change = false;
      for (const [key, value] of nodesMap.entries()) {
        if (!_.isEqual(newNodeInfos.get(key)!, value)) {
          change = true;
          newNodeInfos.set(key, _.cloneDeep(value));
        }
      }

      if (change) {
        get().connections.forEach((connection) => {
          connection.mesh.geometry.dispose();
        });

        set({
          nodeInfosMap: newNodeInfos,
          connections: calcConnections(newNodeInfos),
        });
      }
    },

    nodeInfo: (id: string) => {
      return get().nodeInfosMap.get(id)!;
    },

    selectedEntity: undefined,
    hoveredEntity: undefined,
    visitedEntities: new Set(),
    setSelectedEntity: (entity: SelectedEntity | undefined) => {
      if (entity === get().selectedEntity) {
        return;
      }

      set({
        selectedEntity: entity,
        hoveredEntity: undefined,
        visitedEntities: !entity
          ? get().visitedEntities
          : new Set([...get().visitedEntities, entity.id]),
      });
    },
    setHoveredEntity: (entity: MeshEntityId | undefined) => {
      if (entity === get().hoveredEntity) {
        return;
      }

      document.body.style.cursor = entity ? "pointer" : "default";
      set({ hoveredEntity: entity });
    },
  }));

export const useCombinedMeshStore = createCompositeMeshStore("combined");

export const useMeshStore = (id: string) => {
  switch (id) {
    case "combined":
      return useCombinedMeshStore;
    default:
      throw Error("Invalid visual id: " + id);
  }
};
