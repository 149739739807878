/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "accepteula",
            "endpoint": "https://wejnnozwxa.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "apiEvents",
            "endpoint": "https://a597n33yqi.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "apireport",
            "endpoint": "https://lkvg86h1h7.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "apisublicense",
            "endpoint": "https://clq7s3aj42.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "apitemplates",
            "endpoint": "https://9v87pdqgsj.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "awssubscribe",
            "endpoint": "https://tqoscieqli.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "createaccount",
            "endpoint": "https://i5o3z1nyke.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "downloadapi",
            "endpoint": "https://rq3a1i45v2.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "products",
            "endpoint": "https://8cd0dbmfpe.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "releases",
            "endpoint": "https://wj6w5zdt2b.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "token",
            "endpoint": "https://anedciivn3.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "useraccessapi",
            "endpoint": "https://52zmnfvzv7.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:db3c4016-dbf6-4ed1-9def-e4352a1710ad",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_QLYO0Dd7U",
    "aws_user_pools_web_client_id": "5kdcemtn07fgc99usnr9u1pq1a",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "awsonboardwebapp-20211102094307-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2yc4hghtxfij1.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "organizations-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "orgs-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "products-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "subscriptions-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "templates-dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
