import { API } from "aws-amplify";
import { OrgInfo, UserInfo } from "./ApiTypes";

export type UserPlusOrg = UserInfo & { org?: OrgInfo };

export type UsersReport = UserPlusOrg[];

export const usersReportApiEndpoint = () => {
  const { hostname } = window.location;
  const isTestEnv =
    hostname.startsWith("test") ||
    hostname.startsWith("localhost") ||
    hostname.startsWith("192");

  const endpoint = isTestEnv
    ? "https://rn40iux9wj.execute-api.us-east-1.amazonaws.com/test"
    : "https://api.downloads.anjuna.io";

  return endpoint;
};

export const usersReportAPI = async (
  token: string
): Promise<
  { type: "success"; report: UsersReport } | { type: "error"; reason: string }
> => {
  const apiName = "apireport";
  const path = `/v1/users`;
  const myInit = {
    headers: {},
    queryStringParameters: {
      token,
      report: true,
    },
  };

  try {
    const result = await API.get(apiName, path, myInit);

    console.log(
      "Retrieve users report API call succeeded: " + JSON.stringify(result)
    );

    return {
      type: "success",
      report: result.users,
    };
  } catch (error) {
    console.error(
      "Retrieve users report API call failed",
      JSON.stringify(error)
    );

    return {
      type: "error",
      reason: `We were unable to receive users report.`,
    };
  }
};
