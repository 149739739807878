import React from "react";
import { createInstanceFileCode } from "../../files/createInstanceFileCode";
import { QuickstartParameters } from "../components/QuickstartParameters";
import { useQuickstart } from "../../useQuickstartStore";
import { EditableMarkdown } from "../../EditableMarkdown";
import { Paper } from "@mui/material";
import { stepResultCode } from "../../quickstart-util";

export const CreateInstanceView = () => {
  const instanceTypeParam = useQuickstart(
    (state) => state.parameters.get("instanceType")!
  );
  const allValues = useQuickstart((state) => state.allValues());

  return (
    <Paper
      elevation={0}
      className="flex-col-container"
      style={{
        width: "100%",
        height: "100%",
        padding: 16,
        overflowY: "auto",
      }}
    >
      <EditableMarkdown
        markdown={`## Create a Nitro-capable EC2 Instance`}
        id="create-instance-header"
      />
      <QuickstartParameters
        parameters={[instanceTypeParam]}
        store={useQuickstart}
      />
      <EditableMarkdown
        markdown={`Enter your EC2 key-pair name and run the following:
~~~bash
${stepResultCode(createInstanceFileCode, allValues, [])}
~~~`}
        id="create-instance-code"
      />

      <EditableMarkdown
        markdown={`The JSON output contains the \`InstanceId\`, which uniquely identifies the newly created EC2 instance. Using the InstanceId, you can query AWS to retrieve attributes of this EC2 instance:
~~~bash
EC2_INSTANCE_ID=
aws ec2 describe-instances --instance-ids $EC2_INSTANCE_ID \\
 | jq '.Reservations[].Instances[] | { id: .InstanceId, fqdn: .PublicDnsName, ip: .PublicIpAddress }'
~~~
The output should show the IP address (and public DNS name), which allows you to SSH into it using your SSH key (instructions for creating your SSH key [here](https://docs.anjuna.io/nitro/latest/getting_started/getting_the_runtime/create_nitro_host.html#_create_ssh_key_pair_for_connecting_to_ec2_hosts)):
~~~bash
YOUR_SSH_KEY_FILE_NAME=
EC2_PUBLIC_IP=
ssh -i $EC2_KEY_PAIR_NAME.pem ec2-user@$EC2_PUBLIC_IP
~~~`}
        id="create-instance-footer"
      />
    </Paper>
  );
};
