import React from "react";
import { QuickstartContent } from "./QuickstartContent";
import { useAppStageState } from "../../../context/AppStageContext";
import { downloadApiEndpoint } from "../../../api/downloadAPI";
import { eventsApiEndpoint } from "../../../api/eventsAPI";
import { CenteredCircularProgress } from "./CenteredCircularProgress";
import { useVisual } from "./visuals/useVisual";
import { useQuickstart } from "./useQuickstartStore";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.background.paper,
    opacity: 0.6,
    zIndex: 1000,
  },
}));

const WelcomeActions = () => {
  const status = useQuickstart((state) => state.status);
  const classes = useStyles();

  switch (status.kind) {
    case "fetching":
      return (
        <div className={classes.overlay}>
          <CenteredCircularProgress />
        </div>
      );
    default:
      return null;
  }
};

export const PocBuilder = React.memo(() => {
  const fetch = useQuickstart((state) => state.fetch);
  const setApiInfo = useQuickstart((state) => state.setApiInfo);

  const { stage } = useAppStageState();

  useVisual();

  React.useEffect(() => {
    if (stage.apiToken) {
      setApiInfo({
        downloadApiUrl: downloadApiEndpoint(),
        eventsApiUrl: eventsApiEndpoint(),
        apiToken: stage.apiToken,
      });

      fetch();
    }
  }, [fetch, setApiInfo, stage.apiToken]);

  return (
    <div className="flex-col-container">
      <div className="flex-col-container" style={{ width: "95vw" }}>
        <WelcomeActions />
      </div>
      <div
        style={{ width: "100vw", textAlign: "left" }}
        className="flex-row-container"
      >
        <QuickstartContent />
      </div>
    </div>
  );
});
