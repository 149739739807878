import { API } from "aws-amplify";

export const sleep = (timeoutMs) =>
  new Promise((resolve) => setTimeout(() => resolve(), timeoutMs));

export const awsSubscribe = async ({ regToken, email, name, companyName }) => {
  // Added for testing purposes only. Bypasses AWS Marketplace API.
  const FAKE = regToken.startsWith("new-customer");

  if (FAKE) {
    await sleep(1000);

    if (regToken === "new-customer-success") {
      return {
        type: "success",
        response: {
          access: [
            {
              type: "sgx",
              endsAt: new Date(Date.now() + 1000 * 1000),
              licenseType: "trial",
            },
            {
              type: "nitro",
              endsAt: new Date(Date.now() + 1000 * 1000),
              licenseType: "trial",
            },
          ],
        },
      };
    } else {
      return {
        type: "error",
        message: `We were unable to link your Anjuna account to your AWS Marketplace subscription. Try again by signing out and signing in one more time. If this does not work, please contact support@anjuna.io.`,
      };
    }
  }

  const apiName = "awssubscribe"; // replace this with your api name.
  const path = "/api/v1/aws/subscribe"; // replace this with the path you have configured on your API
  const myInit = {
    body: {
      email,
      companyName: companyName,
      contactPerson: name,
      regToken,
    },
    headers: {},
  };

  try {
    const response = await API.post(apiName, path, myInit);

    console.log("API call succeeded", JSON.stringify(response));

    return {
      companyName,
      ...response,
    };
  } catch (error) {
    console.error(
      "awsSubscribe API call failed",
      JSON.stringify(error.response)
    );

    if (error.response.data.code === "ExpiredTokenException") {
      return {
        type: "error",
        messageHtml: `<p>We were unable to link your Anjuna account to your AWS Marketplace subscription, because the token provided by the AWS Marketplace expired.</p>Please try the following:<ul><li>Refresh the browser tab for the AWS Marketplace web page that sent you to the Resource Center.</li><li>You might be asked to sign in to your AWS account again.</li><li>Within your Anjuna subscription, click the link to Set Up Your Account.</li></ul><p>If this still does not work, please contact support@anjuna.io.</p>`,
      };
    }

    return {
      type: "error",
      message: `We were unable to complete linking your Anjuna account to your AWS Marketplace subscription. Anjuna will be in touch with next steps within the next few business days. If you do not hear from us or have any questions, please contact support@anjuna.io.`,
    };
  }
};
