import React from "react";
import * as THREE from "three";
import { useCombinedMeshStore } from "../../composite-mesh/useCompositeMeshStore";
import { CompositeMeshBuilder } from "../../composite-mesh/CompositeMeshBuilder";
import { useTheme } from "../../../../useThemeStore";
import { isStepVisible, useQuickstart } from "../useQuickstartStore";
import {
  ItemOptions,
  addBuildPhase,
  addClientComputer,
  addDeployPhase,
  addEc2InstanceGroup,
  addInstallPhase,
  addParentInstance,
  addRootGroup,
  addRunningEnclave_EnclaveSide,
  addRunningEnclave_HostSide,
  addVsock,
} from "./visual-builder-helpers";

export const useVisual = () => {
  const instanceId = "combined";
  const update = useCombinedMeshStore((state) => state.update);
  const theme = useTheme();

  const emissive = theme.palette.background.paper;
  const emissiveIntensity = theme.palette.mode === "dark" ? 1 : 0.2;
  const color = theme.palette.background.default;
  const roughness = theme.palette.mode === "dark" ? 0.6 : 0.125;

  const quickstartState = useQuickstart((state) => state.state);

  const definitions = useQuickstart((state) => state.definitions);
  const selectedApp = useQuickstart((state) => state.selectedApp());

  const allValues = useQuickstart((state) => state.allValues());
  const walkthroughStarted = useQuickstart((state) => state.walkthroughStarted);

  React.useEffect(() => {
    const builder = new CompositeMeshBuilder({
      dir: "centered",
    });

    addRootGroup(builder);

    const baseOptions: Omit<
      ItemOptions,
      "step" | "dir" | "definition" | "isActiveDefinition" | "selectedEntity"
    > = {
      builder,
      emissive,
      emissiveIntensity,
      roughness,
      color,
      theme,
      allValues,
      quickstartState,
      walkthroughStarted,
    };

    addClientComputer(baseOptions);

    addEc2InstanceGroup({
      ...baseOptions,
      dir: "right",
      step: "creating instance",
    });

    addParentInstance({
      ...baseOptions,
      dir: "right",
      step: "instance created",
    });

    for (const definition of definitions) {
      const options: Omit<ItemOptions, "step" | "dir"> = {
        ...baseOptions,
        isActiveDefinition: definition.id === selectedApp.id,
        definition,
      };

      addInstallPhase({ ...options, step: "installing tools" });

      addBuildPhase(options);

      addDeployPhase(options);

      definition.containers.forEach(({ container }, i) => {
        addRunningEnclave_HostSide({
          ...options,
          step: "deploying enclave",
          container,
          i,
        });
      });
    }

    builder.up(); // Deploy phase

    for (const definition of definitions) {
      if (definition.id !== selectedApp.id) {
        continue;
      }

      const options: Omit<ItemOptions, "step" | "dir" | "selectedEntity"> = {
        ...baseOptions,
        isActiveDefinition: definition.id === selectedApp.id,
        definition,
      };

      addVsock({
        ...options,
        step: "deploying enclave",
        containers: definition.containers.map((c) => c.container),
      });

      builder.addGroup(
        "",
        undefined,
        {
          status: isStepVisible(quickstartState, "deploying enclave")
            ? undefined
            : "collapsed",
          border: new THREE.Vector3(0, 0, 0),
          gaps: new THREE.Vector3(0, 1, 0),
        },
        {
          kind: "null",
        }
      );

      definition.containers.forEach((container, i) => {
        addRunningEnclave_EnclaveSide({
          ...options,
          step: "deploying enclave",
          container,
          i,
        });
      });

      builder.up();
    }

    update(builder);
  }, [
    allValues,
    color,
    definitions,
    emissive,
    emissiveIntensity,
    instanceId,
    quickstartState,
    roughness,
    selectedApp,
    theme,
    theme.palette.background.paper,
    theme.palette.mode,
    update,
    walkthroughStarted,
  ]);
};
