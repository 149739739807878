import React from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";

type Props = {
  busy: boolean;
  progressColor?: "primary" | "secondary";
  "data-testid"?: string;
} & ButtonProps;

export const ButtonWithProgress = (props: Props) => {
  const { busy, progressColor, ...rest } = props;

  return (
    <Button disabled={busy} {...rest}>
      {props.children}
      {busy ? (
        <CircularProgress
          data-testid={`${
            props["data-testid"] || "button-with-progress"
          }-progress`}
          style={{
            marginLeft: 8,
            color: progressColor === "secondary" ? "white" : "unset",
          }}
          size={20}
        />
      ) : null}
    </Button>
  );
};
