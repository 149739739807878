import React, { useCallback } from "react";
import { TableCell, TableCellProps, TableSortLabel } from "@mui/material";

export const SortableTableCell = (
  props: React.PropsWithChildren<{
    id: string;
    orderBy: string;
    order: "asc" | "desc";
    onSortClick: (orderBy: string) => void;
    className?: string;
    width?: number;
  }>
) => {
  const { id, children, orderBy, order, onSortClick, className, width } = props;

  const handleClick = useCallback(() => onSortClick(id), [onSortClick, id]);

  return (
    <TableCell className={className} width={width} style={{ width }}>
      <TableSortLabel
        active={orderBy === id}
        direction={orderBy === id ? order : "asc"}
        onClick={handleClick}
        data-testid={`sortable-header-${id}`}
        style={{ width }}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  );
};

export const BodyTableCell = (
  props: React.PropsWithChildren<{ width?: number } & TableCellProps>
) => {
  const { children, width, ...rest } = props;

  return (
    <TableCell width={width} {...rest}>
      <span style={{ width }}>{children}</span>
    </TableCell>
  );
};

export const scrollableTableContainerStyle: React.CSSProperties = {
  maxHeight: `calc(100vh - 360px)`,
  overflowY: "auto",
};
