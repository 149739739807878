import React from "react";
import { v4 as uuid } from "uuid";
import {
  Box,
  Button,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ImageUpload } from "../../ImageUpload";
import {
  PocStepCodeFile,
  QuickstartContainerDefinition,
  QuickstartDefinition,
  QuickstartDockerFiles,
} from "../../poc-types";
import { useCloseModalOnEscape } from "../useCloseStepViewOnEscape";
import { FileDesc, FileDropZone, FilePreviews } from "./FileDropZone";
import { standardQuickstartFiles } from "../../quickstartDefinitions";
import { modalStyle } from "./styles";
import { useTheme } from "../../../../../useThemeStore";
import { QuickstartParameters } from "./QuickstartParameters";
import { makeQuickstartStore, useQuickstart } from "../../useQuickstartStore";
import { AddOutlined } from "@mui/icons-material";
import {
  stepResultCode,
  validateContainerId,
  validateDockerImage,
  validateQuickstart,
} from "../../quickstart-util";
import { makeStyles } from "@mui/styles";
import { ValidatedTextField } from "./QuickstartFormControls";

const closeButtonStyle: React.CSSProperties = {
  position: "absolute",
  right: 0,
  top: 0,
  margin: 16,
};

const buttonStyle: React.CSSProperties = {
  right: 0,
  top: 0,
  marginLeft: 16,
};

const labelStyles: React.CSSProperties = {
  textTransform: "uppercase",
  fontSize: "smaller",
  color: "#999",
  fontWeight: 900,
  fontFamily: "Satoshi Variable",
};

export const useTabStyles = makeStyles((theme: Theme) => ({
  tabsRoot: {
    position: "relative",
  },
  tabIndicator: {},
  tab: {
    height: 40,
    textTransform: "initial",
    fontSize: 17,
    borderRadius: "5px 5px 0 0",
    // marginLeft: theme.spacing(0.75),
    marginRight: theme.spacing(1.5),
    padding: 10 + 1,
    paddingLeft: 15,
    paddingBottom: 11,
  },
  selectedTab: {
    background: theme.palette.background.paper,
    "& .MuiTypography-root": {
      color: theme.palette.text.primary,
    },
  },
}));

type Props = {
  operation: "edit" | "add";
  open: boolean;
  defaults?: QuickstartDefinition;
  onClose: () => void;
  onConfirm: (
    title: string,
    files: PocStepCodeFile[],
    containers: QuickstartContainerDefinition[],
    description: string | undefined,
    image: string
  ) => void;
};

const DockerOptions = (props: {
  container: QuickstartContainerDefinition;
  onUpdate: (newContainer: Partial<QuickstartContainerDefinition>) => void;
  store: typeof useQuickstart;
}) => {
  const { container, onUpdate, store } = props;

  const handleOptionChange = React.useCallback(
    (event: React.ChangeEvent, value: string) => {
      onUpdate({
        ...container,
        docker:
          value === "existing"
            ? {
                kind: "existing",
                image:
                  container.docker.kind === "existing"
                    ? container.docker.image
                    : "",
              }
            : {
                kind: "provided",
                files:
                  container.docker.kind === "provided"
                    ? container.docker.files
                    : [],
              },
      });
    },
    [container, onUpdate]
  );

  const handleFilesAdded = React.useCallback(
    (newFiles: FileDesc[]) => {
      onUpdate({
        ...container,
        docker: {
          ...container.docker,
          kind: "provided",
          files: (container.docker as QuickstartDockerFiles).files.concat(
            newFiles.map((file) => ({
              ...file,
              name: `${container.container}/docker/${file.name}`,
              operation: "docker input",
            }))
          ),
        },
      });
    },
    [container, onUpdate]
  );

  const containerFiles = store((state) =>
    state.filesForOperation("docker input", container.container)
  );

  const handleContainerFileDelete = React.useCallback(
    (file: string) => {
      onUpdate({
        ...container,
        docker: {
          ...container.docker,
          kind: "provided",
          files: (container.docker as QuickstartDockerFiles).files.filter(
            (f) => f.name !== file
          ),
        },
      });
    },
    [container, onUpdate]
  );

  const handleDockerImageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onUpdate({
        ...container,
        docker: {
          ...container.docker,
          kind: "existing",
          image: event.target.value,
        },
      });
    },
    [container, onUpdate]
  );

  const handleValidatedDockerImage = React.useCallback((value: string) => {
    return validateDockerImage({
      kind: "existing",
      image: value,
    })
      ? ""
      : "A valid Docker image must be specified.";
  }, []);

  const existingImageUI =
    container.docker.kind === "existing" ? (
      <ValidatedTextField
        value={container.docker.image}
        onChange={handleDockerImageChange}
        onValidate={handleValidatedDockerImage}
        placeholder={"Docker image e.g. nginx:latest"}
        sx={{ mb: 3, width: 300 }}
        data-testid={`add-quickstart-docker-image`}
        size="small"
      />
    ) : null;

  const dockerFilesUI =
    container.docker.kind === "provided" ? (
      <>
        <div
          className="flex-col-container"
          style={{ marginRight: 16, marginBottom: 16 }}
        >
          <Typography style={labelStyles}>Add Docker input files:</Typography>
          <FileDropZone
            files={container.docker.files}
            addFiles={handleFilesAdded}
          />
        </div>
        <div className="flex-col-container">
          <Typography style={labelStyles}>Docker input files:</Typography>
          {containerFiles.length ? (
            <FilePreviews
              files={containerFiles}
              onDelete={handleContainerFileDelete}
            />
          ) : (
            <Typography>No files uploaded.</Typography>
          )}
        </div>
      </>
    ) : null;

  return (
    <FormControl component="fieldset">
      <RadioGroup
        value={container.docker.kind}
        onChange={handleOptionChange}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <FormControlLabel
          value="existing"
          control={<Radio />}
          label="Use existing Docker image"
        />
        <FormControlLabel
          value="provided"
          control={<Radio />}
          label="Provide Dockerfile and inputs"
        />
      </RadioGroup>
      {existingImageUI}
      {dockerFilesUI}
    </FormControl>
  );
};

const ContainerSection = React.memo(
  (props: {
    container: QuickstartContainerDefinition;
    onUpdate: (newContainer: Partial<QuickstartContainerDefinition>) => void;
    store: typeof useQuickstart;
  }) => {
    const { container, onUpdate, store } = props;

    const [containerName, setContainerName] = React.useState(
      // Better to show placeholder than initial untitled
      container.container === "untitled" ? "" : container.container
    );

    const handleContainerIdChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setContainerName(event.target.value);

        if (event.target.value === "") return;

        onUpdate({
          container: event.target.value,
          docker:
            container.docker.kind === "provided"
              ? {
                  kind: "provided",
                  files: container.docker.files.map((file) => ({
                    ...file,
                    content: file.content.replaceAll(
                      `<<${container.container}`,
                      `<<${event.target.value}`
                    ),
                    name: file.name.replace(
                      container.container,
                      event.target.value
                    ),
                  })),
                }
              : container.docker,
        });
      },
      [container.container, container.docker, onUpdate]
    );

    const handleContainerImageUploaded = React.useCallback(
      (newImage: string) => {
        onUpdate({
          ...container,
          imageUrl: newImage,
        });
      },
      [container, onUpdate]
    );

    const configParams = store((state) =>
      state.parametersForOperation("enclave config", container.container)
    );

    const runParams = store((state) =>
      state.parametersForOperation("deploy enclave", container.container)
    );

    const handleParamChange = React.useCallback(
      (param: string, value: string) => {
        const keyInContainer = param.substring(container.container.length + 1);

        onUpdate({
          ...container,
          options: {
            ...container.options,
            [keyInContainer]: value,
          },
        });
      },
      [container, onUpdate]
    );

    const handleValidateContainerId = React.useCallback((value: string) => {
      return validateContainerId(value)
        ? ""
        : "Invalid input. Only alphanumeric characters and hyphens are allowed.";
    }, []);

    const theme = useTheme();

    return (
      <Fade in timeout={300}>
        <div className="flex-col-container flex-spacer" style={{ padding: 16 }}>
          <div className="flex-row-container">
            <div className="flex-col-container flex-spacer">
              <Typography style={labelStyles}>Container:</Typography>
              <ValidatedTextField
                value={containerName}
                onChange={handleContainerIdChange}
                onValidate={handleValidateContainerId}
                placeholder="Container Id e.g. nginx-proxy"
                helperText="Unique identifier for the container and enclave. May contain only alphanumeric characters and hyphens."
                sx={{ mb: 3, width: "90%" }}
                data-testid={`add-quickstart-containerId`}
                size="small"
              />
            </div>
            <div className="flex-col-container flex-spacer">
              <Box sx={{ mb: 3 }} component="div">
                <Typography style={labelStyles}>Container Preview:</Typography>
                <div className="flex-row-container flex-centered">
                  <ImageUpload
                    imageUrl={container.imageUrl}
                    onImageUpload={handleContainerImageUploaded}
                  />
                  <FormHelperText sx={{ ml: 2 }}>
                    Customize how the container appears in the visualization.
                    SVG and PNG files are supported.
                  </FormHelperText>
                </div>
              </Box>
            </div>
          </div>
          <div className="flex-row-container flex-wrap">
            <DockerOptions {...props} />
          </div>
          <Box
            component="div"
            sx={{
              p: 2,
              background: theme.palette.background.default,
              borderRadius: 1,
            }}
          >
            <Typography style={labelStyles}>Configuration options:</Typography>
            <QuickstartParameters
              parameters={configParams}
              store={store}
              onParamChange={handleParamChange}
            />
          </Box>
          <Typography style={labelStyles} sx={{ mt: 2 }}>
            Deploy options:
          </Typography>
          <QuickstartParameters parameters={runParams} store={store} />
        </div>
      </Fade>
    );
  }
);

const Containers = React.memo(
  (props: {
    containers: QuickstartContainerDefinition[];
    onUpdateContainers: (
      newContainers: QuickstartContainerDefinition[]
    ) => void;
    // onUpdateContainerError: (container: string, error: string | null) => void;
    store: typeof useQuickstart;
  }) => {
    const { containers, onUpdateContainers, store } = props;

    const tabClasses = useTabStyles();

    const [selected, setSelected] = React.useState(0);

    const handleSelectTab = React.useCallback(
      (event: React.SyntheticEvent, newValue: number) => {
        setSelected(newValue);
      },
      [setSelected]
    );

    const handleAddContainer = React.useCallback(() => {
      const defaultName = "untitled";

      onUpdateContainers([
        ...containers,
        {
          container: defaultName,
          docker: { kind: "existing", image: "" },
          // files: standardContainerFiles(defaultName),
          imageUrl: "/question.svg",
          options: {
            "cpu count": 2,
            memory: 1024,
            exposedPorts: [undefined],
            files: [],
            mounts: [],
            environment: [],
          },
        },
      ]);

      setSelected(containers.length);
    }, [containers, onUpdateContainers]);

    const handleUpdateContainer = React.useCallback(
      (newContainer: Partial<QuickstartContainerDefinition>) => {
        const newContainers = [...containers];
        newContainers[selected] = {
          ...newContainers[selected],
          ...newContainer,
        };
        // onUpdateContainerError(containers[selected].container, null);
        onUpdateContainers(newContainers);
      },
      [containers, onUpdateContainers, selected]
    );

    const handleDeleteContainer = React.useCallback(
      (index: number) => {
        const newContainers = [...containers];
        newContainers.splice(index, 1);
        // onUpdateContainerError(containers[selected].container, null);
        onUpdateContainers(newContainers);
        setSelected(
          Math.max(0, Math.min(selected - 1, newContainers.length - 1))
        );
      },
      [containers, onUpdateContainers, selected]
    );

    return (
      <Box sx={{ mb: 2, height: "100%" }} component="div">
        <div className="flex-row-container flex-centered">
          <Tabs
            value={selected}
            onChange={handleSelectTab}
            style={{ alignItems: "center" }}
            classes={{
              root: tabClasses.tabsRoot,
              scroller: tabClasses.tabsRoot,
              indicator: tabClasses.tabIndicator,
            }}
          >
            {containers.map((container, index) => {
              return (
                <Tab
                  key={index}
                  classes={{
                    selected: tabClasses.selectedTab,
                    root: tabClasses.tab,
                  }}
                  label={
                    <div className="flex-row-container flex-centered">
                      {container.container}
                      <IconButton
                        style={{ marginLeft: 12 }}
                        size="small"
                        onClick={(e) => {
                          handleDeleteContainer(index);
                          e.stopPropagation();
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </div>
                  }
                />
              );
            })}
          </Tabs>
          {containers.length ? (
            <div className="flex-row-container flex-centered">
              {containers.length >= 4 ? (
                <Typography style={{ fontSize: "smaller" }}>
                  (Maximum containers reached)
                </Typography>
              ) : (
                <IconButton onClick={handleAddContainer}>
                  <AddOutlined color="primary" />
                </IconButton>
              )}
            </div>
          ) : (
            <Button
              variant="contained"
              sx={{ width: 170, mt: "30%", ml: "40%" }}
              onClick={handleAddContainer}
              data-testid="add-quickstart-add-container"
            >
              Add Container
            </Button>
          )}
          {containers.length ? <div className="flex-spacer" /> : null}
        </div>
        {containers[selected] ? (
          <Paper
            style={{
              padding: 16,
              overflowY: "auto",
              height: "calc(100% - 30px)",
              borderRadius: `0 8px 8px 8px`,
            }}
          >
            <ContainerSection
              key={selected}
              container={containers[selected]}
              onUpdate={handleUpdateContainer}
              store={store}
            />
          </Paper>
        ) : null}
      </Box>
    );
  }
);

const ModalContent = React.forwardRef(
  (props: Props & { store: typeof useQuickstart }, ref) => {
    const { onConfirm: onAdd, onClose, store } = props;

    const selectedApp = store((state) => state.selectedApp());
    const { id, title, imageUrl, containers, files, descriptionMarkdown } =
      selectedApp;

    const updateActive = store((state) => state.updateActive);

    const handleTitleChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        updateActive({ id, title: event.target.value });
      },
      [id, updateActive]
    );

    const handleDescriptionChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        updateActive({ id, descriptionMarkdown: event.target.value });
      },
      [id, updateActive]
    );

    const handleImageUploaded = React.useCallback(
      (newImage: string) => {
        updateActive({ id, imageUrl: newImage });
      },
      [id, updateActive]
    );

    const allValues = store((state) => state.allValues());

    const handleUpdateContainers = React.useCallback(
      (newContainers: QuickstartContainerDefinition[]) => {
        updateActive({
          id,
          containers: newContainers,
          files: standardQuickstartFiles(newContainers, allValues),
        });
      },
      [allValues, id, updateActive]
    );

    const handleAdd = React.useCallback(() => {
      onAdd(title, files, containers, descriptionMarkdown, imageUrl);

      onClose();
    }, [
      containers,
      descriptionMarkdown,
      files,
      imageUrl,
      onAdd,
      onClose,
      title,
    ]);

    const scaffoldingFiles = () => [
      ...standardQuickstartFiles(containers, allValues),
    ];

    const theme = useTheme();

    return (
      <Box
        sx={modalStyle}
        style={{
          background: theme.palette.mode === "dark" ? `#202020` : `#f0f0f0`,
        }}
        component="div"
        className="flex-row-container"
      >
        <div className="flex-col-container flex-spacer">
          <IconButton style={closeButtonStyle} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <div
            className="flex-row-container flex-spacer"
            style={{ height: "100%" }}
          >
            <div
              className="flex-col-container"
              style={{
                flex: 0.6,
                paddingRight: 16,
                marginRight: 16,
                overflowY: "auto",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h2"
                component="h1"
                style={{ marginBottom: 20 }}
              >
                Configure Quickstart
              </Typography>
              <Typography style={labelStyles}>Title:</Typography>
              <TextField
                value={title}
                onChange={handleTitleChange}
                placeholder={"Quickstart title"}
                size="small"
                data-testid={`add-quickstart-title`}
              />
              <Typography style={labelStyles} sx={{ mt: 2 }}>
                Description:
              </Typography>
              <TextField
                value={descriptionMarkdown}
                placeholder="Use Markdown to describe what this quickstart is for"
                onChange={handleDescriptionChange}
                fullWidth
                multiline
                minRows={3}
                size="small"
                data-testid={`add-quickstart-description`}
              />
              <Typography style={labelStyles} sx={{ mt: 2 }}>
                Thumb image:
              </Typography>
              <ImageUpload
                imageUrl={imageUrl}
                onImageUpload={handleImageUploaded}
              />
              {containers.length > 0 ? (
                <>
                  <Typography style={labelStyles} sx={{ mt: 2 }}>
                    Standard Files:
                  </Typography>
                  <FilePreviews
                    files={scaffoldingFiles().map((file) => ({
                      ...file,
                      content: stepResultCode(
                        file.content,
                        allValues,
                        containers.map((c) => c.container)
                      ),
                    }))}
                  />
                </>
              ) : null}
              <div className="flex-spacer" />
              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 32 }}
              >
                <div style={{ flex: 1 }} />
                <Button
                  style={buttonStyle}
                  variant="outlined"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  style={buttonStyle}
                  variant="contained"
                  onClick={handleAdd}
                  disabled={!validateQuickstart(selectedApp)}
                  data-testid={`add-quickstart-add-button`}
                >
                  Save
                </Button>
              </div>
            </div>
            <div
              className="flex-col-container flex-spacer"
              style={{
                height: "100%",
              }}
            >
              <Containers
                containers={containers}
                onUpdateContainers={handleUpdateContainers}
                // onUpdateContainerError={handleUpdateContainerError}
                store={store}
              />
            </div>
          </div>
        </div>
      </Box>
    );
  }
);

export const uniqueString = (text: string, existing: string[], sep = " ") => {
  let i = 1;
  let cur = text;
  while (existing.includes(cur)) {
    cur = `${text}${sep}${i}`;
    i++;
  }
  return cur;
};

const useConfigureQuickstartStore = makeQuickstartStore({
  editMode: true,
  connectedToBackend: false,
});

export const ConfigureQuickstartModal = (props: Props) => {
  React.useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = ""; // Required for Chrome and Firefox
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const store = useConfigureQuickstartStore;

  const addDefinition = store((state) => state.addDefinition);
  const removeDefinition = store((state) => state.removeDefinition);

  React.useEffect(() => {
    if (props.operation === "add") {
      addDefinition({
        id: uuid(),
        title: "",
        descriptionMarkdown: "",
        imageUrl: "/question.svg",
        files: [],
        containers: [],
      });
    }
  }, [addDefinition, props.defaults?.id, props.operation]);

  React.useEffect(() => {
    if (props.operation === "edit") {
      if (props.defaults) {
        addDefinition(props.defaults);
      }
    }
  }, [addDefinition, props.defaults, props.operation]);

  const cleanup = React.useCallback(() => {
    removeDefinition("custom");
  }, [removeDefinition]);

  const handleClose = React.useCallback(() => {
    cleanup();

    props.onClose();
  }, [cleanup, props]);

  const handleConfirm = React.useCallback(
    (
      title: string,
      files: PocStepCodeFile[],
      containers: QuickstartContainerDefinition[],
      description: string | undefined,
      image: string
    ) => {
      cleanup();

      props.onConfirm(title, files, containers, description, image);
    },
    [cleanup, props]
  );

  useCloseModalOnEscape(handleClose);

  const stopPropagation = React.useCallback(
    (e: React.PointerEvent) => e.stopPropagation(),
    []
  );

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      onPointerDown={stopPropagation}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContent
        {...props}
        key={"configure-quickstart-modal"}
        onConfirm={handleConfirm}
        onClose={handleClose}
        store={store}
      />
    </Modal>
  );
};
