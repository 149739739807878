import { API } from "aws-amplify";

export type ARCEvent = {
  message: string;
  data: { [key: string]: any } & {
    kind: "ARC";
    version: "1.0";
  };
};

export const eventsApiEndpoint = () => {
  const { hostname } = window.location;
  const isTestEnv =
    hostname.startsWith("test") ||
    hostname.startsWith("localhost") ||
    hostname.startsWith("192");

  const endpoint = isTestEnv
    ? "https://ial5k99rgf.execute-api.us-east-1.amazonaws.com/test"
    : "https://api.downloads.anjuna.io/events";

  return endpoint;
};

export const postEventAPI = async (
  token: string,
  event: ARCEvent
): Promise<{ type: "success" } | { type: "error"; message: string }> => {
  const apiName = "apiEvents";
  const path = "/api/v1/events";

  const myInit = {
    headers: {},
    body: event,
    queryStringParameters: {
      token,
    },
  };

  console.log("Posting event: " + JSON.stringify(event as any, undefined, 2));

  try {
    await API.post(apiName, path, myInit);

    return {
      type: "success",
    };
  } catch (error) {
    console.error(
      "PUT template content API call failed",
      JSON.stringify(error)
    );

    return {
      type: "error",
      message: `Putting template content list failed: ${JSON.stringify(error)}`,
    };
  }
};
