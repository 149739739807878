import React from "react";
import { Button, IconButton, IconButtonProps } from "@mui/material";
import { useQuickstart } from "../../useQuickstartStore";
import { CloseOutlined } from "@mui/icons-material";

export const QuickstartViewNextButton = () => {
  const nextStep = useQuickstart((state) => state.nextStep);

  const ENABLED = false;
  return ENABLED ? (
    <Button
      variant="contained"
      style={{ position: "absolute", bottom: 32, right: 32 }}
      onClick={nextStep}
    >
      Next
    </Button>
  ) : null;
};

export const QuickstartViewCloseButton = (props: IconButtonProps) => {
  const closeStepView = useQuickstart((state) => state.closeStepView);
  return (
    <IconButton
      style={{ position: "absolute", top: 16, right: 16, ...props.style }}
      onClick={closeStepView}
      data-testid="quickstart-close-view-button"
    >
      <CloseOutlined fontSize="large" />
    </IconButton>
  );
};
