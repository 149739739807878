import { FormControlLabel, Switch } from "@mui/material";
import React from "react";
import DropdownList from "../../DropdownList";
import {
  PocScriptStepCheckboxParameter,
  PocScriptStepListParameter,
  PocScriptStepListPararameterObject,
  PocScriptStepModel,
  PocScriptStepNumericParameter,
  PocScriptStepRadioGroupParameter,
  PocScriptStepSelectParameter,
} from "../../poc-types";
import { RadioList } from "../../RadioList";
import { NumericInput } from "../../NumericInput";
import { KeyValue, ObjectList } from "../../enclave-config/ObjectList";
import { NumberList } from "../../enclave-config/NumberList";
import { KeyValueList } from "../../enclave-config/KeyValueList";
import type {
  QuickstartOptions,
  useQuickstart,
} from "../../useQuickstartStore";

const SelectParameter = ({
  parameter,
  value,
  onSelect,
}: {
  parameter: PocScriptStepSelectParameter;
  value: string;
  onSelect: (value: string) => void;
}) => {
  return (
    <DropdownList
      title={parameter.title}
      options={parameter.options}
      value={value}
      onSelect={onSelect}
    />
  );
};

const RadioGroupParameter = ({
  parameter,
  value,
  onSelect,
}: {
  parameter: PocScriptStepRadioGroupParameter;
  value: string;
  onSelect: (value: string) => void;
}) => {
  return (
    <RadioList
      title={parameter.title}
      options={parameter.options}
      value={value}
      onSelect={onSelect}
    />
  );
};

const NumericParameter = ({
  parameter,
  value,
  onSelect,
}: {
  parameter: PocScriptStepNumericParameter;
  value: string;
  onSelect: (value: string) => void;
}) => {
  const onChange = React.useCallback(
    (newValue: number) => {
      onSelect(newValue.toFixed(0));
    },
    [onSelect]
  );

  return (
    <div>
      <NumericInput
        label={parameter.title}
        value={value || parameter.value}
        max={parameter.max}
        min={parameter.min}
        onChange={onChange}
        style={{ marginBottom: 16, width: 150 }}
      />
    </div>
  );
};

const CheckboxParameter = ({
  parameter,
  value,
  checkedValue,
  uncheckedValue,
  onSelect,
}: {
  parameter: PocScriptStepCheckboxParameter;
  value: string;
  checkedValue: string;
  uncheckedValue: string;
  onSelect: (value: string) => void;
}) => {
  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onSelect(checked ? checkedValue : uncheckedValue);
    },
    [checkedValue, onSelect, uncheckedValue]
  );

  return (
    <FormControlLabel
      control={
        <Switch
          checked={value === parameter.checkedValue}
          onChange={onChange}
        />
      }
      label={parameter.title}
    />
  );
};

type ListParamValue =
  | (number | undefined)[]
  | KeyValue[]
  | PocScriptStepListPararameterObject[];

const ListParameter = ({
  parameter,
  value,
  onSelect,
}: {
  parameter: PocScriptStepListParameter;
  value: ListParamValue;
  onSelect: (value: ListParamValue) => void;
}) => {
  const onChange = React.useCallback(
    (
      newValue:
        | KeyValue[]
        | (number | undefined)[]
        | PocScriptStepListPararameterObject[]
    ) => {
      onSelect(newValue);
    },
    [onSelect]
  );

  return parameter.data.kind === "numeric" ? (
    <NumberList
      id={parameter.id}
      title={parameter.title}
      label={parameter.title}
      placeholder={parameter.placeholder}
      min={parameter.data.min}
      max={parameter.data.max}
      onChange={onChange}
      items={value as number[]}
    />
  ) : parameter.data.kind === "key value" ? (
    <KeyValueList
      title={parameter.title}
      keyValues={value as KeyValue[]}
      onChange={onChange}
    />
  ) : (
    <ObjectList
      title={parameter.title}
      objects={value as PocScriptStepListPararameterObject[]}
      schema={parameter.data.schema}
      defaultValue={parameter.data.defaultValue}
      onChange={onChange}
    />
  );
};

export const QuickstartParameter = ({
  parameter,
  values,
  store,
  onChange,
}: {
  parameter: PocScriptStepModel["parameters"][0];
  values: QuickstartOptions;
  store: typeof useQuickstart;
  onChange?: (param: string, value: string) => void;
}) => {
  const value = values[parameter.id as keyof QuickstartOptions] || "";

  const setOption = store((state) => state.setOption);

  const onParameterValueChange = React.useCallback(
    (newValue: any) => {
      if (onChange) {
        // Editing.
        onChange(parameter.id as keyof QuickstartOptions, newValue);
      } else {
        setOption(parameter.id as keyof QuickstartOptions, newValue);
      }
    },
    [onChange, parameter.id, setOption]
  );

  switch (parameter.kind) {
    case "select": {
      return (
        <SelectParameter
          parameter={parameter}
          value={value}
          onSelect={onParameterValueChange}
        />
      );
    }
    case "radio group": {
      return (
        <RadioGroupParameter
          parameter={parameter}
          value={value}
          onSelect={onParameterValueChange}
        />
      );
    }
    case "numeric": {
      return (
        <NumericParameter
          parameter={parameter}
          value={value}
          onSelect={onParameterValueChange}
          data-testid={`numeric-parameter-${parameter.id}`}
        />
      );
    }
    case "checkbox": {
      return (
        <CheckboxParameter
          parameter={parameter}
          value={value}
          checkedValue={parameter.checkedValue}
          uncheckedValue={parameter.uncheckedValue}
          onSelect={onParameterValueChange}
        />
      );
    }
    case "list": {
      return (
        <ListParameter
          parameter={parameter}
          value={value}
          onSelect={onParameterValueChange}
        />
      );
    }
  }
};
