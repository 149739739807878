import React from "react";
import {
  Alert,
  Button,
  ButtonProps,
  CircularProgress,
  Snackbar,
} from "@mui/material";

export const DownloadButton = ({
  downloadAPI,
  filename,
  variant,
  label,
  disabled,
}: {
  downloadAPI: () => Promise<
    | { type: "success"; response?: { location: string } }
    | { type: "error"; message: string }
  >;
  filename: string;
  disabled?: boolean;
  label?: string;
  variant?: ButtonProps["variant"];
}) => {
  const [busy, setBusy] = React.useState(false);
  const [error, setError] = React.useState("");
  const [downloadStarted, setDownloadStarted] = React.useState(false);

  const handleClick = React.useCallback(async () => {
    setBusy(true);

    const result = await downloadAPI();

    if (result.type === "success") {
      setDownloadStarted(true);

      if (result.response) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = result.response.location;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(a.href);
      }
    } else {
      setError(result.message);
    }

    setBusy(false);
  }, [downloadAPI, filename]);

  const handleClose = React.useCallback(() => {
    setError("");
    setDownloadStarted(false);
  }, []);

  return (
    <>
      <Button
        style={{
          textTransform: "inherit",
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
        onClick={handleClick}
        variant={variant}
        disabled={busy || disabled}
        data-testid="download-quickstart-button"
      >
        {/* <FileDownloadIcon style={{ marginRight: 8 }} /> */}
        {label || filename}
        <CircularProgress
          style={{ marginLeft: 8, visibility: busy ? "visible" : "hidden" }}
          size={20}
        />
      </Button>
      <Snackbar
        open={!!error || downloadStarted}
        autoHideDuration={downloadStarted ? 2500 : 10000}
        onClose={handleClose}
      >
        <Alert severity={error ? "error" : "success"}>
          {error
            ? "Software download failed. Please contact support@anjuna.io"
            : "Download started"}
        </Alert>
      </Snackbar>
    </>
  );
};
