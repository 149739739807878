// @ts-check

export const AccountExpiryThresholds = {
  Warn: 30,
  Danger: 7,
};

/**
 * @param {'expired' | Exclude<string, 'expired'> | undefined} accountEndsAt
 * @returns {number}
 */
export const numberOfDaysUntilAccountExpires = (
  accountEndsAt: string | undefined,
  currentDateTime = new Date(Date.now())
) => {
  if (accountEndsAt === "expired" || !accountEndsAt) return 0;

  const endsAt = new Date(accountEndsAt);

  // @ts-ignore-next-line
  const diffTime = endsAt - currentDateTime;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

export const numberOfDaysUntilFreeTrialExpires = (
  endsAtPerTech: string[],
  currentDateTime = new Date(Date.now())
) => {
  const techsAccess = endsAtPerTech
    .filter((endsAt) => !!endsAt) // Ignore techs the org wasn't given access to
    .map((endsAt) => numberOfDaysUntilAccountExpires(endsAt, currentDateTime))
    .filter((days) => days > 0); // Free trial lasts as long as the last available tech

  if (techsAccess.length === 0) return 0;

  return Math.min(...techsAccess);
};

const hasAccountExpired = (accountEndsAt: string, currentDateTime: Date) =>
  new Date(accountEndsAt).getTime() <= currentDateTime.getTime();

/**
 *
 * @param {Date} date
 */
export const formatDate = (date: Date) => {
  const day = date.toLocaleString("default", { day: "numeric" });
  const year = date.getFullYear();
  const month = date.toLocaleString("default", { month: "long" });
  return `${day} ${month} ${year}`;
};

/**
 * @param {'expired' | Exclude<string, 'expired'>} accountEndsAt
 * @param {Date} currentDateTime
 * @returns {string}
 */
export const accountExpirationMessage = (
  accountEndsAt: string,
  currentDateTime = new Date(Date.now())
) => {
  if (accountEndsAt === "expired") {
    return "No access";
  }

  if (hasAccountExpired(accountEndsAt, currentDateTime)) {
    return `${formatDate(new Date(accountEndsAt))} (Expired)`;
  }

  const accountExpireDays = numberOfDaysUntilAccountExpires(
    accountEndsAt,
    currentDateTime
  );

  return `${formatDate(new Date(accountEndsAt))} (${accountExpireDays} days)`;
};
