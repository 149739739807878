// @ts-check
import React from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TerminalIcon from "@mui/icons-material/Terminal";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Modal,
} from "@mui/material";
import { CodeBox } from "./CodeBox";
import { downloadApiEndpoint } from "../../api/downloadAPI";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 920,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  maxHeight: "100%",
};

/**
 * @type {React.CSSProperties}
 */
const closeButtonStyle = {
  position: "absolute",
  right: 0,
  top: 0,
  margin: 8,
};

/**
 *
 * @param {{ apiToken: string, type: string, onOpenSettings: () => void }} props
 */
export const LicenseViaAPIButton = (props) => {
  const { apiToken, type, onOpenSettings } = props;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const endpoint = downloadApiEndpoint();

  return (
    <div>
      <IconButton onClick={handleOpen} title="Get license via API">
        <TerminalIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="license-modal-title"
        aria-describedby="license-modal-description"
      >
        <Box sx={modalStyle}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <TerminalIcon color="primary" fontSize={"large"} />
            <Typography
              id="license-modal-title"
              variant="h2"
              component="h2"
              style={{ marginLeft: 12, marginBottom: 0 }}
            >
              Get license via API
            </Typography>
          </div>
          <IconButton style={closeButtonStyle} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Get using <code style={{ color: "#c75" }}>wget</code>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontSize={"smaller"}>
                Run the following command in a terminal:
              </Typography>
              <CodeBox
                id="wget"
                code={`wget ${endpoint}/v1/releases/license/${type}/license.yaml \\
  --header="X-Anjuna-Auth-Token:${apiToken}"`}
              />
            </AccordionDetails>
          </Accordion>
          <Typography id="license-modal-description" sx={{ mt: 3 }}>
            Manage API access in{" "}
            <Link onClick={onOpenSettings} style={{ cursor: "pointer" }}>
              Settings
            </Link>
            .
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
