// @ts-check
import {
  Grid,
  Card,
  Typography,
  Tab,
  Grow,
  Link,
  SxProps,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { PageContent } from "../components/PageContent";
import { PageHeader } from "../components/PageHeader";
import { Fade } from "@mui/material";
import { Box } from "@mui/system";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useNavigate, useLocation } from "react-router-dom";
import { Downloads } from "./ResourceCenter/Downloads";
import { useAppStageState } from "../context/AppStageContext";
import { EULA } from "./ResourceCenter/EULA";
import { UserAccess } from "./ResourceCenter/UserAccess";
import { techDisplayName } from "./ResourceCenter/techDisplayName";
import { ProductAccess, Tech } from "../api/ApiTypes";
import { PocBuilder } from "./ResourceCenter/POC/POCBuilder";
import { QUICKSTART_URL } from "./ResourceCenter/POC/poc-constants";
import { AskAI } from "./ResourceCenter/AI/AskAI";

const DownloadsContainer = ({
  email,
  active,
  access: accessIn,
  handleChange,
}: {
  email: string;
  active: Tech;
  access: ProductAccess[];
  handleChange: (e: React.SyntheticEvent, value: string) => void;
}) => {
  const access = accessIn.filter((cur) => {
    if (cur.type === "apm") {
      return true;
    }

    // Hides GCP for orgs that do not have GCP access because it's pre-release.
    if (
      (cur.type === "gcp" || cur.type === "azsev" || cur.type === "k8s-sev") &&
      cur.endsAt === "expired"
    ) {
      return false;
    }

    return true;
  });

  return (
    <Grid container>
      <TabContext value={active}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" } as SxProps}
          component="div"
        >
          <TabList onChange={handleChange} aria-label="Software downloads tabs">
            {access.map((cur) => (
              <Tab
                key={cur.type}
                label={techDisplayName(cur.type)}
                value={cur.type}
              />
            ))}
          </TabList>
        </Box>
        {access.map((cur) => (
          <TabPanel key={cur.type} value={cur.type} sx={{ width: "100%" }}>
            <Downloads tech={cur.type} access={access} />
          </TabPanel>
        ))}
      </TabContext>
    </Grid>
  );
};

const PendingApproval = () => {
  return (
    <div style={{ minHeight: "55vh", margin: "5%" }}>
      <Typography variant="h2" style={{ marginBottom: 20 }}>
        Thanks for registering
      </Typography>
      <Typography>
        Your request to access the Anjuna software is being processed.
      </Typography>
      <Typography>
        We will update within the next business day.
      </Typography>
      <br />
      <Typography>
        If you have a question, feel free to contact the team at:
      </Typography>
      <Link href="mailto:resource-center-subscriptions@anjuna.io">
        resource-center-subscriptions@anjuna.io
      </Link>
      .
    </div>
  );
};

const AnimatedCard = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <Grow in timeout={300}>
      <Fade in timeout={600}>
        <Card variant="elevation" className="Dashboard-card">
          {children}
        </Card>
      </Fade>
    </Grow>
  );
};

const useStyles = makeStyles({
  tabRoot: {
    marginBottom: -11,
  },
});

export const DashboardContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const appStageState = useAppStageState();

  const classes = useStyles();

  const handleChange = React.useCallback(
    (e: React.SyntheticEvent, value: string) => {
      navigate(`/${value}`);
    },
    [navigate]
  );

  const typePlusVersion = location.pathname.substr("/".length);
  const indexOfVersion = typePlusVersion.indexOf("/");
  const isAdmin = location.pathname.startsWith("/admin");
  const isQuickstart = location.pathname.startsWith(`/${QUICKSTART_URL}`);
  const isHelp = location.pathname.startsWith(`/help`);
  const tech = typePlusVersion.substring(
    0,
    indexOfVersion >= 0 ? indexOfVersion : undefined
  );
  const active = tech || "nitro"; // Default to nitro if downloads path not specified - for AWS Marketplace.

  let content: JSX.Element | null = null;

  const isAnjunaUser =
    appStageState.stage.type === "dashboard" &&
    appStageState.stage.user.attributes.email.includes("@anjuna.io");

  const canAccessTemplates =
    appStageState.stage.type === "dashboard" &&
    appStageState.stage.templatesAccess;

  const canViewTabs =
    appStageState.stage.type === "dashboard" &&
    (isAnjunaUser || canAccessTemplates);

  if (appStageState.stage.type === "dashboard") {
    if (isAdmin && canViewTabs) {
      content = (
        <AnimatedCard>
          <UserAccess />
        </AnimatedCard>
      );
    } else if (isQuickstart) {
      content = <PocBuilder />;
    } else if (isHelp) {
      content = <AskAI />;
    } else {
      const { accountType, access } = appStageState.stage;
      switch (accountType) {
        case "downloads":
          content = (
            <AnimatedCard>
              <DownloadsContainer
                email={appStageState.stage.user.attributes.email}
                active={active as Tech}
                access={access}
                handleChange={handleChange}
              />
            </AnimatedCard>
          );
          break;
        case "pending":
          content = (
            <AnimatedCard>
              <PendingApproval />
            </AnimatedCard>
          );
          break;
        default:
        case "missing":
          content = (
            <AnimatedCard>
              <PendingApproval />
            </AnimatedCard>
          );
          break;
      }
    }
  }

  const onNavigate = React.useCallback(
    (e: React.SyntheticEvent, value: string | null) => {
      if (value === null) return;
      navigate(
        value === "admin"
          ? "/admin"
          : value === QUICKSTART_URL
          ? `/${QUICKSTART_URL}`
          : value === "help"
          ? `/help`
          : "/"
      );
    },
    [navigate]
  );

  const isProd = window.location.hostname === "downloads.anjuna.io";

  return (
    <>
      <PageHeader>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            style={{
              fontWeight: 400,
              fontSize: 16,
              alignSelf: "flex-end",
              marginBottom: 13,
            }}
          >
            Resource Center
          </Typography>
          <div className="flex-spacer" style={{ flex: 0.5 }} />
          {canViewTabs ? (
            <TabContext
              value={
                isAdmin
                  ? "admin"
                  : isQuickstart
                  ? QUICKSTART_URL
                  : isHelp
                  ? "help"
                  : "home"
              }
            >
              <TabList
                classes={{ root: classes.tabRoot }}
                onChange={onNavigate}
                aria-label="Platform"
              >
                {canAccessTemplates ? (
                  <Tab value={QUICKSTART_URL} label="Quickstart" />
                ) : null}
                <Tab label="Downloads" value="home" />

                {!!isAnjunaUser && <Tab value="admin" label="Admin" />}

                {!!isAnjunaUser && !isProd && <Tab value="help" label="Help" />}
              </TabList>
            </TabContext>
          ) : null}
          <div className="flex-spacer" />
        </div>
      </PageHeader>
      <PageContent application includeFooter>
        <div className="DashboardContainer-container">{content}</div>
        <EULA />
      </PageContent>
    </>
  );
};
