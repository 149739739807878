import { Theme } from "@mui/material";
import * as THREE from "three";
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader.js";

export type LoadedImageMesh = {
  group: THREE.Group;
  width: number;
  height: number;
};

const exemptions = [
  "/nginx.svg",
  "/terminal.svg",
  "anjuna-cli-light.svg",
  "/postgresql.svg",
  "/umami.svg",
];

const themifyColor = (
  color: THREE.Color,
  mode: Theme["palette"]["mode"],
  forUrl: string
) => {
  if (
    !exemptions.includes(forUrl) &&
    ((color.r === 0 && color.g === 0 && color.b === 0) ||
      (color.r === 1 && color.g === 1 && color.b === 1))
  ) {
    return mode === "dark" ? "#fff" : "#333";
  }

  return color;
};

export const loadSVGMesh = (url: string, mode: Theme["palette"]["mode"]) =>
  new Promise<LoadedImageMesh>((resolve, reject) => {
    // instantiate a loader
    const loader = new SVGLoader();

    // load a SVG resource
    loader.load(
      // resource URL
      url,
      // called when the resource is loaded
      function (data) {
        const paths = data.paths;
        const group = new THREE.Group();

        // Use the viewBox to scale the group
        const svgXml = data.xml as any;
        let x = 0,
          y = 0,
          width = 1,
          height = 1;

        const viewBoxAttribute = svgXml.getAttribute("viewBox");
        if (viewBoxAttribute) {
          const viewBox = viewBoxAttribute.split(" ");
          x = parseFloat(viewBox[0]);
          y = parseFloat(viewBox[1]);
          width = parseFloat(viewBox[2]);
          height = parseFloat(viewBox[3]);
        } else {
          width = svgXml.width.baseVal.value || 1;
          height = svgXml.height.baseVal.value || 1;
        }

        group.rotation.set(Math.PI, 0, 0);
        const SCALE = 1;
        group.scale.set(1 / width, 1 / height, -1).multiplyScalar(SCALE); // Scale down to a reasonable size
        group.position.set(-x / width - 1 / 2, y / height + 1 / 2, 0); // Center the group

        for (let i = 0; i < paths.length; i++) {
          const path = paths[i];

          const emissiveIntensity = 0; //mode === "dark" ? 0 : 0.5;
          const material = new THREE.MeshStandardMaterial({
            // color: themifyColor(path.color, mode, url),
            // emissive: themifyColor(path.color, mode, url),
            roughness: 0,
            emissiveIntensity,
            color: themifyColor(path.color, mode, url),
            emissive: themifyColor(path.color, mode, url),
          });

          const shapes = SVGLoader.createShapes(path);
          for (let j = 0; j < shapes.length; j++) {
            const shape = shapes[j];

            const depthStride = 0.05 / paths.length;

            // Define properties for the extrusion
            const extrudeSettings = {
              steps: 1,
              depth: 0.04 + depthStride * i, // Change this for more or less extrusion
              bevelEnabled: true,
              bevelThickness: 0.005,
            };
            const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

            const mesh = new THREE.Mesh(geometry, material);
            mesh.receiveShadow = true;
            mesh.castShadow = true;
            group.add(mesh);
          }
        }

        resolve({ group, width, height });
      },
      // called when loading is in progresses
      function (xhr) {},
      // called when loading has errors
      function (error) {
        console.log("An error happened when loading SVG as a mesh: " + error);
        reject(error.message);
      }
    );
  });
