// @ts-check
import React from "react";
import {
  Alert,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  retrieveOrgsListAPI,
  retrieveNoOrgUsersListAPI,
} from "../../api/userAccessAPI";
import { SortableTableCell } from "./SortableTableCell";
import { OrgInfo, UserInfo, SortOrder } from "../../api/ApiTypes";
import { InviteUsers, HandleInviteUsersModalClose } from "./InviteUsers";

const useStyles = makeStyles({
  label: {
    fontSize: 16,
    fontWeight: 700,
  },
  domain: {
    fontSize: 16,
  },
  value: {
    fontFamily: "Montserrat Medium",
    fontSize: 16,
  },
  expiration: {
    fontSize: 16,
    fontWeight: 400,
    "&.warning": {
      color: "#EF9D0A",
    },
    "&.danger": {
      color: "#A91B12",
      fontWeight: 700,
    },
    "&.success": {
      fontWeight: 400,
    },
  },
  cellContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  message: {},
});

const NoOrgUsersTable = (props: {
  orgs: OrgInfo[];
  users: UserInfo[];
  handleUsersUpdates: (users: UserInfo[]) => void;
}) => {
  const { orgs, users, handleUsersUpdates } = props;

  const classes = useStyles();

  const [editingUser, setEditingUser] = React.useState<
  UserInfo | undefined
  >(undefined);

  const onEditClose = React.useCallback(
    (changes?: HandleInviteUsersModalClose) => {
      if (changes && changes.success) {
        // On this component one email will be updated at a time, so we just need the first one
        handleUsersUpdates(users.filter((cur) => cur.email !== changes.invitedUserEmails[0]));
      }      

      setEditingUser(undefined);
    },
    [users, handleUsersUpdates]
  );

  const ORDERBY_STORAGE_KEY = "arc-no-org-users-orderBy";
  const lastOrderBy = window.localStorage.getItem(ORDERBY_STORAGE_KEY) || "";

  const ORDER_STORAGE_KEY = "arc-no-org-users-order";
  const lastOrder: SortOrder =
    (window.localStorage.getItem(ORDER_STORAGE_KEY) as SortOrder) || "asc";

  const [order, setOrder] = React.useState<SortOrder>(lastOrder);
  const [orderBy, setOrderBy] = React.useState(lastOrderBy);

  const handleRequestSort = React.useCallback(
    (property: string) => {
      const isAsc = orderBy === property && order === "asc";
      const newOrder = isAsc ? "desc" : "asc";
      setOrder(newOrder);
      setOrderBy(property);

      window.localStorage.setItem(ORDERBY_STORAGE_KEY, property);
      window.localStorage.setItem(ORDER_STORAGE_KEY, newOrder);
    },
    [order, orderBy]
  );

  return (
    <>
      {users && users.length ? (
        <TableContainer style={{ height: "100%" }}>
          <Table aria-label="orgs access table">
            <TableHead>
              <TableRow>
                <SortableTableCell
                  id={"user"}
                  order={order}
                  orderBy={orderBy}
                  onSortClick={handleRequestSort}
                >
                  <Typography className={classes.label} fontSize="smaller">
                    User
                  </Typography>
                </SortableTableCell>
                <TableCell>
                  <Typography className={classes.label} fontSize="smaller">
                    Add user to org
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            {editingUser ? (
              <InviteUsers
                orgs={orgs}
                selectedUser={editingUser}
                handleClose={onEditClose}
              />
            ) : null}
            <TableBody style={{ overflowY: "auto" }}>
              {users
                .sort((a, b) => {
                  const first = order === "asc" ? a : b;
                  const second = order === "asc" ? b : a;
                  return (first.name || "").localeCompare(second.name || "");
                })
                .map((user) => {
                  const { name, email, guid, companyName } = user;
                  return (
                    <TableRow
                      key={`${guid}-no-org`}
                      data-testid={`NoOrgUserTable-row-${email}`}
                    >
                      <TableCell>
                        <Typography className={classes.domain}>
                          {`${name} (${email} - ${companyName})` || "(not set)"}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cellContent}>
                        <IconButton
                          onClick={() => setEditingUser(user)}
                          data-testid={`NoOrgUserTable-invite-${email}`}
                        >
                          <PersonAddIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        "There are no users without organizations at the moment."
      )}
    </>
  );
};

export const NoOrgUsersTableContainer = (props: { email: string }) => {
  const { email } = props;

  const [orgs, setOrgs] = React.useState<OrgInfo[]>([]);
  const [users, setUsers] = React.useState<UserInfo[]>([]);
  const [orgError, setOrgError] = React.useState("");
  const [userError, setUserError] = React.useState("");
  const [orgStatus, setOrgStatus] = React.useState("idle");
  const [userStatus, setUserStatus] = React.useState("idle");

  React.useEffect(() => {
    setOrgStatus("retrieving orgs");

    retrieveOrgsListAPI({
      email: email,
    })
      .then((result) => {
        if (result.type === "success") {
          setOrgs(result.orgs);
        } else {
          setOrgs([]);
          setOrgError(result.reason);
        }
      })
      .catch((e) => {
        console.warn(e);

        setOrgError(e.message);
      })
      .finally(() => {
        setOrgStatus("idle");
      });
  }, [email]);

  React.useEffect(() => {
    setUserStatus("retrieving users");

    retrieveNoOrgUsersListAPI({
      email: email,
    })
      .then((result) => {
        if (result.type === "success") {
          setUsers(result.users);
        } else {
          setUsers([]);
          setUserError(result.reason);
        }
      })
      .catch((e) => {
        console.warn(e);

        setUserError(e.message);
      })
      .finally(() => {
        setUserStatus("idle");
      });
  }, [email]);

  const orgErrorAlert = orgError ? (
    <Alert severity={"error"}>
      {`Retrieving org data from server failed: ${orgError}.`}
    </Alert>
  ) : null;

  const userErrorAlert = userError ? (
    <Alert severity={"error"}>
      {`Retrieving user data from server failed: ${userError}.`}
    </Alert>
  ) : null;

  return orgStatus === "retrieving orgs" || userStatus === "retrieving users" ? (
    <CircularProgress sx={{ margin: 8 }} />
  ) : (
    <>
      {orgErrorAlert}
      {userErrorAlert}
      <NoOrgUsersTable
        orgs={orgs}
        users={users}
        handleUsersUpdates={(updatedUserList) => setUsers(updatedUserList)}
      />
    </>
  );
};
