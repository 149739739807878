import React from "react";
import { FormControlLabel, FormControlLabelProps, Switch } from "@mui/material";

type Props = {
  title: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
} & Partial<FormControlLabelProps>;

export const ToggleWithLabel = ({
  title,
  checked,
  onChange,
  ...rest
}: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <FormControlLabel
      {...rest}
      control={<Switch checked={checked} onChange={handleChange} />}
      label={title}
    />
  );
};
