import React, { useState } from "react";
import { Tab, TextField, IconButton, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import clsx from "clsx";

interface EditableTabProps {
  label: string;
  value: number;
  onTabRenamed: (newLabel: string) => void;
  onTabDeleted: () => void;
}

export const useEditableTabStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: "none",
    marginRight: 8,
    borderRadius: `5px 5px 0 0`,
    "&.Mui-selected": {
      fontWeight: 700,
    },
  },
  iconButton: {
    padding: 4,
    height: 32,
    transition: "all 0.3s",
  },
  textEdit: {
    fontSize: "smaller",
  },
}));

export const EditableTab: React.FC<EditableTabProps> = ({
  label,
  value,
  onTabRenamed,
  onTabDeleted,
}) => {
  const classes = useEditableTabStyles();

  const [editing, setEditing] = useState(false);
  const [newLabel, setNewLabel] = useState(label);

  const handleEditClick = () => {
    setEditing(true);
    setNewLabel(label);
  };

  const handleDeleteClick = React.useCallback(() => {
    onTabDeleted();
  }, [onTabDeleted]);

  const handleCancelClick = React.useCallback(() => {
    setEditing(false);
    setNewLabel(label);
  }, [label]);

  const handleSaveClick = React.useCallback(() => {
    onTabRenamed(newLabel);
    setEditing(false);
  }, [newLabel, onTabRenamed]);

  const handleNewLabelChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewLabel(event.target.value);
    },
    []
  );

  return (
    <>
      {editing ? (
        <TextField
          value={newLabel}
          onChange={handleNewLabelChange}
          autoFocus
          size="small"
          inputProps={{
            className: classes.textEdit,
          }}
          style={{ marginBottom: -6 }}
        />
      ) : (
        <div
          className="flex-row-container flex-centered"
          style={{ position: "relative" }}
        >
          <Tab
            label={label}
            value={value}
            className={clsx(classes.root, "Mui-selected")}
            style={{ paddingRight: 78, marginRight: -70, whiteSpace: "nowrap" }}
          />
          <div style={{ marginRight: 12 }}>
            <IconButton
              onClick={handleEditClick}
              className={classes.iconButton}
            >
              <EditIcon style={{ fontSize: 22 }} />
            </IconButton>
            <IconButton
              onClick={handleDeleteClick}
              className={classes.iconButton}
            >
              <DeleteForeverIcon />
            </IconButton>
          </div>
        </div>
      )}
      {editing && (
        <div className="flex-row-container flex-centered">
          <IconButton onClick={handleSaveClick} className={classes.iconButton}>
            <CheckIcon />
          </IconButton>
          <IconButton
            onClick={handleCancelClick}
            className={classes.iconButton}
            style={{ marginRight: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </>
  );
};

export default EditableTab;
