import React from "react";
import { useQuickstart } from "../useQuickstartStore";

export const useCloseModalOnEscape = (onClose: () => void) => {
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup: remove the event listener when the component is unmounted
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onClose]);
};

export const useCloseStepViewOnEscape = () => {
  const closeStepView = useQuickstart((state) => state.closeStepView);

  return useCloseModalOnEscape(closeStepView);
};
