import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { TextField, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import rangeParser from "parse-numeric-range";
import { oneDark } from "react-syntax-highlighter/dist/cjs/styles/prism";
import bash from "react-syntax-highlighter/dist/cjs/languages/prism/bash";
import { useQuickstart } from "./useQuickstartStore";
import { stepResultCode } from "./quickstart-util";

SyntaxHighlighter.registerLanguage("bash", bash);
const SyntaxHighlighterAny = SyntaxHighlighter as any;

interface EditableMarkdownProps {
  id: string;
  markdown: string;
  onSave?: (newMarkdown: string) => void;
  style?: React.CSSProperties;
  className?: string;
}

export const EditableMarkdown: React.FC<EditableMarkdownProps> = ({
  id,
  markdown,
  onSave,
  style,
  className,
}) => {
  const editable = useQuickstart((state) => state.isEditing()) && !!onSave;
  const allValues = useQuickstart((state) => state.allValues());
  const containers = useQuickstart((state) =>
    state.selectedApp().containers.map((c) => c.container)
  );
  const [editing, setEditing] = useState(false);
  const [newMarkdown, setNewMarkdown] = useState(markdown);

  const handleEditClick = () => {
    setEditing(true);
    setNewMarkdown(markdown);
  };

  const handleSaveClick = () => {
    onSave && onSave(newMarkdown);
    setEditing(false);
  };

  const handleCancelClick = () => {
    setEditing(false);
    setNewMarkdown(markdown);
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewMarkdown(event.target.value);
  };

  const syntaxTheme = oneDark;

  const MarkdownComponents: object = {
    code({ node, inline, className: curClassName, ...props }: any) {
      const hasLang = /language-(\w+)/.exec(curClassName || "");
      const hasMeta = node?.data?.meta;

      const applyHighlights: object = (highlights: number) => {
        if (hasMeta) {
          const RE = /{([\d,-]+)}/;
          const metadata = node.data.meta?.replace(/\s/g, "");
          const strlineNumbers = RE?.test(metadata)
            ? RE?.exec!(metadata)![1]
            : "0";
          const highlightLines = rangeParser(strlineNumbers);
          const highlight = highlightLines;
          const data: string | null = highlight.includes(highlights)
            ? "highlight"
            : null;
          return { data };
        } else {
          return {};
        }
      };

      return hasLang ? (
        <SyntaxHighlighterAny
          style={syntaxTheme}
          language={hasLang[1]}
          PreTag="div"
          className="codeStyle"
          showLineNumbers={true}
          wrapLines={hasMeta}
          useInlineStyles={true}
          lineProps={applyHighlights}
        >
          {props.children}
        </SyntaxHighlighterAny>
      ) : (
        <code className={className} {...props} />
      );
    },
  };

  return (
    <div
      className={`flex-row-container ${className} editable-markdown-container`}
      style={{
        width: "100%",
        position: "relative",
        ...style,
      }}
    >
      {!editing && (
        <div style={{ display: "block" }} data-testid={`Markdown-${id}`}>
          <ReactMarkdown
            className="ReactMarkdown"
            components={MarkdownComponents}
          >
            {stepResultCode(markdown, allValues, containers)}
          </ReactMarkdown>
        </div>
      )}
      {editing && (
        <>
          <TextField
            value={newMarkdown}
            onChange={handleTextFieldChange}
            fullWidth
            multiline
          />
          <div className="flex-col-container" style={{ marginLeft: 8 }}>
            <IconButton
              onClick={handleSaveClick}
              style={{ height: "fit-content" }}
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              onClick={handleCancelClick}
              style={{ height: "fit-content" }}
            >
              <CancelIcon />
            </IconButton>
          </div>
        </>
      )}
      {editable && !editing && (
        <IconButton
          onClick={handleEditClick}
          style={{ height: "fit-content", position: "absolute", right: 0 }}
        >
          <EditIcon />
        </IconButton>
      )}
    </div>
  );
};
