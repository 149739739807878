import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

interface InfoButtonPopoverProps {
  title: string;
  content: React.ReactNode;
}

export const InfoButtonPopover: React.FC<InfoButtonPopoverProps> = (props) => {
  const { title, content } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "info-button-popover" : undefined;

  return (
    <div>
      <IconButton onClick={handleClick}>
        <InfoIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          p={2}
          position="relative"
          style={{ maxWidth: 700 }}
          component="div"
        >
          <Typography variant="h3" style={{ textTransform: "capitalize" }}>
            {title}
          </Typography>
          <IconButton
            size="small"
            edge="end"
            color="inherit"
            onClick={handleClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          {content}
        </Box>
      </Popover>
    </div>
  );
};
