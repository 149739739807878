import * as THREE from "three";
import {
  CompositeMeshNode,
  CompositeMeshNodeVM,
  MeshGeom,
  MeshGeomBase,
} from "./composite-mesh-types";
import { assembleCompositeMeshVM } from "./composite-mesh-vm";

const findNode = (
  node: CompositeMeshNode,
  id: string
): CompositeMeshNode | null => {
  if (node.id === id) {
    return node;
  }
  for (const child of node.children) {
    const found = findNode(child, id);
    if (found) {
      return found;
    }
  }

  return null;
};

const findParentOf = (node: CompositeMeshNode, id: string): string | null => {
  for (const child of node.children) {
    if (child.id === id) {
      return node.id;
    }
    const found = findParentOf(child, id);
    if (found) {
      return found;
    }
  }

  return null;
};

const INSTANCE_SIZE: THREE.Vector3 = new THREE.Vector3(2, 2.5, 0.5);
const HSM_INSTANCE_SIZE: THREE.Vector3 = new THREE.Vector3(4, 4, 1);

type Options = {
  instanceSize: THREE.Vector3;
  hsmInstanceSize: THREE.Vector3;
  gaps: THREE.Vector3;
  border: THREE.Vector3;
  groupBorder: THREE.Vector3;
  regionBorder: THREE.Vector3;
  imageScale: number;
  textScale: number;
};

export class CompositeMeshBuilder {
  private node: CompositeMeshNode;

  private current: string;

  private get currentNode() {
    return findNode(this.node, this.current)!;
  }

  private options: Options;

  constructor(options?: Partial<CompositeMeshNode & Options>) {
    this.node = {
      id: "root",
      geom: { kind: "null", images: [] },
      children: [],
      dir: "right",
      gaps: new THREE.Vector3(0, 0, 0),
      border: new THREE.Vector3(0, 0, 0),
      ...options,
    };

    this.options = {
      instanceSize: INSTANCE_SIZE,
      hsmInstanceSize: HSM_INSTANCE_SIZE,
      gaps: new THREE.Vector3(1.5, 2.5, 0.2),
      border: new THREE.Vector3(1, 1, 0.4),
      groupBorder: new THREE.Vector3(1, 1.5, 0.4),
      regionBorder: new THREE.Vector3(2, 2, 0.7),
      imageScale: 4,
      textScale: 1,
      ...options,
    };

    this.current = this.node.id;
  }

  public addService(
    text: string,
    kind: "app" | "cache" | "hsms" | "db",
    image?: string,
    options: Partial<CompositeMeshNode & { shrink: boolean }> = {},
    geomOptions: Partial<MeshGeom> = {},
    moveToAdded = false
  ) {
    const shrinkFactor = options.shrink ? 0.5 : 1;

    switch (kind) {
      case "app":
        return this.add(
          {
            geom: {
              kind: "box",
              size: options.shrink
                ? this.options.instanceSize.clone().multiplyScalar(0.5)
                : this.options.instanceSize,
              label: {
                text,
                scale:
                  0.8 *
                  (options.shrink
                    ? this.options.textScale / 2
                    : this.options.textScale),
                location: {
                  side: "front",
                  alignHorizontally: "center",
                  alignVertically: "center",
                },
              },
              images: image
                ? [
                    {
                      src: image,
                      size:
                        0.7 *
                        (options.shrink
                          ? this.options.imageScale / 2
                          : this.options.imageScale),
                      location: {
                        side: "top",
                        alignHorizontally: "center",
                        alignVertically: "center",
                      },
                    },
                  ]
                : [],
              ...geomOptions,
            },
            ...options,
          },
          moveToAdded
        );
      case "hsms":
        return this.add(
          {
            geom: {
              kind: "cylinder",
              count: 6,
              segments: 4,
              radius: (shrinkFactor * INSTANCE_SIZE.x) / 2.2,
              height: (shrinkFactor * INSTANCE_SIZE.z) / 3,
              textScale: 0.5,
              text: text || "HSM",
              color: (geomOptions as MeshGeomBase).color || "#333",
              decorated: true,
              images: [],
              ...geomOptions,
            },
            ...options,
          },
          false
        );
      case "db":
        return this.add(
          {
            geom: {
              kind: "cylinder",
              count: 3,
              segments: 8,
              radius: (shrinkFactor * INSTANCE_SIZE.x) / 2.2,
              height: (shrinkFactor * INSTANCE_SIZE.z) / 2,
              textScale: shrinkFactor,
              color: (geomOptions as MeshGeomBase).color || "#333",
              decorated: true,
              images: [],
              ...geomOptions,
            },
            ...options,
          },
          false
        );
      case "cache":
        return this.add({
          geom: {
            kind: "cylinder",
            count: 5,
            radius: (shrinkFactor * INSTANCE_SIZE.x) / 2,
            height: (shrinkFactor * INSTANCE_SIZE.z) / 3,
            text: "Cache",
            textScale: shrinkFactor,
            color: (geomOptions as MeshGeomBase).color || "#358",
            images: [],
            ...geomOptions,
          },
          ...options,
        }).up();
    }
  }

  public addRegion(
    text: string,
    image: string | undefined,
    csp: "aws" | "azure" | "dc",
    options: Partial<CompositeMeshNode> = {},
    geomOptions: Partial<MeshGeom> = {}
  ) {
    return this.add({
      gaps: this.options.gaps,
      border: this.options.regionBorder,
      geom: {
        kind: "substrate",
        style: {
          kind: "silhouette",
          color:
            csp === "aws"
              ? "#f85"
              : csp === "azure"
              ? "rgb(64, 160, 255)"
              : "#eee",
        },
        thickness: 0.4,
        label: {
          text,
          scale: this.options.textScale,
          location: {
            side: "top",
            alignHorizontally: "center",
            alignVertically: "bottom",
            offsetV: 0.5,
          },
        },
        images: image
          ? [
              {
                src: image,
                size: this.options.imageScale * 0.4,
                location: {
                  side: "top",
                  alignHorizontally: "left",
                  alignVertically: "bottom",
                  offsetH: 1.7,
                  offsetV: 1,
                },
              },
            ]
          : [],
        ...geomOptions,
      },
      ...options,
    });
  }

  addGroup(
    text: string,
    image?: string,
    options?: Partial<CompositeMeshNode>,
    geomOptions?: Partial<MeshGeom>
  ) {
    return this.add({
      geom: {
        kind: "substrate",
        thickness: 0.4,
        color: "#3f3f3f",
        label: {
          text,
          scale: 0.8 * this.options.textScale,
          location: {
            side: "top",
            alignHorizontally: "center",
            alignVertically: "bottom",
            offsetV: 0.5,
          },
        },
        images: image
          ? [
              {
                src: image,
                size: this.options.imageScale * 0.4,
                location: {
                  side: "top",
                  alignHorizontally: "right",
                  alignVertically: "bottom",
                  offsetH: -1,
                  offsetV: 1,
                },
              },
            ]
          : [],
        ...geomOptions,
      },
      gaps: this.options.gaps,
      border: this.options.groupBorder,
      ...options,
    });
  }

  public add(
    node: Pick<CompositeMeshNode, "geom"> & Partial<CompositeMeshNode>,
    moveToAdded: boolean = true
  ) {
    const id = node.id || `${this.current}-${this.currentNode.children.length}`;

    this.currentNode.children.push({
      ...node,
      children: [],
      dir: node.dir || this.currentNode.dir,
      id,
    });

    // Move to the created node.
    if (moveToAdded) this.current = id;
    return this;
  }

  public up() {
    this.current = findParentOf(this.node, this.current)!;
    return this;
  }

  public to(id: string) {
    this.current = id;
    return this;
  }

  public get model(): CompositeMeshNode {
    return this.node;
  }

  public get vm(): CompositeMeshNodeVM {
    return assembleCompositeMeshVM(this.node);
  }
}
