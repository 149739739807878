import _ from "lodash";

type AnyObj = { [key: string]: any };

export function deepDiff(
  obj1: AnyObj,
  obj2: AnyObj,
  depth: number = 0
): AnyObj {
  let result: AnyObj = {};

  if (depth > 100) {
    throw Error(
      "deepDiff: max depth exceeded, probably due to circular dependencies. obj1: " +
        obj1 +
        ", obj2: " +
        obj2
    );
  }

  for (let key in obj1 || {}) {
    if (!(key in (obj2 || {}))) {
      result[key] = obj1[key];
    } else if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
      let value = deepDiff(obj1[key], obj2[key], depth + 1);
      if (Object.keys(value).length !== 0) {
        result[key] = value;
      }
    } else if (obj1[key] !== obj2[key]) {
      result[key] = obj1[key];
    }
  }

  for (let key in obj2 || {}) {
    if (!(key in (obj1 || {}))) {
      result[key] = obj2[key];
    }
  }

  return result;
}

export const logAnyPropChanges = <T extends { [k: string]: any }>(
  name: string,
  latest: T
) => {
  const DISABLED = true;
  if (DISABLED) return;

  let propChange = false;

  if ((window as any)[name]) {
    Object.keys(latest).forEach((k) => {
      const cur = latest[k];
      const prev = (window as any)[name][k];
      if (!_.isEqual(cur, prev)) {
        try {
          console.log(
            `[${name}] Prop changed: ${k} (was: ${prev}, now: ${cur}).${
              typeof cur === "object" && typeof prev === "object"
                ? `deepDiff: ${JSON.stringify(deepDiff(cur, prev))}`
                : ""
            }`
          );
        } catch (e) {
          console.warn(e);
        }
        propChange = true;
      }
    });
  }

  if (!propChange) {
    console.log(`[${name}] rendered despite no props changing`);
  }

  (window as any)[name] = latest;
};
