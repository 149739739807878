import * as React from "react";
import { BufferGeometry, InstancedMesh, Mesh } from "three";
import { RoundedBox } from "@react-three/drei";
import { MeshProps } from "@react-three/fiber";
import { NamedArrayTuple } from "@react-three/drei/helpers/ts-utils";

type Props = {
  args?: NamedArrayTuple<
    (width?: number, height?: number, depth?: number) => void
  >;
  radius?: number;
  smoothness?: number;
  steps?: number;
  creaseAngle?: number;
} & MeshProps &
  Omit<JSX.IntrinsicElements["mesh"], "args">;

let geometry: BufferGeometry | undefined;

export const AnjRoundedBox = React.forwardRef<Mesh, Props>(function (
  {
    args: [width = 1, height = 1, depth = 1] = [],
    radius = 0.05,
    steps = 1,
    smoothness = 4,
    creaseAngle = 0.4,
    children,
    ...rest
  },
  ref
) {
  return geometry ? (
    <mesh
      receiveShadow
      castShadow
      geometry={geometry.clone()}
      ref={ref}
      {...rest}
    >
      {children}
    </mesh>
  ) : null; /*(
    <RoundedBox
      args={[width, height, depth]}
      radius={radius}
      smoothness={smoothness}
      creaseAngle={creaseAngle}
      ref={ref}
      {...rest}
    >
      {children}
    </RoundedBox>
  );*/
});

export const AnjRoundedBoxGeometryCreator = () => {
  const boxRef = React.useRef<InstancedMesh>(null);

  const [visible, setVisible] = React.useState(true);

  React.useEffect(() => {
    if (boxRef.current) {
      geometry = boxRef.current.geometry;

      setVisible(false);
    }
  }, [boxRef]);

  return visible ? (
    <RoundedBox
      ref={boxRef}
      args={[1, 1, 1]}
      radius={0.05}
      smoothness={4}
      visible={visible}
    >
      <meshStandardMaterial
        attach="material"
        color="hotpink"
        transparent
        opacity={0.4}
      />
    </RoundedBox>
  ) : null;
};
