import { Box, Theme, Typography } from "@mui/material";
import React from "react";
import { useAppStage } from "../../../context/AppStageContext";
import {
  downloadTemplateBundleAPI,
  templatesApiEndpoint,
} from "../../../api/templatesAPI";
import { DownloadButton } from "../DownloadButton";
import { CLIDownloadButton } from "../CLIDownloadButton";
import { makeStyles } from "@mui/styles";
import { green } from "@mui/material/colors";
import clsx from "clsx";
import { postEventAPI } from "../../../api/eventsAPI";
import { PocLayout, QuickstartConstants } from "./poc-constants";
import { useQuickstart } from "./useQuickstartStore";

const useStyles = makeStyles((theme: Theme) => ({
  downloadPaper: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: `1px solid ${green[300]}`,
    background:
      theme.palette.mode === "dark" ? "rgba(0, 255, 0, 0.1)" : green[50],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  instructions: {
    maxHeight: PocLayout.InstructionsHeight,
    overflow: "auto",
    padding: 16,
    borderRadius: 8,
    border: `1px solid ${theme.palette.divider}`,
  },
}));

export const QuickstartDownloadBox = React.memo((props: {}) => {
  const classes = useStyles();
  const editing = useQuickstart((state) => state.isEditing());
  const [{ stage }, dispatch] = useAppStage();

  const endpoint = `${templatesApiEndpoint()}/active`;
  const apiToken = stage.type === "dashboard" ? stage.apiToken : undefined;

  const download = React.useCallback(() => {
    postEventAPI(apiToken!, {
      message: "Downloaded template bundle",
      data: {
        kind: "ARC",
        version: "1.0",
      },
    });

    return downloadTemplateBundleAPI(apiToken!);
  }, [apiToken]);

  const handleOpenSettings = React.useCallback(() => {
    dispatch({
      type: "open modal",
      modalId: "api settings",
    });
  }, [dispatch]);

  return (
    <Box
      className={clsx(
        classes.downloadPaper,
        "flex-col-container flex-centered"
      )}
      component="div"
    >
      <div className="flex-spacer" />
      {editing ? (
        <Typography
          sx={{ mb: 3, fontSize: "smaller", fontWeight: 700, color: "#970" }}
        >
          Disabled while editing is in progress.
        </Typography>
      ) : null}
      <CLIDownloadButton
        apiToken={apiToken!}
        apiEndpoint={endpoint}
        filename={QuickstartConstants.Filename}
        onOpenSettings={handleOpenSettings}
        terminalIconStyles={{ fontSize: 40 }}
        disabled={editing}
        kind="text"
        variant="contained"
        text="Download via Command-line"
      />
      <Typography style={{ marginTop: 10 }}>OR</Typography>
      <div
        className="flex-row-container flex-centered"
        style={{ marginLeft: 16 }}
      >
        <DownloadButton
          filename={QuickstartConstants.Filename}
          label={`Download ${QuickstartConstants.Filename}`}
          downloadAPI={download}
          variant="text"
          disabled={editing}
        />
      </div>
      <div className="flex-spacer" />
    </Box>
  );
});
