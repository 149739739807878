import { create } from "zustand";
import * as THREE from "three";
import {
  PocOperation,
  PocScriptStepParameter,
  PocStepCodeFile,
  PocStoreStatus,
  QuickstartContainerDefinition,
  QuickstartDefinition,
} from "./poc-types";
import { useCombinedMeshStore } from "../composite-mesh/useCompositeMeshStore";
import { Camera } from "@react-three/fiber";
import { MeshEntityId } from "../composite-mesh/composite-mesh-types";
import {
  getTemplateAPI,
  getTemplateOptionsAPI,
  putTemplateAPI,
  putTemplateOptionsAPI,
} from "../../../api/templatesAPI";
import _ from "lodash";
import { parameterDefaults, stepResultCode } from "./quickstart-util";
import {
  quickstartDefinitions,
  quickstartParameters,
  standardContainerFiles,
} from "./quickstartDefinitions";
import {
  allocatorResourcesForInstanceType,
  instanceTypes,
} from "../../../shared/quickstart-util.es6";

export const INITIAL_CAMERA_POS = new THREE.Vector3(-7, -18, 14);
const INITIAL_SCENE_POS = new THREE.Vector3(-0.5, 0, -3.5);
const BUILD_PHASE_CAMERA_POS = INITIAL_CAMERA_POS;
const BUILD_PHASE_SCENE_POS = INITIAL_SCENE_POS;

export const configurableQuickstartOptions = [
  "version",
  "exposedPorts",
  "environment",
  "cpu count",
  "memory",
  "files",
  "mounts",
  "total.cpu count",
  "total.memory",
] as const;

export const requiredQuickstartOptions = ["app", "instanceType"] as const;
export const nonconfigurableQuickstartOptions = [] as const;

export type StandardQuickstartOptions =
  | (typeof requiredQuickstartOptions)[number]
  | (typeof configurableQuickstartOptions)[number]
  | (typeof nonconfigurableQuickstartOptions)[number];

export type ConfigurableQuickstartOptions = {
  [key in (typeof configurableQuickstartOptions)[number]]?: any;
};

export type RequiredQuickstartOptions = {
  [key in (typeof requiredQuickstartOptions)[number]]: any;
};

export type NonconfigurableQuickstartOptions = {
  [key in (typeof nonconfigurableQuickstartOptions)[number]]?: any;
};

export type QuickstartOptions = RequiredQuickstartOptions &
  NonconfigurableQuickstartOptions &
  ConfigurableQuickstartOptions;

export type QuickstartOptionsWithDefaults = {
  [key in (typeof requiredQuickstartOptions)[number]]: any;
} & {
  [key in (typeof nonconfigurableQuickstartOptions)[number]]: any;
} & {
  [key in (typeof configurableQuickstartOptions)[number]]: any;
} & ApiInfo;

export const quickstartSteps = [
  { kind: "creating instance", title: `Creating a Nitro-capable EC2 instance` },
  {
    kind: "instance created",
    title: `Reviewing the created instance`,
  },
  {
    kind: "installing tools",
    title: `Installing tools & Anjuna Runtime`,
  },
  {
    kind: "ready to build",
    title: `Reviewing build inputs`,
    cameraPos: BUILD_PHASE_CAMERA_POS,
    scenePos: BUILD_PHASE_SCENE_POS,
  },
  {
    kind: "building image",
    title: `Building a standalone image`,
    cameraPos: BUILD_PHASE_CAMERA_POS,
    scenePos: BUILD_PHASE_SCENE_POS,
  },
  { kind: "ready to deploy", title: `Previewing the deploy step` },
  { kind: "deploying enclave", title: `Deploying the Confidential Container` },
  { kind: "protected", title: `Workload is fully protected` },
] as const;

export type QuickstartStepInfo = {
  title: string;
  select?: { id: MeshEntityId; delay: number };
  nextStepText?: string;
  cameraPos?: THREE.Vector3;
  scenePos?: THREE.Vector3;
};

export type QuickstartSteps = (typeof quickstartSteps)[number];

export type QuickstartState = (typeof quickstartSteps)[number];

export type ApiInfo = {
  downloadApiUrl: string;
  eventsApiUrl: string;
  apiToken: string;
};

export type QuickstartStore = {
  definitions: QuickstartDefinition[];
  state: QuickstartState;
  walkthroughStarted: boolean;
  status: PocStoreStatus;
  parameters: Map<"instanceType" | "ec2KeyPairName", PocScriptStepParameter>;
  options: RequiredQuickstartOptions;
  apiInfo: ApiInfo;

  // Has the user modified options?
  dirty: boolean;

  allValues: () => QuickstartOptionsWithDefaults;
  selectedApp: () => QuickstartDefinition;
  filesForOperation: (
    operation: PocOperation,
    container: string | undefined
  ) => PocStepCodeFile[];
  parametersForOperation: (
    operation: PocOperation,
    container: string | undefined
  ) => PocScriptStepParameter[];
  startWalkthrough: () => void;
  hasNext: () => boolean;
  hasPrevious: () => boolean;
  nextStep: () => void;
  previousStep: () => void;
  startOver: () => void;
  skipWalkthrough: () => void;
  completePercent: () => number;
  closeStepView: () => void;
  setOption: (key: StandardQuickstartOptions, value: string) => void;

  cameraRef: Camera;
  setCameraRef: (camera: Camera) => void;
  cameraPos: THREE.Vector3;
  scenePos: THREE.Vector3;
  setCameraPos: (camera: THREE.Vector3) => void;
  selectedEntityViewOrPopupWasShown: boolean;
  setEntityViewOrPopupWasShown: (entityId: MeshEntityId) => void;

  editingState: { originalContent: QuickstartDefinition[] } | undefined;
  userEdited: boolean;
  beginEditing: () => void;
  saveDefinitions: () => Promise<void>;
  endEditing: (mode: "save" | "cancel") => Promise<void>;
  addDefinition: (definition: QuickstartDefinition) => void;
  removeDefinition: (id: string) => void;
  updateActive: (
    definition: Pick<QuickstartDefinition, "id"> & Partial<QuickstartDefinition>
  ) => void;
  restoreDefaultContent: () => Promise<void>;
  fetch: () => Promise<void>;
  isEditing: () => boolean;

  setApiInfo: (options: ApiInfo) => void;

  viewport: null | Pick<VisualViewport, "width" | "height">;
  updateViewport: (
    viewport: null | Pick<VisualViewport, "width" | "height">
  ) => void;
};

export const isStepComplete = (
  state: QuickstartState,
  step: (typeof quickstartSteps)[number]["kind"]
): boolean => {
  const stepIndex = indexOfStep(step);
  const currentStepIndex = indexOfStep(state.kind);
  return stepIndex < currentStepIndex;
};

const indexOfStep = (step: (typeof quickstartSteps)[number]["kind"]) =>
  quickstartSteps.findIndex((cur) => cur.kind === step);

export const isStepVisible = (
  state: QuickstartState,
  step: (typeof quickstartSteps)[number]["kind"]
): boolean => {
  const stepIndex = indexOfStep(step);
  const currentStepIndex = indexOfStep(state.kind);

  if (step === "building image") {
    return currentStepIndex >= stepIndex;
  }

  return stepIndex <= currentStepIndex;
};

const prefixedOptionsForContainer = (
  container: QuickstartContainerDefinition
) =>
  Object.keys(container.options).reduce((acc, cur) => {
    return {
      ...acc,
      [`${container.container}.${cur}`]:
        container.options[cur as keyof ConfigurableQuickstartOptions],
    };
  }, {});

export const makeQuickstartStore = (
  storeOptions: { connectedToBackend: boolean; editMode: boolean } = {
    editMode: true,
    connectedToBackend: true,
  }
) =>
  create<QuickstartStore>((set, get) => ({
    state: quickstartSteps[0],
    dirty: false,
    walkthroughStarted: false,
    options: {
      app: quickstartDefinitions({})[0].id,
      instanceType: "m5.xlarge",
    },
    apiInfo: {
      downloadApiUrl: "",
      eventsApiUrl: "",
      apiToken: "",
    },
    viewport: null,
    definitions: quickstartDefinitions({}),
    parameters: new Map([
      [
        "instanceType",
        {
          kind: "select",
          id: "instanceType",
          defaultValue: "m5.xlarge",
          options: instanceTypes().map(({ id, vcpus, memory }) => ({
            value: id,
            label: `${id} (${vcpus} vCPUs, ${memory} GB RAM)`,
          })),
          operation: "app image",
          title: "Instance type",
          descriptionMarkdown: "Select an instance type for your EC2 instance.",
        },
      ],
      ["ec2KeyPairName", {} as any],
    ]),
    allValues: () => {
      const parameterDefaultValues = Array.from(
        get().parameters.values()
      ).reduce((acc, cur) => {
        if (cur.kind === "select") {
          return {
            ...acc,
            [cur.id]: cur.defaultValue,
          };
        }
        return acc;
      }, {});

      const instanceType = get().options["instanceType"];
      const totalResources = allocatorResourcesForInstanceType(instanceType);

      return {
        ...get().apiInfo,
        ...totalResources,
        ...parameterDefaultValues,
        ...get()
          .selectedApp()
          .containers.reduce(
            (acc, cur) => ({
              ...acc,
              ...parameterDefaults(quickstartParameters([cur.container])),
              ...prefixedOptionsForContainer(cur),
            }),
            {}
          ),
        ...get().options,
      } as QuickstartOptionsWithDefaults;
    },
    selectedApp: () => {
      const app = get().options["app"] || get().definitions[0].id;

      const activeDefinition = get().definitions.find((cur) => cur.id === app)!;

      return activeDefinition || get().definitions[0];
    },
    filesForOperation: (
      operation: PocOperation,
      container: string | undefined
    ) => {
      const app = get().selectedApp();

      const containers = app.containers.filter(
        (c) => c.container === container || container === undefined
      );

      const files = [
        ...app.files,
        ...containers.flatMap(({ container: c, docker }) => [
          ...(docker.kind === "provided" ? docker.files : []),
          ...standardContainerFiles(c),
        ]),
      ];

      return files
        .filter((file) => file.operation === operation)
        .map((file) => ({
          ...file,
          content: stepResultCode(
            file.content,
            get().allValues(),
            containers.map((cur) => cur.container)
          ),
        }));
    },
    parametersForOperation: (
      operation: PocOperation,
      container: string | undefined
    ) => {
      return quickstartParameters(
        container
          ? [container]
          : get()
              .selectedApp()
              .containers.map((c) => c.container)
      ).filter((parameter) => parameter.operation === operation);
    },
    hasNext: () => {
      const { state } = get();
      const stepIndex = indexOfStep(state.kind);
      return stepIndex < quickstartSteps.length - 1;
    },
    hasPrevious: () => {
      const { state } = get();
      const stepIndex = indexOfStep(state.kind);
      return stepIndex > 0;
    },
    nextStep: () => {
      const { state } = get();
      const stepIndex = indexOfStep(state.kind);
      if (stepIndex === -1) {
      }
      let nextStep = quickstartSteps[stepIndex + 1];

      if (!nextStep) {
        throw new Error("No next step");
      }

      useCombinedMeshStore.getState().setSelectedEntity(undefined);

      set({
        state: nextStep,
        cameraPos:
          (nextStep as QuickstartStepInfo).cameraPos || INITIAL_CAMERA_POS,
        scenePos:
          (nextStep as QuickstartStepInfo).scenePos || INITIAL_SCENE_POS,
        selectedEntityViewOrPopupWasShown: false,
      });
    },
    previousStep: () => {
      const { state } = get();
      const stepIndex = indexOfStep(state.kind);
      let previousStep = quickstartSteps[stepIndex - 1];

      useCombinedMeshStore.getState().setSelectedEntity(undefined);

      set({
        state: previousStep,
        cameraPos:
          (previousStep as QuickstartStepInfo).cameraPos || INITIAL_CAMERA_POS,
        scenePos:
          (previousStep as QuickstartStepInfo).scenePos || INITIAL_SCENE_POS,
        selectedEntityViewOrPopupWasShown: false,
      });
    },
    closeStepView: () => {
      useCombinedMeshStore.getState().setSelectedEntity(undefined);

      set({
        cameraPos:
          (get().state as QuickstartStepInfo).cameraPos || INITIAL_CAMERA_POS,
        scenePos:
          (get().state as QuickstartStepInfo).scenePos || INITIAL_SCENE_POS,
      });
    },
    startWalkthrough: () => {
      set({
        walkthroughStarted: true,
        state: quickstartSteps[0],
        cameraPos: INITIAL_CAMERA_POS,
        selectedEntityViewOrPopupWasShown: false,
      });
    },
    startOver: () => {
      set({
        state: quickstartSteps[0],
        cameraPos: INITIAL_CAMERA_POS,
        selectedEntityViewOrPopupWasShown: false,
      });

      useCombinedMeshStore.getState().setSelectedEntity(undefined);
      useCombinedMeshStore.setState({
        visitedEntities: new Set(),
      });

      get().cameraRef.position.set(
        INITIAL_CAMERA_POS.x,
        INITIAL_CAMERA_POS.y,
        INITIAL_CAMERA_POS.z
      );
      get().cameraRef.lookAt(0, 0, 0);
      get().cameraRef.updateProjectionMatrix();
    },
    skipWalkthrough: () => {
      set({
        state: quickstartSteps[quickstartSteps.length - 1],
        cameraPos: INITIAL_CAMERA_POS,
        selectedEntityViewOrPopupWasShown: false,
      });

      useCombinedMeshStore.getState().setSelectedEntity(undefined);
    },
    completePercent: () => {
      const { state } = get();
      const stepIndex = indexOfStep(state.kind);
      return (stepIndex / (quickstartSteps.length - 1)) * 100;
    },
    cameraRef: undefined as any,
    setCameraRef: (cameraRef: Camera) => set({ cameraRef }),
    cameraPos: INITIAL_CAMERA_POS,
    scenePos: INITIAL_SCENE_POS,
    setCameraPos: (camera: THREE.Vector3) => set({ cameraPos: camera }),
    setOption: (key: StandardQuickstartOptions, value: string) => {
      if (storeOptions.editMode) {
        const definition = get().selectedApp();

        const container = definition.containers.find((cur) =>
          key.startsWith(`${cur.container}.`)
        );

        if (container) {
          const keyWithoutPrefix = key.split(
            "."
          )[1] as keyof ConfigurableQuickstartOptions;
          container.options[keyWithoutPrefix] = value;
        }

        return;
      }

      const isConfigurable = true; //configurableQuickstartOptions.includes(key as any);
      const isApp = key === "app";

      // If switching quickstarts, clear out any existing options
      const newOptions = isApp
        ? {
            ...get().apiInfo,
            instanceType: "m5.xlarge",
            [key]: value,
          }
        : {
            ...get().options,
            [key]: value,
          };

      if ((isConfigurable || isApp) && storeOptions.connectedToBackend) {
        putTemplateOptionsAPI(get().apiInfo.apiToken, newOptions);
      }

      set({
        dirty: isConfigurable,
        options: newOptions,
      });
    },
    selectedEntityViewOrPopupWasShown: false,

    editingState: undefined,
    status: { kind: "idle" },
    userEdited: false,
    beginEditing: () => {
      set({
        editingState: { originalContent: _.cloneDeep(get().definitions) },
      });
    },
    saveDefinitions: async () => {
      if (!storeOptions.connectedToBackend) return;

      try {
        set({ status: { kind: "saving template" } });

        const result = await putTemplateAPI(
          get().allValues().apiToken,
          get().definitions
        );

        if (result.type !== "success") throw Error(result.message);

        set({ status: { kind: "idle" }, definitions: result.definitions });
      } catch (e) {
        set({ status: { kind: "error", message: JSON.stringify(e) } });
      }
    },
    endEditing: async (mode: "save" | "cancel") => {
      if (mode === "save") {
        await get().saveDefinitions();

        set({ editingState: undefined });
      } else
        set({
          definitions: get().editingState!.originalContent,
          editingState: undefined,
        });
    },
    addDefinition: (definition: QuickstartDefinition) => {
      set({
        definitions: [
          ...get().definitions.filter((c) => c.id !== definition.id),
          definition,
        ],
        options: {
          ...get().options,
          app: definition.id,
        },
      });

      get()
        .saveDefinitions()
        .then(() => {
          get().setOption("app", definition.id);
        });
    },
    removeDefinition: (id: string) => {
      set({
        definitions: get().definitions.filter((cur) => cur.id !== id),
      });
    },
    updateActive: (
      definition: Pick<QuickstartDefinition, "id"> &
        Partial<QuickstartDefinition>
    ) => {
      set({
        definitions: get().definitions.map((cur) =>
          cur.id === definition.id ? { ...cur, ...definition } : cur
        ),
      });
    },
    restoreDefaultContent: async () => {
      set({ status: { kind: "saving template" } });

      try {
        const result = await putTemplateAPI(
          get().allValues().apiToken,
          undefined
        );

        if (result.type !== "success") throw Error(result.message);

        set({
          status: { kind: "idle" },
          definitions: result.definitions,
        });
      } catch (e) {
        set({ status: { kind: "error", message: JSON.stringify(e) } });
      }
    },
    isEditing: () => !!get().editingState,

    setApiInfo: (apiInfo: ApiInfo) =>
      set({
        apiInfo,
      }),
    fetch: async () => {
      if (get().status.kind === "fetching") {
        return;
      }

      if (storeOptions.connectedToBackend) {
        set({ status: { kind: "fetching" } });

        const values = get().allValues();
        const templateResult = await getTemplateAPI(values.apiToken);
        const progressResult = await getTemplateOptionsAPI(values.apiToken);

        if (
          templateResult.type === "success" &&
          progressResult.type === "success"
        ) {
          const { definitions, userEdited } = templateResult.template;

          set({
            definitions,
            userEdited,
            options: {
              ...get().options,
              ...progressResult.options,
            },
          });
        }
      }

      set({ status: { kind: "idle" } });
    },
    setEntityViewOrPopupWasShown: (entity: MeshEntityId) => {
      set({
        selectedEntityViewOrPopupWasShown: true,
      });
    },
    updateViewport: (
      viewport: null | Pick<VisualViewport, "width" | "height">
    ) => {
      set({
        viewport,
      });
    },
  }));

export const useQuickstart = makeQuickstartStore({
  editMode: false,
  connectedToBackend: true,
});
