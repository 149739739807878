import {
  Box,
  Button,
  IconButton,
  Popover,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { CodeEditor } from "../../CodeEditor";
import { CodeFileMode } from "../../poc-types";
import { useTheme } from "../../../../../useThemeStore";
import { CloseOutlined } from "@mui/icons-material";

export type FileDesc = {
  name: string;
  content: string;
  mode: CodeFileMode;
};

const useStyles = makeStyles((theme: Theme) => ({
  dropZone: {
    border: `2px dashed ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    textAlign: "center",
    cursor: "pointer",
  },
  dropZoneActive: {
    border: `2px dashed ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.action.hover,
  },
}));

const imageSrc = (mode: CodeFileMode) => {
  switch (mode) {
    case "sh":
      return "/terminal.svg";
    case "dockerfile":
      return "/docker.svg";
    case "python":
      return "/python.svg";
    default:
      return "/file-config.svg";
  }
};

export const modeFromFilename = (filename: string): CodeFileMode => {
  if (filename.includes("Dockerfile")) return "dockerfile";

  const ext = filename.split(".").pop();
  switch (ext) {
    case "sh":
      return "sh";
    case "py":
      return "python";
    case "yaml":
      return "yaml";
    case "yml":
      return "yaml";
    case "sql":
      return "sql";
    case "conf":
    case "cnf":
    case "ini":
      return "ini";
    default:
      return "text" as any;
  }
};

export const FilePreview = (props: {
  file: FileDesc;
  onDelete?: () => void;
}) => {
  const { file, onDelete } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDelete = React.useCallback(
    (e: React.MouseEvent) => {
      onDelete && onDelete();

      e.stopPropagation();
    },
    [onDelete]
  );

  const open = Boolean(anchorEl);

  const theme = useTheme();

  return (
    <>
      <Button
        variant="outlined"
        style={{
          textTransform: "unset",
          marginRight: 16,
          marginBottom: 16,
          color: theme.palette.text.primary,
        }}
        onClick={handleClick}
      >
        <img
          src={imageSrc(file.mode)}
          alt={file.name}
          width={32}
          style={{ marginRight: 8 }}
        />
        {file.name}
        {onDelete ? (
          <IconButton onClick={handleDelete}>
            <CloseOutlined />{" "}
          </IconButton>
        ) : null}
      </Button>
      {
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
        >
          <Box
            // p={2}
            width={800}
            height={600}
            component="div"
            className="flex-row-container"
          >
            <CodeEditor
              code={file.content}
              name={file.name}
              mode={file.mode}
              readOnly
            />
          </Box>
        </Popover>
      }
    </>
  );
};

export const FilePreviews = (props: {
  files: FileDesc[];
  onDelete?: (filename: string) => void;
}) => {
  const { files, onDelete } = props;

  return files.length > 0 ? (
    <div
      style={{ marginBottom: 8, padding: 8 }}
      className="flex-row-container flex-wrap"
    >
      {files.map((file) => (
        <FilePreview
          key={file.name}
          file={file}
          onDelete={onDelete ? () => onDelete(file.name) : undefined}
        />
      ))}
    </div>
  ) : null;
};

export const FileDropZone = ({
  files,
  addFiles,
}: {
  files: FileDesc[];
  addFiles: (files: FileDesc[]) => void;
}) => {
  const classes = useStyles();

  const processNewFiles = (newFiles: File[]) => {
    Promise.all(newFiles.map((f) => f.text())).then((texts) => {
      addFiles([
        ...newFiles.map((f, i) => ({
          name: f.name,
          content: texts[i],
          mode: modeFromFilename(f.name),
        })),
      ]);
    });
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files) as File[];

    processNewFiles(newFiles);
    setDropZoneActive(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const newFiles = Array.from(event.target.files) as File[];
    processNewFiles(newFiles);
  };

  const [isDropZoneActive, setDropZoneActive] = React.useState(false);

  const handleDragEnter = () => {
    setDropZoneActive(true);
  };

  const handleDragLeave = () => {
    setDropZoneActive(false);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setDropZoneActive(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <div
        className={`${classes.dropZone} ${
          isDropZoneActive ? classes.dropZoneActive : ""
        }`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onClick={() => {
          const input = document.createElement("input");
          input.type = "file";
          input.multiple = true;
          input.onchange = handleFileSelect as any;
          input.click();
        }}
        style={{ padding: 40 }}
      >
        <Typography style={{ pointerEvents: "none" }}>
          Click to select or drop Docker input files here
        </Typography>
      </div>
    </div>
  );
};
