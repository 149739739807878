import React from "react";
import { EditableMarkdown } from "../../EditableMarkdown";
import { QuickstartParameter } from "./QuickstartParameter";
import { PocScriptStepParameter } from "../../poc-types";
import { QuickstartOptions, useQuickstart } from "../../useQuickstartStore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useTheme,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

const ParameterWrapper = ({
  parameter,
  values,
  store,
  onChange,
  index,
}: {
  index: number;
  parameter: PocScriptStepParameter;
  values: QuickstartOptions;
  store: typeof useQuickstart;
  onChange?: (param: string, value: string) => void;
}) => {
  const theme = useTheme();

  const description = parameter.descriptionMarkdown ? (
    <div
      style={{
        marginRight: 16,
        minWidth: 270,
        borderTop: index > 0 ? `1px solid ${theme.palette.divider}` : undefined,
        marginTop: index > 0 ? 24 : 0,
      }}
    >
      <EditableMarkdown
        id={`${parameter.id}-description`}
        markdown={parameter.descriptionMarkdown}
      />
    </div>
  ) : null;

  const content = (
    <div
      style={{
        height: "fit-content",
        minWidth: 350,
      }}
    >
      {description}
      <QuickstartParameter
        parameter={parameter}
        values={values}
        store={store}
        onChange={onChange}
      />
    </div>
  );

  return <div>{content}</div>;
};

export const QuickstartParameters = (props: {
  parameters: PocScriptStepParameter[];
  store: typeof useQuickstart;
  onParamChange?: (param: string, value: string) => void;
}) => {
  const values = props.store((state) => state.allValues());

  const standaloneParameters = props.parameters.filter(
    (p) => p.display !== "collapsible"
  );
  const collapsedParameters = props.parameters.filter(
    (p) => p.display === "collapsible"
  );

  const renderParam = (parameter: PocScriptStepParameter, index: number) => (
    <ParameterWrapper
      key={index}
      index={index}
      parameter={parameter}
      values={values}
      store={props.store}
      onChange={props.onParamChange}
    />
  );

  return (
    <>
      {standaloneParameters.map(renderParam)}
      {collapsedParameters.length ? (
        <Accordion style={{ marginTop: 8 }}>
          <AccordionSummary>
            <KeyboardArrowDown style={{ marginRight: 8 }} />
            Advanced configuration
          </AccordionSummary>
          <AccordionDetails>
            {collapsedParameters.map(renderParam)}
          </AccordionDetails>
        </Accordion>
      ) : null}
    </>
  );
};
