// @ts-check
import React from "react";
import { Link, Typography } from "@mui/material";
import { useAppStage } from "../context/AppStageContext";
import {
  AccountExpiryThresholds,
  numberOfDaysUntilAccountExpires,
  numberOfDaysUntilFreeTrialExpires,
} from "./account/accountUtil";

export const ContactSales = () => (
  <Link
    style={{
      textTransform: "uppercase",
      color: "inherit",
      fontWeight: 700,
      fontSize: 16,
      fontFamily: "Montserrat Medium",
      textDecorationColor: "inherit",
    }}
    target="_blank"
    href="mailto:sales@anjuna.io"
  >
    Contact Sales
  </Link>
);

const ViewDetails = ({ onViewDetails }: { onViewDetails: () => void }) => (
  <Link
    style={{
      textTransform: "uppercase",
      color: "inherit",
      fontWeight: 700,
      fontSize: 16,
      fontFamily: "Montserrat Medium",
      textDecorationColor: "inherit",
      marginLeft: 48,
      cursor: "pointer",
    }}
    onClick={() => onViewDetails()}
  >
    View Details
  </Link>
);

export const AccountBanner = () => {
  const [{ stage }, dispatch] = useAppStage();

  const onViewDetails = React.useCallback(() => {
    dispatch({
      type: "open modal",
      modalId: "account details",
    });
  }, [dispatch]);

  if (stage.type !== "dashboard" || !stage.access) return null;

  const daysUntilExpiry = stage.access.map((cur) =>
    numberOfDaysUntilAccountExpires(cur.endsAt)
  );

  const numExpiringSoon = daysUntilExpiry.filter(
    (days) => days <= AccountExpiryThresholds.Warn && days > 0
  ).length;
  if (!stage.freeTrialAccess && numExpiringSoon < 1) return null;

  const hasExpiringVerySoon = daysUntilExpiry.some(
    (days) => days > 0 && days <= AccountExpiryThresholds.Danger
  );
  const minDaysUntilExpiry = numberOfDaysUntilFreeTrialExpires(
    stage.access.map((a) => a.endsAt)
  );
  if (minDaysUntilExpiry <= 0) return null;

  const messageText = stage.freeTrialAccess
    ? `You are using the free trial - ${minDaysUntilExpiry} days are remaining.`
    : numExpiringSoon === 1
    ? "Your access to an Anjuna Software technology expires soon."
    : `Your access to ${numExpiringSoon} Anjuna Software technologies expires soon.`;

  const message = <Typography variant="subtitle1">{messageText}</Typography>;

  /**
   * @type {React.CSSProperties}
   */
  const alertStyles: React.CSSProperties = {
    backgroundColor: hasExpiringVerySoon ? "#FF8575" : "#FFD893",
    color: hasExpiringVerySoon ? "#A91B12" : "#8D5B00",
    borderRadius: 0,
    padding: "12px 32px",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  };

  return (
    <div style={alertStyles}>
      <div style={{ flex: 1 }} />
      {message}
      {!stage.freeTrialAccess ? (
        <ViewDetails onViewDetails={onViewDetails} />
      ) : null}
      <div style={{ flex: 1 }} />
    </div>
  );
};
