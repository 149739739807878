import React from "react";
import { ParamScriptView } from "./components/ParamScriptView";

export const EnclaveConfigView = ({ container }: { container: string }) => {
  return (
    <ParamScriptView
      operation={"enclave config"}
      container={container}
      descriptionMarkdown={`## Configure Enclave

Specify how the Anjuna Nitro Runtime sets up the application's environment and starts the application in an AWS Nitro Enclave.`}
    />
  );
};

export const InstallView = () => {
  return <ParamScriptView operation={"install"} />;
};

export const DockerImageView = ({ container }: { container: string }) => {
  return (
    <ParamScriptView
      operation={"docker input"}
      container={container}
      descriptionMarkdown={`## Docker Inputs

Specifies inputs to the Docker image that is generated and then used to build an Enclave Image File.

The container can be specified by:
* a Docker URI to any Docker registry you have access to (for example 1122334455.dkr.ecr.us-east-1.amazonaws.com/hello-world:latest)
* a local URI (for example nginx:latest)
* a directory that contains a Dockerfile

See [here](https://docs.anjuna.io/nitro/latest/getting_started/command_reference/anjuna_nitro_cli/build_enclave.html) for more information.`}
    />
  );
};

export const BuildEnclaveView = () => {
  return (
    <ParamScriptView
      operation={"build enclave"}
      descriptionMarkdown={`## Build

Builds a Docker image then uses the \`anjuna-nitro-cli build-enclave\` tool to create an Enclave Image File (EIF) from the Docker image.

See [here](https://docs.anjuna.io/nitro/latest/getting_started/command_reference/anjuna_nitro_cli/build_enclave.html) for more information.`}
    />
  );
};
