import React from "react";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";

export interface KeyValue {
  key: string;
  value: string;
}

interface Props {
  title: string;
  keyValues: KeyValue[];
  onChange: (keyValues: KeyValue[]) => void;
}

export const KeyValueList: React.FC<Props> = ({
  title,
  keyValues,
  onChange,
}) => {
  const handleAddKeyValue = () => {
    onChange([...keyValues, { key: "", value: "" }]);
  };

  const handleRemoveKeyValue = (index: number) => {
    const newKeyValues = [
      ...keyValues.slice(0, index),
      ...keyValues.slice(index + 1),
    ];
    onChange(newKeyValues);
  };

  const handleChangeKey = (index: number, newKey: string) => {
    const newKeyValues = [
      ...keyValues.slice(0, index),
      { ...keyValues[index], key: newKey },
      ...keyValues.slice(index + 1),
    ];
    onChange(newKeyValues);
  };

  const handleChangeValue = (index: number, newValue: string) => {
    const newKeyValues = [
      ...keyValues.slice(0, index),
      { ...keyValues[index], value: newValue },
      ...keyValues.slice(index + 1),
    ];
    onChange(newKeyValues);
  };

  return (
    <Box sx={{ position: "relative" }} component="div">
      <Typography style={{ marginBottom: 8 }}>{title}:</Typography>
      {keyValues.map((kv, index) => (
        <div
          className="flex-row-container flex-centered flex-wrap"
          key={index}
          style={{ marginBottom: 8 }}
        >
          <TextField
            value={kv.key}
            onChange={(e) => handleChangeKey(index, e.target.value)}
            variant="outlined"
            size="small"
            placeholder="Key"
            style={{ marginRight: 8, width: 120 }}
          />
          <TextField
            value={kv.value}
            onChange={(e) => handleChangeValue(index, e.target.value)}
            variant="outlined"
            placeholder="Value"
            size="small"
            style={{ width: 120 }}
          />
          <IconButton edge="end" onClick={() => handleRemoveKeyValue(index)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
      <Button
        onClick={handleAddKeyValue}
        variant="outlined"
        style={
          keyValues.length ? { position: "absolute", bottom: 0, right: 0 } : {}
        }
      >
        Add
      </Button>
    </Box>
  );
};
