// @ts-check
import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  CircularProgress,
  SxProps,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useAppStage } from "../../context/AppStageContext";
import { makeStyles } from "@mui/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  UserDownload,
  UserDownloads,
  downloadsReportAPI,
} from "../../api/downloadsReportAPI";
import clsx from "clsx";
import { CodeBox } from "./CodeBox";
import { OrgsTableContainer } from "./OrgsTable";
import { EditOrgAccess } from "./EditOrgAccess";
import {
  SortableTableCell,
  scrollableTableContainerStyle,
} from "./SortableTableCell";
import { downloadApiEndpoint } from "../../api/downloadAPI";
import {
  ProductAccess,
  SortOrder,
  TECH_TYPES_UI,
  Tech,
} from "../../api/ApiTypes";
import { NoOrgUsersTableContainer } from "./NoOrgUsersTable";
import { isUsingNewOrgsTable } from "../../shared/utils";
import { usersReportApiEndpoint } from "../../api/usersReportAPI";
import { UsersReportTableContainer } from "./UsersReportTable";

const modalStyles: SxProps = {
  width: "100%",
  height: "100%",
  // p: 4,
  textAlign: "center",
  overflow: "hidden",
};

const useStyles = makeStyles({
  content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  software: {
    fontSize: 14,
    fontWeight: 700,
    paddingTop: 6,
    paddingBottom: 22,
    paddingLeft: 6,
    paddingRight: 6,
  },
  expiration: {
    fontSize: 16,
    fontWeight: 400,
    "&.warning": {
      color: "#EF9D0A",
    },
    "&.danger": {
      color: "#A91B12",
      fontWeight: 700,
    },
  },
  label: {
    color: "#A3A3A3",
    fontFamily: "Montserrat Medium",
    fontSize: 16,
  },
  value: {
    fontFamily: "Montserrat Medium",
    fontSize: 16,
  },
  button: {
    height: 44,
    fontWeight: "bold",
    fontSize: 16,
    padding: "16px 100px",
  },
  title: {
    marginBottom: 0,
    fontSize: 16,
    fontFamily: "Montserrat Medium",
    fontWeight: 400,
  },
  downloadsCell: {},
  downloadsHeaderLabel: {
    color: "#555",
    fontSize: 15,
    whiteSpace: "nowrap",
    fontWeight: 700,
  },
  noDownloads: {
    color: "#777",
  },
  someDownloads: {
    color: "#EF9D0A",
  },
  manyDownloads: {
    color: "#A91B12",
    fontWeight: 700,
  },
  techLabel: {
    position: "absolute",
    top: 20,
    left: "0%",
    // width: "100%",
    fontSize: 10,
    borderRadius: 8,
    border: "1px solid #999",
    fontWeight: 700,
    padding: "0 3px",
    color: "#999",
  },
  techLabelNitro: {
    left: -12,
    width: 30,
  },
  techLabelSgx: {
    left: -3,
    width: 24,
  },
  techLabelGcp: {
    right: 8,
    width: 26,
  },
  techLabelAzsev: {
    right: 24,
    width: 24,
  },
  techLabelAks: {
    right: 38,
    width: 24,
  },
  techLabelApm: {
    right: 58,
    width: 28,
  },
});

const ORDERBY_STORAGE_KEY = "downloads-report-orderBy";
const ORDER_STORAGE_KEY = "downloads-report-order";

const tableContentHeight = `100%`;

const downloadsByType = (
  start: number,
  end: number,
  type: Tech,
  downloads: UserDownload[]
) => {
  const dateFrom = new Date(Date.now() - end * 1000 * 60 * 60 * 24);
  const dateTo = new Date(Date.now() - start * 1000 * 60 * 60 * 24);

  return downloads.filter(
    (download: UserDownload) =>
      download.type === type &&
      new Date(download.downloadedAt) > dateFrom &&
      new Date(download.downloadedAt) <= dateTo
  );
};

const downloadsByDateRange = (
  start: number,
  end: number,
  downloads: UserDownload[]
) => ({
  nitro: downloadsByType(start, end, "nitro", downloads),
  sgx: downloadsByType(start, end, "sgx", downloads),
  gcp: downloadsByType(start, end, "gcp", downloads),
  azsev: downloadsByType(start, end, "azsev", downloads),
  "k8s-sev": downloadsByType(start, end, "k8s-sev", downloads),
  apm: downloadsByType(start, end, "apm", downloads),
});

const Download = ({ count, tech }: { count: number; tech: Tech }) => {
  const classes = useStyles();
  return (
    <span
      className={
        count < 5
          ? classes.noDownloads
          : count < 20
          ? classes.someDownloads
          : classes.manyDownloads
      }
      style={{ position: "relative" }}
    >
      {count}
      {count ? (
        <span
          className={clsx(classes.techLabel, {
            [classes.techLabelGcp]: tech === "gcp",
            [classes.techLabelSgx]: tech === "sgx",
            [classes.techLabelNitro]: tech === "nitro",
            [classes.techLabelAzsev]: tech === "azsev",
            [classes.techLabelAks]: tech === "k8s-sev",
            [classes.techLabelApm]: tech === "apm",
          })}
        >
          {tech === "k8s-sev" ? "aks" : tech}
        </span>
      ) : null}
    </span>
  );
};

type Interval = "month1" | "month2" | "month3" | "pastYear" | "ever";

export type UserDownloadsData = UserDownloads & {
  dateData: {
    month1: ReturnType<typeof downloadsByDateRange>;
    month2: ReturnType<typeof downloadsByDateRange>;
    month3: ReturnType<typeof downloadsByDateRange>;
    pastYear: ReturnType<typeof downloadsByDateRange>;
    ever: ReturnType<typeof downloadsByDateRange>;
  };
};

const downloadsLength = (data: UserDownloadsData, time: Interval) =>
  data.dateData[time].sgx.length +
  data.dateData[time].nitro.length +
  data.dateData[time].gcp.length +
  data.dateData[time].azsev.length +
  data.dateData[time]["k8s-sev"].length +
  data.dateData[time].apm.length;

const compareDownloads = (
  first: UserDownloadsData,
  second: UserDownloadsData,
  time: Interval
) => downloadsLength(first, time) - downloadsLength(second, time);

const DownloadsReportContent = (props: { token: string }) => {
  const { token } = props;

  const classes = useStyles();

  const [status, setStatus] = React.useState("idle");
  /**
   * @type {[any, any]}
   */
  const [data, setData] = React.useState<UserDownloadsData[]>([]);

  const lastOrderBy = window.localStorage.getItem(ORDERBY_STORAGE_KEY) || "";

  const lastOrder: SortOrder =
    (window.localStorage.getItem(ORDER_STORAGE_KEY) as SortOrder) || "asc";

  const [order, setOrder] = useState<"asc" | "desc">(lastOrder);
  const [orderBy, setOrderBy] = useState(lastOrderBy);

  const handleRequestSort = React.useCallback(
    (property: string) => {
      const isAsc = orderBy === property && order === "asc";
      const newOrder = isAsc ? "desc" : "asc";
      setOrder(newOrder);
      setOrderBy(property);

      window.localStorage.setItem(ORDERBY_STORAGE_KEY, property);
      window.localStorage.setItem(ORDER_STORAGE_KEY, newOrder);
    },
    [order, orderBy]
  );

  React.useEffect(() => {
    setStatus("fetching");

    downloadsReportAPI(token)
      .then((result) => {
        switch (result.type) {
          case "success":
            setData(
              result.report.map((user) => ({
                companyName: user.companyName,
                id: user.id,
                downloads: user.downloads,
                dateData: {
                  month1: downloadsByDateRange(0, 30, user.downloads),
                  month2: downloadsByDateRange(30, 60, user.downloads),
                  month3: downloadsByDateRange(60, 120, user.downloads),
                  pastYear: downloadsByDateRange(0, 365, user.downloads),
                  ever: downloadsByDateRange(0, 30 * 365, user.downloads),
                },
              }))
            );
            break;
          default:
            break;
        }
      })
      .catch((e) => {
        console.warn(e);
      })
      .finally(() => {
        setStatus("idle");
      });
  }, [token]);

  return (
    <div style={{ textAlign: "left", overflow: "auto" }}>
      <Typography variant="h2">Downloads Summary</Typography>
      {status === "fetching" ? (
        <div style={{ display: "flex", flexDirection: "row", height: 100 }}>
          <div style={{ flex: 1 }} />
          <CircularProgress style={{ margin: "auto" }} />
          <div style={{ flex: 1 }} />
        </div>
      ) : (
        <TableContainer>
          <Table aria-label="downloads report">
            <TableHead>
              <TableRow>
                <SortableTableCell
                  id="id"
                  order={order}
                  orderBy={orderBy}
                  onSortClick={handleRequestSort}
                  className={classes.downloadsCell}
                >
                  <Typography
                    className={classes.downloadsHeaderLabel}
                    fontSize="smaller"
                  >
                    {isUsingNewOrgsTable() ? "Company Name" : "Company Email"}
                  </Typography>
                </SortableTableCell>
                <SortableTableCell
                  id="month1"
                  order={order}
                  orderBy={orderBy}
                  onSortClick={handleRequestSort}
                  className={classes.downloadsCell}
                >
                  <Typography className={classes.downloadsHeaderLabel}>
                    Past 30 days
                  </Typography>
                </SortableTableCell>
                <SortableTableCell
                  id="month2"
                  order={order}
                  orderBy={orderBy}
                  onSortClick={handleRequestSort}
                  className={classes.downloadsCell}
                >
                  <Typography className={classes.downloadsHeaderLabel}>
                    30 - 60 days
                  </Typography>
                </SortableTableCell>
                <SortableTableCell
                  id="month3"
                  order={order}
                  orderBy={orderBy}
                  onSortClick={handleRequestSort}
                  className={classes.downloadsCell}
                >
                  <Typography className={classes.downloadsHeaderLabel}>
                    60 - 120 days
                  </Typography>
                </SortableTableCell>
                <SortableTableCell
                  id="pastYear"
                  order={order}
                  orderBy={orderBy}
                  onSortClick={handleRequestSort}
                  className={classes.downloadsCell}
                >
                  <Typography
                    className={classes.downloadsHeaderLabel}
                    fontSize="smaller"
                  >
                    Past year
                  </Typography>
                </SortableTableCell>
                <SortableTableCell
                  id="ever"
                  order={order}
                  orderBy={orderBy}
                  onSortClick={handleRequestSort}
                  className={classes.downloadsCell}
                >
                  <Typography
                    className={classes.downloadsHeaderLabel}
                    fontSize="smaller"
                  >
                    Ever (all)
                  </Typography>
                </SortableTableCell>
              </TableRow>
            </TableHead>
            <TableBody style={scrollableTableContainerStyle}>
              {data
                .sort((a, b) => {
                  const first = order === "asc" ? a : b;
                  const second = order === "asc" ? b : a;
                  if (orderBy === "id")
                    return (first.id || "").localeCompare(second.id || "");
                  if (orderBy === "companyName")
                    return (first.companyName || "").localeCompare(
                      second.companyName || ""
                    );
                  if (
                    orderBy.startsWith("month") ||
                    orderBy === "pastYear" ||
                    orderBy === "ever"
                  ) {
                    console.log(
                      "sorting",
                      compareDownloads(first, second, orderBy as Interval)
                    );

                    return compareDownloads(first, second, orderBy as Interval);
                  }
                  return 0;
                })
                .map(({ companyName, id, dateData }) => {
                  return (
                    <TableRow key={id}>
                      <TableCell
                        data-testid="email-domain-cell"
                        className={classes.software}
                      >
                        {isUsingNewOrgsTable() ? companyName : id}
                      </TableCell>
                      {Object.values(dateData).map((row) => (
                        <TableCell className={classes.software}>
                          <Download count={row.nitro.length} tech="nitro" />
                          <span style={{ color: "#777" }}>{" / "}</span>
                          <Download count={row.sgx.length} tech="sgx" />
                          <span style={{ color: "#777" }}>{" / "}</span>
                          <Download count={row.gcp.length} tech="gcp" />
                          <span style={{ color: "#777" }}>{" / "}</span>
                          <Download count={row.azsev.length} tech="azsev" />
                          <span style={{ color: "#777" }}>{" / "}</span>
                          <Download
                            count={row["k8s-sev"].length}
                            tech="k8s-sev"
                          />
                          <span style={{ color: "#777" }}>{" / "}</span>
                          <Download count={row.apm.length} tech="apm" />
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

const NoOrgUserInviteContent = (props: { attributes: { email: string } }) => {
  const { attributes } = props;

  return (
    <div style={{ height: "100%" }}>
      <NoOrgUsersTableContainer
        key="NoOrgUsersTableContainer"
        email={attributes.email}
      />
    </div>
  );
};

const UsersReportContent = (props: { token: string }) => {
  const endpoint = usersReportApiEndpoint();

  return (
    <div style={{ height: "100%" }}>
      <UsersReportTableContainer
        key="UsersReportTableContainer"
        token={props.token}
      />
      <div style={{ textAlign: "left", marginTop: 32 }}>
        <Typography variant="h3">Users Report API</Typography>
        <Typography fontSize={"smaller"}>
          Returns all users alongisde their associated organization, if any.
          <p />
          Run the following command in a terminal:
        </Typography>
        <CodeBox
          id="md5"
          code={`curl -G ${endpoint}/v1/users \\
--data-urlencode token=${props.token} \\
--data-urlencode report=true`}
        />
      </div>
    </div>
  );
};

const UserAccessContent = (props: {
  attributes: { email: string };
  token: string;
}) => {
  const { attributes, token } = props;

  const [creating, setCreating] = React.useState(false);
  const [orgsKey, setOrgsKey] = React.useState(0);

  const defaultAccess = TECH_TYPES_UI.map(
    (type) =>
      ({
        type,
        endsAt: "expired",
        licenseType: "trial",
        licenseYaml: undefined,
      } as ProductAccess)
  );

  const creatingOrgModal = creating ? (
    <EditOrgAccess
      id={""}
      access={defaultAccess}
      companyName={""}
      licenseEmail={""}
      templatesAccess={false}
      prereleasesAccess={false}
      freeTrialAccess={false}
      handleClose={(changes) => {
        setCreating(false);

        changes && setOrgsKey(orgsKey + 1); // Triggers a remount (and refetch) of orgs data.
      }}
    />
  ) : null;

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Typography style={{ textAlign: "left" }} variant="h2">
          Manage Access
        </Typography>
        <div style={{ flex: 1 }} />
        <Button
          variant="contained"
          onClick={() => setCreating(true)}
          data-testid="create-org"
        >
          Create Organization
        </Button>
      </div>

      {creatingOrgModal}

      <OrgsTableContainer
        key={orgsKey}
        email={attributes.email}
        token={token}
      />
    </div>
  );
};

const DownloadsAPIContent = (props: { token: string }) => {
  const { token } = props;

  const endpoint = downloadApiEndpoint();

  return (
    <div style={{ textAlign: "left" }}>
      <Typography variant="h3">Downloads Report API</Typography>
      <Typography fontSize={"smaller"}>
        Returns a history of downloads, grouped by company.
        <p />
        Run the following command in a terminal:
      </Typography>
      <CodeBox
        id="md5"
        code={`curl -G ${endpoint}/analytics/v1/usage \\
--data-urlencode token=${token} \\
--data-urlencode report=true`}
      />
    </div>
  );
};

const Content = React.forwardRef<
  any,
  {
    canApproveUsers: boolean;
    token: string;
    attributes: { email: string };
  }
>((props, ref) => {
  // @ts-ignore
  const { canApproveUsers, token } = props;

  const classes = useStyles();

  const [active, setActive] = React.useState(
    canApproveUsers ? "access" : "downloads"
  );

  const handleTabChange = React.useCallback((e: any, value: any) => {
    setActive(value);
  }, []);

  return (
    <Box sx={modalStyles} component="div">
      <div className={classes.content}>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <TabContext value={active}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              component="div"
            >
              <TabList
                onChange={handleTabChange}
                aria-label="User management tabs"
              >
                {canApproveUsers && (
                  <Tab label="Manage Access" value="access" />
                )}
                {canApproveUsers && isUsingNewOrgsTable() && (
                  <Tab label="Unassigned users" value="invite" />
                )}
                {canApproveUsers && isUsingNewOrgsTable() && (
                  <Tab label="All users" value="all users" />
                )}
                <Tab label="Downloads Report" value="downloads" />
              </TabList>
            </Box>
            <TabPanel
              value="access"
              style={{ flex: 1, height: tableContentHeight }}
            >
              <UserAccessContent {...props} />
            </TabPanel>
            <TabPanel
              value="downloads"
              style={{
                flex: 1,
                height: tableContentHeight,
                padding: "20px 4px",
              }}
            >
              <DownloadsReportContent token={token} />
            </TabPanel>
            <TabPanel
              value="downloads"
              style={{
                flex: 1,
                height: tableContentHeight,
                padding: "20px 4px",
              }}
            >
              <DownloadsAPIContent token={token} />
            </TabPanel>
            <TabPanel
              value="invite"
              style={{ flex: 1, height: tableContentHeight }}
            >
              <NoOrgUserInviteContent {...props} />
            </TabPanel>
            <TabPanel
              value="all users"
              style={{ flex: 1, height: tableContentHeight }}
            >
              <UsersReportContent token={props.token} />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </Box>
  );
});

export const UserAccess = () => {
  const [{ stage }] = useAppStage();

  if (stage.type !== "dashboard") {
    return null;
  }

  return (
    <Content
      token={stage.apiToken!}
      canApproveUsers={stage.canApproveUsers}
      attributes={stage.user.attributes}
    />
  );
};
