import React from "react";
import {
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  BodyTableCell,
  SortableTableCell,
  scrollableTableContainerStyle,
} from "./SortableTableCell";
import { UserInfo, SortOrder } from "../../api/ApiTypes";
import { UserPlusOrg, usersReportAPI } from "../../api/usersReportAPI";
import { numberOfDaysUntilAccountExpires } from "../../components/account/accountUtil";
import clsx from "clsx";

const checkboxColumnWidth = 100;

const useStyles = makeStyles({
  label: {
    fontSize: 14,
    fontWeight: 700,
  },
  domain: {
    fontSize: 14,
  },
  value: {
    fontSize: 14,
  },
  message: {},
  checkboxCell: {
    fontSize: 22,
  },
});

const CheckboxValue = (props: { value?: boolean }) => {
  const classes = useStyles();

  return (
    <Typography
      className={clsx(classes.domain, classes.checkboxCell)}
      style={{
        width: checkboxColumnWidth,
        color: props.value ? "green" : "lightgray",
      }}
    >
      {props.value !== undefined ? (props.value ? `☑` : `☐`) : ""}
    </Typography>
  );
};

const UsersReportTable = (props: { users: UserPlusOrg[] }) => {
  const { users } = props;

  const classes = useStyles();

  const ORDERBY_STORAGE_KEY = "arc-users-report-orderBy";
  const lastOrderBy = window.localStorage.getItem(ORDERBY_STORAGE_KEY) || "";

  const ORDER_STORAGE_KEY = "arc-users-report-order";
  const lastOrder: SortOrder =
    (window.localStorage.getItem(ORDER_STORAGE_KEY) as SortOrder) || "asc";

  const [order, setOrder] = React.useState<SortOrder>(lastOrder);
  const [orderBy, setOrderBy] = React.useState(lastOrderBy);

  const handleRequestSort = React.useCallback(
    (property: string) => {
      const isAsc = orderBy === property && order === "asc";
      const newOrder = isAsc ? "desc" : "asc";
      setOrder(newOrder);
      setOrderBy(property);

      window.localStorage.setItem(ORDERBY_STORAGE_KEY, property);
      window.localStorage.setItem(ORDER_STORAGE_KEY, newOrder);
    },
    [order, orderBy]
  );

  return users && users.length ? (
    <>
      <TableContainer style={{ overflowX: "hidden" }}>
        <Table>
          <TableHead>
            <TableRow>
              <SortableTableCell
                id={"email"}
                order={order}
                orderBy={orderBy}
                onSortClick={handleRequestSort}
              >
                <Typography className={classes.label}>User email</Typography>
              </SortableTableCell>
              <SortableTableCell
                id={"name"}
                order={order}
                orderBy={orderBy}
                onSortClick={handleRequestSort}
                width={250}
              >
                <Typography className={classes.label}>Full name</Typography>
              </SortableTableCell>
              <SortableTableCell
                id={"orgName"}
                order={order}
                orderBy={orderBy}
                onSortClick={handleRequestSort}
                width={200}
              >
                <Typography className={classes.label}>Organization</Typography>
              </SortableTableCell>
              <SortableTableCell
                id={"orgFreeTrialAccess"}
                order={order}
                orderBy={orderBy}
                onSortClick={handleRequestSort}
                className={classes.checkboxCell}
                width={checkboxColumnWidth}
              >
                <Typography className={classes.label}>Free trial</Typography>
              </SortableTableCell>
              <SortableTableCell
                id={"orgPrereleasesAccess"}
                order={order}
                orderBy={orderBy}
                onSortClick={handleRequestSort}
                width={checkboxColumnWidth}
              >
                <Typography className={classes.label}>Pre-releases</Typography>
              </SortableTableCell>
              <SortableTableCell
                id={"orgTemplatesAccess"}
                order={order}
                orderBy={orderBy}
                onSortClick={handleRequestSort}
                width={checkboxColumnWidth}
              >
                <Typography className={classes.label}>Quickstarts</Typography>
              </SortableTableCell>
            </TableRow>
          </TableHead>
          <TableBody
            style={{ ...scrollableTableContainerStyle, overflowX: "hidden" }}
          >
            {users
              .map((user) => {
                const { org, ...userInfo } = user;
                const {
                  access: orgAccess,
                  freeTrialAccess: orgFreeTrialAccess,
                  prereleasesAccess: orgPrereleasesAccess,
                  templatesAccess: orgTemplatesAccess,
                  name: orgName,
                  licenseEmail: orgLicenseEmail,
                } = org || {};

                return {
                  ...userInfo,
                  orgAccess: orgAccess
                    ? orgAccess
                        .map((a) => a.endsAt)
                        .map((endsAt) =>
                          Math.max(0, numberOfDaysUntilAccountExpires(endsAt))
                        )
                        .reduce((a, b) => (b > 0 ? a + 1 : a), 0)
                    : 0,
                  orgFreeTrialAccess,
                  orgPrereleasesAccess,
                  orgTemplatesAccess,
                  orgName,
                  orgLicenseEmail,
                };
              })
              .sort((a, b) => {
                const first = order === "asc" ? a : b;
                const second = order === "asc" ? b : a;

                return (
                  first[orderBy as keyof UserInfo]?.toString() || ""
                ).localeCompare(
                  second[orderBy as keyof UserInfo]?.toString() || ""
                );
              })
              .map((user) => {
                const {
                  name,
                  email,
                  orgName,
                  orgFreeTrialAccess,
                  orgPrereleasesAccess,
                  orgTemplatesAccess,
                } = user;
                return (
                  <TableRow
                    key={`${email}`}
                    data-testid={`UsersReportTable-row-${email}`}
                  >
                    <BodyTableCell data-testid="user-email-cell">
                      <Typography className={classes.domain}>
                        {email}
                      </Typography>
                    </BodyTableCell>
                    <BodyTableCell width={250}>
                      <Typography className={classes.domain}>{name}</Typography>
                    </BodyTableCell>
                    <BodyTableCell width={200}>
                      <Typography className={classes.domain}>
                        {orgName}
                      </Typography>
                    </BodyTableCell>
                    <BodyTableCell
                      className={classes.checkboxCell}
                      width={checkboxColumnWidth}
                    >
                      <CheckboxValue value={orgFreeTrialAccess} />
                    </BodyTableCell>
                    <BodyTableCell
                      className={classes.checkboxCell}
                      width={checkboxColumnWidth}
                    >
                      <CheckboxValue value={orgPrereleasesAccess} />
                    </BodyTableCell>
                    <BodyTableCell
                      className={classes.checkboxCell}
                      width={checkboxColumnWidth}
                    >
                      <CheckboxValue value={orgTemplatesAccess} />
                    </BodyTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ) : (
    "There are no users signed up yet."
  );
};

export const UsersReportTableContainer = (props: { token: string }) => {
  const { token } = props;

  const [users, setUsers] = React.useState<UserInfo[]>([]);
  const [userError, setUserError] = React.useState("");
  const [userStatus, setUserStatus] = React.useState("idle");

  React.useEffect(() => {
    setUserStatus("retrieving users");

    usersReportAPI(token)
      .then((result) => {
        if (result.type === "success") {
          setUsers(result.report);
        } else {
          setUsers([]);
          setUserError(result.reason);
        }
      })
      .catch((e) => {
        console.warn(e);

        setUserError(e.message);
      })
      .finally(() => {
        setUserStatus("idle");
      });
  }, [token]);

  const userErrorAlert = userError ? (
    <Alert severity={"error"}>
      {`Retrieving users report data from server failed: ${userError}.`}
    </Alert>
  ) : null;

  return userStatus === "retrieving users" ? (
    <CircularProgress sx={{ margin: 8 }} />
  ) : (
    <>
      {userErrorAlert}
      <UsersReportTable users={users} />
    </>
  );
};
