import React from "react";
import { Button, Theme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { ButtonWithProgress } from "./ButtonWithProgress";
import { useQuickstart } from "./useQuickstartStore";

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {},
  editButton: {
    marginRight: 16,
  },
}));

const EditControlsViewing = () => {
  const classes = useStyles();

  const beginEditing = useQuickstart((state) => state.beginEditing);

  const savingTemplate = useQuickstart(
    (state) => state.status.kind === "saving template"
  );
  const restoreDefaultContent = useQuickstart(
    (state) => state.restoreDefaultContent
  );

  return (
    <div className="flex-row-container flex-centered">
      <Button
        title="Edit template"
        data-testid="edit-template-button"
        onClick={beginEditing}
        className={classes.editButton}
        variant="outlined"
        style={{ fontWeight: "bold" }}
      >
        Edit
        <EditIcon style={{ marginLeft: 8 }} fontSize="small" />
      </Button>
      <ButtonWithProgress
        variant="outlined"
        style={{ marginRight: 8 }}
        busy={savingTemplate}
        onClick={restoreDefaultContent}
        data-testid="template-restore-defaults-button"
      >
        Restore to Defaults
      </ButtonWithProgress>
    </div>
  );
};

const EditControlsEditing = () => {
  const endEditing = useQuickstart((state) => state.endEditing);

  const savingTemplate = useQuickstart(
    (state) => state.status.kind === "saving template"
  );

  const cancelEditing = React.useCallback(() => {
    endEditing("cancel");
  }, [endEditing]);

  const saveEditing = React.useCallback(() => {
    endEditing("save");
  }, [endEditing]);

  const classes = useStyles();

  return (
    <div className="flex-row-container flex-centered">
      <ButtonWithProgress
        onClick={saveEditing}
        className={classes.iconButton}
        variant="contained"
        style={{ marginRight: 8, minWidth: 100 }}
        busy={savingTemplate}
        data-testid="save-template-button"
      >
        Save
        <CheckIcon style={{ marginLeft: 8, fontSize: 20 }} />
      </ButtonWithProgress>
      <Button
        onClick={cancelEditing}
        className={classes.iconButton}
        style={{ marginRight: 16 }}
        variant="outlined"
        data-testid="cancel-edit-template-button"
      >
        Cancel
        <CloseIcon style={{ marginLeft: 8, fontSize: 20 }} />
      </Button>
    </div>
  );
};

export const EditControls = () => {
  const editing = useQuickstart((state) => state.isEditing());

  const controls = editing ? <EditControlsEditing /> : <EditControlsViewing />;

  return <div className="flex-row-container flex-centered">{controls}</div>;
};
