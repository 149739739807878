import React from "react";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  PocScriptStepListPararameterObject,
  PocScriptStepListPararameterObjectSchema,
} from "../poc-types";
import { EditableMarkdown } from "../EditableMarkdown";
import { InfoButtonPopover } from "../InfoButtonPopover";

export interface KeyValue {
  key: string;
  value: string;
}

interface Props {
  title: string;
  objects: PocScriptStepListPararameterObject[];
  schema: PocScriptStepListPararameterObjectSchema;
  defaultValue: PocScriptStepListPararameterObject;
  onChange: (objects: PocScriptStepListPararameterObject[]) => void;
}

export const ObjectList: React.FC<Props> = ({
  title,
  objects,
  schema,
  defaultValue,
  onChange,
}) => {
  const handleAdd = () => {
    onChange([...objects, defaultValue]);
  };

  const handleRemove = (index: number) => {
    const newObjects = [
      ...objects.slice(0, index),
      ...objects.slice(index + 1),
    ];
    onChange(newObjects);
  };

  const handleChangeEntry = (index: number, key: string, newValue: string) => {
    const newObjects = [
      ...objects.slice(0, index),
      {
        ...objects[index],
        [key]: newValue,
      },
      ...objects.slice(index + 1),
    ];
    onChange(newObjects);
  };

  const handleDescriptionMarkdownSaved = React.useCallback(
    (markdown: string) => {
      console.log(markdown);
    },
    []
  );

  return (
    <Box component="div">
      <Typography style={{ marginBottom: 8 }}>{title}:</Typography>
      {objects.map((obj, index) => (
        <div
          className="flex-col-container"
          key={index}
          style={{ marginBottom: 8 }}
        >
          {Object.entries(obj).map(([key, value]) => (
            <div
              key={key}
              className="flex-row-container flex-centered"
              style={{ marginBottom: 8 }}
            >
              <Typography
                key={key}
                style={{
                  width: 100,
                  textAlign: "right",
                  paddingRight: 8,
                }}
              >
                {key}
                {schema[key].required ? "*" : ""}
              </Typography>
              <TextField
                value={value}
                onChange={(e) => handleChangeEntry(index, key, e.target.value)}
                variant="outlined"
                size="small"
                style={{
                  marginLeft: 8,
                  flex:
                    schema[key].kind === "long text" ||
                    schema[key].kind === "multiline text"
                      ? 1
                      : "unset",
                }}
                multiline={schema[key].kind === "multiline text"}
                minRows={3}
                data-testid={`object-list-${title}-${key}`}
              />
              {schema[key].descriptionMarkdown && (
                <InfoButtonPopover
                  title={key}
                  content={
                    <EditableMarkdown
                      id={`${title}-${key}`}
                      markdown={schema[key].descriptionMarkdown!}
                      onSave={handleDescriptionMarkdownSaved}
                    />
                  }
                />
              )}
            </div>
          ))}
          <div className="flex-row-container flex-centered">
            <div className="flex-spacer" />
            <IconButton edge="end" onClick={() => handleRemove(index)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      ))}
      <Button
        onClick={handleAdd}
        variant="outlined"
        data-testid={`${title}-add-object-button`}
      >
        Add
      </Button>
    </Box>
  );
};
