import React from "react";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { AddCircleOutline, Delete as DeleteIcon } from "@mui/icons-material";
import { NumericInput } from "../NumericInput";

interface Props {
  id: string;
  title: string;
  label: string;
  placeholder: string | undefined;
  items: number[];
  min: number;
  max: number;
  onChange: (items: (number | undefined)[]) => void;
}

export const NumberList: React.FC<Props> = ({
  id,
  title,
  items,
  label,
  placeholder,
  max,
  min,
  onChange,
}) => {
  const handleAddKeyValue = () => {
    onChange([...items, undefined]);
  };

  const handleRemoveKeyValue = (index: number) => {
    const newKeyValues = [...items.slice(0, index), ...items.slice(index + 1)];
    onChange(newKeyValues);
  };

  const handleChange = (index: number, newValue: number) => {
    const newValues = [
      ...items.slice(0, index),
      newValue,
      ...items.slice(index + 1),
    ];

    onChange(newValues);
  };

  const theme = useTheme();

  return (
    <Box component="div">
      <Typography style={{ marginBottom: 8 }}>{title}:</Typography>
      <div className="flex-col-container">
        {items.map((kv, index) => (
          <div
            className={"flex-row-container"}
            key={index}
            style={{ marginBottom: 8, alignItems: "flex-start" }}
          >
            <NumericInput
              label={label}
              max={max}
              min={min}
              value={kv}
              onChange={(value) => handleChange(index, value)}
              size="small"
              data-testid={`number-list-${id}-input-${index}`}
              placeholder={placeholder}
            />
            <IconButton
              edge="end"
              onClick={() => handleRemoveKeyValue(index)}
              data-testid={`number-list-${id}-delete`}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </div>
      <Button
        onClick={handleAddKeyValue}
        variant="outlined"
        data-testid={`number-list-${id}-add-button`}
        className={"flex-row-container flex-centered"}
        style={{ marginBottom: 8, background: theme.palette.background.paper }}
      >
        <AddCircleOutline style={{ marginRight: 8 }} />
        <div style={{ fontWeight: 700 }}>Add</div>
      </Button>
    </Box>
  );
};
