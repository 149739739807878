import React from "react";
import * as THREE from "three";
import _ from "lodash";
import { Box, Cone, Cylinder, Html, Text } from "@react-three/drei";
import { animated, useSpring } from "@react-spring/web";
import { a } from "@react-spring/three";
import {
  CompositeMeshNodeInfo,
  CompositeMeshNodeVM,
  MeshCylinderGeom,
  MeshGeomBase,
} from "./composite-mesh-types";
import {
  ConnectionInfo,
  ConnectionNode,
  calcBoundedSize,
  calculatePositionAndOrientation,
  expandDirectionBasedOnAlignment,
  mapSideToVector3,
} from "./composite-mesh-vm";
import { CacheImage } from "./CacheImage";
import { AnjRoundedBox } from "./AnjRoundedBox";
import { useMeshStore } from "./useCompositeMeshStore";
import { CompositeConnection3d } from "./CompositeConnection3d";
import { useTheme } from "../../../useThemeStore";
import {
  GroupProps,
  Object3DProps,
  ThreeEvent,
  useFrame,
} from "@react-three/fiber";
import { MacbookModel } from "../POC/views/create-instance/MacbookModel";
import { NodeHtmlComponents } from "./NodeHtml";
import { useInteractivity } from "./useInteractivity";
import { useMeshSelectableMaterialSpring } from "./mesh-helpers";
import { logAnyPropChanges } from "../../../core/logAnyPropsChanges";

type Props = {
  instanceId: string;
  id: string;
  scale?: THREE.Vector3;
  position?: THREE.Vector3;
  rotation?: THREE.Euler;
  parentCollapsed?: boolean;
};

export const AnimatedHtml = animated(Html);

export const AnimatedGroup = animated(
  (
    props: {
      scale?: number | THREE.Vector3Tuple;
      position?: THREE.Vector3Tuple;
      rotation?: THREE.Vector3Tuple;
      children: React.ReactNode;
    } & GroupProps
  ) => {
    if (
      props.scale === 0 ||
      (Array.isArray(props.scale) &&
        (props.scale[0] === 0 || props.scale[1] === 0 || props.scale[2] === 0))
    )
      return null;

    const { position, scale, rotation, children, ...rest } = props;
    return (
      <a.group position={position} scale={scale} rotation={rotation} {...rest}>
        {children}
      </a.group>
    );
  }
);

const MeshGeomLabel = React.memo(
  (props: { node: Pick<CompositeMeshNodeVM, "geom" | "size"> }) => {
    const { mode } = useTheme().palette;

    if (props.node.geom.kind === "null" || !props.node.geom.label) {
      return null;
    }

    const { location, text, scale, color } = props.node.geom.label;
    const { offsetH, offsetV, offsetD, transform } = location;

    const { position, orientation } = calculatePositionAndOrientation(
      props.node.size,
      location,
      offsetH,
      offsetV,
      offsetD
    );

    if (location.orientation === "vertical") {
      orientation.set(0, 0, -Math.PI / 2);
    }

    return transform === false ? (
      <Html
        position={position}
        distanceFactor={10 * (scale || 1)}
        style={{ width: 300 }}
      >
        {text}
      </Html>
    ) : (
      <Text
        position={position}
        rotation={orientation}
        anchorX={location.alignHorizontally}
        anchorY={
          location.alignVertically === "center"
            ? "middle"
            : location.alignVertically
        }
        outlineColor={mode === "dark" ? "#000" : "#fff"}
        outlineWidth={0.01}
        outlineOpacity={0.6}
        fontSize={scale || 0.5}
        color={color || (mode === "dark" ? "#ccc" : "#333")}
      >
        {text}
      </Text>
    );
  }
);

const MeshGeomImage = React.memo(
  (props: {
    size: CompositeMeshNodeVM["size"];
    instanceId: string;
    image: MeshGeomBase["images"][number];
  }) => {
    const { location, src, size, entity, caption } = props.image;
    const { offsetH, offsetV } = location;

    const { position, orientation } = calculatePositionAndOrientation(
      props.size,
      location,
      offsetH,
      offsetV
    );

    return (
      <CacheImage
        position={position.add(props.image.offset || new THREE.Vector3())}
        rotation={orientation}
        scale={size || calcBoundedSize(props.size, props.image.location.side)}
        url={src}
        instanceId={props.instanceId}
        entity={entity}
        caption={caption}
        interactive={props.image.interactive}
      />
    );
  }
);

const ProtectedEnclosure = React.memo(
  (props: { node: CompositeMeshNodeInfo["node"]; height: number }) => {
    const isProtected = true;
    const visible = isProtected;

    const heightScaleFactor = props.height || 32;
    const spring = useSpring({
      scale: visible ? [1.0, 1.0, heightScaleFactor] : [0, 0, 0],
      position: [0, 0, heightScaleFactor / 2],
    });

    const materialRef = React.useRef<any>();
    const speed = 7;
    const opacity = 0.4;

    const interactivityResult = useInteractivity({
      entity: props.node.geom.entity,
    });

    useFrame(({ clock }) => {
      if (materialRef.current && isProtected) {
        materialRef.current.opacity =
          opacity + 0.075 * Math.sin(clock.getElapsedTime() * speed);
      }
    });

    return (
      <>
        <AnimatedGroup {...(spring as any)}>
          {isProtected && (
            <AnjRoundedBox radius={0.1} {...(interactivityResult as any)}>
              <meshStandardMaterial
                ref={materialRef}
                roughness={0}
                color={"#5cf"}
                transparent
                opacity={opacity}
                side={THREE.FrontSide}
              />
            </AnjRoundedBox>
          )}
        </AnimatedGroup>
      </>
    );
  }
);

type MeshGeomBoxProps = {
  isSelected?: boolean;
  isHovered?: boolean;
  node: CompositeMeshNodeInfo["node"];
} & Object3DProps;

const AnimatedStandardMeshMaterial = a.meshStandardMaterial as any;

const MeshGeomBox = React.forwardRef((props: MeshGeomBoxProps, ref) => {
  const { node, isSelected, isHovered, ...interactivityOptions } = props;

  const { style, color, opacity, emissive, emissiveIntensity, roughness } =
    node.geom as MeshGeomBase;

  const sizeSpring = useSpring({
    scale: node.size.toArray(),
  });

  const materialSpring = useMeshSelectableMaterialSpring({
    isSelected,
    isHovered,
    color,
    emissive,
    emissiveIntensity,
  });

  return (
    <AnimatedGroup {...(sizeSpring as any)}>
      {style?.kind === "client" ? (
        <group position={[0, 0, 0.25]}>
          <MacbookModel
            entity={node.geom.entity}
            render={() =>
              node.geom.htmlComponents ? (
                <NodeHtmlComponents
                  isSelected={false}
                  isHovered={false}
                  nodeSize={node.size}
                  htmlComponents={node.geom.htmlComponents}
                  innerHtmlOnly
                />
              ) : null
            }
          />
        </group>
      ) : style?.kind !== "no fill" ? (
        <AnjRoundedBox
          receiveShadow
          castShadow
          {...(interactivityOptions as any)}
          ref={ref as GroupProps["ref"]}
        >
          <AnimatedStandardMeshMaterial
            roughness={roughness !== undefined ? roughness : 0.7}
            {...materialSpring}
            opacity={opacity}
            transparent={opacity !== undefined && opacity < 1}
          />
        </AnjRoundedBox>
      ) : null}
      {style?.kind === "silhouette" ? (
        <group
          scale={[
            1 + 0.1 / props.node.size.x,
            1 + 0.1 / props.node.size.y,
            0.25,
          ]}
          onPointerMove={(e) => {
            e.stopPropagation();
          }}
        >
          <AnjRoundedBox>
            <meshBasicMaterial color={style.color} />
          </AnjRoundedBox>
        </group>
      ) : null}
      {node.geom.protected && (
        <ProtectedEnclosure node={node} height={node.geom.protected} />
      )}
    </AnimatedGroup>
  );
});

const MeshGeomCylinder = React.memo(
  (props: { node: CompositeMeshNodeInfo["node"]; geom: MeshCylinderGeom }) => {
    const { geom } = props;

    const { mode } = useTheme().palette;

    const radius = geom.radius || 1;
    const count = geom.count || 1;
    const height = geom.height || 1;
    const segments = geom.segments || 32;
    const decorated = geom.decorated;

    return (
      <>
        {new Array(count).fill(undefined).map((c, i) => (
          <group position={new THREE.Vector3(0, 0, height * i)}>
            {segments === 4 ? (
              <group scale={[radius * 2, radius * 2, height / 1.5]}>
                <AnjRoundedBox
                // args={[radius * 2, radius * 2, height / 1.5]}
                // castShadow
                // receiveShadow
                >
                  <meshStandardMaterial
                    roughness={0}
                    color={geom.color || "#257"}
                  />
                </AnjRoundedBox>
              </group>
            ) : (
              <Cylinder
                args={[radius, radius, height / 1.5]}
                rotation={new THREE.Euler(Math.PI / 2, Math.PI / segments, 0)}
                castShadow
                receiveShadow
              >
                <meshStandardMaterial
                  roughness={0}
                  color={geom.color || "#257"}
                  flatShading
                />
              </Cylinder>
            )}
            {decorated ? (
              <>
                <Box args={[radius / 1.5, radius * 2 + 0.1, 0.05]}>
                  <meshStandardMaterial roughness={0} color={"#58f"} />
                </Box>
                <Box
                  position={new THREE.Vector3(radius / 2, 0, 0)}
                  args={[0.1, radius * 2 + 0.1, 0.05]}
                >
                  <meshStandardMaterial roughness={0} color={"#58f"} />
                </Box>
              </>
            ) : null}
          </group>
        ))}
        {/* <Text
          position={[2, 0, height * (count - 0.5) + 0.02]}
          scale={[0.4, 0.4, 0.4]}
        >
          {props.node.id}
        </Text> */}
        <Text
          scale={geom.textScale || 1}
          position={[0, 0, height * (count - 0.5) + 0.02]}
          color={mode === "dark" ? "#ccc" : "#333"}
        >
          {geom.text}
        </Text>
      </>
    );
  }
);

const MeshGeomShape = React.forwardRef((props: MeshGeomBoxProps, ref) => {
  const { node } = props;

  const { geom } = node;

  switch (geom.kind) {
    case "null":
      return null;
    case "substrate":
    case "box":
      if (geom.hidden) return null;
      return <MeshGeomBox {...props} ref={ref} />;
    case "cylinder": {
      if (geom.hidden) return null;
      return <MeshGeomCylinder {...props} geom={geom} />;
    }
    default:
      return null;
  }
});

const CompositeMeshNodeInner = React.memo(
  (props: {
    instanceId: string;
    node: CompositeMeshNodeInfo["node"];
    parentCollapsed?: boolean;
  }) => {
    const { instanceId, node, parentCollapsed } = props;

    const [visible, setVisible] = React.useState(0);

    React.useEffect(() => {
      const visibility = parentCollapsed || node.status === "collapsed" ? 0 : 1;

      setVisible(visibility);
    }, [node.id, node.status, parentCollapsed]);

    // Want a smaller expansion amount for larger objects.
    const expandFactor: number = 1; // - 0.2 / Math.max(...node.size.toArray(), 1);

    const { entity } = node.geom;
    const {
      spring: selectedSpring,
      ref,
      isSelected,
      isHovered,
      onPointerDown,
      onPointerEnter,
      onPointerLeave,
      InfoButton,
    } = useInteractivity({ entity, scale: visible, expandFactor });

    const position = new THREE.Vector3()
      .add(node.size.clone().multiplyScalar(0.5))
      .sub(
        visible ? new THREE.Vector3() : expandDirectionBasedOnAlignment(node)
      );

    const viewSpring = useSpring({
      position: position.toArray(),
      // delay: node.geom.animDelayFactor,
      // delay:
      //   (node.geom.animDelayFactor || 1) *
      //   (visible
      //     ? node.depth * ANIMATION_DELAY_MS
      //     : (node.deepestDescendantDepth - node.depth) *
      //       REVERSE_ANIMATION_DELAY_MS),
    });

    const label = <MeshGeomLabel node={node} />;
    const images = node.geom.images.map((image) => (
      <MeshGeomImage
        key={image.src}
        image={image}
        size={node.size}
        instanceId={props.instanceId}
      />
    ));

    const htmlComponents = node.geom.htmlComponents && (
      <NodeHtmlComponents
        isSelected={isSelected}
        isHovered={isHovered}
        nodeSize={node.size}
        htmlComponents={node.geom.htmlComponents}
        entity={node.geom.entity}
      />
    );

    const onPointerMove = React.useCallback((e: ThreeEvent<PointerEvent>) => {
      e.stopPropagation();
    }, []);

    const interactivityProps =
      node.geom.pointerEvents === "none"
        ? {}
        : {
            onPointerDown,
            onPointerOver: onPointerEnter,
            onPointerLeave,
            onPointerMove,
          };

    const shape = (
      <MeshGeomShape
        node={node}
        isSelected={isSelected}
        isHovered={isHovered}
        {...interactivityProps}
        ref={ref}
      />
    );

    const positionSpring = useSpring({
      position: node.pos.toArray(),
    });

    const modelHasInfoButton = node.geom.style?.kind === "client";
    const imageHasInfoButton = node.geom.images.some((image) => !!image.entity);
    const infoButton =
      imageHasInfoButton || modelHasInfoButton || !entity?.infoButton ? null : (
        <AnimatedHtml
          position={node.size
            .clone()
            .multiply(new THREE.Vector3(-0.5, -0.5, 0))}
          zIndexRange={[10, 20]}
          style={{ transform: "translate(-50%, -50%)" }}
        >
          <InfoButton />
        </AnimatedHtml>
      );

    return (
      <AnimatedGroup {...(positionSpring as any)}>
        <AnimatedGroup {...(viewSpring as any)} {...(selectedSpring as any)}>
          {shape}
          {label}
          {images}
          {htmlComponents}
          {infoButton}
        </AnimatedGroup>
        {node.children.map((cur) => {
          return (
            <CompositeMeshNode
              key={`${instanceId}-${cur}`}
              instanceId={instanceId}
              id={cur}
              parentCollapsed={parentCollapsed || node.status === "collapsed"}
            />
          );
        })}
      </AnimatedGroup>
    );
  }
);

const CompositeMeshNode = React.memo((props: Props) => {
  const { node } = useMeshStore(props.instanceId)(
    (state) => state.nodeInfo(props.id),
    (va, vb) => {
      const { deepestDescendantDepth: dA, ...restA } = va.node;
      const { deepestDescendantDepth: dB, ...restB } = vb.node;

      return _.isEqual(restA, restB);
    }
  );

  return (
    <CompositeMeshNodeInner
      instanceId={props.instanceId}
      node={node}
      parentCollapsed={props.parentCollapsed}
    />
  );
});

const Handle = React.memo(
  (props: {
    node: ConnectionNode;
    position: THREE.Vector3;
    visible: boolean;
    color?: string;
  }) => {
    const { position, color } = props;

    const spring = useSpring({
      position: position.toArray(),
      scale: props.visible ? [1, 1, 1] : [0, 0, 0],
      // delay: props.visible
      //   ? node.depth * ANIMATION_DELAY_MS
      //   : (node.deepestDescendantDepth - node.depth) *
      //     REVERSE_ANIMATION_DELAY_MS,
    });

    return (
      <a.mesh {...(spring as any)}>
        <sphereGeometry args={[0.15, 10, 10]} />
        <meshStandardMaterial color={color || "#0ff"} />
      </a.mesh>
    );
  }
);

const Connection = React.memo((connection: ConnectionInfo) => {
  logAnyPropChanges("Connection", connection);

  const {
    visible: visibleIn,
    toVisible: toVisibleIn,
    node,
    absolutePos,
    toNode,
    toAbsolutePos,
    toControlDirection,
    kind,
    color,
  } = connection;

  const [visible, setVisible] = React.useState(false);
  React.useEffect(() => {
    setVisible(visibleIn);
  }, [visibleIn]);

  const [toVisible, setToVisible] = React.useState(false);
  React.useEffect(() => {
    setToVisible(toVisibleIn);
  }, [toVisibleIn]);

  const targetVector = mapSideToVector3(
    toControlDirection,
    new THREE.Vector3(1, 1, 1)
  )
    .clone()
    .normalize()
    .negate();
  const quaternion = new THREE.Quaternion();
  quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), targetVector);

  const arrowLength = 0.5;

  const end =
    kind === "connection"
      ? toAbsolutePos
      : toAbsolutePos.clone().sub(targetVector.clone().multiplyScalar(0.1));

  const center = absolutePos.clone().add(toAbsolutePos).multiplyScalar(0.5);

  const show = visible && toVisible;

  const plusSpring = useSpring({
    position: center.toArray(),
    scale: show ? 0.7 : 0,
    rotation: [Math.PI / 2, 0, 0] as THREE.Vector3Tuple,
    // delay: show ? node.depth * ANIMATION_DELAY_MS : 0,
  });

  const lineSpring = useSpring({
    scale: show ? 1 : 0,
    config: { duration: show ? undefined : 0 },
  });

  return (
    <>
      {kind === "connection" ? (
        <Handle
          node={node}
          position={absolutePos}
          visible={visible}
          color={color}
        />
      ) : null}
      {kind === "connection" || kind === "arrow" ? (
        <group position={absolutePos.toArray()}>
          <AnimatedGroup {...(lineSpring as any)} /*scale={show ? 1 : 0}*/>
            <group position={absolutePos.clone().negate().toArray()}>
              <CompositeConnection3d connection={connection} />
              <Cone
                args={[0.25, arrowLength, 32]} // radius, height, radialSegments
                quaternion={quaternion}
                position={end}
              >
                <meshStandardMaterial color={color || "#0ff"} />
              </Cone>
            </group>
          </AnimatedGroup>
        </group>
      ) : null}
      {kind === "plus" ? (
        <AnimatedGroup {...plusSpring}>
          <group scale={new THREE.Vector3(0.25, 0.8, 0.25)}>
            <AnjRoundedBox>
              <meshStandardMaterial color="#0ff" />
            </AnjRoundedBox>
          </group>
          <group scale={new THREE.Vector3(0.8, 0.25, 0.25)}>
            <AnjRoundedBox>
              <meshStandardMaterial color="#0ff" />
            </AnjRoundedBox>
          </group>
        </AnimatedGroup>
      ) : null}
      {/* {kind === "arrow" ? (
        <AnimatedGroup {...(arrowSpring as any)}>
          <Cone
            args={[0.25, arrowLength, 32]} // radius, height, radialSegments
            quaternion={quaternion}
            position={end}
          >
            <meshStandardMaterial color={color || "#0ff"} />
          </Cone>
        </AnimatedGroup>
      ) : null} */}
      {kind === "connection" ? (
        <Handle
          node={toNode}
          position={toAbsolutePos}
          visible={toVisible}
          color={color}
        />
      ) : null}
    </>
  );
});

export const CompositeMeshConnections = React.memo(
  ({ instanceId }: { instanceId: string }) => {
    logAnyPropChanges("CompositeMeshConnections", { instanceId });

    const connections = useMeshStore(instanceId)((state) => state.connections);

    const connectionElems = connections.map((connection) => {
      // const key = `${connection.kind}-${connection.node.id}-${connection.toNode.id}-${connection.absolutePos.x}-${connection.absolutePos.y}-${connection.absolutePos.z}-${connection.toAbsolutePos.x}-${connection.toAbsolutePos.y}-${connection.toAbsolutePos.z}`;
      const key = `${connection.kind}-${connection.node.id}-${connection.toNode.id}`;
      return <Connection key={key} {...connection} />;
    });

    return <>{connectionElems}</>;
  }
);

export const CompositeMesh3d = React.memo((props: Props) => {
  logAnyPropChanges("CompositeMesh3d", props);

  const nodeInfo = useMeshStore(props.instanceId)((state) =>
    state.nodeInfo(props.id)
  );

  const centeredPosition = nodeInfo
    ? expandDirectionBasedOnAlignment(nodeInfo.node)
    : new THREE.Vector3();

  const position = centeredPosition;

  const meshSpring = useSpring({
    position: position.toArray(),
    scale: props.scale || 1,
  });

  return nodeInfo ? (
    <AnimatedGroup {...(meshSpring as any)}>
      <CompositeMeshNode {...props} />
    </AnimatedGroup>
  ) : null;
});
