import React from "react";

import { AccountMenu } from "./AccountMenu";
import { AccountIcon } from "./AccountIcon";

export const AccountButton = () => (
  <AccountMenu>
    <AccountIcon />
  </AccountMenu>
);
