/**
 * @param {string} name
 * @returns query string parameter's value or empty string if it doesn't exist.
 */
export const getUrlParameter = (name) => {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const getPath = () => {
  return window.location.pathname;
};

export const isDownloadsRoute = () =>
  !window.location.search.includes("x-amzn-marketplace-token");
