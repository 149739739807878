import { API } from "aws-amplify";
import { QuickstartDefinition } from "../pages/ResourceCenter/POC/poc-types";
import { QuickstartOptions } from "../pages/ResourceCenter/POC/useQuickstartStore";

type Template = QuickstartDefinition;

export const templatesApiEndpoint = () => {
  const { hostname } = window.location;
  const isTestEnv =
    hostname.startsWith("test") ||
    hostname.startsWith("localhost") ||
    hostname.startsWith("192");

  const endpoint = isTestEnv
    ? "https://2xq104ynz2.execute-api.us-east-1.amazonaws.com/test"
    : "https://api.downloads.anjuna.io/templates";

  return endpoint;
};

export const getTemplateAPI = async (token: string) => {
  const apiName = "apitemplates";
  const path = "/api/v1/templates";

  const myInit = {
    headers: {},
    queryStringParameters: {
      token,
    },
  };

  try {
    const { template, userEdited } = await API.get(apiName, path, myInit);

    return {
      type: "success",
      template,
      userEdited,
    };
  } catch (error) {
    console.error("GET templates API call failed", JSON.stringify(error));

    return {
      type: "error",
      message: `Fetching templates list failed: ${JSON.stringify(error)}`,
    };
  }
};

export const putTemplateAPI = async (
  token: string,
  definitions: QuickstartDefinition[] | undefined
): Promise<
  | { type: "success"; definitions: Template[]; userEdited: boolean }
  | { type: "error"; message: string }
> => {
  const apiName = "apitemplates";
  const path = "/api/v1/templates";

  const myInit = {
    headers: {},
    body: {
      template: definitions ? { definitions } : undefined,
    },
    queryStringParameters: {
      token,
    },
  };

  console.log(
    "Putting quickstart definitions: " +
      JSON.stringify(definitions as any, undefined, 2)
  );

  try {
    const result = await API.put(apiName, path, myInit);

    return {
      type: "success",
      definitions: result.definitions,
      userEdited: true,
    };
  } catch (error) {
    console.error(
      "PUT template content API call failed",
      JSON.stringify(error)
    );

    return {
      type: "error",
      message: `Putting template content list failed: ${JSON.stringify(error)}`,
    };
  }
};

export const getTemplateOptionsAPI = async (
  token: string
): Promise<
  | { type: "success"; options: QuickstartOptions }
  | { type: "error"; message: string }
> => {
  const apiName = "apitemplates";
  const path = "/api/v1/templates/active";

  const myInit = {
    headers: {},
    queryStringParameters: {
      token,
    },
  };

  try {
    const templateOptions = await API.get(apiName, path, myInit);

    return {
      type: "success",
      options: templateOptions,
    };
  } catch (error) {
    console.error(
      "GET template options API call failed",
      JSON.stringify(error)
    );

    return {
      type: "error",
      message: `Fetching template options list failed: ${JSON.stringify(
        error
      )}`,
    };
  }
};

export const downloadTemplateBundleAPI = async (
  token: string
): Promise<
  | { type: "success"; response: { location: string } }
  | { type: "error"; message: string }
> => {
  try {
    const response = await fetch(
      `${templatesApiEndpoint()}/v1/templates/active/quickstart.tar.gz?token=` +
        token,
      { method: "GET" }
    );

    const url = URL.createObjectURL(await response.blob());

    return {
      type: "success",
      response: {
        location: url,
      },
    };
  } catch (error) {
    console.error("GET template bundle API call failed", JSON.stringify(error));

    return {
      type: "error",
      message: `Fetching template bundle list failed: ${JSON.stringify(error)}`,
    };
  }
};

export const putTemplateOptionsAPI = async (
  token: string,
  templateOptions: QuickstartOptions
): Promise<
  | { type: "success"; options: QuickstartOptions }
  | { type: "error"; message: string }
> => {
  const apiName = "apitemplates";
  const path = "/api/v1/templates/active";

  const myInit = {
    headers: {},
    body: {
      templateOptions,
    },
    queryStringParameters: {
      token,
    },
  };

  try {
    const result = await API.put(apiName, path, myInit);

    return {
      type: "success",
      options: result,
    };
  } catch (error) {
    console.error(
      "PUT template options API call failed",
      JSON.stringify(error)
    );

    return {
      type: "error",
      message: `Putting template options list failed: ${JSON.stringify(error)}`,
    };
  }
};
