import React from "react";
import Editor from "@monaco-editor/react";
import { FONT_SIZE } from "../../../useThemeStore";

type Props = {
  name: string;
  mode: string;
  code: string;
  readOnly?: boolean;
  userEdited?: boolean;
  minLines?: number;
  onChange?: (value: string, event?: any) => void;
  onBlur?: (value: string, event?: any) => void;
  validate?: (value: string) => Promise<string | void>;
  discardUserEdits?: () => void;
};

export const CodeEditor = React.memo((props: Props) => {
  const [code, setCode] = React.useState(props.code);

  React.useEffect(() => {
    setTimeout(() => {
      setCode(props.code);
    }, 100);
  }, [props.code]);

  return (
    <Editor
      options={{
        readOnly: props.readOnly,
        tabSize: 2,
        fontSize: FONT_SIZE + 1,
        automaticLayout: true,
        minimap: {
          enabled: false,
        },
      }}
      theme="vs-dark"
      defaultLanguage="bash"
      language="shell"
      value={code}
      height={"100%"}
    />
  );
});
