// Mirrors quickstart-util.js but exports in ES6 format
// Remember to keep in sync with quickstart-util.js
//

const INSTANCE_TYPES = `m5.xlarge	4	16.00
m5.2xlarge	8	32.00
m5.4xlarge	16	64.00
m5.8xlarge	32	128.00
m5.12xlarge	48	192.00
m5.16xlarge	64	256.00
m5.24xlarge	96	384.00
m5a.xlarge	4	16.00
m5a.2xlarge	8	32.00
m5a.4xlarge	16	64.00
m5a.8xlarge	32	128.00
m5a.12xlarge	48	192.00
m5a.16xlarge	64	256.00
m5a.24xlarge	96	384.00
m5ad.xlarge	4	16.00
m5ad.2xlarge	8	32.00
m5ad.4xlarge	16	64.00
m5ad.8xlarge	32	128.00
m5ad.12xlarge	48	192.00
m5ad.16xlarge	64	256.00
m5ad.24xlarge	96	384.00
m5d.xlarge	4	16.00
m5d.2xlarge	8	32.00
m5d.4xlarge	16	64.00
m5d.8xlarge	32	128.00
m5d.12xlarge	48	192.00
m5d.16xlarge	64	256.00
m5d.24xlarge	96	384.00`;

export const instanceTypes = () => {
  const rows = INSTANCE_TYPES.split("\n");

  const data = rows.map((row) => {
    const [id, vcpus, memory] = row.split("\t");
    return { id, vcpus: Number(vcpus), memory: Number(memory) };
  });

  return data;
};

export const allocatorResourcesForInstanceType = (instanceType: string) => {
  const instance =
    instanceTypes().find((cur) => cur.id === instanceType) ||
    instanceTypes()[0];

  return {
    "total.cpu count": instance.vcpus - 2,
    "total.memory": (instance.memory * 1024) / 2,
  };
};
