import React from "react";
import { Tooltip, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Typography from "@mui/material/Typography";
import DoneIcon from "@mui/icons-material/Done";

const useStyles = makeStyles<Theme>((theme) => ({
  code: {
    background: theme.palette.background.paper,
  },
}));

export const CodeBox = (props: {
  id: string;
  code: string;
  style?: React.CSSProperties;
  "data-testid"?: string;
}) => {
  const { id, code, style } = props;

  const classes = useStyles();

  const codeElementId = `pre-${id}`;

  const [copied, setCopied] = React.useState(false);

  const handleCopyClick = React.useCallback(() => {
    const element = document.getElementById(codeElementId);

    element && navigator.clipboard.writeText(element.innerText);

    window.setTimeout(() => {
      setCopied(true);

      window.setTimeout(() => {
        setCopied(false);
      }, 5000);
    }, 50);
  }, [codeElementId]);

  return (
    <code
      className={classes.code}
      data-testid={props["data-testid"]}
      style={{
        display: "flex",
        flexDirection: "row",
        boxShadow: "inset 0 0 1.75px #e1e1e1",
        padding: 8,
        width: "100%",
        marginTop: 12,
        position: "relative",
        borderRadius: 4,
        border: `1px solid transparent`,
        transition: "all 0.5s",
        ...style,
      }}
    >
      <Tooltip
        arrow
        title={
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {copied ? (
                <>
                  <DoneIcon style={{ marginRight: 8, fontSize: 20 }} />
                  <Typography
                    color="white"
                    style={{
                      textTransform: "uppercase",
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    Copied
                  </Typography>
                </>
              ) : (
                <>
                  <ContentCopyIcon style={{ marginRight: 8, fontSize: 20 }} />
                  <Typography
                    color="white"
                    style={{
                      textTransform: "uppercase",
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    Click to copy
                  </Typography>
                </>
              )}
            </div>
          </>
        }
      >
        <pre
          tabIndex={0}
          onClick={handleCopyClick}
          style={{
            fontFamily: "monospace",
            lineHeight: 1.5,
            margin: 0,
            overflowX: "auto",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          id={codeElementId}
        >
          {code}
        </pre>
      </Tooltip>
    </code>
  );
};
