import { createTheme } from "@mui/material/styles";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export const FONT_SIZE = 14;

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#f4f4f4",
      paper: "#ffffff",
    },
    primary: {
      main: "#00994F",
      dark: "#006E3C",
      light: "#00C65E",
      contrastText: "#000000",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: "#ffffff",
          fontWeight: 700,
        },
        root: {},
      },
    },
  },
  typography: {
    fontSize: FONT_SIZE,
    fontFamily: [
      "Satoshi",
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
    ].join(","),
    body1: {
      fontFamily: "Inter Regular",
      color: "#4A4A4A",
    },
    body2: {
      fontFamily: "Inter Regular",
      color: "#4A4A4A",
    },
    h1: {
      fontFamily: "Satoshi Variable",
      fontWeight: 700,
      fontSize: 34,
      color: "#000000",
      margin: `16px 0`,
    },
    h2: {
      fontFamily: "Satoshi Variable",
      fontWeight: 700,
      fontSize: 28,
      color: "black",
      margin: `8px 0`,
    },
    h3: {
      fontFamily: "Satoshi Variable",
      fontWeight: 700,
      fontSize: 22,
    },
    h4: {
      fontFamily: "Satoshi Variable",
      fontWeight: 700,
      fontSize: 18,
    },
    overline: {
      fontFamily: "Inter Regular",
      fontSize: 10,
    },
    subtitle1: {
      fontFamily: "Inter Regular",
      fontSize: 16,
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "rgb(12, 24, 40)",
      paper: "rgb(20, 34, 50)",
    },
    primary: {
      main: "#00994F",
      contrastText: "white",
    },
    secondary: {
      main: "rgb(15, 66, 65)",
    },
  },
  typography: {
    fontSize: FONT_SIZE,
    fontFamily: [
      "Satoshi Variable",
      "Inter Regular",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
    ].join(","),
    button: {
      fontFamily: "Satoshi Variable",
      fontWeight: 700,
    },
    body1: {
      fontFamily: "Inter Regular",
      // fontSize: 17,
      color: "#cbd5e1",
    },
    body2: {
      fontFamily: "Inter Regular",
      // fontSize: 17,
      color: "#cbd5e1",
    },
    h1: {
      fontFamily: "Satoshi Variable",
      fontWeight: 700,
      fontSize: FONT_SIZE * 2,
      color: "white",
      margin: `16px 0`,
    },
    h2: {
      fontFamily: "Satoshi Variable",
      fontWeight: 700,
      fontSize: FONT_SIZE * 2 - 4,
      color: "white",
      margin: `8px 0`,
    },
    h3: {
      fontFamily: "Satoshi Variable",
      color: "white",
      fontWeight: 700,
      fontSize: FONT_SIZE * 1.5,
    },
    h4: {
      fontFamily: "Satoshi Variable",
      fontWeight: 700,
      fontSize: 20,
      color: "white",
    },
    overline: {
      fontFamily: "Inter Regular",
      fontSize: 12,
    },
    subtitle1: {
      fontFamily: "Inter Regular",
      fontSize: 18,
    },
  },
});

export type UserThemePreferenceStore = {
  userPreference?: "dark" | "light";
  toggleTheme: (systemPreference: "dark" | "light" | undefined) => void;
};

export const useUserThemePreferenceStore = create(
  persist<UserThemePreferenceStore>(
    (set, get) => ({
      userPreference: undefined,
      toggleTheme: (systemPreference: "dark" | "light" | undefined) => {
        set({
          userPreference:
            get().userPreference === "dark" ||
            get().userPreference === undefined
              ? "light"
              : get().userPreference === "light"
              ? "dark"
              : systemPreference === "dark"
              ? "light"
              : "dark",
        });
      },
    }),
    {
      name: "theme-preference",
    }
  )
);

export type SystemThemePreferenceStore = {
  systemPreference?: "dark" | "light";
  // Comes from the system preferences.
  setDarkModeSystemPreference: (darkModePreference: boolean) => void;
};

export const useSystemThemePreferenceStore = create<SystemThemePreferenceStore>(
  (set, get) => ({
    systemPreference: undefined,
    setDarkModeSystemPreference: (darkModePreference: boolean) => {
      set({
        systemPreference: darkModePreference ? "dark" : "light",
      });
    },
  })
);
// export type ThemeStore = {
//   // Comes from the system preferences.
//   setDarkModeSystemPreference: (darkModePreference: boolean) => void;

//   theme: () => Theme;
//   setTheme: (mode: "light" | "dark") => void;
// };

// export const useThemeStore = create<ThemeStore>((set, get) => ({
//   theme: () => lightTheme,
//   // setDarkModeSystemPreference: (darkModePreference: boolean) => {
//   //   set({
//   //     // theme: darkModePreference ? darkTheme : lightTheme,
//   //     theme: lightTheme,
//   //   });
//   // },
//   // toggleTheme: () => {
//   //   set({
//   //     theme: get().theme.palette.mode === "dark" ? lightTheme : darkTheme,
//   //   });
//   // },
// }));

export const useTheme = () => {
  const userPreference = useUserThemePreferenceStore(
    (state) => state.userPreference
  );
  const systemPreference = useSystemThemePreferenceStore(
    (state) => state.systemPreference
  );

  return userPreference
    ? userPreference === "dark"
      ? darkTheme
      : lightTheme
    : systemPreference === "dark"
    ? darkTheme
    : lightTheme;
};
