import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Button,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Typography,
  Popper,
  ClickAwayListener,
  Theme,
} from "@mui/material";
import { useAppStage } from "../../context/AppStageContext";
import { useAmplifyAuth } from "../../hooks/useAmplifyAuth";
import { ToggleWithLabel } from "../../pages/ResourceCenter/POC/Toggle";
import {
  useSystemThemePreferenceStore,
  useTheme,
  useUserThemePreferenceStore,
} from "../../useThemeStore";
import { User } from "../../context/AppStageContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    paper: {
      marginRight: theme.spacing(2),
    },
    menu: {},
    button: {
      marginRight: -theme.spacing(3),
    },
    popper: {
      // Bring on top of any slideout drawer currently open (e.g. enclave properties)
      zIndex: 10000,
    },
    accountDetails: {},
    userInfo: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: theme.spacing(2),
      paddingTop: 0,
      paddingBottom: 0,
      minWidth: 210,
      cursor: "default",
      "&:hover": {
        background: "none",
      },
    },
    userInfoIcon: {
      margin: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
    },
  })
);

export const AccountMenu = (props: React.PropsWithChildren<{}>) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement | null>(null);

  const { systemPreference } = useSystemThemePreferenceStore();
  const { toggleTheme } = useUserThemePreferenceStore();
  const theme = useTheme();

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (event: React.MouseEvent | TouchEvent | MouseEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as any)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 27) {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const [{ stage }, dispatch] = useAppStage();
  const { signout } = useAmplifyAuth();

  const { attributes } = (stage as { user: User }).user;

  const name = (attributes.name || "").split(" ");

  /**
   * @param {'api settings' | 'account details'} modalId
   */
  const openModal = React.useCallback(
    (modalId: "api settings" | "account details" | "licence via api") => {
      setOpen(false);

      dispatch({
        type: "open modal",
        modalId,
      });
    },
    [dispatch]
  );

  const openApiSettings = React.useCallback(
    () => openModal("api settings"),
    [openModal]
  );
  const openAccountDetails = React.useCallback(
    () => openModal("account details"),
    [openModal]
  );

  const onDarkModeChange = React.useCallback(() => {
    toggleTheme(systemPreference);

    // setTheme
  }, [systemPreference, toggleTheme]);

  return (
    <div className={classes.root} data-testid="account-menu">
      <div>
        <span style={{ position: "absolute", width: 35, height: 45 }}></span>

        <Button
          ref={anchorRef}
          title="Account"
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={classes.button}
        >
          {props.children}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          className={classes.popper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    className={classes.menu}
                  >
                    <MenuItem
                      className={classes.userInfo}
                      disableGutters={true}
                      data-testid="user-info-box"
                    >
                      <Typography>{name.join(" ")}</Typography>
                      <Typography>{attributes.email}</Typography>
                    </MenuItem>
                    <MenuItem divider disabled />
                    <MenuItem
                      onClick={openAccountDetails}
                      disabled={!stage.apiToken}
                      className={classes.accountDetails}
                    >
                      Account Details
                    </MenuItem>
                    <MenuItem
                      disabled={!stage.apiToken}
                      onClick={openApiSettings}
                    >
                      API Settings
                    </MenuItem>
                    <MenuItem divider disabled />
                    <MenuItem>
                      <ToggleWithLabel
                        title={"Dark mode"}
                        checked={theme.palette.mode === "dark"}
                        onChange={onDarkModeChange}
                      />
                    </MenuItem>
                    <MenuItem divider disabled />
                    <MenuItem
                      onClick={(e) => {
                        handleClose(e);
                        signout();
                      }}
                    >
                      Log Out
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};
