import React from "react";
import * as THREE from "three";
import { Canvas, useThree } from "@react-three/fiber";
import {
  Html,
  OrbitControls,
  RoundedBox,
  SoftShadows,
  Stats,
} from "@react-three/drei";
import { a } from "@react-spring/three";
import { useSpring } from "@react-spring/web";
import { OrbitControls as OrbitControlsImpl } from "three/examples/jsm/controls/OrbitControls";
import {
  CompositeMesh3d,
  CompositeMeshConnections,
} from "../composite-mesh/CompositeMesh3d";
import { useTheme } from "../../../useThemeStore";
import { useMeshStore } from "../composite-mesh/useCompositeMeshStore";
import { useQuickstart } from "./useQuickstartStore";
import { Box, Fade, Typography } from "@mui/material";
import { logAnyPropChanges } from "../../../core/logAnyPropsChanges";
import { AnjRoundedBoxGeometryCreator } from "../composite-mesh/AnjRoundedBox";

const FloorPlane = React.memo((props: { id: string }) => {
  const closeStepView = useQuickstart((state) => state.closeStepView);
  const setHoveredEntity = useMeshStore(props.id)(
    (state) => state.setHoveredEntity
  );

  const onPlanePointerEnter = React.useCallback(() => {
    document.body.style.cursor = "auto";
    setHoveredEntity(undefined);
  }, [setHoveredEntity]);

  const onPlanePointerDown = React.useCallback(
    (e: any) => {
      closeStepView();
    },
    [closeStepView]
  );

  const theme = useTheme();

  const selectedApp = useQuickstart((state) => state.selectedApp());
  const quickstartState = useQuickstart((state) => state.state);

  const FLOOR_W = 30;
  const FLOOR_H = 24;

  return (
    <>
      <group>
        <RoundedBox
          scale={[FLOOR_W, FLOOR_H, 15]}
          position={[0, 0, 7.5]}
          receiveShadow
          onPointerMove={onPlanePointerEnter}
          onPointerDown={onPlanePointerDown}
          name="background"
        >
          <meshStandardMaterial
            color={theme.palette.background.paper}
            emissive={theme.palette.background.paper}
            emissiveIntensity={0.5}
            side={THREE.BackSide}
          />
        </RoundedBox>
      </group>
      {quickstartState.kind === "creating instance" || true ? (
        <Html
          transform
          distanceFactor={25}
          rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          position={new THREE.Vector3(FLOOR_W / 2, FLOOR_H / 2 - 8, 4)}
          style={{
            opacity: 0.1,
          }}
        >
          <Fade in timeout={1000} key={selectedApp.id}>
            <Box className="flex-row-container flex-centered" component="div">
              <img
                src={selectedApp.imageUrl}
                alt={selectedApp.title}
                width={80}
              />
              <Typography style={{ maxWidth: 100, fontSize: "smaller" }}>
                {selectedApp.title}
              </Typography>
            </Box>
          </Fade>
        </Html>
      ) : null}
    </>
  );
});

const SceneContent = React.memo((props: { id: string }) => {
  const cameraPos = useQuickstart((state) => state.cameraPos);
  const scenePos = useQuickstart((state) => state.scenePos);
  const { camera } = useThree();
  const setCameraPos = useQuickstart((state) => state.setCameraPos);

  // Scene scaling interferes with the zooming in and out of the camera on views.
  // const nodeInfo = useMeshStore(props.id)((state) => state.nodeInfo("root"));
  // const maxExtent = Math.max(...nodeInfo.node.size.toArray());
  const scaleOutFac = 1; //Math.log(Math.log(maxExtent));

  const positionGroupRef = React.useRef<THREE.Group>(null);

  const selectedEntity = useMeshStore(props.id)(
    (state) => state.selectedEntity
  );

  const selectedObjectPosition = new THREE.Vector3();
  if (selectedEntity?.kind === "view") {
    selectedEntity.item.getWorldPosition(selectedObjectPosition);
    selectedObjectPosition.sub(
      selectedEntity.cameraLookAtOffset || new THREE.Vector3(0, 0, 0)
    );

    if (positionGroupRef.current) {
      const direction = new THREE.Vector3();
      selectedEntity.item.getWorldDirection(direction);

      const isVerticalDirection = Math.abs(direction.z) > 0.9;
      if (isVerticalDirection) {
        direction.set(0, -0.01, 1).normalize();
      }

      const SELECTED_OBJECT_CAMERA_POS = direction
        .multiplyScalar(selectedEntity.distance || 25)
        .multiplyScalar(1 / scaleOutFac);

      if (!cameraPos.equals(SELECTED_OBJECT_CAMERA_POS)) {
        const cam = camera.clone();
        cam.position.set(
          SELECTED_OBJECT_CAMERA_POS.x,
          SELECTED_OBJECT_CAMERA_POS.y,
          SELECTED_OBJECT_CAMERA_POS.z
        );
        cam.lookAt(new THREE.Vector3());
        if (!cam.position.equals(new THREE.Vector3()))
          setCameraPos(cam.position);
      }
    }
  }

  const scenePosition = scenePos;

  const positionSpring = useSpring({
    position: selectedObjectPosition.negate().add(scenePosition).toArray(),
    scale: 1 / scaleOutFac,
    onChange: () => {
      camera.position.lerp(cameraPos, 0.1);
      camera.lookAt(new THREE.Vector3());
    },
    onRest: () => {
      camera.position.set(cameraPos.x, cameraPos.y, cameraPos.z);
      camera.lookAt(new THREE.Vector3());
    },
  });

  return (
    <a.group {...(positionSpring as any)} ref={positionGroupRef}>
      <FloorPlane id={props.id} />
      <CompositeMesh3d id="root" instanceId={props.id} />
      <CompositeMeshConnections instanceId={props.id} />
    </a.group>
  );
});

const CanvasContent = React.memo((props: { id: string }) => {
  const { mode } = useTheme().palette;
  const { camera } = useThree();
  const setCameraRef = useQuickstart((state) => state.setCameraRef);
  setCameraRef(camera);

  logAnyPropChanges("CanvasContent", {
    ...props,
    mode,
  });

  const controlsRef = React.useRef<OrbitControlsImpl>(null);

  const limits = true;
  const controlLimits = limits
    ? {
        minAzimuthAngle: -Math.PI / 2.5,
        maxAzimuthAngle: Math.PI / 2.5,
      }
    : undefined;

  return (
    <>
      <ambientLight intensity={mode === "dark" ? 0.5 : 0.4} />
      <directionalLight
        castShadow
        position={[-1.5, -2, 5]}
        shadow-mapSize={[1024, 1024]}
        intensity={mode === "dark" ? 0.5 : 1}
      >
        <orthographicCamera
          attach="shadow-camera"
          args={[-15, 15, 5, -8, 1, 100]}
        />
      </directionalLight>
      <OrbitControls
        {...controlLimits}
        ref={controlsRef as any}
        enablePan={false}
        enableZoom={false}
        // enableRotate={false}
      />
      <SoftShadows />

      <SceneContent {...props} />
      {window.location.hostname === "localhost" ? <Stats /> : null}
    </>
  );
});

export const Visualisation = React.memo(
  (props: { id: string; cameraPos: THREE.Vector3 }) => {
    logAnyPropChanges("Visualisation", props);

    const containerRef = React.createRef<HTMLCanvasElement>();

    const updateViewport = useQuickstart((state) => state.updateViewport);

    const observer = React.useRef(
      new ResizeObserver((entries: ResizeObserverEntry[]) => {
        const { contentRect } = entries[0];
        updateViewport(contentRect);
      })
    );

    React.useEffect(() => {
      if (containerRef.current) {
        observer.current.observe(containerRef.current);
      }
    }, [containerRef]);

    return (
      <Canvas
        ref={containerRef}
        camera={{
          fov: 30,
          position: props.cameraPos,
          up: [0, 0, 1],
          zoom: 1,
          near: 2,
          far: 50,
        }}
        shadows
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <CanvasContent id={props.id} />
        <AnjRoundedBoxGeometryCreator />
      </Canvas>
    );
  }
);
