import React from "react";
import logo from "../assets/anjuna-seaglass-login.svg";
import { PageFooter } from "./PageFooter";
import graphic from "../assets/anjuna-logo-graphic.svg";
import { Fade, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

type Props = {
  children: any;
  application?: boolean;
  includeHero?: boolean;
  includeFooter?: boolean;
  includeDecoration?: boolean;
  noPad?: boolean;
  style?: React.CSSProperties;
};

const useStyles = makeStyles((theme: Theme) => ({
  heroImage: {
    [theme.breakpoints.down(1010)]: {
      display: "none",
    },
  },
}));

/**
 *
 * @param {{children:any,application?:boolean,includeHero?:boolean,includeFooter?:boolean,includeDecoration?:boolean,noPad?:boolean,style?:{*}}} param0
 * @returns
 */
export const PageContent = ({
  children,
  application,
  includeFooter,
  includeHero,
  includeDecoration,
  noPad,
  style,
}: Props) => {
  const classes = useStyles();
  return (
    <div
      className={`PageContent-container ${
        application ? "PageContent-application" : ""
      }`}
      style={{ ...style }}
    >
      {includeHero ? (
        <div
          className={clsx("PageContent-heroImage", classes.heroImage)}
          style={{
            backgroundImage: `url(${logo})`,
          }}
        />
      ) : null}
      {includeDecoration ? (
        <Fade in timeout={300}>
          <div
            className="Dashboard-decorationImage"
            style={{
              opacity: 0.5,
              backgroundImage: `url(${graphic})`,
            }}
          />
        </Fade>
      ) : null}
      <Fade in timeout={300}>
        <div
          className={`PageContent-content ${
            noPad ? "PageContent-content-noPad" : undefined
          }`}
        >
          <div className="PageContent-body">{children}</div>
          {includeFooter ? (
            <>
              <div className="flex-spacer" />
              <div className="PageContent-footer">
                <PageFooter />
              </div>
            </>
          ) : null}
        </div>
      </Fade>
    </div>
  );
};
