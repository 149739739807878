const IDEAL_VIEWPORT_HEIGHT = 800;
const IDEAL_VIEWPORT_WIDTH = 1050;

export const constrainToViewport = (
  viewport: null | { width: number; height: number },
  idealViewport: { width: number; height: number } = {
    width: IDEAL_VIEWPORT_WIDTH,
    height: IDEAL_VIEWPORT_HEIGHT,
  }
): number => {
  if (!viewport) return 1;

  const { width, height } = viewport;
  const { width: idealWidth, height: idealHeight } = idealViewport;
  const widthRatio = width / idealWidth;
  const heightRatio = height / idealHeight;
  return Math.min(widthRatio, heightRatio);
};
