import { Paper, TextField, Typography } from "@mui/material";
import React from "react";
import { postArcAIApi } from "../../../api/aiAPI";
import { useAppStageState } from "../../../context/AppStageContext";
import { Markdown } from "./Markdown";
import { ButtonWithProgress } from "../POC/ButtonWithProgress";

export const AskAI = React.memo(() => {
  const [question, setQuestion] = React.useState("");
  const [temperature, setTemperature] = React.useState("0.5");
  const [model, setModel] = React.useState("gpt-4");
  const [answer, setAnswer] = React.useState("");
  const [prompt, setPrompt] = React.useState(
    `Format all output using markdown, including the language used in any code blocks.`
  );

  const appStageState = useAppStageState();
  const token =
    (appStageState.stage.type === "dashboard" &&
      appStageState.stage.apiToken) ||
    "";

  const [busy, setBusy] = React.useState(false);

  const handleQuestionChange = React.useCallback((e: any) => {
    setQuestion(e.target.value);
  }, []);

  const handleTemperatureChange = React.useCallback((e: any) => {
    setTemperature(e.target.value);
  }, []);

  const handleModelChange = React.useCallback((e: any) => {
    setModel(e.target.value);
  }, []);

  const handlePromptChange = React.useCallback((e: any) => {
    setPrompt(e.target.value);
  }, []);

  const handleAsk = React.useCallback(async () => {
    setBusy(true);

    try {
      const { response } = await postArcAIApi(
        token,
        question,
        parseFloat(temperature) || 0,
        model,
        prompt
      );

      console.log("response", response);

      setAnswer(response);
    } finally {
      setBusy(false);
    }
  }, [model, prompt, question, temperature, token]);

  return (
    <div className="flex-col-container" style={{ width: "90vw" }}>
      <br />
      <div className="flex-row-container flex-spacer">
        <div className="flex-col-container flex-spacer">
          <Typography variant="h1">Need help?</Typography>
          <Typography style={{ marginBottom: 12 }}>
            Consult Anjuna's documentation by asking it a question.
          </Typography>
          <div className="flex-col-container">
            <div style={{ flex: 1 }}>
              <TextField
                multiline
                minRows={3}
                value={question}
                fullWidth
                onChange={handleQuestionChange}
                style={{ marginBottom: 16 }}
                placeholder="Your question..."
              />
            </div>
            <div
              style={{ flex: 0.2 }}
              className="flex-row-container flex-centered"
            >
              <ButtonWithProgress
                variant="contained"
                onClick={handleAsk}
                busy={busy}
                style={{ minWidth: 200 }}
              >
                Submit
              </ButtonWithProgress>
              {busy ? (
                <Typography style={{ marginLeft: 16 }}>
                  Please be patient, this may take a minute.
                </Typography>
              ) : null}
            </div>
          </div>
          <br />
          {answer && !busy ? (
            <>
              <Typography variant="h2">Answer</Typography>
              <div style={{ maxWidth: 1050 }}>
                <Markdown id="ask-response" markdown={answer} />
              </div>
            </>
          ) : null}
        </div>
        <div className="flex-col-container">
          <Paper
            elevation={0}
            sx={{ m: 4, p: 4 }}
            className="flex-col-container"
          >
            <Typography variant="h3" sx={{ mb: 1 }}>
              [INTERNAL] Algorithm Options
            </Typography>
            <Typography>Tunable options for the AI inference</Typography>
            <br />
            <TextField
              label="Model"
              value={model}
              helperText="Inference model"
              sx={{ mt: 0 }}
              onChange={handleModelChange}
            />
            <TextField
              label="Temperature"
              value={temperature}
              helperText="0 - 1 a measure of AI's creativity"
              sx={{ mt: 4 }}
              onChange={handleTemperatureChange}
            />
            <TextField
              label="Prompt"
              value={prompt}
              helperText="Final prompt to AI applied to content retrieved from the docs"
              multiline
              sx={{ mt: 4 }}
              onChange={handlePromptChange}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
});
