import React from "react";
import { ConnectionInfo } from "./composite-mesh-vm";
import { PulsingCubicBezier } from "./PulsingCubicBezier";

type Props = {
  connection: ConnectionInfo;
};

export const CompositeConnection3d = ({ connection }: Props) => {
  return <PulsingCubicBezier {...connection} />;
};
