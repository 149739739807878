import React from "react";
import { useLocation } from "react-router-dom";
import { EditControls } from "./EditControls";
import { useAppStageState } from "../../../context/AppStageContext";
import { QUICKSTART_URL } from "./poc-constants";

export const TemplatesHeader = React.memo(() => {
  const location = useLocation();

  const { stage } = useAppStageState();
  const canEditTemplates = stage.type === "dashboard" && stage.canApproveUsers;

  if (!location.pathname.includes(QUICKSTART_URL)) return null;

  const editControls = canEditTemplates ? (
    <>
      <div className="flex-spacer" />
      <div style={{ marginLeft: 40 }}>
        <EditControls />
      </div>
    </>
  ) : null;

  return editControls ? (
    <div
      className="flex-row-container flex-centered"
      style={{ position: "absolute", top: 14, right: 120 }}
    >
      {editControls}
    </div>
  ) : null;
});
