// @ts-check
import { useState, useEffect } from "react";
import { Auth, Hub } from "aws-amplify";
import { useAppStageDispatch } from "../context/AppStageContext";

/**
 * @typedef {{}} CognitoUser
 * @typedef {{ type: 'loading' } | { type: 'error' } | { type: 'user', user: CognitoUser | null }} AuthState
 */

/**
 * @returns {{signout: () => void}}
 */
export const useAmplifyAuth = () => {
  const appStageDispatch = useAppStageDispatch();

  const [triggerFetch, setTriggerFetch] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchUserData = async () => {
      try {
        if (isMounted) {
          const data = await Auth.currentAuthenticatedUser();
          if (data) {
            if (data.username) {
              appStageDispatch({ type: "user signed in", user: data });
              if (data.attributes)
                // @ts-ignore-next-line
                window.heap.identify(data.attributes.email);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    const onAuthEventListener = (data) => {
      const { payload } = data;
      onAuthEvent(payload);
    };

    const HubListener = () => {
      Hub.listen("auth", onAuthEventListener);
    };

    const onAuthEvent = (payload) => {
      switch (payload.event) {
        case "signUp_failure": {
          console.warn("sign up failure");
          break;
        }
        case "signUp": {
          break;
        }
        case "signIn":
          // @ts-ignore-next-line
          if (window.heap && window.heap.identify)
            // @ts-ignore-next-line
            window.heap.identify(payload.data.attributes.email);
          appStageDispatch({
            type: "user signed in",
            user: payload.data,
          });
          if (isMounted) {
            setTriggerFetch(true);
          }
          break;
        case "signOut":
          appStageDispatch({ type: "user signed out" });
          setTriggerFetch(false);
          break;
        default:
          return;
      }
    };

    HubListener();
    fetchUserData();

    return () => {
      Hub.remove("auth", onAuthEventListener);
      isMounted = false;
    };
  }, [triggerFetch, appStageDispatch]);

  const signout = async () => {
    try {
      console.log("signed out");
      await Auth.signOut();
      setTriggerFetch(false);
    } catch (error) {
      console.error("Error signing out user ", error);
    }
  };

  return { signout };
};
