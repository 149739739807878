import React from "react";
import * as THREE from "three";
import { QuickstartStepInfo, useQuickstart } from "../POC/useQuickstartStore";
import { useCombinedMeshStore } from "./useCompositeMeshStore";
import { MeshEntity } from "./composite-mesh-types";
import { SpringValue, config, useSpring } from "@react-spring/three";
import { ThreeEvent } from "@react-three/fiber";
import { IconButton } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import { useTheme } from "../../../useThemeStore";

type Options = {
  entity?: MeshEntity;
  scale?: number;
  expandFactor?: number;
  cameraDistanceWhenSelected?: number;
  cameraLookAtOffsetWhenSelected?: THREE.Vector3;
  onDeselect?: () => void;
};

export type InteractiveObjectProps = {
  ref: React.Ref<THREE.Group> | React.Ref<THREE.Mesh>;
  onPointerDown: (e: any) => void;
  onPointerEnter: (e: React.MouseEvent | ThreeEvent<PointerEvent>) => void;
  onPointerLeave: (e: React.MouseEvent | ThreeEvent<PointerEvent>) => void;
};

export type InteractivityResult = {
  spring: {
    scale: SpringValue<number>;
  };
  isHovered: boolean;
  isSelected: boolean | undefined;
  InfoButton: React.FC;
} & InteractiveObjectProps;

export const useInteractivity = ({
  entity,
  scale,
  expandFactor,
  cameraDistanceWhenSelected,
  cameraLookAtOffsetWhenSelected,
  onDeselect,
}: Options): InteractivityResult => {
  const quickstartState = useQuickstart(
    (state) => state.state
  ) as QuickstartStepInfo;
  const selectedEntityViewOrPopupWasShown = useQuickstart(
    (state) => state.selectedEntityViewOrPopupWasShown
  );

  const setEntityViewOrPopupWasShown = useQuickstart(
    (state) => state.setEntityViewOrPopupWasShown
  );

  const selectedEntity = useCombinedMeshStore((state) => state.selectedEntity);
  const selectedEntityId = selectedEntity?.id;
  const setSelectedEntity = useCombinedMeshStore(
    (state) => state.setSelectedEntity
  );

  const hoveredEntity = useCombinedMeshStore((state) => state.hoveredEntity);
  const setHoveredEntity = useCombinedMeshStore(
    (state) => state.setHoveredEntity
  );

  const entityId = entity?.id;
  const infoButton = entity?.infoButton;

  const visited = useCombinedMeshStore(
    (state) => entityId && state.visitedEntities.has(entityId)
  );

  const isHovered = !!entityId && hoveredEntity === entityId;
  const scaleToUse = scale !== undefined ? scale : 1;
  const isSelected = !!entityId && selectedEntityId === entityId;

  const HOVER_EXPAND_FACTOR = 1;
  const SELECTED_EXPAND_FACTOR = 1.1;

  const spring = useSpring({
    scale: isSelected
      ? (expandFactor || 1) * SELECTED_EXPAND_FACTOR * scaleToUse
      : isHovered
      ? (expandFactor || 1) * HOVER_EXPAND_FACTOR * scaleToUse
      : scaleToUse,
    config: {
      ...config.default,
    },
  });

  const ref = React.useRef<THREE.Group>(null);

  const onPointerDown = React.useCallback(
    (e: any) => {
      if (!entityId) return;

      if (selectedEntity?.id === entityId) {
        setSelectedEntity(undefined);
        onDeselect && onDeselect();
      } else if (ref.current) {
        setSelectedEntity(
          entity.display === "view"
            ? {
                id: entityId,
                kind: "view",
                item: ref.current,
                distance: cameraDistanceWhenSelected || 20,
                cameraLookAtOffset:
                  cameraLookAtOffsetWhenSelected || new THREE.Vector3(0, 0, 0),
              }
            : entity.display === "popup"
            ? {
                kind: "popup",
                id: entityId,
                item: ref.current,
              }
            : undefined
        );

        setEntityViewOrPopupWasShown(entityId);
      }

      e.stopPropagation();
    },
    [
      cameraDistanceWhenSelected,
      cameraLookAtOffsetWhenSelected,
      entity?.display,
      entityId,
      onDeselect,
      selectedEntity?.id,
      setEntityViewOrPopupWasShown,
      setSelectedEntity,
    ]
  );

  React.useEffect(() => {
    if (quickstartState.select && !selectedEntityViewOrPopupWasShown) {
      setTimeout(() => {
        if (
          quickstartState.select &&
          selectedEntityId !== quickstartState.select.id &&
          entityId === quickstartState.select.id
        ) {
          onPointerDown({ stopPropagation: () => {} });
        }
      }, quickstartState.select.delay);
    }
  }, [
    entityId,
    onPointerDown,
    quickstartState.select,
    selectedEntity,
    selectedEntityId,
    selectedEntityViewOrPopupWasShown,
    setSelectedEntity,
  ]);

  const onPointerEnter = React.useCallback(
    (e: React.MouseEvent | ThreeEvent<PointerEvent>) => {
      if (!entityId) return;
      setHoveredEntity(entityId);
      if ((e.target as any).style) (e.target as any).style.cursor = "pointer";
      else document.body.style.cursor = "pointer";
      e.stopPropagation();
    },
    [entityId, setHoveredEntity]
  );

  const onPointerLeave = React.useCallback(
    (e: React.MouseEvent | ThreeEvent<PointerEvent>) => {
      if (entityId === hoveredEntity) {
        e.stopPropagation();
      }
    },
    [entityId, hoveredEntity]
  );

  const theme = useTheme();
  const infoButtonStyles = {
    color: visited ? "#aaa" : "#dc0",
    transition: "all .3s",
    opacity: visited ? 0.5 : 1,
  };

  return {
    spring,
    isHovered,
    isSelected,
    ref,
    onPointerDown,
    onPointerEnter,
    onPointerLeave,
    InfoButton: () =>
      infoButton ? (
        <IconButton
          onPointerDown={onPointerDown}
          key={entityId}
          data-testid={`quickstart-info-button-${entityId}`}
        >
          <div
            style={{
              position: "absolute",
              width: 29,
              height: 29,
              borderRadius: "50%",
              background: theme.palette.background.paper,
              zIndex: -1,
              opacity: 0.5,
            }}
          />
          <InfoRounded style={infoButtonStyles} fontSize="large" />
        </IconButton>
      ) : null,
  };
};
