// @ts-check
import React from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, CircularProgress, Modal, Tooltip } from "@mui/material";
import { useAppStage } from "../../context/AppStageContext";
import { resetTokenApi } from "../../api/resetTokenAPI";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

/**
 * @type {React.CSSProperties}
 */
const closeButtonStyle = {
  position: "absolute",
  right: 0,
  top: 0,
  margin: 8,
};

const ModalContent = React.forwardRef((props, ref) => {
  // @ts-ignore
  const { apiToken, handleClose, handleResetToken, busy } = props;

  const [copied, setCopied] = React.useState(false);

  const codeElementId = "pre";

  const handleCopyClick = React.useCallback(() => {
    navigator.clipboard.writeText(
      document.getElementById(codeElementId)?.innerText || ""
    );

    window.setTimeout(() => {
      setCopied(true);

      window.setTimeout(() => {
        setCopied(false);
      }, 3000);
    }, 100);
  }, []);

  return (
    <Box sx={modalStyle} component="div">
      <Typography
        id="modal-modal-title"
        variant="h2"
        component="h1"
        style={{ marginBottom: 20 }}
      >
        API Settings
      </Typography>
      <IconButton style={closeButtonStyle} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <Typography id="modal-modal-description">
        Your Anjuna API token allows you to authenticate API requests.
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          margin: `20px 0`,
        }}
      >
        <code
          style={{
            display: "flex",
            flexDirection: "row",
            boxShadow: "inset 0 0 1.75px #e1e1e1",
            padding: 16,
            background: "#fafafa",
            width: "100%",
            position: "relative",
            borderRadius: 4,
            border: `1px solid transparent`,
            transition: "all 0.5s",
          }}
        >
          <Tooltip
            arrow
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {copied ? (
                    <>
                      <DoneIcon style={{ marginRight: 8, fontSize: 20 }} />
                      <Typography
                        color="white"
                        style={{
                          textTransform: "uppercase",
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                      >
                        Copied
                      </Typography>
                    </>
                  ) : (
                    <>
                      <ContentCopyIcon
                        style={{ marginRight: 8, fontSize: 20 }}
                      />
                      <Typography
                        color="white"
                        style={{
                          textTransform: "uppercase",
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                      >
                        Click to copy
                      </Typography>
                    </>
                  )}
                </div>
              </>
            }
          >
            <pre
              tabIndex={0}
              onClick={handleCopyClick}
              style={{
                fontFamily: "monospace",
                fontSize: 20,
                color: "#494a52",
                lineHeight: 1.5,
                margin: 0,
                overflowX: "auto",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              id={codeElementId}
              data-testid="ManageSettings-token"
            >
              {apiToken}
            </pre>
          </Tooltip>
          <div style={{ flex: 1 }} />
          <Button
            style={{ minWidth: 195, height: "fit-content" }}
            variant="contained"
            onClick={handleResetToken}
          >
            Regenerate Token
            {busy ? (
              <CircularProgress
                size={20}
                style={{ marginLeft: 8, color: "white" }}
                data-testid="ManageSettings-resetting-progress"
              />
            ) : null}
          </Button>
        </code>
      </div>
      <Typography>
        Do not share this key with anyone outside of your organization. It is
        recommended that you rotate the key periodically. If it becomes
        compromised, regenerate it immediately.
      </Typography>
    </Box>
  );
});

export const ManageSettings = () => {
  const [{ stage }, dispatch] = useAppStage();

  const handleClose = React.useCallback(() => {
    dispatch({
      type: "exit modal",
    });
  }, [dispatch]);

  const [busy, setBusy] = React.useState(false);

  const handleResetToken = React.useCallback(async () => {
    setBusy(true);

    const email =
      stage.type === "dashboard" ? stage.user.attributes.email : undefined;

    if (!email) {
      console.error("Unable to access email. Current stage is: " + stage.type);
      return;
    }

    const result = await resetTokenApi({
      email,
    });

    if (result.type === "success") {
      dispatch({ type: "api token updated", apiToken: result.token });
    }

    setBusy(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, stage.type]);

  if (stage.type !== "dashboard") {
    return null;
  }

  return (
    <div>
      <Modal
        open={stage.currentModal === "api settings"}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent
          // @ts-ignore
          apiToken={stage.apiToken}
          handleClose={handleClose}
          handleResetToken={handleResetToken}
          attributes={stage.user.attributes}
          busy={busy}
        />
      </Modal>
    </div>
  );
};
