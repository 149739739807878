// @ts-check
import React from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useAppStage } from "../../context/AppStageContext";
import logo from "../../assets/anjuna.svg";
import { licenseTypeDisplayName, techDisplayName } from "./techDisplayName";
import { makeStyles } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { LicenseType, ProductAccess, Tech } from "../../api/ApiTypes";
import { accountExpirationMessage } from "../../components/account/accountUtil";

/**
 * @typedef {import("../../../amplify/backend/function/awsonboardwebapputillayer/opt").ProductAccess} ProductAccess
 */

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const useStyles = makeStyles({
  software: {
    fontSize: 16,
    fontWeight: 700,
  },
  label: {
    color: "#A3A3A3",
    fontFamily: "Montserrat Medium",
    fontSize: 16,
  },
  value: {
    fontFamily: "Montserrat Medium",
    fontSize: 16,
  },
  button: {
    height: 44,
    fontWeight: "bold",
    fontSize: 16,
    padding: "16px 100px",
  },
  logo: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: 150,
    height: 34,
    minHeight: 34,
    marginTop: 16,
    marginBottom: 4,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  title: {
    marginBottom: 20,
    fontSize: 16,
    fontFamily: "Montserrat Medium",
    fontWeight: 400,
  },
  licenseMenuItem: {
    fontSize: 16,
    display: "block",
  },
});

export const AccountDetailsTable = (props: {
  access: ProductAccess[];
  onExpiryEdited?: (type: any, endsAt: any) => void;
  onLicenseTypeChanged?: (type: Tech, license: LicenseType) => void;
}) => {
  // @ts-ignore
  const { access, onExpiryEdited, onLicenseTypeChanged } = props;

  const classes = useStyles();

  return (
    <TableContainer>
      <Table aria-label="expiration table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography className={classes.label} fontSize="smaller">
                Software
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.label} fontSize="smaller">
                Expiry
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.label} fontSize="smaller">
                License Type
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {access.map(({ endsAt, type, licenseType }) => {
            return (
              <TableRow key={`${type}-expiration`}>
                <TableCell className={classes.software}>
                  {techDisplayName(type)}
                </TableCell>
                <TableCell data-testid={`expiration-date-${type}`}>
                  {onExpiryEdited ? (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        value={moment(endsAt)}
                        onChange={(newValue) => {
                          onExpiryEdited(
                            type,
                            newValue === null
                              ? "expired"
                              : moment(newValue).toDate().toUTCString()
                          );
                        }}
                      />
                    </LocalizationProvider>
                  ) : (
                    <Typography>{accountExpirationMessage(endsAt)}</Typography>
                  )}
                </TableCell>
                <TableCell
                  className={classes.software}
                  style={{ minWidth: 180 }}
                >
                  {onLicenseTypeChanged ? (
                    <FormControl fullWidth size="small">
                      <Select
                        data-testid={`license-type-${type}`}
                        labelId="select-license-type"
                        id="select-license-type"
                        value={licenseType || ""}
                        displayEmpty
                        onChange={(e) =>
                          onLicenseTypeChanged(
                            type,
                            e.target.value as LicenseType
                          )
                        }
                        style={{ fontSize: 16 }}
                        renderValue={(value) => (
                          <div>
                            {licenseTypeDisplayName(value)
                              ? `${licenseTypeDisplayName(value)} software`
                              : "-"}
                          </div>
                        )}
                      >
                        <MenuItem
                          className={classes.licenseMenuItem}
                          data-testid={`license-type-production`}
                          value={"production"}
                        >
                          <div style={{ fontSize: "larger" }}>
                            {licenseTypeDisplayName("production")} software
                          </div>
                          <div style={{ fontSize: 15 }}>
                            Running enclaves continue to run after the license
                            expires.
                            <br />
                            New enclaves continue to start even if the license
                            has expired.
                          </div>
                        </MenuItem>
                        <MenuItem
                          className={classes.licenseMenuItem}
                          data-testid={`license-type-trial`}
                          value={"trial"}
                        >
                          <div style={{ fontSize: "larger" }}>
                            {licenseTypeDisplayName("trial")} software
                          </div>
                          <div style={{ fontSize: 15 }}>
                            Running enclaves will terminate when the license
                            expires.
                            <br />
                            It is possible to start new enclaves even with
                            expired licenses.
                          </div>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    licenseTypeDisplayName(licenseType)
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ModalContent = React.forwardRef((props, ref) => {
  // @ts-ignore
  const { handleClose, access, companyName } = props;

  const classes = useStyles();

  return (
    <Box sx={modalStyles} component="div">
      <div className={classes.logo} />
      <Typography
        id="modal-modal-title"
        variant="h1"
        component="h1"
        className={classes.title}
      >
        Account Details
      </Typography>
      <div id="modal-modal-description">
        <Typography className={classes.label}>Account name</Typography>
        <Typography className={classes.value}>{companyName}</Typography>
      </div>
      <p />
      <AccountDetailsTable access={access} />
      <br />
      <Button
        onClick={handleClose}
        className={classes.button}
        variant="contained"
        title="Close"
      >
        OK
      </Button>
    </Box>
  );
});

export const AccountDetails = () => {
  const [{ stage }, dispatch] = useAppStage();

  const handleClose = React.useCallback(() => {
    dispatch({
      type: "exit modal",
    });
  }, [dispatch]);

  if (stage.type !== "dashboard") {
    return null;
  }

  return (
    <div>
      <Modal
        open={stage.currentModal === "account details"}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent
          // @ts-ignore
          companyName={stage.companyName}
          access={stage.access}
          handleClose={handleClose}
          attributes={stage.user.attributes}
        />
      </Modal>
    </div>
  );
};
