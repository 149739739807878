// @ts-check
import React from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TerminalIcon from "@mui/icons-material/Terminal";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonProps,
  Link,
  Modal,
} from "@mui/material";
import { CodeBox } from "./CodeBox";
import { DownloadBinary } from "../../api/downloadAPI";
import { wgetCode } from "./POC/poc-constants";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 920,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  maxHeight: "100%",
};

const closeButtonStyle: React.CSSProperties = {
  position: "absolute",
  right: 0,
  top: 0,
  margin: 8,
};

type Props = ButtonProps & {
  apiEndpoint: string;
  apiToken: string;
  filename: string;
  onOpenSettings: any;
  terminalIconStyles?: React.CSSProperties;
  verify?: {
    version: string;
    artifactIndex: number;
    artifactInfo: Omit<DownloadBinary, "filename">;
    type: string;
  };
  disabled?: boolean;
} & (
    | { kind: "icon"; terminalIconStyles?: React.CSSProperties }
    | { kind: "text"; text: string }
  );

/**
 *
 * @param {{ apiToken: string, type: string, version: string, onOpenSettings, artifactIndex: number, artifactInfo: import("../../api/downloadAPI").DownloadBinary }} props
 */
export const CLIDownloadButton = (props: Props) => {
  const {
    apiToken,
    apiEndpoint,
    onOpenSettings,
    verify,
    filename,
    terminalIconStyles,
    disabled,
    kind,
    ...buttonProps
  } = props;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <div>
      {kind === "icon" ? (
        <IconButton
          onClick={handleOpen}
          title="Download via Command-Line"
          disabled={disabled}
        >
          <TerminalIcon style={{ ...terminalIconStyles }} />
        </IconButton>
      ) : (
        <Button
          onClick={handleOpen}
          title="Download via Command-Line"
          disabled={disabled}
          style={{ textTransform: "none", fontWeight: 700 }}
          {...buttonProps}
        >
          {props.text}
        </Button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle} component="div">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <TerminalIcon color="primary" fontSize={"large"} />
            <Typography
              id="modal-modal-title"
              variant="h2"
              component="h2"
              style={{ marginLeft: 12, marginBottom: 0 }}
            >
              Downloading via Command-Line
            </Typography>
          </div>
          <IconButton style={closeButtonStyle} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Download using <code style={{ color: "#c75" }}>wget</code>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontSize={"smaller"}>
                Run the following command in a terminal:
              </Typography>
              <CodeBox
                id="wget"
                data-testid="quickstart-download-code"
                code={wgetCode(apiEndpoint, filename, apiToken)}
              />
            </AccordionDetails>
          </Accordion>
          {verify && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography data-testid="expand-checksums">
                  Verify the downloaded package
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography fontSize={"smaller"}>
                  First, download the installer. Then, run the following command
                  to fetch the expected checksums, and check them using the
                  command-line utilities{" "}
                  <code style={{ color: "#c75" }}>md5sum</code> and{" "}
                  <code style={{ color: "#c75" }}>sha256sum</code>.
                </Typography>
                <CodeBox
                  id="md5"
                  code={`md5sum --check <(curl ${apiEndpoint}/${verify.type}/${verify.version} \\
  -s -H "X-Anjuna-Auth-Token: ${apiToken}" \\
  | jq -r '"\\(.artifacts[${verify.artifactIndex}].md5) \\(.artifacts[${verify.artifactIndex}].filename)"')`}
                />
                <CodeBox
                  id="sha256"
                  code={`sha256sum --check <(curl ${apiEndpoint}/${verify.type}/${verify.version} \\
  -s -H "X-Anjuna-Auth-Token: ${apiToken}" \\
  | jq -r '"\\(.artifacts[${verify.artifactIndex}].sha256) \\(.artifacts[${verify.artifactIndex}].filename)"')`}
                />
                <br />
                <Typography fontSize={"smaller"}>
                  The expected output looks like{" "}
                  <code style={{ color: "#c75" }}>{filename}: OK</code>, which
                  means your installer downloaded successfully. You can continue
                  to install and use the Anjuna Runtime.
                </Typography>
                <br />
                <Typography fontSize={"smaller"}>
                  A failed output looks like this:
                </Typography>
                <CodeBox
                  id="expectedOutput"
                  code={`${filename}: FAILED
sha256sum: WARNING: 1 computed checksum did NOT match`}
                />
                <br />
                <Typography fontSize={"smaller"}>
                  If you see a failed output, first retry the download as this
                  can be the result of ordinary network corruption. If you
                  continue to see failed checksums, please contact{" "}
                  <Link
                    target="_blank"
                    rel="noopener"
                    href="mailto:support@anjuna.io"
                  >
                    Anjuna Support
                  </Link>
                  .
                </Typography>
              </AccordionDetails>
            </Accordion>
          )}
          <Typography id="modal-modal-description" sx={{ mt: 3 }}>
            Manage API access in{" "}
            <Link onClick={onOpenSettings} style={{ cursor: "pointer" }}>
              Settings
            </Link>
            .
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
