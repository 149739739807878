import React from "react";
import * as THREE from "three";
import { IconButton, Theme, Typography } from "@mui/material";
import { CompositeMeshBuilder } from "../../composite-mesh/CompositeMeshBuilder";
import {
  CompositeMeshNode,
  ConnectedTo,
  Direction,
  MeshEntity,
  MeshEntityId,
  MeshGeomBase,
  MeshSubstrateGeom,
} from "../../composite-mesh/composite-mesh-types";
import {
  QuickstartOptionsWithDefaults,
  QuickstartState,
  QuickstartSteps,
  isStepComplete,
  isStepVisible,
  useQuickstart,
} from "../useQuickstartStore";
import { themify } from "../../composite-mesh/mesh-helpers";
import {
  QuickstartContainerDefinition,
  QuickstartDefinition,
} from "../poc-types";
import { EditableMarkdown } from "../EditableMarkdown";
import { GettingQuickstartView } from "../views/create-instance/GettingQuickstartView";
import {
  BuildEnclaveView,
  DockerImageView,
  EnclaveConfigView,
  InstallView,
} from "../views/StandardViews";
import { makeStyles } from "@mui/styles";
import { CloseOutlined, InfoOutlined } from "@mui/icons-material";
import clsx from "clsx";
import { CreateInstanceView } from "../views/create-instance/CreateInstanceView";
import { QuickstartViewCloseButton } from "../views/components/QuickstartViewControls";
import { ParamScriptView } from "../views/components/ParamScriptView";
import { anjunaCliSvg } from "./visual-types";
import { useCloseStepViewOnEscape } from "../views/useCloseStepViewOnEscape";
import { containerParameter } from "../quickstartDefinitions";

export type ItemOptions = {
  builder: CompositeMeshBuilder;
  isActiveDefinition: boolean;
  definition: QuickstartDefinition;
  emissive: string;
  emissiveIntensity: number;
  roughness: number;
  color: string;
  dir?: Direction;
  quickstartState: QuickstartState;
  step: QuickstartSteps["kind"];
  popup?: Pick<MeshGeomBase, "entity" | "htmlComponents">;
  theme: Theme;
  allValues: QuickstartOptionsWithDefaults;
  walkthroughStarted: boolean;
};

const SMALL_TERMINAL_SIZE = new THREE.Vector3(2, 0, 2);
const smallTerminalsOffset = new THREE.Vector3(0, 0, 0);
const ITEM_LABEL_SCALE = 0.35;

const BUILD_ITEM_SIZE = new THREE.Vector3(1.5, 0.1, 1.5);
const BUILD_GROUP_GAPS = new THREE.Vector3(1.5, 1.5, 0);
const BUILD_GROUP_BORDER = new THREE.Vector3(0, 0, 0.25);

const RUN_PHASE_ITEM_SIZE = new THREE.Vector3(2.5, 2.5, 0.25);

const popupHtmlScale = 0.99;
const unSelectedHtmlScale = 0;
const viewHtmlScale = 0.9;

const POPUP_IMAGE_WIDTH = 50;
const POPUP_IMAGE_HEIGHT = POPUP_IMAGE_WIDTH;

const addItem = (
  builder: CompositeMeshBuilder,
  id: string,
  options: Partial<CompositeMeshNode> = {},
  geomOptions: Partial<CompositeMeshNode["geom"]> = {}
) => {
  builder.addService(
    "",
    "app",
    undefined,
    {
      dir: "right",
      id,
      ...options,
    },
    {
      style: { kind: "no fill" },
      ...geomOptions,
    }
  );
};

export function addDeployPhase(options: Omit<ItemOptions, "dir" | "step">) {
  const deployColor = themify("#aaffcc", options.theme);
  addGroup(
    "Deploy Phase",
    `${options.definition.id}-deploy phase`,
    {
      ...options,
      emissive: deployColor,
      color: deployColor,
      dir: "top",
      step: "ready to deploy",
      axisToAlignChildrenAlong: "y",

      popup: addPopup(
        "deploy phase",
        "Deploy Phase",
        <EditableMarkdown
          id="deploy-phase"
          markdown={`Deploy the Anjuna Confidential Container Image built in the previous step.
  
  See [documentation](https://docs.anjuna.io/nitro/latest/getting_started/first_steps/running_nginx.html)
  for details.
  `}
        />
      ),
    },
    {},
    {
      entity: {
        id: "deploy phase",
        display: "popup",
        infoButton: !isStepComplete(options.quickstartState, "ready to deploy"),
      },
    }
  );

  deployEnclaveScript({ ...options, step: "deploying enclave" });
  addStopScript({ ...options, step: "deploying enclave" });
  addLogsScript({ ...options, step: "deploying enclave" });

  options.builder.up();
}

export function addBuildPhase(options: Omit<ItemOptions, "step" | "dir">) {
  const { theme, builder } = options;
  const buildColor = themify("#88bbff", theme);
  addGroup(
    "Build Phase",
    `${options.definition.id}-build phase`,
    {
      ...options,
      emissive: buildColor,
      color: buildColor,
      dir: "top",
      step: "ready to build",
      axisToAlignChildrenAlong: "x",
      // axisToAlignChildrenAlong: "z",
      popup: addPopup(
        "build phase",
        "Build Phase",
        <EditableMarkdown
          id="build-phase"
          markdown={`Build a deployable image. Note: for the Quickstart we are building the image on the same instance that it will be deployed to. Normally, this is achieved by building in a trusted environment as part of the application build process.
  
  See [documentation](https://docs.anjuna.io/nitro/latest/getting_started/first_steps/first_steps_AWSNitro.html)
  for details.
  `}
        />
      ),
    },
    {},
    {
      entity: {
        id: "build phase",
        display: "popup",
        infoButton: !isStepComplete(options.quickstartState, "ready to build"),
      },
    }
  );

  addUnmodifiedAppGroup({ ...options, step: "ready to build" });

  options.definition.containers.forEach((container, i) => {
    addGroup(
      "",
      `${options.definition.id}-container ${i}`,
      {
        ...options,
        step: "ready to build",
      },
      {
        dir: "back",
        border: new THREE.Vector3(0, 0, 0),
        gaps: new THREE.Vector3(0, 0, 0.75),
        axisToAlignChildrenAlong: "x",
        connectedTo: [
          {
            id: `${options.definition.id}-cli`,
            kind: "arrow",
            sourceOffset: new THREE.Vector3(-0.125, 0, 0),
            targetOffset: new THREE.Vector3(0.125, 0, 0),
          },
        ],
      },
      {
        minSize: new THREE.Vector3(0, 0, 0),
        thickness: "bounds of children",
        opacity: 0.3,
        pointerEvents: "none",
        // style: { kind: "no fill" },
      }
    );

    addDocker({
      ...options,
      step: "ready to build",
      dir: "top",
      container,
      i,
    });

    addAppImage({
      ...options,
      step: "ready to build",
      dir: "top",
      container,
      i,
    });

    addEnclaveConfig({
      ...options,
      step: "ready to build",
      dir: "right",
      container,
      i,
    });

    builder.up(); // App
  });

  builder.up(); // App group

  addAnjunaPackager({ ...options, step: "building image" });
  addBuildScript({ ...options, step: "building image" });
  builder.up(); // Packager group

  addConfidentialContainerImageGroup({
    ...options,
    step: "building image",
  });
  builder.up(); // Build phase
}

const addConfidentialContainerImageGroup = (options: ItemOptions) => {
  const { builder, definition, quickstartState } = options;

  builder.addGroup(
    "",
    undefined,
    {
      id: `${definition.id}-workload image`,
      border: new THREE.Vector3(0, 0, 0),
      gaps: BUILD_GROUP_GAPS,
      dir: "right",
      status: isStepComplete(quickstartState, "ready to build")
        ? undefined
        : "collapsed",
    },
    {
      opacity: 0,
      style: { kind: "no fill" },
    }
  );

  definition.containers.forEach((container, i) => {
    addConfidentialContainerImage({
      ...options,
      step: "building image",
      container,
      dir: "back",
    });
  });

  builder.up();
};

export function addInstallPhase(options: ItemOptions) {
  const prepareColor = themify("#eeddaa", options.theme);

  addGroup(
    "Install Phase",
    `${options.definition.id}-install phase`,
    {
      ...options,
      emissive: prepareColor,
      color: prepareColor,
      dir: "top",
      step: "installing tools",
      axisToAlignChildrenAlong: "y",
      popup: addPopup(
        "install phase",
        "Install Phase",
        <EditableMarkdown
          id="install-phase"
          markdown={`To run AWS Nitro Enclaves a few steps need to be performed on the EC2 instance:
* Download the Quickstart
* Install several needed packages
* Allocate necessary resources for the Nitro Enclaves
* Start required services and enable Docker
  
See [documentation](https://docs.anjuna.io/nitro/latest/getting_started/getting_the_runtime/setup_nitro_host.html)
for details.
`}
        />
      ),
    },
    {},
    {
      entity: {
        id: "install phase",
        display: "popup",
        infoButton: !isStepComplete(
          options.quickstartState,
          "installing tools"
        ),
      },
    }
  );

  const installComplete = isStepComplete(
    options.quickstartState,
    "installing tools"
  );

  addItem(
    options.builder,
    `${options.definition.id}-getting quickstart`,
    {
      dir: "front",
      id: `${options.definition.id}-getting quickstart`,
      status: !installComplete ? undefined : "collapsed",
    },
    {
      size: SMALL_TERMINAL_SIZE,
      offset: smallTerminalsOffset,
      entity: {
        id: "getting quickstart view",
        display: "view",
        infoButton: !installComplete,
      },
      htmlComponents: [
        {
          component: <GettingQuickstartView />,
          id: "getting quickstart view",
          display: { kind: "selection", initialScale: unSelectedHtmlScale },
          scale: viewHtmlScale,
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
          },
        },
      ],
      images: [
        {
          src: "/terminal.svg",
          entity: {
            id: "getting quickstart view",
            display: "view",
            infoButton: true,
          },
          interactive: true,
          size: SMALL_TERMINAL_SIZE.x,
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
            offsetH: 0,
            offsetV: 0,
          },
          caption: {
            text: "download quickstart",
            scale: 0.5,
            location: {
              side: "right",
              alignHorizontally: "right",
              alignVertically: "center",
            },
          },
        },
      ],
    }
  );

  addItem(
    options.builder,
    "",
    {
      dir: "back",
      id: `${options.definition.id}-install`,
      status:
        isStepVisible(options.quickstartState, "installing tools") &&
        !isStepComplete(options.quickstartState, "installing tools")
          ? undefined
          : "collapsed",
    },
    {
      size: SMALL_TERMINAL_SIZE,
      offset: smallTerminalsOffset,
      entity: { id: "install", display: "view", infoButton: true },
      htmlComponents: [
        {
          component: <InstallView />,
          id: "install",
          scale: viewHtmlScale,
          display: { kind: "selection", initialScale: unSelectedHtmlScale },
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
          },
        },
      ],
      images: [
        {
          src: "/terminal.svg",
          entity: { id: "install", display: "view", infoButton: true },
          size: SMALL_TERMINAL_SIZE.x,
          interactive: true,
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
            offsetH: 0,
            offsetV: 0,
          },
          caption: {
            text: "install.sh",
            scale: 0.5,
            location: {
              side: "right",
              alignHorizontally: "right",
              alignVertically: "center",
            },
          },
        },
      ],
    }
  );

  options.builder.up();
}

function addGroup(
  text: string,
  id: string,
  {
    builder,
    color,
    dir,
    emissive,
    emissiveIntensity,
    roughness,
    step,
    quickstartState,
    popup,
    isActiveDefinition,
    axisToAlignChildrenAlong,
  }: ItemOptions &
    Pick<CompositeMeshNode, "axisToAlignChildrenAlong" | "constrainSizeTo">,
  options: Partial<CompositeMeshNode> = {},
  geomOptions: Partial<MeshSubstrateGeom> = {}
) {
  builder.addGroup(
    "",
    undefined,
    {
      id,
      gaps: new THREE.Vector3(1.5, 1.5, 0),
      border: new THREE.Vector3(0.5, 0.25, 0.2),
      dir,
      axisToAlignChildrenAlong,
      constrainSizeTo: "max of siblings",
      status:
        isActiveDefinition && isStepVisible(quickstartState, step)
          ? undefined
          : "collapsed",
      ...options,
    },
    {
      thickness: 0.2,
      minSize: new THREE.Vector3(3, 3, 0),
      opacity: 1,
      emissive,
      emissiveIntensity: emissiveIntensity + 0.1,
      roughness,
      color,
      label: {
        text: text.toUpperCase(),
        scale: ITEM_LABEL_SCALE - 0.075,
        location: {
          side: "front",
          alignHorizontally: "center",
          alignVertically: "center",
          // offsetV: 0,
        },
      },
      ...popup,
      ...geomOptions,
    } as MeshSubstrateGeom
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: 8,
  },
}));

type SelectedEntityPopupProps = React.PropsWithChildren<{
  title: string;
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
}>;

const SelectedEntityPopup = ({
  children,
  title,
  marginTop,
  marginLeft,
  marginRight,
}: SelectedEntityPopupProps) => {
  const closeStepView = useQuickstart((state) => state.closeStepView);

  const classes = useStyles();

  useCloseStepViewOnEscape();

  return (
    <div
      key={title}
      className={clsx("flex-col-container", classes.popover)}
      style={{
        marginLeft: marginLeft || -400,
        marginRight: marginRight || 25,
        marginTop,
      }}
      onPointerDown={(e) => e.stopPropagation()}
    >
      <div className="flex-row-container flex-centered">
        <InfoOutlined style={{ marginRight: 8 }} color="info" />
        <Typography variant="h4">{title}</Typography>
        <div className="flex-spacer" />
        <IconButton
          onClick={closeStepView}
          data-testid="quickstart-close-popup-button"
        >
          <CloseOutlined />
        </IconButton>
      </div>
      {children}
    </div>
  );
};

export function addParentInstance({
  builder,
  color,
  dir,
  emissive,
  emissiveIntensity,
  roughness,
  quickstartState,
}: Omit<ItemOptions, "definition" | "isActiveDefinition">) {
  builder.addGroup(
    "",
    undefined,
    {
      id: `parent instance`,
      gaps: new THREE.Vector3(1, 1, 0.5),
      border: new THREE.Vector3(0.25, 0.5, 0.25),
      dir,
      axisToAlignChildrenAlong: "x",
      status: isStepVisible(quickstartState, "instance created")
        ? undefined
        : "collapsed",
    },
    {
      thickness: 0.25,
      minSize: new THREE.Vector3(4, 4, 0),
      opacity: 1,
      emissive,
      emissiveIntensity: emissiveIntensity + 0.1,
      roughness,
      color,
      style: { kind: "silhouette", color: "#a11" },
      entity: {
        id: "parent instance",
        display: "popup",
        infoButton: !isStepComplete(quickstartState, "instance created"),
      },
      ...addPopup(
        "parent instance",
        "Parent Instance",
        <Typography>
          The parent instance allocates CPU cores and memory to the enclave. The
          resources are allocated to the enclave for the duration of its
          lifetime. The parent instance is the only EC2 instance that can
          communicate with its enclave.
        </Typography>
      ),
      label: {
        text: "Parent Instance",
        scale: ITEM_LABEL_SCALE + 0.05,
        location: {
          side: "top",
          alignHorizontally: "center",
          alignVertically: "bottom",
          offsetV: 0,
        },
      },
      images: [
        {
          src: "/aws-ec2.svg",
          size: SMALL_TERMINAL_SIZE.x,
          location: {
            side: "top",
            alignHorizontally: "center",
            alignVertically: "center",
            offsetV: 0.25,
            // offsetH: 0.5,
          },
        },
      ],
    }
  );
}

const addPopup = (
  entity: MeshEntityId,
  title: string,
  content: JSX.Element,
  popupProps?: Partial<SelectedEntityPopupProps>
): Pick<MeshGeomBase, "entity" | "htmlComponents"> => ({
  htmlComponents: [
    {
      component: (
        <SelectedEntityPopup title={title} marginTop={-140} {...popupProps}>
          {content}
        </SelectedEntityPopup>
      ),
      id: entity,
      display: { kind: "selection", initialScale: popupHtmlScale },
      scale: 8,
      location: {
        side: "front",
        alignHorizontally: "left",
        alignVertically: "top",
        transform: false,
      },
    },
    // addEntityLabel(entity),
  ],
});

export function addEc2InstanceGroup({
  builder,
  emissive,
  emissiveIntensity,
  roughness,
  color,
  theme,
  allValues,
  quickstartState,
}: Omit<ItemOptions, "definition" | "isActiveDefinition">) {
  builder.addGroup(
    "",
    undefined,
    {
      id: `ec2 instance`,
      border: new THREE.Vector3(0.1, 0.1, 0.4),
      gaps: new THREE.Vector3(1, 1, 0.25),
      dir: "right",
      axisToAlignChildrenAlong: "x",
      status: isStepVisible(quickstartState, "instance created")
        ? undefined
        : "collapsed",
    },
    {
      thickness: 0.75,
      offset: new THREE.Vector3(0, 0, 0.5),
      emissive,
      emissiveIntensity,
      opacity: 1,
      roughness: roughness + 0.1,
      color,
      label: {
        text: allValues["instanceType"],
        scale: 0.4,
        location: {
          side: "front",
          alignHorizontally: "center",
          alignVertically: "center",
        },
      },
      images: [
        {
          src: "/aws.svg",
          size: 0.75,
          location: {
            side: "front",
            alignHorizontally: "left",
            alignVertically: "center",
            // offsetV: 0.4,
            offsetH: 0.5,
          },
        },
      ],
      entity: {
        id: "ec2 instance",
        display: "popup",
        infoButton: !isStepComplete(quickstartState, "instance created"),
      },
      ...addPopup(
        "ec2 instance",
        "Nitro-Capable EC2",
        <div className="flex-col-container flex-centered">
          <div style={{ flex: 0.5 }} />
          <div className="flex-row-container">
            <div className="flex-col-container">
              <EditableMarkdown
                id={`ec2-description`}
                markdown={`An instance built on the [AWS Nitro System](https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/instance-types.html#ec2-nitro-instances).`}
                onSave={() => {}}
              />
            </div>
          </div>
          <div className="flex-spacer" />
        </div>
      ),
    }
  );
}

export const addClientComputer = ({
  builder,
  quickstartState,
  walkthroughStarted,
}: Omit<ItemOptions, "step" | "dir" | "definition" | "isActiveDefinition">) => {
  addItem(
    builder,
    "client computer",
    {
      connectedTo: [
        {
          id: "ec2 instance",
          kind: "arrow",
          sourceOffset: new THREE.Vector3(0, 0.2, 0.75),
        },
      ],
      status:
        walkthroughStarted &&
        !isStepComplete(quickstartState, "instance created")
          ? undefined
          : "collapsed",
    },
    {
      style: {
        kind: isStepVisible(quickstartState, "creating instance")
          ? "client"
          : "no fill",
      },
      size: new THREE.Vector3(3, 3, 3),
      offset: new THREE.Vector3(0, 0, -1),
      entity: {
        id: "client computer",
        display: "view",
        infoButton: !isStepComplete(quickstartState, "creating instance"),
      },
      htmlComponents: [
        {
          component: <CreateInstanceView />,
          id: "create instance view",
          display: { kind: "custom" },
          location: {
            side: "top",
            alignHorizontally: "center",
            alignVertically: "center",
          },
        },
        {
          component: <QuickstartViewCloseButton />,
          id: "close",
          display: { kind: "custom" },
          location: {
            side: "front",
            alignHorizontally: "right",
            alignVertically: "top",
          },
        },
      ],
    }
  );
};

export function addRootGroup(builder: CompositeMeshBuilder) {
  builder.addGroup(
    "",
    undefined,
    {
      dir: "front",
      border: new THREE.Vector3(2, 2, 0.5),
      gaps: new THREE.Vector3(4, 4, 0),
    },
    {
      style: { kind: "no fill" },
    }
  );
}

function addBuildScript({
  builder,
  definition,
  quickstartState,
  theme,
}: ItemOptions) {
  const entity: MeshEntity = {
    id: "build enclave",
    display: "view",
    infoButton: !isStepComplete(quickstartState, "building image"),
  };

  addItem(
    builder,
    "",
    {
      id: `${definition.id}-cli`,
      dir: "top",
      connectedTo: definition.containers.map((container) => ({
        id: `${definition.id}-${container.container}-eif`,
        kind: "arrow",
        targetOffset: new THREE.Vector3(0.25, 0, 0),
      })),
      status: !isStepComplete(quickstartState, "building image")
        ? undefined
        : "collapsed",
    },
    {
      entity,
      htmlComponents: [
        {
          component: <BuildEnclaveView />,
          id: "build enclave",
          display: { kind: "selection", initialScale: unSelectedHtmlScale },
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
          },
        },
      ],
      size: SMALL_TERMINAL_SIZE,
      label: {
        text: "CLI",
        scale: ITEM_LABEL_SCALE + 0.1,
        location: {
          side: "front",
          alignHorizontally: "center",
          alignVertically: "top",
          offsetH: 0,
          offsetV: 1.4,
        },
      },
      images: [
        {
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
          },
          src: "/terminal.svg",
          size: SMALL_TERMINAL_SIZE.x,
          entity,
          interactive: true,
          // caption: {
          //   location: {
          //     side: "front",
          //     alignHorizontally: "center",
          //     alignVertically: "top",
          //   },
          //   text: "build.sh",
          // },
        },
        {
          location: {
            side: "front",
            alignHorizontally: "right",
            alignVertically: "top",
          },
          src: anjunaCliSvg(theme.palette.mode),
          size: 1,
          offset: new THREE.Vector3(-0.25, -0.125, 0.65),
        },
      ],
    }
  );
}

export function addEnclaveConfig({
  builder,
  quickstartState,
  definition,
  dir,
  container,
  i,
}: ItemOptions & { container: QuickstartContainerDefinition; i: number }) {
  const entity: MeshEntity = {
    id: `${container.container}-enclave config`,
    display: "view",
    infoButton: !isStepComplete(quickstartState, "building image"),
  };

  addItem(
    builder,
    "config",
    {
      dir,
      id: `${definition.id}-${container.container}-enclave config`,
      status:
        isStepVisible(quickstartState, "ready to build") &&
        !isStepComplete(quickstartState, "building image")
          ? undefined
          : "collapsed",
    },
    {
      size: BUILD_ITEM_SIZE,
      offset: new THREE.Vector3(0, 0, -0.5),
      entity,
      htmlComponents: [
        {
          component: <EnclaveConfigView container={container.container} />,
          id: "enclave config",
          display: { kind: "selection", initialScale: unSelectedHtmlScale },
          scale: viewHtmlScale,
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
          },
        },
      ],
      images: [
        {
          src: "/config.svg",
          size: BUILD_ITEM_SIZE.x,
          entity,
          interactive: true,
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
          },
        },
      ],
    }
  );
}

function addAnjunaPackager(options: ItemOptions) {
  const { builder, quickstartState } = options;

  builder.addGroup(
    "",
    undefined,
    {
      border: BUILD_GROUP_BORDER,
      gaps: BUILD_GROUP_GAPS,
      dir: "right",
      status: buildPhaseContentVisibility(quickstartState),
    },
    {
      thickness: "bounds of children",
      style: { kind: "no fill" },
    }
  );
}

const buildPhaseContentVisibility = (state: QuickstartState) => {
  return isStepVisible(state, "ready to build") &&
    !isStepComplete(state, "building image")
    ? undefined
    : "collapsed";
};

function addUnmodifiedAppGroup({
  builder,
  quickstartState,
  definition,
}: ItemOptions) {
  builder.addGroup(
    "",
    undefined,
    {
      id: `${definition.id}-unmodified app`,
      border: new THREE.Vector3(),
      gaps: new THREE.Vector3(0, 2, 0),
      dir: "top",
      status: buildPhaseContentVisibility(quickstartState),
    },
    {
      thickness: 0.125,
      style: { kind: "no fill" },
      label: {
        text: "Unmodified App",
        scale: ITEM_LABEL_SCALE,
        location: {
          side: "front",
          alignHorizontally: "center",
          alignVertically: "top",
          offsetV: 4.25,
        },
      },
    }
  );
}

const containerEntityId = (container: string, id: string) =>
  `${container}-${id}`;

function addAppImage({
  builder,
  definition,
  container,
  quickstartState,
  dir,
  i,
}: ItemOptions & { container: QuickstartContainerDefinition; i: number }) {
  const entity: MeshEntity = {
    id: containerEntityId(container.container, "app image"),
    display: "popup",
    infoButton: !isStepComplete(quickstartState, "building image"),
  };

  addItem(
    builder,
    "app",
    {
      dir,
      status: buildPhaseContentVisibility(quickstartState),
      id: `${definition.id}-${container.container}-app image`,
    },
    {
      size: BUILD_ITEM_SIZE,
      ...addPopup(
        containerEntityId(container.container, "app image"),
        "Workload",
        <div className="flex-col-container flex-centered">
          <div style={{ flex: 0.5 }} />
          <div className="flex-row-container">
            <img
              src={container.imageUrl || definition.imageUrl}
              width={POPUP_IMAGE_WIDTH}
              height={POPUP_IMAGE_HEIGHT}
              style={{ marginRight: 16 }}
              alt="Application"
            />
            <div className="flex-col-container">
              {definition.descriptionMarkdown && (
                <EditableMarkdown
                  id={`app-description`}
                  markdown={definition.descriptionMarkdown}
                />
              )}
            </div>
          </div>
          <div className="flex-spacer" />
        </div>,
        {
          marginLeft: -200,
          marginRight: -200,
        }
      ),
      entity,
      images: [
        {
          src: container.imageUrl,
          size: BUILD_ITEM_SIZE.x,
          entity,
          interactive: true,
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
          },
        },
      ],
    }
  );
}

function addDocker({
  builder,
  quickstartState,
  definition,
  container,
  dir,
}: ItemOptions & { container: QuickstartContainerDefinition; i: number }) {
  const entity: MeshEntity = {
    id: containerEntityId(container.container, "docker input"),
    display: "view",
    infoButton: !isStepComplete(quickstartState, "building image"),
  };

  addItem(
    builder,
    `${definition.id}-${container.container}-docker`,
    {
      dir,
      connectedTo: [
        {
          id: `${definition.id}-${container.container}-enclave config`,
          kind: "plus",
          sourceOffset: new THREE.Vector3(-0.5, 0, 0.8),
        },
      ],
      status:
        isStepVisible(quickstartState, "ready to build") &&
        !isStepComplete(quickstartState, "building image")
          ? undefined
          : "collapsed",
    },
    {
      size: BUILD_ITEM_SIZE,
      entity,
      images: [
        {
          src: "/docker.svg",
          size: BUILD_ITEM_SIZE.x,
          entity,
          interactive: true,
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
          },
        },
      ],
      htmlComponents: [
        {
          component: <DockerImageView container={container.container} />,
          id: "docker input",
          display: { kind: "selection", initialScale: unSelectedHtmlScale },
          scale: viewHtmlScale,
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
          },
        },
      ],
    }
  );
}

function addConfidentialContainerImage({
  builder,
  theme,
  definition,
  container,
  quickstartState,
  dir,
}: ItemOptions & { container: QuickstartContainerDefinition }) {
  const entity: MeshEntity = {
    id: containerEntityId(container.container, "eif"),
    display: "popup",
    infoButton: !isStepComplete(quickstartState, "building image"),
  };

  addItem(
    builder,
    `${definition.id}-${container.container}-eif`,
    {
      dir,
      status:
        !isStepComplete(quickstartState, "building image") &&
        isStepComplete(quickstartState, "ready to build")
          ? undefined
          : "collapsed",
    },
    {
      offset: new THREE.Vector3(0, 0, 0.5),
      size: SMALL_TERMINAL_SIZE,
      ...addPopup(
        entity.id,
        "Anjuna Confidential Container Image",
        <div className="flex-col-container">
          <div className="spacer" />
          <div className="flex-row-container">
            <div className="flex-col-container">
              {definition.descriptionMarkdown && (
                <EditableMarkdown
                  id={`confidential-workload-description`}
                  markdown={definition.descriptionMarkdown}
                  onSave={() => {}}
                />
              )}
            </div>
          </div>
          <EditableMarkdown
            id={`step-confidential-workload`}
            markdown={`Ready to be deployed.
~~~bash
Enclave Image successfully created.
{
  "Measurements": {
    "HashAlgorithm": "Sha384 { ... }",
    "PCR0": "...",
    "PCR1": "...",
    "PCR2": "..."
  }
}
~~~`}
            onSave={() => {}}
          />
          <div className="spacer" />
        </div>
      ),
      entity,
      images: [
        {
          src: container.imageUrl || definition.imageUrl,
          entity,
          interactive: true,
          size: SMALL_TERMINAL_SIZE.x,
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
            offsetH: 0,
            offsetV: 0,
          },
        },
        {
          src: anjunaCliSvg(theme.palette.mode) || "",
          size: 1,
          offset: new THREE.Vector3(0, -0.1, 0),
          location: {
            side: "front",
            alignHorizontally: "right",
            alignVertically: "bottom",
            offsetH: -0.5,
            offsetV: 0.75,
          },
        },
      ],
    }
  );
}

function deployEnclaveScript({
  builder,
  quickstartState,
  definition,
}: ItemOptions) {
  const entity: MeshEntity = {
    id: "deploy enclave",
    display: "view",
    infoButton: !isStepComplete(quickstartState, "ready to deploy"),
  };

  addItem(
    builder,
    `${definition.id}-run`,
    {
      dir: "right",
      border: new THREE.Vector3(0.25, 0.25, 0.25),
      gaps: new THREE.Vector3(0.25, 0.25, 0.25),
      status: isStepVisible(quickstartState, "ready to deploy")
        ? undefined
        : "collapsed",
    },
    {
      size: SMALL_TERMINAL_SIZE,
      offset: new THREE.Vector3(0, 0, 0),
      entity,
      images: [
        {
          src: "/terminal.svg",
          entity,
          interactive: true,
          size: SMALL_TERMINAL_SIZE.x,
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
            offsetH: 0,
            offsetV: 0,
          },
          caption: {
            text: "run.sh",
            scale: 0.5,
            location: {
              side: "front",
              alignHorizontally: "center",
              alignVertically: "top",
            },
          },
        },
      ],
      htmlComponents: [
        {
          component: (
            <ParamScriptView
              operation="deploy enclave"
              descriptionMarkdown={`## Deploy
  
  Launches a new enclave. See the [Anjuna docs](https://docs.anjuna.io/nitro/latest/getting_started/command_reference/anjuna_nitro_cli/run_enclave.html) for information on launching enclaves.`}
            />
          ),
          scale: viewHtmlScale,
          id: "deploy enclave",
          display: { kind: "selection", initialScale: unSelectedHtmlScale },
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
          },
        },
      ],
    }
  );
}

function addStopScript({ builder, quickstartState, definition }: ItemOptions) {
  const entity: MeshEntity = {
    id: "terminate enclave",
    display: "view",
    infoButton: true,
  };

  addItem(
    builder,
    `${definition.id}-stop`,
    {
      dir: "back",
      border: new THREE.Vector3(0.25, 0.25, 0.25),
      gaps: new THREE.Vector3(0.25, 0.25, 0.25),
      status: isStepVisible(quickstartState, "deploying enclave")
        ? undefined
        : "collapsed",
    },
    {
      size: SMALL_TERMINAL_SIZE,
      offset: new THREE.Vector3(-0.25, 0, 0),
      entity,
      images: [
        {
          src: "/terminal.svg",
          entity,
          interactive: true,
          size: SMALL_TERMINAL_SIZE.x,
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
            offsetH: 0,
            offsetV: 0,
          },
          caption: {
            text: "stop.sh",
            scale: 0.5,
            location: {
              side: "front",
              alignHorizontally: "center",
              alignVertically: "top",
            },
          },
        },
      ],
      htmlComponents: [
        {
          component: (
            <ParamScriptView
              operation="terminate enclave"
              descriptionMarkdown={`## Terminate

Terminates a running enclave.

See the [Anjuna docs](https://docs.anjuna.io/nitro/latest/getting_started/command_reference/anjuna_nitro_cli/terminate_enclave.html) for more information.`}
            />
          ),
          scale: viewHtmlScale,
          id: "deploy enclave",
          display: { kind: "selection", initialScale: unSelectedHtmlScale },
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
          },
        },
      ],
    }
  );
}

function addLogsScript({ builder, quickstartState, definition }: ItemOptions) {
  const entity: MeshEntity = {
    id: "view enclave logs",
    display: "view",
    infoButton: true,
  };

  addItem(
    builder,
    `${definition.id}-logs`,
    {
      dir: "back",
      border: new THREE.Vector3(0.25, 0.25, 0.25),
      gaps: new THREE.Vector3(0.25, 0.25, 0.25),
      status: isStepVisible(quickstartState, "deploying enclave")
        ? undefined
        : "collapsed",
    },
    {
      size: SMALL_TERMINAL_SIZE,
      offset: new THREE.Vector3(-0.5, 0, 0),
      entity,
      images: [
        {
          src: "/terminal.svg",
          entity,
          interactive: true,
          size: SMALL_TERMINAL_SIZE.x,
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
            offsetH: 0,
            offsetV: 0,
          },
          caption: {
            text: "logs.sh",
            scale: 0.5,
            location: {
              side: "front",
              alignHorizontally: "center",
              alignVertically: "top",
            },
          },
        },
      ],
      // entity: "view enclave logs",
      htmlComponents: [
        {
          component: (
            <ParamScriptView
              operation="view enclave logs"
              descriptionMarkdown={`## Access Logs

Connect to the console of an enclave if it was started in \`debug\` mode.

See the [Anjuna docs](https://docs.anjuna.io/nitro/latest/getting_started/command_reference/anjuna_nitro_cli/console.html) for more information.`}
            />
          ),
          scale: viewHtmlScale,
          id: "deploy enclave",
          display: { kind: "selection", initialScale: unSelectedHtmlScale },
          location: {
            side: "front",
            alignHorizontally: "center",
            alignVertically: "center",
          },
        },
      ],
    }
  );
}

export function addRunningEnclave_HostSide({
  builder,
  definition,
  container,
  quickstartState,
  allValues,
  isActiveDefinition,
  color,
  emissive,
  emissiveIntensity,
  i,
}: ItemOptions & { container: string; i: number }) {
  if (i > 0) return;

  const entity: MeshEntity = {
    id: "netd parent",
    display: "popup",
    infoButton: true,
  };

  addItem(
    builder,
    `${definition.id}-${container}-netd-parent-host`,
    {
      connectedTo: [{ id: `${definition.id}-vsock`, kind: "connection" }],
      dir: i === 0 ? "right" : "back",
      status:
        isActiveDefinition &&
        isStepVisible(quickstartState, "deploying enclave")
          ? undefined
          : "collapsed",
    },
    {
      style: { kind: "solid" },
      color,
      emissive,
      emissiveIntensity,
      opacity: 1,
      size: new THREE.Vector3(1, 1.5, 0.1),
      offset: new THREE.Vector3(0, 0, -0.5),
      entity,
      images: [
        {
          src: "/network.svg",
          entity,
          size: 1,
          location: {
            side: "top",
            alignHorizontally: "center",
            alignVertically: "center",
            offsetH: 0,
            offsetV: 0,
          },
        },
      ],
      ...addPopup(
        "netd parent",
        "Network Daemon",
        <div className="flex-col-container">
          <div className="spacer" />
          <img
            src={"/network.svg"}
            width={POPUP_IMAGE_WIDTH}
            alt="anjuna-nitro-netd-parent"
          />
          {definition.descriptionMarkdown && (
            <EditableMarkdown
              id={`anjuna-nitro-netd-parent-description`}
              markdown={`
  ### \`anjuna-nitro-netd-parent\`
  A network bridge between the AWS EC2 host and the enclave.
  
  \`anjuna-nitro-netd-parent\` enables AWS Nitro Enclaves to send and receive network traffic:
  * Outbound connections initiated from within the enclave.
  * Inbound connections to the enclave’s exposed ports.
  
  `}
              onSave={() => {}}
            />
          )}
          <div className="spacer" />
        </div>
      ),
    }
  );
}

export function addVsock({
  builder,
  theme,
  definition,
  quickstartState,
  allValues,
  isActiveDefinition,
  containers,
}: ItemOptions & { containers: string[] }) {
  addItem(
    builder,
    `${definition.id}-vsock`,
    {
      status:
        isActiveDefinition &&
        isStepVisible(quickstartState, "deploying enclave")
          ? undefined
          : "collapsed",
      dir: "right",
      connectedTo: containers.map(
        (_, i) =>
          ({
            id: `${definition.id}-anjuna-enclave-cli-${i}`,
            kind: "connection",
            sourceSide: "right",
            sourceControl: "right",
            targetSide: "left",
            targetControl: "left",
          } as ConnectedTo)
      ),
    },
    {
      size: new THREE.Vector3(0.75, 2, 0.25),
      offset: new THREE.Vector3(0, -0.075, 0.25),
      style: { kind: "solid" },
      color: theme.palette.background.paper,
      emissive: theme.palette.background.paper,
      label: {
        text: "vsock",
        location: {
          side: "top",
          alignHorizontally: "center",
          alignVertically: "center",
          orientation: "vertical",
        },
      },
      entity: {
        id: "vsock",
        display: "popup",
        infoButton: true,
      },
      ...addPopup(
        "vsock",
        "vsock",
        <Typography>
          The vsock (virtual socket) in AWS Nitro Enclaves enables bidirectional
          communication between the parent EC2 instance and the enclave. Given
          that Nitro Enclaves lack external network connectivity, vsock is
          essential for transferring data efficiently and securely between the
          instance and the enclave.
        </Typography>
      ),
    }
  );
}

export function addRunningEnclave_EnclaveSide({
  builder,
  theme,
  emissive,
  emissiveIntensity,
  roughness,
  definition,
  quickstartState,
  allValues,
  isActiveDefinition,
  container,
  i,
}: ItemOptions & { container: QuickstartContainerDefinition; i: number }) {
  const exposedPorts =
    allValues[containerParameter(container.container, "exposedPorts")];

  builder.addGroup(
    "",
    undefined,
    {
      gaps: new THREE.Vector3(0.5, 0.5, 1.5),
      border: new THREE.Vector3(0, 0, 0.125),
      dir: i === 0 ? "right" : "back", //i === 1 ? "back" : i === 2 ? "right" : "front",
      status:
        isActiveDefinition &&
        isStepVisible(quickstartState, "deploying enclave")
          ? undefined
          : "collapsed",
    },
    {
      protected: 32,
      thickness: 0.125,
      opacity: 1,
      emissive,
      emissiveIntensity: emissiveIntensity + 0.1,
      roughness,
      color: theme.palette.background.paper,
      images: [],
      animDelayFactor: 0.5 + 0.5 * i,
      label: {
        text: "Enclave",
        scale: ITEM_LABEL_SCALE + 0.1,
        location: {
          side: "top",
          alignHorizontally: "center",
          alignVertically: "bottom",
          offsetH: -0.2,
          offsetV: -0.8,
        },
      },
    }
  );

  const entity: MeshEntity = {
    id: containerEntityId(container.container, `anjuna runtime`),
    display: "popup",
    infoButton: true,
  };

  addItem(
    builder,
    `${definition.id}-anjuna-enclave-cli-${i}`,
    {
      status:
        isActiveDefinition &&
        isStepVisible(quickstartState, "deploying enclave")
          ? undefined
          : "collapsed",
      connectedTo: [
        {
          id: `${definition.id}-docker-container-${i}`,
          kind: "connection",
          sourceSide: "left",
          targetSide: "left",
        },
      ],
      dir: "back",
    },
    {
      style: { kind: "solid" },
      color: theme.palette.background.paper,
      size: RUN_PHASE_ITEM_SIZE,
      ...addPopup(
        containerEntityId(container.container, "anjuna runtime"),
        "Anjuna Runtime",
        <EditableMarkdown
          id={`anjuna-runtime-description`}
          markdown={`An [AWS Nitro Enclave](https://aws.amazon.com/ec2/nitro/nitro-enclaves) is a secure environment that allows code execution while preventing external access to the enclave's memory and CPU. However, it comes with limitations, such as a lack of internet-connected network interface and persistent filesystem access.
  
  Anjuna extends the capabilities of AWS Nitro Enclaves by enabling more applications to run within them. The Anjuna Nitro Runtime facilitates the execution of an application inside an AWS Nitro Enclave without any modifications to the application. This includes providing access to network communication, encryption, and key management. Anjuna's "lift-and-shift" strategy negates the need for changes to applications or recompilation, making it easier to manage evolving applications and SDKs.`}
          onSave={() => {}}
        />
      ),
      entity,
      images: [
        {
          src: anjunaCliSvg(theme.palette.mode),
          size: RUN_PHASE_ITEM_SIZE.x,
          entity,
          interactive: true,
          location: {
            side: "top",
            alignHorizontally: "center",
            alignVertically: "center",
            offsetH: 0,
            offsetV: 0,
          },
        },
        {
          src: "/nitro.svg",
          size: 0.75,
          location: {
            side: "top",
            alignHorizontally: "left",
            alignVertically: "bottom",
            offsetH: 0.5,
            offsetV: 0.5,
          },
        },
      ],
    }
  );

  addItem(
    builder,
    `${definition.id}-docker-container-${i}`,
    {
      status:
        isActiveDefinition &&
        isStepVisible(quickstartState, "deploying enclave")
          ? undefined
          : "collapsed",
      dir: "top",
      connectedTo: [
        {
          id: `${definition.id}-running-app-${i}`,
          kind: "connection",
          sourceSide: "left",
          targetSide: "left",
        },
      ],
    },
    {
      size: new THREE.Vector3(3, 2.5, 0),
      offset: new THREE.Vector3(0, 0.125, 0),
      entity: {
        id: containerEntityId(container.container, "docker container"),
        display: "popup",
        infoButton: true,
      },
      images: [
        {
          src: "/docker.svg",
          size: RUN_PHASE_ITEM_SIZE.x,
          location: {
            side: "top",
            alignHorizontally: "center",
            alignVertically: "center",
            offsetH: 0,
            offsetV: 0,
          },
          interactive: true,
          entity: {
            id: containerEntityId(container.container, "docker container"),
            display: "popup",
            infoButton: true,
          },
        },
      ],
      ...addPopup(
        containerEntityId(container.container, "docker container"),
        "Protected Container",
        <EditableMarkdown
          id={`docker-container-description`}
          markdown={`A memory-protected container running inside an AWS Nitro Enclave. It has transparent access to the enclave's network interface via the Anjuna Runtime.`}
          onSave={() => {}}
        />
      ),
    }
  );

  addItem(
    builder,
    `${definition.id}-running-app-${i}`,

    {
      status: isStepVisible(quickstartState, "deploying enclave")
        ? undefined
        : "collapsed",
      connectedTo: [
        {
          id: `${definition.id}-running-app-${i}`,
          kind: "connection",
          targetOffset: new THREE.Vector3(-1, 0, 0),
          label: exposedPorts.join(", "),
        },
      ],
      dir: "top",
    },
    {
      size: RUN_PHASE_ITEM_SIZE,
      offset: new THREE.Vector3(0, 0.0, 0),
      entity: {
        id: containerEntityId(container.container, "confidential workload"),
        display: "popup",
        infoButton: true,
      },
      images: [
        {
          src: container.imageUrl,
          size: RUN_PHASE_ITEM_SIZE.x,
          location: {
            side: "top",
            alignHorizontally: "center",
            alignVertically: "center",
            offsetH: 0,
            offsetV: 0,
          },
          interactive: true,
          entity: {
            id: containerEntityId(container.container, "confidential workload"),
            display: "popup",
            infoButton: true,
          },
        },
      ],
      ...addPopup(
        containerEntityId(container.container, "confidential workload"),
        "Confidential Workload",
        <EditableMarkdown
          id={`confidential-workload-description`}
          markdown={`A memory-protected application running inside an AWS Nitro Enclave. Accesses the network and filesystem transparently via the Anjuna Runtime.`}
          onSave={() => {}}
        />
      ),
    }
  );

  builder.up();
}
