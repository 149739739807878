// @ts-check
import { API } from "aws-amplify";
import { AccountInfo } from "./ApiTypes";

type AccountAPIResponse = (
  | { type: "error"; message: string }
  | { type: "pending"; companyName: string }
  | { type: "missing" }
  | (Omit<AccountInfo, "status" | "companyName"> & {
      type: "success";
      token: string;
      showEULA: boolean;
      companyName: string;
    })
) & { message: string };

export const createAccountAPI = async ({
  email,
  name,
  companyName,
}: {
  email: string;
  name: string;
  companyName: string;
}): Promise<AccountAPIResponse> => {
  const apiName = "createaccount"; // replace this with your api name.
  const path = "/api/v1/accounts"; // replace this with the path you have configured on your API
  const myInit = {
    body: {
      email,
      companyName: companyName,
      contactPerson: name,
    },
    headers: {},
  };

  try {
    const response = await API.post(apiName, path, myInit);

    return {
      companyName,
      ...response,
    };
  } catch (error) {
    console.error("createAccount API call failed", JSON.stringify(error));

    return {
      type: "pending",
      companyName: "",
      message: "pending",
    };
  }
};
