import { API } from "aws-amplify";
import { DownloadInfo, Tech } from "./ApiTypes";

/**
 * Returns a list of available product downloads for a specific technology type.
 */
export const getReleasesAPI = async ({
  type,
  apiToken,
}: {
  type: Tech;
  apiToken: string;
}): Promise<
  | { type: "success"; products: DownloadInfo[]; license: string }
  | { type: "error"; message: string }
> => {
  const apiName = "releases";
  const path = "/api/v1/releases";

  const myInit = {
    headers: {},
  };

  try {
    const { products } = await API.get(
      apiName,
      `${path}/${type}?token=${apiToken}`,
      myInit
    );

    return {
      type: "success",
      license: products[0].license,
      products,
    };
  } catch (error) {
    console.error("GET releases API call failed", JSON.stringify(error));

    return {
      type: "error",
      message: `Fetching releases list failed: ${JSON.stringify(error)}`,
    };
  }
};
