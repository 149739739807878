// @ts-check
import React from "react";
import { CircularProgress, Typography } from "@mui/material";
import { PageContent } from "../components/PageContent";
import { useAppStageDispatch } from "../context/AppStageContext";
import { createAccountAPI } from "../api/createAccountAPI";

// @ts-ignore-next-line
export const CreatingDownloadsAccountContainer = ({ user }) => {
  const appStageDispatch = useAppStageDispatch();

  const { attributes } = user;

  React.useEffect(() => {
    createAccountAPI({
      email: attributes.email,
      name: attributes.name,
      companyName: attributes.family_name,
    })
      .then((result) => {
        switch (result.type) {
          case "success": {
            const { type, token, ...rest } = result;
            appStageDispatch({
              type: "account linked",
              user,
              accountType: "downloads",
              apiToken: token,
              ...rest,
            });
            break;
          }
          case "pending":
            appStageDispatch({
              type: "account linked",
              user,
              accountType: "pending",
              apiToken: undefined,
              showEULA: false,
              access: [],
              companyName: result.companyName,
              canApproveUsers: false,
              templatesAccess: false,
              prereleasesAccess: false,
              freeTrialAccess: false,
            });
            break;
          case "missing":
            appStageDispatch({
              type: "account linked",
              user,
              accountType: "missing",
              apiToken: undefined,
              showEULA: false,
              access: [],
              companyName: "Unknown",
              canApproveUsers: false,
              templatesAccess: false,
              prereleasesAccess: false,
              freeTrialAccess: false,
            });
            break;
          default:
          case "error":
            appStageDispatch({
              type: "account link failed",
              reason: result.message,
              reasonHtml: "",
              user,
              accountType: "downloads",
            });
            break;
        }
      })
      .catch((e) => {
        console.error("Error creating account: " + JSON.stringify(e));
      });
  }, [
    user,
    attributes.email,
    attributes.name,
    attributes.family_name,
    appStageDispatch,
  ]);

  return (
    <PageContent application includeHero includeFooter={false}>
      <div
        className="LinkingAccount-message"
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <div className="flex-spacer" />
        <Typography variant="h2">{"Accessing account"}</Typography>
        <CircularProgress size={24} style={{ marginLeft: 16 }} />
        <div className="flex-spacer" />
      </div>
    </PageContent>
  );
};
