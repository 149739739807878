// @ts-check
import React from "react";
import { CircularProgress, Typography } from "@mui/material";
import { awsSubscribe } from "../api/awsSubscribe";
import { PageContent } from "../components/PageContent";
import { User, useAppStageDispatch } from "../context/AppStageContext";
import { getUrlParameter } from "../core/getUrlParameter";
import { CoreConstants } from "../core/constants";
import { useQuickstart } from "./ResourceCenter/POC/useQuickstartStore";

export const LinkingAccountContainer = ({ user }: { user: User }) => {
  const appStageDispatch = useAppStageDispatch();

  const { attributes } = user;

  const regToken = getUrlParameter(CoreConstants.AmazonRegistrationToken);

  const setApiInfo = useQuickstart((state) => state.setApiInfo);

  React.useEffect(() => {
    awsSubscribe({
      regToken,
      email: attributes.email,
      name: attributes.name,
      companyName: attributes.family_name,
    }).then((result) => {
      if (result.type === "success") {
        appStageDispatch({
          type: "account linked",
          user,
          accountType: "downloads",
          apiToken: result.token,
          showEULA: result.showEULA,
          access: result.access,
          companyName: result.companyName,
          canApproveUsers: false,
          templatesAccess: result.templatesAccess,
          prereleasesAccess: result.prereleasesAccess,
          freeTrialAccess: result.freeTrialAccess,
        });
      } else {
        appStageDispatch({
          type: "account link failed",
          reason: result.message || "",
          reasonHtml: result.messageHtml || "",
          user,
          accountType: "downloads",
        });
      }
    });
  }, [
    user,
    regToken,
    attributes.email,
    attributes.name,
    attributes.family_name,
    appStageDispatch,
    setApiInfo,
  ]);

  return (
    <PageContent application includeHero includeFooter={false}>
      <div className="LinkingAccount-message">
        <div className="flex-spacer" />
        <Typography variant="h2">{"Accessing account"}</Typography>
        <CircularProgress style={{ marginLeft: 16 }} />
        <div className="flex-spacer" />
      </div>
    </PageContent>
  );
};
