// @ts-check
import React from "react";
import { Grid } from "@mui/material";
import { useAppStage } from "../context/AppStageContext";

export const PageFooter = () => {
  const [{ stage }] = useAppStage();

  switch (stage.type) {
    case "check account":
    case "link account":
    case "link account error":
    case "create downloads account":
    case "dashboard": {
      return (
        <Grid
          container
          direction="row"
          alignItems="center"
          className="PageFooter-container"
        ></Grid>
      );
    }
    default:
      return null;
  }
};
