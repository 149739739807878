import React from "react";
import TagIcon from "@mui/icons-material/Tag";
import { IconButton, Typography, Popover, Grid, Box } from "@mui/material";
import { CodeBox } from "./CodeBox";

export const ShowChecksumsButton = ({ md5, sha256 }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "checksums-popover" : undefined;

  return (
    <div>
      <IconButton onClick={handleClick} data-testid="view-checksums">
        <TagIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 3, maxWidth: "780px" }} fontSize="smaller">
          <Typography variant="h2">Checksums</Typography>
          <Grid item container direction="column">
            You can compute the checksum of the downloaded file and compare it
            with the the official checksum provided:
            <Grid
              item
              container
              direction="row"
              alignItems={"center"}
              sx={{ marginTop: 1 }}
            >
              <Grid item sx={{ width: 86, marginRight: 1 }} textAlign={"right"}>
                MD5:
              </Grid>
              <Grid item>
                <CodeBox id="md5" code={md5} style={{ margin: 0 }} />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              alignItems={"center"}
              sx={{ marginTop: 1 }}
            >
              <Grid item sx={{ width: 86, marginRight: 1 }} textAlign={"right"}>
                SHA-256:
              </Grid>
              <Grid item>
                <CodeBox id="sha256" code={sha256} style={{ margin: 0 }} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </div>
  );
};
