import React from "react";
import scrollIntoView from "scroll-into-view-if-needed";

export const ScrollTo = ({ behavior }: { behavior?: ScrollBehavior }) => {
  React.useEffect(() => {
    const element = document.getElementById("expanded-scrollto");
    window.setTimeout(() => {
      element && element.scrollIntoView({ block: "end", behavior });
    }, 100);
  }, [behavior]);

  return <div style={{ visibility: "hidden" }} id="expanded-scrollto" />;
};

export const delayedEffect = (delayMs: number) => (effect: () => void) =>
  new Promise<void>((res) =>
    setTimeout(() => {
      effect();
      res();
    }, delayMs)
  );

export const ElementScrollPos = ({ id }: { id: string }) => {
  return (
    <div
      style={{
        visibility: "hidden",
        width: "100%",
        height: 1,
        // marginTop: -50,
      }}
      id={id}
    />
  );
};

export const useScrollToElement = (id: string, updateCriteria: string) => {
  React.useEffect(() => {
    delayedEffect(150)(() => {
      const element = document.getElementById(id);
      element &&
        scrollIntoView(element, {
          behavior: "smooth",
          scrollMode: "always",
          block: "start",
        });
    });
  }, [id, updateCriteria]);
};
