import { API } from "aws-amplify";
import { Tech } from "./ApiTypes";

export type UserDownload = {
  type: Tech;
  version: string;
  downloadedAt: string;
};

export type UserDownloads = { companyName: string; id: string; downloads: UserDownload[] };

export type DownloadsReport = UserDownloads[];

export const downloadsReportAPI = async (
  apiToken: string
): Promise<
  | { type: "success"; report: DownloadsReport }
  | { type: "error"; reason: string }
> => {
  const apiName = "apireport";
  const path = `/v1/usage`;
  const myInit = {
    headers: {},
    queryStringParameters: {
      token: apiToken,
      report: true,
      includeAnjuna: true,
    },
  };

  try {
    const result = await API.get(apiName, path, myInit);

    console.log(
      "Retrieve downloads report API call succeeded: " + JSON.stringify(result)
    );

    return {
      type: "success",
      report: result,
    };
  } catch (error) {
    console.error(
      "Retrieve downloads report API call failed",
      JSON.stringify(error)
    );

    return {
      type: "error",
      reason: `We were unable to receive downloads report.`,
    };
  }
};
