import { Button, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { ChangeEvent } from "react";

const SUPPORTED_IMAGE_FORMATS = ["image/svg+xml", "image/png"];

interface ImageUploadProps {
  imageUrl: string | null;
  onImageUpload: (image: string) => void;
  label?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  imageWrapper: {
    padding: 0,
  },
}));

function convertImageToFileDataURL(svgFile: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      const svgDataURL = event.target?.result as string;
      resolve(svgDataURL);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(svgFile);
  });
}

export const ImageUpload: React.FC<ImageUploadProps> = ({
  imageUrl,
  onImageUpload,
  label,
}) => {
  const classes = useStyles();

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && SUPPORTED_IMAGE_FORMATS.includes(file.type)) {
      convertImageToFileDataURL(file)
        .then((fileAsUrl) => {
          onImageUpload(fileAsUrl);
        })
        .catch(console.warn);
    }
  };

  return (
    <div className="flex-row-container flex-centered">
      <Button
        component="label"
        variant="outlined"
        title={label || `${imageUrl ? "Replace" : "Upload a"} Preview Image`}
      >
        {imageUrl ? (
          <div className={classes.imageWrapper}>
            <img
              src={imageUrl}
              alt="Preview Thumbnail"
              style={{
                maxWidth: 120,
                maxHeight: 100,
                minWidth: 80,
              }}
            />
          </div>
        ) : null}
        <input
          type="file"
          accept={SUPPORTED_IMAGE_FORMATS.join(",")}
          hidden
          onChange={handleImageChange}
        />
      </Button>
    </div>
  );
};
