import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

export const DropdownList = (props: {
  title: string;
  options: { label: string; value: string }[];
  value: string;
  onSelect: (value: string) => void;
}) => {
  const { title, options } = props;

  const handleChange = (event: SelectChangeEvent<string>) => {
    props.onSelect(event.target.value);
  };

  return (
    <FormControl sx={{ m: 0, minWidth: 120 }}>
      <InputLabel id="select-label">{title}</InputLabel>
      <Select
        labelId={title}
        id="select"
        value={props.value}
        onChange={handleChange}
        label="Select an option"
        // size="small"
        style={{ width: 320 }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropdownList;
