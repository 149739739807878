import React from "react";
import { IconButton, ToggleButton, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { useQuickstart } from "../../useQuickstartStore";
import { ConfigureQuickstartModal } from "./ConfigureQuickstartModal";
import {
  PocStepCodeFile,
  QuickstartContainerDefinition,
  QuickstartDefinition,
} from "../../poc-types";
import { CheckCircle } from "@mui/icons-material";
import { useTheme } from "../../../../../useThemeStore";
import { logAnyPropChanges } from "../../../../../core/logAnyPropsChanges";
import { v4 as uuid } from "uuid";

const QuickstartChoice = React.memo(
  (props: {
    index: number;
    choice: QuickstartDefinition;
    selected: boolean;
    onSelect: (choice: string) => void;
    onEdit: () => void;
  }) => {
    const { choice, selected, onSelect, onEdit } = props;

    logAnyPropChanges("QuickstartChoice", props);

    const onClick = React.useCallback(
      (e: React.MouseEvent, value: any) => {
        onSelect(value);
      },
      [onSelect]
    );

    const theme = useTheme();

    const editing = useQuickstart((state) => state.isEditing());

    return (
      <ToggleButton
        value={choice.id}
        selected={selected}
        onClick={onClick}
        style={{
          width: 130,
          padding: 4,
          textTransform: "initial",
          border: "none",
        }}
        data-testid={`workload-choice-${choice.id}`}
      >
        {selected && (
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              borderRadius: 3,
              background:
                theme.palette.mode === "dark"
                  ? "rgba(255,255,255,0.3)"
                  : "rgba(0,0,0,0.3)",
            }}
          >
            <div
              style={{
                width: 32,
                height: 32,
                background: "white",
                position: "absolute",
                left: "calc(50% - 16px)",
                top: "calc(50% - 16px)",
                borderRadius: "50%",
              }}
            >
              <CheckCircle
                style={{
                  width: 36,
                  height: 36,
                  marginTop: -2,
                  marginLeft: -2,
                  color: theme.palette.primary.light,
                }}
              />
            </div>
          </div>
        )}
        <div className="flex-col-container" style={{ alignItems: "center" }}>
          <img
            src={choice.imageUrl}
            alt={choice.title}
            style={{ maxWidth: 90, height: 70 }}
          />
          <Typography
            style={{
              fontSize: 13,
            }}
          >
            {choice.title}
          </Typography>
        </div>
        {editing && (
          <IconButton
            onClick={onEdit}
            style={{ position: "absolute", right: 4, top: 4 }}
          >
            <EditIcon />
          </IconButton>
        )}
      </ToggleButton>
    );
  }
);

export const QuickstartSelector = React.memo(() => {
  logAnyPropChanges("QuickstartSelector", {});

  const [ConfigureChoiceModalOpen, setConfigureChoiceModalOpen] =
    React.useState(false);

  const addDefinition = useQuickstart((state) => state.addDefinition);
  const definitions = useQuickstart((state) => state.definitions);

  const handleAddClicked = React.useCallback(() => {
    setConfigureChoiceModalOpen(true);
  }, []);

  const handleAdd = React.useCallback(
    (
      title: string,
      files: PocStepCodeFile[],
      containers: QuickstartContainerDefinition[],
      descriptionMarkdown: string | undefined,
      imageUrl: string
    ) => {
      addDefinition({
        id: uuid(),
        title,
        descriptionMarkdown,
        imageUrl,
        containers,
        files,
      });
    },
    [addDefinition]
  );

  const editing = useQuickstart((state) => state.isEditing());

  const addChoiceAllowed = editing;

  const selected = useQuickstart((state) => state.selectedApp());

  const setOption = useQuickstart((state) => state.setOption);
  const walkthroughStarted = useQuickstart((state) => state.walkthroughStarted);
  const startWalkthrough = useQuickstart((state) => state.startWalkthrough);
  const handleSelectApp = React.useCallback(
    (value: string) => {
      setOption("app", value);
      if (!walkthroughStarted) startWalkthrough();
    },
    [setOption, startWalkthrough, walkthroughStarted]
  );

  const handleCloseModal = React.useCallback(() => {
    setConfigureChoiceModalOpen(false);
  }, []);

  const updateActive = useQuickstart((state) => state.updateActive);
  const saveDefinitions = useQuickstart((state) => state.saveDefinitions);

  const [editChoiceModalOpen, setEditChoiceModalOpen] = React.useState(false);

  const handleSaveEdit = React.useCallback(
    (
      title: string,
      files: PocStepCodeFile[],
      containers: QuickstartContainerDefinition[],
      descriptionMarkdown: string | undefined,
      imageUrl: string
    ) => {
      updateActive({
        ...selected,
        title,
        descriptionMarkdown,
        imageUrl,
        files,
        containers,
      });

      saveDefinitions();
    },
    [saveDefinitions, selected, updateActive]
  );

  const handleEditChoiceClicked = React.useCallback(() => {
    setEditChoiceModalOpen(true);
  }, []);

  const ITEMS_PER_ROW = 2;
  const ROWS = Math.ceil(definitions.length / ITEMS_PER_ROW);

  return (
    <div
      className="flex-col-container"
      style={{ maxHeight: 300, overflowY: "auto" }}
    >
      {new Array(ROWS).fill(undefined).map((_, i) => (
        <div className="flex-row-container flex-centered">
          {definitions
            .slice(i * ITEMS_PER_ROW, (i + 1) * ITEMS_PER_ROW)
            .map((choice, choiceIndex) => (
              <div
                style={{
                  marginRight: 8,
                  marginBottom: 8,
                }}
              >
                <QuickstartChoice
                  key={choice.id}
                  index={i * ITEMS_PER_ROW + choiceIndex}
                  choice={choice}
                  selected={choice.id === selected.id}
                  onSelect={handleSelectApp}
                  onEdit={handleEditChoiceClicked}
                />
              </div>
            ))}
        </div>
      ))}
      {addChoiceAllowed ? (
        <IconButton
          onClick={handleAddClicked}
          style={{ borderRadius: 10, width: 100 }}
          data-testid={`configure-quickstart-add-button`}
        >
          <AddCircleOutlineIcon
            style={{
              fontSize: 70,
            }}
          />
        </IconButton>
      ) : null}
      {editChoiceModalOpen && (
        <ConfigureQuickstartModal
          operation="edit"
          open={editChoiceModalOpen}
          defaults={selected}
          onConfirm={handleSaveEdit}
          onClose={() => setEditChoiceModalOpen(false)}
        />
      )}
      {ConfigureChoiceModalOpen && (
        <ConfigureQuickstartModal
          operation="add"
          open={ConfigureChoiceModalOpen}
          onConfirm={handleAdd}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
});
