import { LicenseType, Tech } from "../../api/ApiTypes";

export const techDisplayName = (name: Tech): string => {
  switch (name) {
    case "nitro":
      return "AWS Nitro";
    case "sgx":
      return "Intel® SGX";
    case "gcp":
      return "SEV on GCP";
    case "azsev":
      return "SEV on Azure";
    case "k8s-sev":
      return "K8S-SEV";
    case "apm":
      return "Policy Manager";
  }
};

export const techDownloadsTitle = (name: Tech): string => {
  switch (name) {
    case "nitro":
      return "AWS Nitro Runtime";
    case "sgx":
      return "Intel® SGX Runtime";
    case "gcp":
      return "SEV on GCP Runtime";
    case "azsev":
      return "SEV on Azure Runtime";
    case "k8s-sev":
      return "Kubernetes Toolset for AMD SEV";
    case "apm":
      return "Policy Manager";
  }
};

export const licenseTypeDisplayName = (type?: LicenseType): string => {
  switch (type) {
    case "production":
      return "Licensed";
    case "trial":
      return "Trial";
    case "engineering":
      return "Licensed";
    default:
      return "";
  }
};
