// @ts-check
import React from "react";
import { Card, Grid, Typography } from "@mui/material";
import { PageContent } from "../components/PageContent";
import { PageHeader } from "../components/PageHeader";

export const LinkingAccountFailedContainer = ({
  reason,
  reasonHtml,
  user,
  accountType,
}) => {
  return (
    <>
      <PageHeader />
      <PageContent application includeFooter>
        <Card variant="elevation" className="Error-card">
          <Grid container direction="column">
            <div style={{ flex: 0.75 }} />
            <Grid
              item
              container
              direction="row"
              alignSelf="center"
              style={{ width: "unset" }}
            >
              <Grid item>
                <Typography variant="h1">{"Anjuna software access"}</Typography>
                {reason && <Typography>{reason}</Typography>}
                {reasonHtml && (
                  <div dangerouslySetInnerHTML={{ __html: reasonHtml }} />
                )}
              </Grid>
            </Grid>
            <div style={{ height: 200 }} />
          </Grid>
        </Card>
      </PageContent>
    </>
  );
};
