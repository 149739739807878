// @ts-check
import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DownloadsTable } from "./DownloadsTable";
import { techDisplayName, techDownloadsTitle } from "./techDisplayName";
import { ContactSales } from "../../components/AccountBanner";

const useStyles = makeStyles({
  contactContainer: {
    marginTop: 24,
    color: "#258465",
    fontWeight: "bolder",
  },
});

/**
 *
 * @param {{tech: import("../../api/createAccountAPI").ProductAccess['type'], access: import("../../api/createAccountAPI").ProductAccess[]}} param0
 * @returns
 */
export const Downloads = ({ tech, access }) => {
  const classes = useStyles();
  const techAccess = access.find((cur) => cur.type === tech);
  if (
    !techAccess ||
    techAccess.endsAt === "expired" ||
    new Date(techAccess.endsAt) < new Date(Date.now())
  ) {
    return (
      <div>
        <Typography variant="h2">License has expired</Typography>
        <Typography>
          You no longer have access to {techDisplayName(tech)} software
          downloads.
        </Typography>
        <div className={classes.contactContainer}>
          <ContactSales />
        </div>
      </div>
    );
  }

  return (
    <>
      <Grid item>
        <Typography variant="h2" sx={{ mb: 3 }}>{`Anjuna ${techDownloadsTitle(
          tech
        )} Downloads`}</Typography>
      </Grid>
      <DownloadsTable tech={tech} productAccess={techAccess} />
    </>
  );
};
