import React from "react";
import { Vector3, Euler } from "three";
import { useSpring, animated } from "@react-spring/web";
import { a } from "@react-spring/three";
import { LoadedImageMesh, loadSVGMesh } from "./loadSVGMesh";
import { Html, Text } from "@react-three/drei";
import { useTheme } from "../../../useThemeStore";
import { MeshEntity, MeshGeomLabelOptions } from "./composite-mesh-types";
import { useInteractivity } from "./useInteractivity";
import { GroupProps } from "@react-three/fiber";
import { loadRasterImage } from "./loadRasterImage";

const AnimatedHtml = animated(Html);

interface ImageProps {
  url: string;
  width?: number;
  height?: number;
  position?: Vector3;
  rotation?: Euler;
  scale?: number;
  entity?: MeshEntity;
  instanceId: string;
  interactive?: boolean;
  caption?: MeshGeomLabelOptions;
}

const imageCache = new Map<string, LoadedImageMesh>();
const addUrlToCache = (url: string) => {
  loadSVGMesh(url, "light").then((result) => {
    imageCache.set(url, result);
  });
};

const images: string[] = [
  "/aws.svg",
  "/aws-ec2.svg",
  "/nginx.svg",
  "/docker.svg",
  "/python.svg",
  "/pocoo_flask-ar21.svg",
  "/anjuna-cli.svg",
  "/anjuna-cli-light.svg",
  "/terminal.svg",
  "/config.svg",
  "/network.svg",
];

const initCache = () => {
  images.forEach(addUrlToCache);
};

initCache();

const ImageMesh: React.FC<ImageProps> = ({
  url,
  position,
  rotation,
  scale,
  interactive,
  entity,
  caption,
}) => {
  const [loadedSVG, setLoadedSVG] = React.useState<LoadedImageMesh | null>(
    null
  );
  const theme = useTheme();
  const { palette } = theme;
  const emissiveIntensity = palette.mode === "dark" ? 0 : 0.5;

  const {
    ref,
    isSelected,
    isHovered,
    onPointerDown,
    onPointerEnter,
    onPointerLeave,
    spring,
    InfoButton,
  } = useInteractivity({ entity, scale });

  React.useEffect(() => {
    if (imageCache.has(url)) {
      const cached = imageCache.get(url)!;
      const group = cached.group.clone();
      (
        (group.children[0] as THREE.Mesh).material as THREE.MeshStandardMaterial
      ).emissiveIntensity = emissiveIntensity;

      setLoadedSVG({
        ...cached,
        group,
      });
      return;
    }

    if (url.endsWith(".svg") || url.includes("image/svg")) {
      loadSVGMesh(url, palette.mode).then(setLoadedSVG);
    } else {
      loadRasterImage(url).then(setLoadedSVG);
    }
  }, [emissiveIntensity, palette.mode, url]);

  const { mode } = palette;

  const scaleToUse = scale || 1;

  const highlightStrength = 1;

  const highlightSpring = useSpring({
    opacity: isHovered ? 0.7 : isSelected ? 1 : 0.01,
  });

  if (!loadedSVG) return null;

  const highlightSize = scaleToUse * 40;
  const aspectRatio = loadedSVG.width / loadedSVG.height;
  const highlightW = highlightSize * aspectRatio;
  const highlightH = highlightSize;

  const captionOffset = 4.25 / 8;

  const infoButton = (
    <AnimatedHtml
      zIndexRange={[10, 20]}
      position={new Vector3(-scaleToUse, 0, scaleToUse)
        .clone()
        .multiplyScalar(0.5)}
      style={{
        transform: `translate(-50%, 0%)`,
      }}
    >
      <InfoButton />
    </AnimatedHtml>
  );

  return (
    <group position={position}>
      {infoButton}
      <group
        rotation={rotation}
        ref={ref as GroupProps["ref"]}
        onPointerDown={onPointerDown}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
      >
        {interactive && !isSelected && (
          <AnimatedHtml
            transform
            distanceFactor={10}
            position={[0, 0, scaleToUse / 15]}
            style={{
              ...highlightSpring,
              pointerEvents: isSelected ? "auto" : "none",
            }}
          >
            {
              <div
                style={{
                  position: "absolute",
                  left: -highlightW / 2,
                  top: -highlightH / 2,
                  width: highlightW,
                  height: highlightH,
                  border: `1px solid rgba(0,255,255,${highlightStrength})`,
                  background: `rgba(0,255,255,${highlightStrength / 4})`,
                  borderRadius: "10%",
                }}
              />
            }
          </AnimatedHtml>
        )}
        <a.group {...spring}>
          <primitive object={loadedSVG.group} />
          {caption ? (
            <Text
              scale={0.4 / scaleToUse}
              position={[
                caption.location.alignHorizontally === "left"
                  ? -captionOffset
                  : caption.location.alignHorizontally === "right"
                  ? captionOffset
                  : 0,
                caption.location.alignHorizontally === "left" ||
                caption.location.alignHorizontally === "right"
                  ? 0
                  : caption.location.alignVertically === "bottom"
                  ? -captionOffset
                  : captionOffset,
                0,
              ]}
              anchorX={
                caption.location.alignHorizontally === "left"
                  ? "right"
                  : caption.location.alignHorizontally === "right"
                  ? "left"
                  : "center"
              }
              color={mode === "dark" ? "#ccc" : "#333"}
              outlineWidth={0.04}
              outlineColor={mode === "dark" ? "#000" : "#fff"}
            >
              {caption.text}
            </Text>
          ) : null}
        </a.group>
      </group>
    </group>
  );
};

export const CacheImage: React.FC<ImageProps> = (props) => {
  return <ImageMesh {...props} />;
};
