import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useEditableTabStyles } from "../../EditableTab";
import { PocOperation, PocStepCodeFile } from "../../poc-types";
import { CodeEditor } from "../../CodeEditor";
import { useQuickstart } from "../../useQuickstartStore";

export const CodeFiles = ({
  files,
  readOnly,
}: {
  files: PocStepCodeFile[];
  readOnly?: boolean;
}) => {
  const classes = useEditableTabStyles();

  const showTabs = files.length > 0;

  const [selectedFileIndex, setSelectedFileIndex] = React.useState(0);

  const selectedFile = files[selectedFileIndex];

  const handleSelectTab = React.useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setSelectedFileIndex(newValue);
    },
    [setSelectedFileIndex]
  );

  return (
    <Box
      sx={{ width: "100%", height: "100%", marginTop: 0, marginBottom: 0 }}
      style={{ minWidth: 350 }}
      component="div"
    >
      {showTabs ? (
        <Tabs
          value={selectedFileIndex}
          onChange={handleSelectTab}
          variant="scrollable"
          allowScrollButtonsMobile
        >
          {files.map((file, index) => {
            return (
              <Tab key={file.name} label={file.name} className={classes.root} />
            );
          })}
        </Tabs>
      ) : null}
      {selectedFile && (
        <div style={{ height: "90%" }}>
          <CodeEditor
            code={selectedFile.content}
            mode={selectedFile.mode}
            name={selectedFile.name}
            readOnly={readOnly}
          />
        </div>
      )}
    </Box>
  );
};

export const QuickstartFiles = (props: {
  operation: PocOperation;
  container?: string;
}) => {
  const { operation, container } = props;
  const files = useQuickstart((state) =>
    state.filesForOperation(operation, container)
  );

  return <CodeFiles files={files} readOnly />;
};
