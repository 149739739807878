import React from "react";
import { MeshEntity, MeshGeomHtmlComponent } from "./composite-mesh-types";
import { IconButton, ThemeProvider } from "@mui/material";
import { useTheme } from "../../../useThemeStore";
import { AnimatedHtml } from "./CompositeMesh3d";
import { useSpring } from "@react-spring/three";
import { calculatePositionAndOrientation } from "./composite-mesh-vm";
import { useQuickstart } from "../POC/useQuickstartStore";
import { constrainToViewport } from "../POC/views/components/viewLayout";

type HtmlComponentProps = { htmlComponent: MeshGeomHtmlComponent };
export const HtmlComponent = ({ htmlComponent }: HtmlComponentProps) => {
  const theme = useTheme();

  const content =
    htmlComponent.display.kind === "visited" ? (
      <IconButton>{htmlComponent.component}</IconButton>
    ) : (
      htmlComponent.component
    );

  return <ThemeProvider theme={theme}>{content}</ThemeProvider>;
};

const NodeHtmlComponent = ({
  isSelected,
  isHovered,
  innerHtmlOnly,
  htmlComponent,
  nodeSize,
  entity,
}: {
  nodeSize: THREE.Vector3;
  isSelected?: boolean;
  isHovered?: boolean;
  innerHtmlOnly?: boolean;
  htmlComponent: MeshGeomHtmlComponent;
  entity?: MeshEntity;
}) => {
  const viewport = useQuickstart((state) => state.viewport);
  const viewportConstrainRatio = constrainToViewport(viewport);
  const viewResX = 1100 * viewportConstrainRatio;
  const viewResY = 650 * viewportConstrainRatio;

  const selectionBasedScale =
    htmlComponent.display.kind === "selection"
      ? isSelected
        ? 1
        : htmlComponent.display.initialScale
      : htmlComponent.display.kind === "hover"
      ? isHovered
        ? 1
        : htmlComponent.display.initialScale
      : 1;

  const show =
    (htmlComponent.display.kind === "custom" && innerHtmlOnly) ||
    (htmlComponent.display.kind === "selection" && isSelected) ||
    (htmlComponent.display.kind === "hover" && isHovered) ||
    htmlComponent.display.kind === "always" ||
    htmlComponent.display.kind === "visited";

  const ANIM_MS = 300;

  const facadeStyleSpring = useSpring({
    width: viewResX,
    height: viewResY,
    transform: `scale(${show ? selectionBasedScale : 0})`,
    opacity: show ? 1 : 0,
    onStart: () => {
      setFacadeFullyHidden(false);
    },
    config: {
      duration: ANIM_MS,
    },
  });

  const [facadeFullyHidden, setFacadeFullyHidden] = React.useState(false);

  const facadeFadeStyleSpring = useSpring({
    opacity: show ? 0 : 1,
    delay: ANIM_MS + 250,
    onRest: () => {
      setFacadeFullyHidden(true);
    },
  });

  const isPopup = entity?.display === "popup";
  const isInfoButton = htmlComponent.display.kind === "visited";

  const htmlSpring = useSpring({
    opacity: show ? 1 : 0,
    delay: isPopup ? 0 : ANIM_MS,
    transform: isPopup
      ? `scale(${show ? selectionBasedScale : 0.8})`
      : undefined,
  });

  const { location } = htmlComponent;
  const { offsetH, offsetV, offsetD } = location;

  const { position, orientation } = calculatePositionAndOrientation(
    nodeSize,
    location,
    offsetH,
    offsetV,
    offsetD
  );

  const positionSpring = useSpring({
    position: position.toArray(),
  });

  if (htmlComponent.display.kind === "custom" && !innerHtmlOnly) return null;

  if (!show) return null;

  const content = show ? (
    <HtmlComponent key={htmlComponent.id} htmlComponent={htmlComponent} />
  ) : null;

  const viewOnlyStyles =
    isPopup || isInfoButton
      ? undefined
      : {
          width: viewResX,
          height: viewResY,
          transform: "translate3d(-50%,-50%,0)",
        };

  const distanceFactor = 4.5;

  const contentWrapper = (
    <div
      style={{ pointerEvents: "auto", display: "contents" }}
      onPointerDown={(e) => e.stopPropagation()}
    >
      {content}
    </div>
  );

  const facade =
    innerHtmlOnly || isPopup || facadeFullyHidden ? null : (
      <AnimatedHtml
        transform={htmlComponent.location.transform !== false}
        distanceFactor={distanceFactor / viewportConstrainRatio}
        {...positionSpring}
        rotation={orientation}
        style={{
          ...facadeStyleSpring,
          ...facadeFadeStyleSpring,
        }}
        pointerEvents={"none"}
        zIndexRange={[1100, 1200]}
      >
        {contentWrapper}
      </AnimatedHtml>
    );

  const infoButtonPositionSpring = isInfoButton ? positionSpring : undefined;

  const realThing = (
    <AnimatedHtml
      distanceFactor={(distanceFactor + 1) * 1.85}
      style={{
        ...viewOnlyStyles,
        ...htmlSpring,
      }}
      {...infoButtonPositionSpring}
      zIndexRange={[1200, 1300]}
    >
      {contentWrapper}
    </AnimatedHtml>
  );

  return innerHtmlOnly ? (
    <HtmlComponent key={htmlComponent.id} htmlComponent={htmlComponent} />
  ) : (
    <>
      {facade}
      {realThing}
    </>
  );
};

export const NodeHtmlComponents = (props: {
  isHovered?: boolean;
  isSelected?: boolean;
  innerHtmlOnly?: boolean;
  nodeSize: THREE.Vector3;
  htmlComponents: MeshGeomHtmlComponent[];
  entity?: MeshEntity;
}) => {
  const { htmlComponents, ...rest } = props;

  const htmlJsx = htmlComponents?.map((htmlComponent, index) => (
    <NodeHtmlComponent
      key={htmlComponent.id}
      {...rest}
      htmlComponent={htmlComponent}
    />
  ));

  return <>{htmlJsx || null}</>;
};
