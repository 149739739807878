// @ts-check
import React from "react";
import { Amplify } from "aws-amplify";
import { ThemeProvider } from "@mui/material/styles";
import {
  CssBaseline,
  Grid,
  StyledEngineProvider,
  useMediaQuery,
} from "@mui/material";
import awsconfig from "./aws-exports";
import "./App.css";
import { AuthContainer } from "./pages/Auth/AuthContainer";
import { LinkingAccountContainer } from "./pages/LinkingAccount";
import { DashboardContainer } from "./pages/Dashboard";
import { AppStageProvider, useAppStage } from "./context/AppStageContext";
import { useAmplifyAuth } from "./hooks/useAmplifyAuth";
import { LinkingAccountFailedContainer } from "./pages/LinkingAccountFailed";
import { CreatingDownloadsAccountContainer } from "./pages/CreatingDownloadsAccount";
import { isDownloadsRoute } from "./core/getUrlParameter";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ManageSettings } from "./pages/ResourceCenter/ManageSettings";
import { AccountDetails } from "./pages/ResourceCenter/AccountDetails";
import { useSystemThemePreferenceStore, useTheme } from "./useThemeStore";

Amplify.configure(awsconfig);

const Content = () => {
  const [{ stage }] = useAppStage();

  useAmplifyAuth();

  switch (stage.type) {
    case "login": {
      return <AuthContainer />;
    }
    case "create downloads account": {
      return <CreatingDownloadsAccountContainer user={stage.user} />;
    }
    case "link account": {
      return <LinkingAccountContainer user={stage.user} />;
    }
    case "check account": {
      return isDownloadsRoute() ? (
        <CreatingDownloadsAccountContainer user={stage.user} />
      ) : (
        <LinkingAccountContainer user={stage.user} />
      );
    }
    case "link account error": {
      return (
        <LinkingAccountFailedContainer
          reason={stage.reason}
          reasonHtml={stage.reasonHtml}
          user={stage.user}
          accountType={stage.accountType}
        />
      );
    }
    case "dashboard": {
      return <DashboardContainer />;
    }
    default:
      return <p>Unhandled application stage: {JSON.stringify(stage)}</p>;
  }
};

const App = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = useTheme();
  const setDarkModeSystemPreference = useSystemThemePreferenceStore(
    (state) => state.setDarkModeSystemPreference
  );

  React.useEffect(() => {
    setDarkModeSystemPreference(prefersDarkMode);
  }, [prefersDarkMode, setDarkModeSystemPreference]);

  return React.useMemo(
    () => (
      <AppStageProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ height: "100vh" }}
              className={
                theme.palette.mode === "dark" ? "app-dark" : "app-light"
              }
            >
              <BrowserRouter>
                <Routes>
                  <Route path="/*" element={<Content />} />
                </Routes>
              </BrowserRouter>
              <ManageSettings />
              <AccountDetails />
            </Grid>
          </ThemeProvider>
        </StyledEngineProvider>
      </AppStageProvider>
    ),
    [theme]
  );
};

export default App;
