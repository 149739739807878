import { TextField, TextFieldProps } from "@mui/material";
import { useState } from "react";

export const ValidatedTextField = (
  props: TextFieldProps & { onValidate: (value: string) => string }
) => {
  const [error, setError] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(props.onValidate(event.target.value));

    props.onChange?.(event);
  };

  return (
    <TextField
      error={!!error}
      {...props}
      helperText={error ? error : props.helperText}
      value={props.value}
      onChange={handleChange}
    />
  );
};
