import { useSpring } from "@react-spring/web";
import { MESH_SELECTION_COLOR } from "./composite-mesh-vm";
import { Theme } from "@mui/material";
import { useTheme } from "../../../useThemeStore";

function rgbToHex(rgb: string): string {
  // Remove the "rgb(" at the start and the ")" at the end, and split at commas
  const [r, g, b] = rgb.slice(4, -1).split(",").map(Number);

  // Convert each color component to hexadecimal
  let red = r.toString(16);
  let green = g.toString(16);
  let blue = b.toString(16);

  // Pad with 0 if necessary
  red = red.length === 1 ? "0" + red : red;
  green = green.length === 1 ? "0" + green : green;
  blue = blue.length === 1 ? "0" + blue : blue;

  return "#" + red + green + blue;
}

export function invertColor(rgb: string): string {
  // Remove the "rgb(" at the start and the ")" at the end, and split at commas
  const [r, g, b] = rgb.slice(4, -1).split(",").map(Number);

  // Invert each color component
  const red = 255 - r;
  const green = 255 - g;
  const blue = 255 - b;

  return `rgb(${red}, ${green}, ${blue})`;
}

const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
  if (hex.length !== 7) throw new Error(`Invalid hex: ${hex}`);

  return {
    r: parseInt(hex.substring(1, 3), 16),
    g: parseInt(hex.substring(3, 5), 16),
    b: parseInt(hex.substring(5, 7), 16),
  };
};

type HSL = {
  h: number;
  s: number;
  l: number;
};

const rgbToHSL = (rgb: { r: number; g: number; b: number }): HSL => {
  // Then convert RGB to HSL
  let { r, g, b } = { ...rgb };
  r /= 255;
  g /= 255;
  b /= 255;
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h = (max + min) / 2;
  let s = (max + min) / 2;
  let l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // Achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return { h: h * 360, s: s * 100, l: l * 100 };
};

function darkenHSL(hsl: HSL, decrease: number): HSL {
  return { ...hsl, l: Math.max(0, hsl.l - decrease) };
}

type RGB = {
  r: number;
  g: number;
  b: number;
};

function hslToRGB(hsl: HSL): RGB {
  let { h, s, l } = hsl;
  h /= 360;
  s /= 100;
  l /= 100;

  let r: number, g: number, b: number;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number): number => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    let p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  };
}

export const themifyRgb = (rgb: RGB, theme: Theme): string => {
  const { h, s, l } = darkenHSL(
    rgbToHSL(rgb),
    theme.palette.mode === "dark" ? 70 : 0
  );

  const { r, g, b } = hslToRGB({ h, s, l });

  return `rgb(${r}, ${g}, ${b})`;
};

export const themify = (hexOrRgb: string, theme: Theme): string => {
  if (hexOrRgb.startsWith("rgb(")) {
    const [r, g, b] = hexOrRgb.slice(4, -1).split(",").map(Number);
    return themifyRgb({ r, g, b }, theme);
  }

  const { r, g, b } = hexToRgb(hexOrRgb);
  return themifyRgb({ r, g, b }, theme);
};

function lerpColor(color1: string, color2: string, ratio: number): string {
  let c1 = color1;
  if (c1.startsWith("rgb(")) {
    c1 = rgbToHex(c1);
  } else if (c1.length === 4) {
    c1 = "#" + c1[1] + c1[1] + c1[2] + c1[2] + c1[3] + c1[3];
  }
  let c2 = color2;
  if (c2.startsWith("rgb(")) {
    c2 = rgbToHex(c2);
  } else if (c2.length === 4) {
    c2 = "#" + c2[1] + c2[1] + c2[2] + c2[2] + c2[3] + c2[3];
  }

  if (c1.length !== 7 || c2.length !== 7 || ratio < 0 || ratio > 1) {
    throw new Error(`Invalid colors: ${c1}, ${c2}`);
  }

  const toHex = (c: number): string => {
    const hex = Math.round(c).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const r =
    parseInt(c1.substring(1, 3), 16) * (1 - ratio) +
    parseInt(c2.substring(1, 3), 16) * ratio;
  const g =
    parseInt(c1.substring(3, 5), 16) * (1 - ratio) +
    parseInt(c2.substring(3, 5), 16) * ratio;
  const b =
    parseInt(c1.substring(5, 7), 16) * (1 - ratio) +
    parseInt(c2.substring(5, 7), 16) * ratio;

  return "#" + toHex(r) + toHex(g) + toHex(b);
}

export const useMeshSelectableMaterialSpring = ({
  isSelected,
  isHovered,
  color,
  emissive,
  emissiveIntensity,
}: {
  isSelected?: boolean;
  isHovered?: boolean;
  color?: string;
  emissive?: string;
  emissiveIntensity?: number;
}) => {
  const theme = useTheme();

  const SELECTION_WEIGHT = theme.palette.mode === "dark" ? 0.4 : 1;

  const selectionColor =
    theme.palette.mode === "dark" ? "#004477" : MESH_SELECTION_COLOR;

  return useSpring({
    color: lerpColor(
      color || "#333333",
      selectionColor,
      isSelected ? SELECTION_WEIGHT : isHovered ? SELECTION_WEIGHT - 0.1 : 0
    ),
    emissive:
      emissive &&
      lerpColor(
        emissive,
        selectionColor,
        isSelected ? SELECTION_WEIGHT : isHovered ? SELECTION_WEIGHT - 0.1 : 0
      ),
    emissiveIntensity:
      (emissiveIntensity || 0) + (isSelected ? 0.7 : isHovered ? 0.4 : 0),
  });
};
