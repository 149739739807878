import { templatesApiEndpoint } from "../../../api/templatesAPI";

export enum PocLayout {
  LeftColWidth = 350,
  InstructionsHeight = 150,
  ContentZIndex = 1,
}

export enum QuickstartConstants {
  Filename = "quickstart.tar.gz",
}

export const wgetCode = (
  apiEndpoint: string,
  filename: string,
  apiToken: string
): string => {
  return `wget ${apiEndpoint}/${filename} \\
  --header="X-Anjuna-Auth-Token:${apiToken}"`;
};

export const wgetQuickstartCode = (apiToken: string) => {
  const endpoint = `${templatesApiEndpoint()}/active`;

  return wgetCode(endpoint, QuickstartConstants.Filename, apiToken);
};

export const QUICKSTART_URL = "quickstart";
