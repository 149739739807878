import YAML from "yaml";
import type {
  PocScriptStepParameter,
  QuickstartDefinition,
  QuickstartDockerDefinition,
} from "./poc-types";
import { ConfigurableQuickstartOptions } from "./useQuickstartStore";
import { exposedPortsDataFormat } from "./quickstartDefinitions";

const replaceAllOccurrences = (str: string, find: string, replace: string) =>
  str.split(find).join(replace);

const removeAnyContainerPrefix = (str: string, containers: string[]) =>
  containers.reduce(
    (acc, cur) => replaceAllOccurrences(acc, `${cur}.`, ""),
    str
  );

export const yamlStringify = (obj: any) => {
  let result = YAML.stringify(obj);

  // Replace occurences of mode: "0644" with mode: 0644 and group: "1024" with group: 1024
  const modeRegex = new RegExp(`mode: "(\\d+)"`, "g");
  const modeMatches = result.matchAll(modeRegex);
  Array.from(modeMatches).forEach((match) => {
    result = replaceAllOccurrences(result, match[0], `mode: ${match[1]}`);
  });

  const groupRegex = new RegExp(`group: "(\\d+)"`, "g");
  const groupMatches = result.matchAll(groupRegex);
  Array.from(groupMatches).forEach((match) => {
    result = replaceAllOccurrences(result, match[0], `group: ${match[1]}`);
  });

  return result;
};

export const stepResultCode = (
  code: string,
  values: { [key: string]: any },
  containers: string[]
) => {
  let result = code;
  Object.entries(values).forEach(([key, value]) => {
    // match all occurrences of <<key>> in the code where key contains "=>"
    const processorToken = "=>";
    const regex = new RegExp(`<<${key}${processorToken}.*>>`, "g");
    const matches = result.matchAll(regex);

    for (const match of matches) {
      const matchStr = match[0];
      const token = replaceAllOccurrences(matchStr, "<<", "").split(">>")[0];
      const parameter = token.split(processorToken)[0];
      const processor = token.split(processorToken)[1];
      const parameterValues = values[parameter] as string[];
      const processorPrefix = processor.substring(0, processor.indexOf("$"));
      const processedToken = parameterValues.map(
        (cur) => `${processorPrefix}${cur}`
      );
      result = replaceAllOccurrences(
        result,
        matchStr,
        processedToken.join(" ")
      );
    }

    // Array index processor
    const indexRegex = new RegExp(`<<${key}\\[(\\d+)\\]>>`, "g");
    const indexMatches = result.matchAll(indexRegex);
    Array.from(indexMatches).forEach((match, index) => {
      result = replaceAllOccurrences(result, match[0], value[index]);
    });

    // Handles empty array values
    const valueRegex = new RegExp(`<<${key}>>`, "g");
    const valueMatches = result.matchAll(valueRegex);
    Array.from(valueMatches).forEach((match) => {
      const parsedValue = value;
      if (
        parsedValue &&
        parsedValue[key] &&
        Array.isArray(parsedValue[key]) &&
        parsedValue[key].length === 0
      )
        result = replaceAllOccurrences(result, `<<${key}>>\n`, "");
    });

    if (Array.isArray(value)) {
      const keyWithoutContainerPrefix = removeAnyContainerPrefix(
        key,
        containers
      );

      const isValidValue = (v: any) =>
        !!v && (typeof v === "object" || typeof v === "string" || !isNaN(v));
      let formattedValue = yamlStringify({
        [keyWithoutContainerPrefix]: value.filter(isValidValue),
      });

      if (
        keyWithoutContainerPrefix === "environment" &&
        Array.isArray(value) &&
        value.length > 0
      ) {
        // https://docs.anjuna.io/nitro/latest/getting_started/configuration_reference/nitro_enclave_config.html#environment_variables
        formattedValue =
          `environment:\n` +
          (value as { key: string; value: string }[])
            .filter((entry) => entry.key && entry.value)
            .map((entry) => `  - ${entry.key}=${entry.value}`)
            .join("\n");
        formattedValue += "\n";
      }

      result = replaceAllOccurrences(
        result,
        `<<${key}>>\n`,
        value.length === 0 ? "" : `${formattedValue}`
      );

      result = replaceAllOccurrences(
        result,
        `<<${key}>>`,
        value.length === 0 ? "" : `${formattedValue}`
      );
    } else {
      // Regular substitution
      const keyWithoutContainerPrefix = removeAnyContainerPrefix(
        key,
        containers
      );

      result = replaceAllOccurrences(result, `<<${key}>>`, value);

      result = replaceAllOccurrences(
        result,
        `<<${keyWithoutContainerPrefix}>>`,
        value
      );
    }
  });

  return result;
};

// Mirrored in functemplates/index.js
export function parameterDefaults(parameters: PocScriptStepParameter[]) {
  return parameters.reduce((acc, parameter) => {
    switch (parameter.kind) {
      case "numeric":
        return {
          ...acc,
          [parameter.id]: parameter.value,
        };
      case "checkbox":
        return {
          ...acc,
          [parameter.id]: parameter.defaultValue,
        };
      case "select": {
        if (parameter.defaultValue !== undefined) {
          return {
            ...acc,
            [parameter.id]: parameter.defaultValue,
          };
        }
        break;
      }
      case "list":
        return {
          ...acc,
          [parameter.id]: parameter.data.items,
        };

      default:
        break;
    }
    return acc;
  }, {});
}

export const tagsBasedVisibility = (
  filteredByTags: string[] | undefined,
  tags: string[]
) => {
  return (
    !filteredByTags ||
    filteredByTags.every((cur) =>
      cur.split("|").some((splitted) => tags.includes(splitted))
    )
  );
};

export const validateContainerId = (containerId: string) => {
  // \w represents any alphanumeric character and underscores, - represents hyphen
  const isValid = /^[\w-]+$/.test(containerId);

  return isValid;
};

export const validateDockerImage = (docker: QuickstartDockerDefinition) => {
  switch (docker.kind) {
    case "existing":
      return docker.image;
    case "provided":
      return docker.files.length > 0;
  }
};

export const validateContainerOptions = (
  options: ConfigurableQuickstartOptions
) => {
  const { min, max } = exposedPortsDataFormat;
  return options.exposedPorts
    ? options.exposedPorts.every((p: number | undefined) => {
        return p !== undefined && p >= min && p <= max;
      })
    : true;
};

export const validateQuickstart = (
  quickstart: Partial<QuickstartDefinition>
) => {
  if (!quickstart.title || !quickstart.descriptionMarkdown) {
    return false;
  }

  if (!quickstart.containers?.length) return false;

  if (
    !quickstart.containers.every(
      (container) =>
        validateContainerId(container.container) &&
        validateDockerImage(container.docker) &&
        validateContainerOptions(container.options)
    )
  ) {
    return false;
  }

  return true;
};
