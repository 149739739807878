// @ts-check
import React from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import logo from "../../assets/anjuna.svg";
import { makeStyles } from "@mui/styles";
import { AccountDetailsTable } from "./AccountDetails";
import { createOrgAPI, updateUserAccessAPI } from "../../api/userAccessAPI";
import { useAppStageState } from "../../context/AppStageContext";
import { LicenseType, ProductAccess, Tech } from "../../api/ApiTypes";
import { isUsingNewOrgsTable } from "../../shared/utils";

/**
 * @typedef {import("../../../amplify/backend/function/awsonboardwebapputillayer/opt").ProductAccess} ProductAccess
 */

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1050,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const useStyles = makeStyles({
  software: {
    fontSize: 16,
    fontWeight: 700,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  expiration: {
    fontSize: 16,
    fontWeight: 400,
    "&.warning": {
      color: "#EF9D0A",
    },
    "&.danger": {
      color: "#A91B12",
      fontWeight: 700,
    },
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    color: "#A3A3A3",
    fontFamily: "Montserrat Medium",
    fontSize: 16,
  },
  value: {
    fontFamily: "Montserrat Medium",
    fontSize: 16,
  },
  button: {
    minWidth: 120,
    marginRight: 8,
  },
  logo: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: 150,
    height: 34,
    minHeight: 34,
    marginTop: 16,
    marginBottom: 4,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: 400,
    textAlign: "left",
  },
  domain: {
    textAlign: "left",
    fontWeight: 600,
    fontSize: 20,
  },
  licenseEmail: {
    fontSize: 20,
    fontWeight: 400,
  },
  helperText: {
    fontSize: 16,
  },
});

type OrgAccessOptions = {
  companyName: string;
  access: ProductAccess[];
  licenseEmail?: string;
  templatesAccess: boolean;
  prereleasesAccess: boolean;
  freeTrialAccess: boolean;
};

/**
 * @typedef {{ handleClose: (changes?: { companyName: string, access: ProductAccess[], domain: string, licenseEmail?: string, templatesAccess: boolean }) => void, email: string, companyName: string, orgDomain: string, access: ProductAccess[], licenseEmail?: string, templatesAccess?: boolean }} Props
 */
type Props = {
  handleClose: (
    changes?: {
      domain?: string;
    } & OrgAccessOptions
  ) => void;
  email: string;
  id: string;
} & OrgAccessOptions;

const ModalContent = React.forwardRef<any, Props>((props, ref) => {
  const {
    handleClose,
    access: accessIn,
    id,
    email,
    companyName: companyNameIn,
    licenseEmail: licenseEmailIn,
    templatesAccess: templatesAccessIn,
    prereleasesAccess: prereleasesAccessIn,
    freeTrialAccess: freeTrialAccessIn,
  } = props;

  const classes = useStyles();

  /**
   * @type {[ProductAccess[], Function]}
   */
  const [access, setAccess] = React.useState(accessIn);

  const [companyName, setCompanyName] = React.useState(companyNameIn);
  const [orgDomain, setDomain] = React.useState(
    isUsingNewOrgsTable() ? "" : id
  );
  const [error, setError] = React.useState("");
  const [companyFormError, setCompanyFormError] = React.useState("");
  const [domainFormError, setDomainFormError] = React.useState("");
  const [status, setStatus] = React.useState("idle");
  const [licenseEmail, setLicenseEmail] = React.useState(licenseEmailIn);
  const [templatesAccess, setTemplatesAccess] = React.useState(
    templatesAccessIn || false
  );
  const [prereleasesAccess, setPrereleasesAccess] = React.useState(
    prereleasesAccessIn || false
  );
  const [freeTrialAccess, setFreeTrialAccess] = React.useState(
    freeTrialAccessIn || false
  );
  const [licenseAccountEmailError, setLicenseAccountEmailError] =
    React.useState(false);

  const validateLicenseAccountEmail = (accountEmail: string) => {
    const name = accountEmail.substring(0, accountEmail.indexOf("@"));
    const domain = accountEmail.substring(accountEmail.indexOf("@") + 1);
    return !accountEmail || (name.length && domain.indexOf(".") >= 0);
  };

  const onExpiryEdited = React.useCallback(
    (type: Tech, endsAt: string) => {
      setAccess(
        access.map((cur) => (cur.type === type ? { ...cur, endsAt } : cur))
      );
    },
    [access]
  );

  const onLicenseTypeChanged = React.useCallback(
    (type: Tech, licenseType: LicenseType) => {
      setAccess(
        access.map((cur) => (cur.type === type ? { ...cur, licenseType } : cur))
      );
    },
    [access]
  );

  const onCompanyNameChanged = React.useCallback((newName: string) => {
    newName && setCompanyFormError("");
    setCompanyName(newName);
  }, []);

  const onDomainChanged = React.useCallback((newDomain: string) => {
    newDomain && setDomainFormError("");
    setDomain(newDomain);
  }, []);

  const onLicenseEmailChanged = React.useCallback((newLicenseEmail: string) => {
    setLicenseEmail(newLicenseEmail);
  }, []);

  const toggleTemplatesAccess = React.useCallback(() => {
    setTemplatesAccess(!templatesAccess);
  }, [templatesAccess]);

  const togglePrereleasesAccess = React.useCallback(() => {
    setPrereleasesAccess(!prereleasesAccess);
  }, [prereleasesAccess]);

  const toggleFreeTrialAccess = React.useCallback(() => {
    setFreeTrialAccess(!freeTrialAccess);
  }, [freeTrialAccess]);

  const onApply = React.useCallback(() => {
    if (!companyName) {
      setCompanyFormError("Please enter a company name");
    }

    if (!isUsingNewOrgsTable() && !orgDomain) {
      setDomainFormError("Please enter a valid email domain");
    }

    if ((!isUsingNewOrgsTable() && !orgDomain) || !companyName) return;

    setStatus("updating");

    let apiCall;

    if (isUsingNewOrgsTable()) {
      apiCall = id
        ? updateUserAccessAPI(
            {
              email: email,
              newAccess: access,
              newName: companyName,
              newLicenseEmail: licenseEmail,
              newTemplatesAccess: templatesAccess,
              newPrereleasesAccess: prereleasesAccess,
              newFreeTrialAccess: freeTrialAccess,
            },
            id
          )
        : createOrgAPI({
            email: email,
            newAccess: access,
            newName: companyName,
            newLicenseEmail: licenseEmail,
            newTemplatesAccess: templatesAccess,
            newPrereleasesAccess: prereleasesAccess,
            newFreeTrialAccess: freeTrialAccess,
          });
    } else {
      apiCall = id
        ? updateUserAccessAPI({
            email: email,
            orgDomain: id || orgDomain,
            newAccess: access,
            newName: companyName,
            newDomain: orgDomain,
            newLicenseEmail: licenseEmail,
            newTemplatesAccess: templatesAccess,
            newPrereleasesAccess: prereleasesAccess,
            newFreeTrialAccess: freeTrialAccess,
          })
        : createOrgAPI({
            email: email,
            orgDomain: id || orgDomain,
            newAccess: access,
            newName: companyName,
            newDomain: orgDomain,
            newLicenseEmail: licenseEmail,
            newTemplatesAccess: templatesAccess,
            newPrereleasesAccess: prereleasesAccess,
            newFreeTrialAccess: freeTrialAccess,
          });
    }

    apiCall
      .then((result) => {
        if (result.type === "error") {
          setError(result.reason);
        } else {
          handleClose({
            access,
            companyName,
            domain: orgDomain,
            licenseEmail,
            templatesAccess,
            prereleasesAccess,
            freeTrialAccess,
          });
        }
      })
      .finally(() => {
        setStatus("idle");
      });
  }, [
    companyName,
    id,
    orgDomain,
    email,
    access,
    licenseEmail,
    templatesAccess,
    prereleasesAccess,
    freeTrialAccess,
    handleClose,
  ]);

  const onCancel = React.useCallback(() => {
    handleClose();
  }, [handleClose]);

  const errorAlert = error ? (
    <Alert style={{ marginTop: 16 }} severity={"error"}>
      {id
        ? `Updating org access failed: ${error}`
        : `Create organization failed. ${error}`}
    </Alert>
  ) : null;

  return (
    <Box sx={modalStyles} component="div">
      <div className="flex-row-container flex-centered">
        <div
          className="flex-col-container"
          style={{ flex: 0.6, marginRight: 48 }}
        >
          <TextField
            required
            helperText={companyFormError || ""}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            error={!!companyFormError}
            placeholder="Company name"
            name="family_name"
            size="small"
            InputProps={{ className: classes.title }}
            id="modal-modal-title"
            onChange={(e) => {
              onCompanyNameChanged(e.target.value);
            }}
            value={companyName}
            style={{ marginBottom: 16 }}
          />
          {isUsingNewOrgsTable() ? null : (
            <TextField
              required
              helperText={domainFormError || ""}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              error={!!domainFormError}
              placeholder="@email.com"
              size="small"
              InputProps={{ className: classes.domain }}
              onChange={(e) => {
                onDomainChanged(e.target.value);
              }}
              value={orgDomain ? `@${orgDomain.replaceAll(/@/g, "")}` : ""}
            />
          )}
          <Typography
            style={{
              textAlign: "left",
              marginTop: 16,
              marginBottom: 8,
              fontSize: 16,
            }}
          >
            Email address to associate with any licenses generated by this
            organization. If left blank, the email address of the current logged
            in user at the time the license is downloaded will be used.
          </Typography>
          <TextField
            placeholder="License account email"
            name="license_mail_name"
            size="small"
            id="license-email-title"
            InputProps={{ className: classes.licenseEmail }}
            onChange={(e) => {
              onLicenseEmailChanged(e.target.value);
            }}
            onBlur={(e) => {
              setLicenseAccountEmailError(
                !validateLicenseAccountEmail(e.target.value)
              );
            }}
            value={licenseEmail}
            helperText={
              licenseAccountEmailError
                ? "Please enter a valid email address."
                : licenseEmailIn !== licenseEmail
                ? "You need to refresh the page to generate a license with the new email"
                : undefined
            }
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            error={!!licenseAccountEmailError}
            fullWidth
          />
          <FormControlLabel
            style={{ display: "flex", margin: "20px 0 0 -9px" }}
            control={
              <Checkbox
                checked={templatesAccess}
                onChange={toggleTemplatesAccess}
              />
            }
            label="Quickstarts Access"
          />
          <FormControlLabel
            style={{ display: "flex", margin: "20px 0 0 -9px" }}
            control={
              <Checkbox
                checked={prereleasesAccess}
                onChange={togglePrereleasesAccess}
              />
            }
            label="Access to pre-release software"
          />
          <FormControlLabel
            style={{ display: "flex", margin: "20px 0 0 -9px" }}
            control={
              <Checkbox
                checked={freeTrialAccess}
                onChange={toggleFreeTrialAccess}
              />
            }
            label="Free trial access to Anjuna products"
          />
        </div>
        <div style={{ flex: 1 }}>
          <AccountDetailsTable
            access={access}
            onExpiryEdited={onExpiryEdited}
            onLicenseTypeChanged={onLicenseTypeChanged}
          />
        </div>
      </div>
      <div className={classes.flexRow} style={{ marginTop: 24 }}>
        <div style={{ flex: 1 }} />
        <Button
          onClick={onApply}
          className={classes.button}
          variant="contained"
          title="Close"
          disabled={
            status !== "idle" ||
            !!companyFormError ||
            !!licenseAccountEmailError
          }
        >
          {id ? "Apply" : "Create"}
          {status === "updating" ? (
            <CircularProgress
              data-testid="org-access-progress"
              size={20}
              style={{ marginLeft: 8 }}
            />
          ) : null}
        </Button>
        <Button
          className={classes.button}
          variant="outlined"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
      {errorAlert}
    </Box>
  );
});

export const EditOrgAccess = (props: Omit<Props, "email">) => {
  const { stage } = useAppStageState();

  if (stage.type !== "dashboard") return null;

  return (
    <div>
      <Modal
        open
        onClose={() => props.handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent email={stage.user.attributes.email} {...props} />
      </Modal>
    </div>
  );
};
