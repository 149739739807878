// @ts-check
import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { fetchDownloads } from "./fetchDownloads";
import { CircularProgress, Fade, Link, Typography, Stack } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import { downloadAPI, downloadApiEndpoint } from "../../api/downloadAPI";
import { useLocation } from "react-router";
import { CLIDownloadButton } from "./CLIDownloadButton";
import { LicenseViaAPIButton } from "./LicenseViaAPIButton";
import { useAppStage } from "../../context/AppStageContext";
import { ShowChecksumsButton } from "./ShowChecksumsButton";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { techDisplayName } from "./techDisplayName";
import { ScrollTo } from "../Components/ScrollTo";
import { DownloadButton } from "./DownloadButton";
import { DownloadInfo, ProductAccess, Tech } from "../../api/ApiTypes";

/**
 * @param {Omit<import("../../api/downloadAPI").DownloadInfo, 'details'>} param0
 * @param {import("../../api/createAccountAPI").ProductAccess['type']} type
 * @returns {import("../../api/downloadAPI").DownloadInfo}
 */
function createData(
  {
    version,
    build,
    releaseDate,
    releaseNotesUrl,
    docsUrl,
    artifacts,
    licenseRequired,
    isPrerelease,
  }: DownloadInfo,
  type: Tech
) {
  return {
    type,
    version,
    build,
    releaseDate,
    releaseNotesUrl,
    docsUrl,
    artifacts,
    licenseRequired,
    isPrerelease,
  };
}

const useStyles = makeStyles({
  licenseInfoContainer: {
    marginBottom: 16,
  },
  licensePill: {
    fontWeight: 600,
    border: "1px solid #8C8C8C",
    width: 150,
    height: 24,
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&.required": {
      borderColor: "#44D1A2",
      background: "#44D1A2",
    },
  },
  licensePillText: {
    fontSize: 12,
    textTransform: "uppercase",
    color: "#8C8C8C",
    "&.required": {
      color: "#fff",
    },
  },
  prereleasePill: {
    fontWeight: 600,
    border: "1px solid #8C8C8C",
    width: 150,
    height: 24,
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#A244D1",
    background: "#A244D1",
  },
  prereleasePillText: {
    fontSize: 12,
    textTransform: "uppercase",
    color: "#fff",
  },
  linkTooltip: {
    margin: 0,
    background: "#F9F9F9",
    padding: `32px 28px`,
    width: 500,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tooltipTitle: {
    fontSize: 16,
    marginBottom: 8,
    fontWeight: 700,
  },
  tooltipContent: {
    marginBottom: 12,
    fontSize: 14,
    "&.small": {
      marginLeft: 8,
    },
  },
  tooltipLink: {
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: "bold",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  linkActions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  documentation: {
    margin: `8px 0`,
  },
});

enum ColumnWidths {
  description = "35%",
  installer = "35%",
  size = "10%",
  hash = "10%",
  api = "10%",
}

function Row(props: {
  row: DownloadInfo;
  latest: boolean;
  licenseEnabled: boolean;
  prereleasesVisible?: boolean;
}) {
  const { row, latest, licenseEnabled, prereleasesVisible } = props;
  const location = useLocation();
  const classes = useStyles();
  /**
   * @type {[Boolean | undefined, Function]}
   */
  const [userExpanded, setUserExpanded] = React.useState<boolean | undefined>(
    undefined
  );
  const expandedFromRoute = location.pathname === `/${row.type}/${row.version}`;
  const open = userExpanded !== undefined ? userExpanded : expandedFromRoute;
  const [{ stage }, dispatch] = useAppStage();

  const apiToken = stage.type === "dashboard" ? stage.apiToken : undefined;

  const handleOpenSettings = React.useCallback(() => {
    dispatch({
      type: "open modal",
      modalId: "api settings",
    });
  }, [dispatch]);

  // For prior to October 23 which is when docsUrl is added into the database for new products.
  const legacyDocsUrl = `https://docs.anjuna.io/${
    row.type === "azsev" || row.type === "gcp" ? "sev" : row.type
  }/${!!latest ? "latest" : `v${row.version}`}/index.html`;

  const docsUrl = row.docsUrl
    ? !!latest
      ? row.docsUrl.replace(`v${row.version}`, "latest")
      : row.docsUrl
    : legacyDocsUrl;

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: 0 } }}>
        <TableCell component="th" scope="row" style={{ fontSize: 15 }}>
          {row.version} {latest ? " (Latest)" : ""}
        </TableCell>
        <TableCell style={{ fontSize: 15 }}>{row.releaseDate}</TableCell>
        <TableCell style={{ fontSize: 15 }}>
          <Link target="_blank" rel="noopener" href={row.releaseNotesUrl}>
            Release notes (v{row.version})
          </Link>
        </TableCell>
        {licenseEnabled ? (
          <TableCell style={{ fontSize: 15 }}>
            <div
              className={clsx(classes.licensePill, {
                required: !!row.licenseRequired,
              })}
            >
              <Typography
                className={clsx(classes.licensePillText, {
                  required: !!row.licenseRequired,
                })}
              >
                {!!row.licenseRequired
                  ? "License required"
                  : "License not required"}
              </Typography>
            </div>
          </TableCell>
        ) : null}
        {prereleasesVisible ? (
          <TableCell style={{ fontSize: 15 }}>
            {row.isPrerelease ? (
              <div className={classes.prereleasePill}>
                <Typography className={classes.prereleasePillText}>
                  Prerelease
                </Typography>
              </div>
            ) : null}
          </TableCell>
        ) : null}
        <TableCell>
          <IconButton
            aria-label="expand row"
            title="View downloads for this version"
            size="small"
            onClick={() => setUserExpanded(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} component="div">
              {/* <Typography gutterBottom component="div">
                To get the Anjuna Runtime installer, download it from the
                following list, choosing the operating system you plan to use:
              </Typography> */}
              <Table size="small" aria-label="artifact">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: ColumnWidths.description }}>
                      <Typography fontSize="smaller">Description</Typography>
                    </TableCell>
                    <TableCell sx={{ width: ColumnWidths.installer }}>
                      <Typography fontSize="smaller">Installer</Typography>
                    </TableCell>
                    <TableCell sx={{ width: ColumnWidths.size }}>
                      <Typography fontSize="smaller">Size</Typography>
                    </TableCell>
                    <TableCell sx={{ width: ColumnWidths.hash }}>
                      <Typography fontSize="smaller">Hash</Typography>
                    </TableCell>
                    <TableCell sx={{ width: ColumnWidths.api }}>
                      <Typography fontSize="smaller" align="center">
                        API
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.artifacts.map((artifact, artifactIndex) => (
                    <TableRow key={artifact.description}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ fontSize: 16 }}
                        sx={{ width: ColumnWidths.description }}
                      >
                        {artifact.description}
                      </TableCell>
                      <TableCell sx={{ width: ColumnWidths.installer }}>
                        <DownloadButton
                          filename={artifact.filename}
                          downloadAPI={() =>
                            downloadAPI({
                              apiToken: apiToken!,
                              filename: artifact.filename,
                            })
                          }
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ fontSize: 16 }}
                        sx={{ width: ColumnWidths.size }}
                      >
                        {Math.round(
                          (10 * artifact.contentLength) / 1024 / 1024
                        ) / 10}{" "}
                        MB
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: ColumnWidths.hash }}
                      >
                        <ShowChecksumsButton
                          md5={artifact.md5}
                          sha256={artifact.sha256}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ width: ColumnWidths.api }}
                      >
                        {apiToken ? (
                          <CLIDownloadButton
                            apiEndpoint={`${downloadApiEndpoint()}/v1/releases`}
                            apiToken={apiToken}
                            filename={artifact.filename}
                            verify={{
                              version: row.version,
                              artifactIndex: artifactIndex,
                              artifactInfo: artifact,
                              type: row.type,
                            }}
                            onOpenSettings={handleOpenSettings}
                            kind={"icon"}
                          />
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Typography fontSize={14} className={classes.documentation}>
                View the{" "}
                <Link target="_blank" href={docsUrl}>
                  documentation
                </Link>{" "}
                for more information.
              </Typography>
              {expandedFromRoute ? <ScrollTo /> : null}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    version: PropTypes.string.isRequired,
    releaseDate: PropTypes.string.isRequired,
    releaseNotesUrl: PropTypes.string.isRequired,
    docsUrl: PropTypes.string,
    artifacts: PropTypes.arrayOf(
      PropTypes.shape({
        filename: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export const DownloadsTable = ({
  tech,
  productAccess,
}: {
  tech: Tech;
  productAccess: ProductAccess;
}) => {
  const [status, setStatus] = React.useState("loading");

  /**
   * @type {[import("../../api/downloadAPI").DownloadInfo[], Function]} Loading
   */
  const [downloads, setDownloads] = React.useState<DownloadInfo[]>([]);

  const [licenseEnabled, setLicenseEnabled] = React.useState(false);
  const [prereleasesVisible, setPrereleasesVisible] = React.useState(false);

  const classes = useStyles();

  const [{ stage }, dispatch] = useAppStage();

  const apiToken = stage.type === "dashboard" ? stage.apiToken : undefined;

  React.useEffect(() => {
    (async () => {
      const { products } = await fetchDownloads(tech, apiToken);

      setStatus("loaded");

      if (JSON.stringify(products) !== JSON.stringify(downloads)) {
        setDownloads(products);
        if (products.some((info) => info.licenseRequired))
          setLicenseEnabled(true);
        if (products.some((info) => info.isPrerelease))
          setPrereleasesVisible(true);
      }
    })();
  }, [apiToken, downloads, tech]);

  const rows = downloads.map((downloadInfo) => createData(downloadInfo, tech));

  const handleOpenSettings = React.useCallback(() => {
    dispatch({
      type: "open modal",
      modalId: "licence via api",
    });
  }, [dispatch]);

  return (
    <>
      {licenseEnabled && productAccess.licenseYaml ? (
        <div className={classes.licenseInfoContainer}>
          <Typography>
            To download your {techDisplayName(tech)} license, please click here
          </Typography>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Link
              href={`data:text/plain;charset=utf-8,${encodeURIComponent(
                productAccess.licenseYaml
              )}`}
              download="license.yaml"
            >
              Download License
            </Link>
            {apiToken ? (
              <LicenseViaAPIButton
                apiToken={apiToken}
                type={tech}
                onOpenSettings={handleOpenSettings}
              />
            ) : null}
          </Stack>
          <Typography>
            Follow these{" "}
            <Link
              target="_blank"
              href={`https://docs.anjuna.io/${tech}/latest/getting_started/getting_the_runtime/license.html#_use_your_license`}
            >
              instructions
            </Link>{" "}
            to install the license.
          </Typography>
        </div>
      ) : null}
      {status === "loading" ? (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1 }} />
          Getting available downloads...
          <div style={{ flexBasis: 25 }} />
          <CircularProgress size={20} />
          <div style={{ flex: 1 }} />
        </div>
      ) : (
        <Fade in>
          <TableContainer>
            <Table aria-label="downloads table" data-testid={"downloads-table"}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography fontSize={14}>Version</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={14}>Release Date</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={14}>Release Notes</Typography>
                  </TableCell>
                  {!!licenseEnabled && (
                    <TableCell>
                      <Typography fontSize={14}>License Required</Typography>
                    </TableCell>
                  )}
                  {!!prereleasesVisible && (
                    <TableCell>
                      <Typography fontSize={14}>Release Status</Typography>
                    </TableCell>
                  )}
                  <TableCell sx={{ width: 40 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <Row
                    key={row.version}
                    row={row}
                    latest={index === 0}
                    licenseEnabled={licenseEnabled}
                    prereleasesVisible={prereleasesVisible}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Fade>
      )}
    </>
  );
};
