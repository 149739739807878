import React from "react";
import ReactMarkdown from "react-markdown";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import rangeParser from "parse-numeric-range";
import { oneDark } from "react-syntax-highlighter/dist/cjs/styles/prism";
import bash from "react-syntax-highlighter/dist/cjs/languages/prism/bash";
// import yaml from "react-syntax-highlighter/dist/cjs/languages/prism/yaml";

SyntaxHighlighter.registerLanguage("bash", bash);
// SyntaxHighlighter.registerLanguage("yaml", yaml);
const SyntaxHighlighterAny = SyntaxHighlighter as any;

interface MarkdownProps {
  id: string;
  markdown: string;
  style?: React.CSSProperties;
  className?: string;
}

export const Markdown: React.FC<MarkdownProps> = ({
  id,
  markdown,
  style,
  className,
}) => {
  const syntaxTheme = oneDark;

  const MarkdownComponents: object = {
    code({ node, inline, className: curClassName, ...props }: any) {
      const hasLang = /language-(\w+)/.exec(curClassName || "");
      const hasMeta = node?.data?.meta;

      const applyHighlights: object = (highlights: number) => {
        if (hasMeta) {
          const RE = /{([\d,-]+)}/;
          const metadata = node.data.meta?.replace(/\s/g, "");
          const strlineNumbers = RE?.test(metadata)
            ? RE?.exec!(metadata)![1]
            : "0";
          const highlightLines = rangeParser(strlineNumbers);
          const highlight = highlightLines;
          const data: string | null = highlight.includes(highlights)
            ? "highlight"
            : null;
          return { data };
        } else {
          return {};
        }
      };

      return hasLang ? (
        <SyntaxHighlighterAny
          style={syntaxTheme}
          language={hasLang[1]}
          PreTag="div"
          className="codeStyle"
          showLineNumbers={true}
          wrapLines={hasMeta}
          useInlineStyles={true}
          lineProps={applyHighlights}
        >
          {props.children}
        </SyntaxHighlighterAny>
      ) : (
        <code className={className} {...props} />
      );
    },
  };

  return (
    <div
      className={`flex-row-container ${className} editable-markdown-container`}
      style={{
        width: "100%",
        position: "relative",
        overflowX: "auto",
        ...style,
      }}
    >
      <div style={{ display: "block" }} data-testid={`Markdown-${id}`}>
        <ReactMarkdown
          className="ReactMarkdown"
          components={MarkdownComponents}
        >
          {markdown}
        </ReactMarkdown>
      </div>
    </div>
  );
};
