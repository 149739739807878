import { getReleasesAPI } from "../../api/releasesAPI";

/**
 * @returns {Promise<{ products: DownloadInfo[] }>}
 */
export const fetchDownloads = async (type, apiToken) => {
  const response = await getReleasesAPI({ type, apiToken });

  if (response.type === "success") return response;
  else {
    console.error(response.message);
    return { products: [] };
  }
};
