import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useAppStage } from "../../context/AppStageContext";

const SIZE = 40;

const useStyles = makeStyles((theme) => ({
  accountIcon: {
    width: SIZE,
    height: SIZE,
    lineHeight: `${SIZE}px`,
    borderRadius: "100%",
    textTransform: "uppercase",
    textAlign: "center",
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
}));

export const AccountIcon = (props) => {
  const classes = useStyles();

  const [{ stage }] = useAppStage();

  const { attributes } = stage.user;

  const display = (attributes.name || "").split(" ").map((part) => part[0]);

  return (
    <Grid item container alignItems="center" direction="row">
      <Grid item>
        <Typography
          className={classes.accountIcon}
          variant="h3"
          style={{ fontSize: 17 }}
        >
          {display}
        </Typography>
      </Grid>
      <KeyboardArrowDownIcon sx={{ marginLeft: 0.5 }} />
    </Grid>
  );
};
