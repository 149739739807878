// @ts-check
import React from "react";
import {
  Alert,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { retrieveOrgsListAPI } from "../../api/userAccessAPI";
import {
  accountExpirationMessage,
  AccountExpiryThresholds,
  numberOfDaysUntilAccountExpires,
} from "../../components/account/accountUtil";
import clsx from "clsx";
import { EditOrgAccess } from "./EditOrgAccess";
import { licenseTypeDisplayName, techDisplayName } from "./techDisplayName";
import {
  SortableTableCell,
  scrollableTableContainerStyle,
} from "./SortableTableCell";
import { OrgInfo, SortOrder, TECH_TYPES_UI, Tech } from "../../api/ApiTypes";
import { HandleInviteUsersModalClose, InviteUsers } from "./InviteUsers";
import { isUsingNewOrgsTable } from "../../shared/utils";
import { UserPlusOrg, usersReportAPI } from "../../api/usersReportAPI";

const useStyles = makeStyles({
  label: {
    fontSize: 14,
    fontWeight: 700,
  },
  domain: {
    fontSize: 14,
    maxWidth: 240,
  },
  value: {
    fontFamily: "Montserrat Medium",
    fontSize: 14,
  },
  expiration: {
    fontSize: 14,
    fontWeight: 400,
    "&.warning": {
      color: "#EF9D0A",
    },
    "&.danger": {
      color: "#A91B12",
      fontWeight: 700,
    },
    "&.success": {
      fontWeight: 400,
    },
  },
  cellContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  message: {},
});

const TechAccessCell = (props: { message: string; endsAt?: string }) => {
  const classes = useStyles();

  const { message, endsAt } = props;

  const daysLeft = numberOfDaysUntilAccountExpires(endsAt);

  const expirationClass =
    daysLeft <= AccountExpiryThresholds.Danger
      ? "danger"
      : daysLeft <= AccountExpiryThresholds.Warn
      ? "warning"
      : "success";

  return (
    <TableCell className={clsx(classes.expiration, expirationClass)}>
      <div className={classes.cellContent}>
        <span className={classes.message}> {message}</span>
      </div>
    </TableCell>
  );
};

const OrgsTable = (props: { orgs: OrgInfo[]; users: UserPlusOrg[] }) => {
  const { orgs, users } = props;

  const classes = useStyles();

  const [editingOrg, setEditingOrg] = React.useState<string | undefined>(
    undefined
  );

  const [orgToInviteUsers, setOrgToInviteUsers] = React.useState<
    OrgInfo | undefined
  >(undefined);

  const onEditClose = React.useCallback(
    (changes?: Omit<OrgInfo, "name"> & { companyName: string }) => {
      if (changes) {
        const orgToUpdate = orgs.find(
          (cur) =>
            (isUsingNewOrgsTable() ? cur.orgId : cur.domain) === editingOrg
        );
        if (orgToUpdate) {
          orgToUpdate.access = changes.access;
          orgToUpdate.name = changes.companyName;
          orgToUpdate.licenseEmail = changes.licenseEmail;
          orgToUpdate.templatesAccess = changes.templatesAccess;
          orgToUpdate.prereleasesAccess = changes.prereleasesAccess;
          orgToUpdate.freeTrialAccess = changes.freeTrialAccess;

          if (!isUsingNewOrgsTable()) orgToUpdate.domain = changes.domain;
        }
      }

      setEditingOrg(undefined);
    },
    [editingOrg, orgs]
  );

  const onInviteClose = React.useCallback(
    (changes?: HandleInviteUsersModalClose) => setOrgToInviteUsers(undefined),
    []
  );

  const ORDERBY_STORAGE_KEY = "arc-orgs-orderBy";
  const lastOrderBy = window.localStorage.getItem(ORDERBY_STORAGE_KEY) || "";

  const ORDER_STORAGE_KEY = "arc-orgs-order";
  /**
   * @type {any}
   */
  const lastOrder: SortOrder =
    (window.localStorage.getItem(ORDER_STORAGE_KEY) as SortOrder) || "asc";

  const [order, setOrder] = React.useState<SortOrder>(lastOrder);
  const [orderBy, setOrderBy] = React.useState(lastOrderBy);

  const handleRequestSort = React.useCallback(
    (property: string) => {
      const isAsc = orderBy === property && order === "asc";
      const newOrder = isAsc ? "desc" : "asc";
      setOrder(newOrder);
      setOrderBy(property);

      window.localStorage.setItem(ORDERBY_STORAGE_KEY, property);
      window.localStorage.setItem(ORDER_STORAGE_KEY, newOrder);
    },
    [order, orderBy]
  );

  const {
    templatesAccess,
    prereleasesAccess,
    freeTrialAccess,
    access,
    name: orgName,
    licenseEmail,
  } = orgs.find(
    (cur) => (isUsingNewOrgsTable() ? cur.orgId : cur.domain) === editingOrg
  ) || {};

  /**
   *
   * @param {import("./AccountDetails").ProductAccess['endsAt'] | undefined} a
   * @param {import("./AccountDetails").ProductAccess['endsAt'] | undefined} b
   */
  const sortByAccess = (a?: string, b?: string) => {
    const LARGE_NUM = 1e6;
    if (a === "expired" && b === "expired") return 0;
    if (!a || a === "expired") return LARGE_NUM;
    if (!b || b === "expired") return -LARGE_NUM;

    return new Date(a).getTime() - new Date(b).getTime();
  };

  const renderTechCell = (tech: Tech) => {
    return (
      <SortableTableCell
        id={tech}
        key={tech}
        order={order}
        orderBy={orderBy}
        onSortClick={handleRequestSort}
      >
        <Typography className={classes.label} fontSize="smaller">
          {techDisplayName(tech)}
        </Typography>
      </SortableTableCell>
    );
  };

  return (
    <TableContainer style={{ height: "100%" }}>
      <Table aria-label="orgs access table">
        <TableHead>
          <TableRow>
            <SortableTableCell
              id={"company"}
              order={order}
              orderBy={orderBy}
              onSortClick={handleRequestSort}
            >
              <Typography className={classes.label} fontSize="smaller">
                Company
              </Typography>
            </SortableTableCell>
            <>
              {isUsingNewOrgsTable() ? null : (
                <SortableTableCell
                  id={"domain"}
                  order={order}
                  orderBy={orderBy}
                  onSortClick={handleRequestSort}
                >
                  <Typography className={classes.label} fontSize="smaller">
                    Email
                  </Typography>
                </SortableTableCell>
              )}
            </>
            <SortableTableCell
              id={"templatesAccess"}
              order={order}
              orderBy={orderBy}
              onSortClick={handleRequestSort}
            >
              <Typography className={classes.label} fontSize="smaller">
                Quickstarts Access
              </Typography>
            </SortableTableCell>
            {TECH_TYPES_UI.map((tech) => renderTechCell(tech))}
            <TableCell>
              <Typography className={classes.label} fontSize="smaller">
                Edit
              </Typography>
            </TableCell>
            <>
              <TableCell>
                <Typography className={classes.label} fontSize="smaller">
                  Unassigned Users
                </Typography>
              </TableCell>
            </>
          </TableRow>
        </TableHead>
        {access && editingOrg ? (
          <EditOrgAccess
            id={editingOrg}
            access={access}
            companyName={orgName || ""}
            handleClose={onEditClose}
            licenseEmail={licenseEmail}
            templatesAccess={templatesAccess || false}
            prereleasesAccess={prereleasesAccess || false}
            freeTrialAccess={freeTrialAccess || false}
          />
        ) : null}
        {orgToInviteUsers ? (
          <InviteUsers
            orgs={orgs}
            selectedOrg={orgToInviteUsers}
            handleClose={onInviteClose}
            users={users}
          />
        ) : null}
        <TableBody style={scrollableTableContainerStyle}>
          {orgs
            .sort((a, b) => {
              if (orderBy === "company") {
                const first = order === "asc" ? a : b;
                const second = order === "asc" ? b : a;
                return (first.name || "").localeCompare(second.name || "");
              } else if (!isUsingNewOrgsTable() && orderBy === "domain") {
                const first = order === "asc" ? a : b;
                const second = order === "asc" ? b : a;
                return (first.domain || "").localeCompare(second.domain || "");
              } else if (orderBy === "templatesAccess") {
                const first = order === "asc" ? a : b;
                const second = order === "asc" ? b : a;
                return (
                  Number(second.templatesAccess || false) -
                  Number(first.templatesAccess || false)
                );
              } else {
                const first = order === "asc" ? a : b;
                const second = order === "asc" ? b : a;
                return sortByAccess(
                  first.access.find((c) => c.type === orderBy)?.endsAt,
                  second.access.find((c) => c.type === orderBy)?.endsAt
                );
              }
            })
            .map((org) => {
              const {
                orgId,
                domain,
                name,
                access: curAccess,
                templatesAccess: curTemplatesAccess,
              } = org;

              const renderTechAccessCell = (type: Tech) => {
                const tech = curAccess.find((cur) => cur.type === type);

                const licenseType =
                  tech?.endsAt === "expired"
                    ? ""
                    : `${licenseTypeDisplayName(tech?.licenseType)} - `;

                const expireMessage = `${licenseType}${accountExpirationMessage(
                  tech?.endsAt || "expired"
                )}`;

                return (
                  <TechAccessCell
                    key={type}
                    message={expireMessage}
                    endsAt={tech?.endsAt || "expired"}
                  />
                );
              };

              return (
                <TableRow
                  key={`${isUsingNewOrgsTable() ? orgId : domain}-access`}
                  data-testid={`OrgsTable-row-${
                    isUsingNewOrgsTable() ? orgId : domain
                  }`}
                >
                  <TableCell>
                    <Typography className={classes.domain}>
                      {name || "(not set)"}
                    </Typography>
                  </TableCell>
                  <>
                    {isUsingNewOrgsTable() ? null : (
                      <TableCell style={{ width: 280 }}>
                        <Typography
                          className={classes.domain}
                          style={{ overflowWrap: "anywhere" }}
                        >
                          {domain}
                        </Typography>
                      </TableCell>
                    )}
                  </>

                  <TableCell>
                    <Typography className={classes.domain}>
                      {(curTemplatesAccess || false).toString()}
                    </Typography>
                  </TableCell>
                  {TECH_TYPES_UI.map((tech) => renderTechAccessCell(tech))}
                  <TableCell>
                    <IconButton
                      onClick={() =>
                        setEditingOrg(isUsingNewOrgsTable() ? orgId : domain)
                      }
                      data-testid={`OrgsTable-edit-${
                        isUsingNewOrgsTable() ? orgId : domain
                      }`}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <>
                    {isUsingNewOrgsTable() ? (
                      <TableCell>
                        <IconButton
                          onClick={() => setOrgToInviteUsers(org)}
                          data-testid={`OrgsTable-invite-${
                            isUsingNewOrgsTable() ? orgId : domain
                          }`}
                        >
                          <PersonAddIcon />
                        </IconButton>
                      </TableCell>
                    ) : null}
                  </>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const OrgsTableContainer = (props: { email: string; token: string }) => {
  const { email, token } = props;

  const [orgs, setOrgs] = React.useState<OrgInfo[]>([]);
  const [error, setError] = React.useState("");
  const [status, setStatus] = React.useState("idle");

  const [users, setUsers] = React.useState<UserPlusOrg[]>([]);
  const [userError, setUserError] = React.useState("");
  const [userStatus, setUserStatus] = React.useState("idle");

  React.useEffect(() => {
    setStatus("retrieving");

    retrieveOrgsListAPI({
      email: email,
    })
      .then((result) => {
        if (result.type === "success") {
          setOrgs(result.orgs);
        } else {
          setOrgs([]);
          setError(result.reason);
        }
      })
      .catch((e) => {
        console.warn(e);

        setError(e.message);
      })
      .finally(() => {
        setStatus("idle");
      });
  }, [email]);

  React.useEffect(() => {
    setUserStatus("retrieving users");

    usersReportAPI(token)
      .then((result) => {
        if (result.type === "success") {
          setUsers(result.report);
        } else {
          setUsers([]);
          setUserError(result.reason);
        }
      })
      .catch((e) => {
        console.warn(e);

        setUserError(e.message);
      })
      .finally(() => {
        setUserStatus("idle");
      });
  }, [token]);

  const userErrorAlert = userError ? (
    <Alert severity={"error"}>
      {`Retrieving users report data from server failed: ${userError}.`}
    </Alert>
  ) : null;

  const errorAlert = error ? (
    <Alert severity={"error"}>
      {`Retrieving access for organizations failed: ${error}.`}
    </Alert>
  ) : null;

  return status === "retrieving" || userStatus === "retrieving" ? (
    <CircularProgress sx={{ margin: 8 }} />
  ) : (
    <>
      {errorAlert}
      {userErrorAlert}
      <OrgsTable orgs={orgs} users={users} />
    </>
  );
};
